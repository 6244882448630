import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import React from 'react';

export const dialogFooter = (handleOnCancel, handleOnSave) => (
  <>
    <Button
      className="p-button-text" icon="pi pi-times" label="Cancel"
      onClick={handleOnCancel}
    />
    <Button
      className="p-button-text" icon="pi pi-check" label="Save"
      onClick={handleOnSave}
    />
  </>);

export const header = (handleOpenNew, handleSearchInput) => (
  <div className="table-header">
    <Button
      className="p-button-success mr-2" icon="pi pi-plus" label="New"
      onClick={handleOpenNew}
    />
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText onInput={handleSearchInput} placeholder="Search..." type="search" />
    </span>
  </div>
);

const OPTION_1 = 5;
const OPTION_2 = 10;
const OPTION_3 = 15;

export const rowsPerPageOptions = [OPTION_1, OPTION_2, OPTION_3];
