const PREFIX = 'beot';

class SessionStorage {
  static get (key) {
    return sessionStorage.getItem(`${PREFIX}${key}`);
  }

  static set (key, value) {
    sessionStorage.setItem(`${PREFIX}${key}`, value);
  }

  static remove (key) {
    sessionStorage.removeItem(key);
  }

  static destroy () {
    sessionStorage.clear();
  }
}

export default SessionStorage;
