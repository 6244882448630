import {createSelector} from 'reselect';
import get from 'lodash/get';

const authSelector = state => state.auth;

export const getIsAuthenticated = createSelector(
  [authSelector],
  auth => get(auth, 'isAuthenticated', false)
);

export const getAccessToken = createSelector(
  [authSelector],
  auth => get(auth, 'accessToken', '')
);

export const getFirstLogin = createSelector(
  [authSelector],
  auth => get(auth, 'firstLogin', '')
);
