/* eslint-disable id-length */
import XLSX from 'sheetjs-style';

export const showSuccessfulToast = (toast, message) => {
  toast.current.show({
    severity : 'success',
    summary  : 'Successful',
    detail   : message,
    life     : 3000
  });
};
export const showUnsuccessfulToast = (toast, message) => {
  toast.current.show({
    severity : 'error',
    summary  : 'Failed',
    detail   : message,
    life     : 5000
  });
};

export const getRecentYears = currentYear => {
  const yearsCount = 20;
  const years = Array.from({length : yearsCount}, (_, index) => currentYear - index);

  return years.map(year => ({
    label : year.toString(),
    value : year
  }));
};

export const getRecentYearsParam = (currentYear, startDate) => {
  const yearsCount = 20;
  const years = Array.from({length : yearsCount}, (_, index) => currentYear - index).filter(year => year >= startDate);

  return years.map(year => ({
    label : year.toString(),
    value : year
  }));
};

export const getMonths = () => {
  const monthsInYear = Array.from({length : 12}, (_, month) => {
    const monthName = new Date(0, month).toLocaleString('default', {month : 'long'});


    return {
      label : monthName,
      value : month + 1
    };
  });

  return monthsInYear;
};

export const boldHeaders = (sheet, color) => {
  // Assuming headers are in the first row
  const range = XLSX.utils.decode_range(sheet['!ref']);

  // Column indexes
  const cols = Array.from({length : range.e.c + 1}, (_, i) => i);

  cols.forEach(C => {
    const cell = sheet[XLSX.utils.encode_cell({c : C,
      r : 0})];

    if (!cell) {
      return;
    }

    // Cell style
    cell.s = {
      font : {
        bold : true
      },
      fill : {
        fgColor : {rgb : color}
      }
    };
  });
};


