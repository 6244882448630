const ENV_URL = {
  // ENV_URL      : 'http://localhost:3030/',
  // API_ENDPOINT : 'http://localhost:3030/api',

  ENV_URL      : 'https://hub-redesign-api-dev.bitstoneint.com/',
  API_ENDPOINT : 'https://hub-redesign-api-dev.bitstoneint.com/api',

  // ENV_URL      : 'https://api2.bitstone.eu/',
  // API_ENDPOINT : 'https://api2.bitstone.eu/api',

  HOLIDAY_API : 'https://date.nager.at/api/v3/publicholidays/'
};

export default ENV_URL;
