import apiResponse from './apiResponse';
import auth from './auth';
import app from './app';
import objective from './objective';
import user from './user';
import teamMembers from './teamMembers';
import plans from './plans';
import statistics from './statistics';
import evaluation from './evaluation';
import teamDetails from './teamDetails';
import link from './link';
import team from './team';
import role from './role';
import job from './job';
import skills from './skill';
import timetracking from './timetracking';
import searchInputValue from './searchInputValue';
import holidays from './holidays';

const rootReducers = {
  apiResponse,
  auth,
  app,
  user,
  teamMembers,
  objective,
  plans,
  statistics,
  evaluation,
  teamDetails,
  link,
  team,
  role,
  job,
  searchInputValue,
  skills,
  timetracking,
  holidays
};

export default rootReducers;
