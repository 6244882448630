import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_STATISTICS_DATA = buildActionType(getParts(API_ENDPOINT.STATISTICS.GET_STATISTICS_DATA));

const getStatisticsReducer = (state, {payload: {data}}) => ({
  ...state,
  statisticsData : data
});

const cleanupReducer = () => initialState;

const statisticsReducer = handleActions({
  [GET_STATISTICS_DATA]        : getStatisticsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default statisticsReducer;
