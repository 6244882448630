import {createSelector} from 'reselect';
import {get} from 'lodash';
import {ROLES, CAPABILITIES} from '../../constant';
import {userCan} from '../../utils/user';

const userSelector = state => state.user;
const plansSelector = state => state.plans;

const decideUserRole = user => {
  if (user.user === undefined) {
    return '';
  }

  if (userCan(user.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key)) {
    return ROLES.ADMIN;
  }

  return ROLES.USER;
};

export const getAllUsers = createSelector(
  [userSelector],
  users => get(users, 'users', {})
);

export const getProjectsTitle = createSelector(
  [userSelector],
  projects => get(projects, 'projects', {})
);

export const getAllTeamLeads = createSelector(
  [userSelector],
  teamLeads => get(teamLeads, 'teamLeads', {})
);

export const getUser = createSelector(
  [userSelector],
  user => get(user, 'user', {})
);

export const getUserCapabilities = createSelector(
  [userSelector],
  user => get(user, 'capabilities', {})
);

export const getUserDetails = createSelector(
  [userSelector],
  userDetails => get(userDetails, 'userDetails', {user : {}})
);

export const getUserRole = createSelector(
  [userSelector],
  user => get(user, 'role', decideUserRole(user))
);

export const getUserName = createSelector(
  [getUser],
  user => get(user, 'name', '')
);

export const getUserId = createSelector(
  [getUser],
  user => get(user, 'id', '')
);

export const getUserEmail = createSelector(
  [getUser],
  user => get(user, 'email', '')
);

export const getUserTeam = createSelector(
  [getUser],
  user => get(user, 'team', '')
);

export const isCurrentUserTeamLead = createSelector(
  [getUser],
  user => get(user, 'isTeamLead', false) || get(user, 'isOwner', false)
);

export const isCurrentUserOwner = createSelector(
  [getUser],
  user => get(user, 'isOwner', false)
);

export const getTeamLeader = createSelector(
  [userSelector],
  user => get(user, 'teamLeader', {})
);

export const getPlans = createSelector(
  [plansSelector],
  plans => get(plans, 'plans', {
    items : [],
    plans : []
  })
);

export const getJobs = createSelector(
  [userSelector],
  jobs => get(jobs, 'jobs', [])
);

export const getTeams = createSelector(
  [userSelector],
  teams => get(teams, 'teams', [])
);
