import {put} from 'redux-saga/effects';
import {HTTP_STATUS} from 'constant';
import {appCleanup} from 'action/apiResponse';
import LocalStorage from 'service/LocalStorage';
import SessionStorage from 'service/SessionStorage';

const middleware = () => function* notAuthorizedMiddleware (req, next) {
  const response = yield next(new Request(req));

  if (HTTP_STATUS.NOT_AUTHORISED.test(response.status)) {
    yield LocalStorage.destroy();
    yield SessionStorage.destroy();
    yield put(appCleanup());
  }

  return response;
};

export default middleware;
