import {createAction} from 'redux-actions';

const PREFIX = 'api::';

export const ActionTypes = {
  API_REQUEST         : `${PREFIX}API_REQUEST`,
  API_REQUEST_SUCCESS : `${PREFIX}API_REQUEST_SUCCESS`,
  API_REQUEST_FAILURE : `${PREFIX}API_REQUEST_FAILURE`,
  API_SERVER_ERROR    : `${PREFIX}API_SERVER_ERROR`,

  REQUEST_TRACKER : `${PREFIX}REQUEST_TRACKER`
};

const throwIfMissing = param => {
  throw new Error(`Parameter ${param} is required`);
};

export const apiRequest = ({
  url = throwIfMissing('url'),
  method = throwIfMissing('method'),
  resourceMethod = throwIfMissing('resourceMethod'),
  resourceName = throwIfMissing('resourceName'),
  ...rest
}) => createAction(
  ActionTypes.API_REQUEST,
  null,
  () => ({
    url,
    method,
    resourceName,
    resourceMethod,
    ...rest
  })
);

const apiRequestSuccess = ({
  url = throwIfMissing('url'),
  method = throwIfMissing('method'),
  resourceMethod = throwIfMissing('resourceMethod'),
  resourceName = throwIfMissing('resourceName'),
  ...rest
}) => createAction(
  ActionTypes.API_REQUEST_SUCCESS,
  null,
  () => ({
    url,
    method,
    resourceName,
    resourceMethod,
    ...rest
  })
);

const apiRequestFailure = ({
  url = throwIfMissing('url'),
  method = throwIfMissing('method'),
  resourceMethod = throwIfMissing('resourceMethod'),
  resourceName = throwIfMissing('resourceName'),
  ...rest
}) => createAction(
  ActionTypes.API_REQUEST_FAILURE,
  null,
  () => ({
    url,
    method,
    resourceName,
    resourceMethod,
    ...rest
  })
);
const requestTracker = createAction(ActionTypes.REQUEST_TRACKER);

const apiServerError = createAction(ActionTypes.API_SERVER_ERROR);

export {
  apiRequestFailure,
  apiRequestSuccess,
  apiServerError,
  requestTracker
};
