import Plans from './Plans';
import {connect} from 'react-redux';
import {getObjectives, getTotalObjectives} from 'selector/objective';
import {apiRequestAction} from 'action/apiResource';
import {getUserName, getUserId, isCurrentUserTeamLead, getUserEmail, getUserTeam, getUser, getPlans} from 'selector/user';
import {getIsCallInProgress, getHasCallSucceeded} from 'selector/app';
import {getTeamMembers} from 'selector/teamMembers';

const mapStateToProps = state => ({
  objectives       : getObjectives(state),
  user             : getUser(state),
  isUserTeamLead   : isCurrentUserTeamLead(state),
  currentUserName  : getUserName(state),
  currentUserEmail : getUserEmail(state),
  currentUserTeam  : getUserTeam(state),
  totalObjectives  : getTotalObjectives(state),
  currentUserId    : getUserId(state),
  teamMembers      : getTeamMembers(state),
  isLoading        : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.user.getUserPlans.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.deleteObjective.requestTracker
  }),
  editSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  }),
  deleteSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.deleteObjective.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.keyResult.deleteKeyResult.requestTracker
  }),
  plans : getPlans(state)

});

const mapDispatchToProps = {
  getUserPlans : apiRequestAction.user.getUserPlans({
    skipEntityIdCheck : true
  }),
  deleteObjective : apiRequestAction.objective.deleteObjective(),
  deleteKeyResult : apiRequestAction.keyResult.deleteKeyResult(),
  editObjective   : apiRequestAction.objective.editObjective(),
  editKeyResult   : apiRequestAction.keyResult.editKeyResult()

};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
