const HTTP_STATUS = {
  OK           : /^2[0-9]{2}$/,
  REDIRECT     : /^3[0-9]{2}$/,
  CLIENT_ERROR : /^4[0-9]{2}$/,
  SERVER_ERROR : /^5[0-9]{2}$/,

  NOT_AUTHORISED : /^401$/
};

export default HTTP_STATUS;
