/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, {useEffect, useState, useRef} from 'react';
import {func, bool, object, array, oneOfType} from 'prop-types';
import {Dialog} from 'primereact/dialog';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {BENEFITS, CAPABILITIES} from '../../constant';
import {InputText} from 'primereact/inputtext';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {map} from 'lodash';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactComponent as AddNewExperienceIcon} from '../../assets/icons/plus-icon-black.svg';
import {formatOnlyMonthDate, formatOnlyYearDate, userCan} from '../../utils/user';
import {SaveButton, AddNewExperienceButton} from '../StyledButtons/StyledButtons';
import {getMonths, getRecentYears, getRecentYearsParam} from '../../utils/utils';
import {Button} from 'primereact/button';
import {Chips} from 'primereact/chips';
import {Toast} from 'primereact/toast';

import './ExtraFieldsComponent.scss';

const ExtraFieldsComponent = props => {
  const emptyExperience = {
    startDate   : null,
    endDate     : null,
    position    : '',
    company     : '',
    description : ''
  };
  const emptyExperienceTemplate = {
    startDateMonth : null,
    startDateYear  : null,
    endDateMonth   : null,
    endDateYear    : null,
    position       : '',
    company        : '',
    description    : ''
  };
  const emptyProject = {
    title            : '',
    mainGoal         : '',
    responsabilities : '',
    technicalSkills  : ''
  };

  const LEARNINGDEV = 750;
  const TEAMBUILDING = 100;

  const [experienceData, setExperienceData] = useState([]);
  const [yearOptions, setYearOptions] = useState(null);
  const [yearFullOptions, setYearFullOptions] = useState(null);
  const [monthOptions, setMonthOptions] = useState(null);
  const [bitstoneInfo, setBitstoneInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [projectsInfo, setProjectsInfo] = useState([]);
  const [education, setEducation] = useState([]);
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');
  const [experienceDataUI, setExperienceDataUI] = useState();
  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const [otherSkills, setOtherSkills] = useState([]);
  const [isValidForm, setIsValidForm] = useState(true);
  const [birthdate, setBirthdate] = useState(null);
  const toastRef = useRef(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();

    const yearOptionsTemp = getRecentYears(currentYear);

    const present = {label : 'Present',
      value : 'Present'};

    yearOptionsTemp.unshift(present);

    setYearFullOptions(yearOptionsTemp);

    const monthsInYear = getMonths();

    monthsInYear.unshift(present);
    setMonthOptions(monthsInYear);
  }, []);

  useEffect(() => {
    const tempData = map(experienceData, item => {
      let endDateMonth = null;

      let endDateYear = null;

      if (item.endDate === 'Present') {
        endDateMonth = 'Present';
        endDateYear = 'Present';
      } else {
        endDateMonth = item.endDate ? formatOnlyMonthDate(new Date(item.endDate)) : null;
        endDateYear = item.endDate ? formatOnlyYearDate(new Date(item.endDate)) : null;
      }

      return {
        ...item,
        startDateMonth : item.startDate ? formatOnlyMonthDate(new Date(item.startDate)) : null,
        startDateYear  : item.startDate ? formatOnlyYearDate(new Date(item.startDate)) : null,
        endDateMonth,
        endDateYear
      };
    });

    setExperienceDataUI(tempData);
  }, [experienceData]);


  useEffect(() => {
    props.getSkills();
  }, [props.addNewSkillsSuccessful]);

  useEffect(() => {
    if (props?.userInfo.extras?.relevantExperience) {
      setExperienceData(Object.values(props.userInfo.extras.relevantExperience));
    }
    if (props?.userInfo.extras?.projectsInfo) {
      setProjectsInfo(Object.values(props.userInfo.extras.projectsInfo));
    }
    if (props?.userInfo.extras?.personalInfo?.education) {
      setEducation(props.userInfo.extras.personalInfo.education);
    }
    if (props?.userInfo.extras?.personalInfo?.birthdate) {
      setBirthdate(props.userInfo.extras.personalInfo.birthdate);
    }
    if (props?.userInfo.extras?.personalInfo?.isVegan) {
      setDietaryRestrictions(props.userInfo.extras.personalInfo.isVegan);
    }

    setBitstoneInfo({
      joinDate       : props.userInfo.joinDate,
      benefits       : props.userInfo.extras?.bitstoneInfo?.benefits,
      learningAndDev : props.userInfo.extras?.bitstoneInfo?.learningAndDev || LEARNINGDEV,
      teamBuildings  : props.userInfo.extras?.bitstoneInfo?.teamBuildings || TEAMBUILDING,
      currentRole    : props.userInfo.extras?.bitstoneInfo?.currentRole,
      about          : props.userInfo.extras?.bitstoneInfo?.about
    });
    setPersonalInfo({
      officialName         : props.userInfo.extras?.personalInfo?.officialName,
      birthdate            : props.userInfo.extras?.personalInfo?.birthdate,
      personalEmailAddress : props.userInfo.extras?.personalInfo?.personalEmailAddress,
      hometown             : props.userInfo.extras?.personalInfo?.hometown,
      currentAddress       : props.userInfo.extras?.personalInfo?.currentAddress,
      education            : props.userInfo.extras?.personalInfo?.education,
      skills               : props.userInfo.extras?.personalInfo?.skills ? Object.values(props.userInfo.extras?.personalInfo?.skills) : [],
      languages            : props.userInfo.extras?.personalInfo?.languages,
      hobbies              : props.userInfo.extras?.personalInfo?.hobbies,
      isVegan              : props.userInfo.extras?.personalInfo?.isVegan
    });
  }, [props.userInfo]);

  const hideExtraInfoDialog = () => {
    props.setDisplayExtraFields(false);
    props.setSubmitted(false);
  };

  const hideSkillsModal = () => {
    setOpenSkillsModal(false);
  };

  const saveChanges = async () => {
    props.setSubmitted(true);

    const isValidExperience = experienceDataUI.every(item =>
      Object.keys(emptyExperienceTemplate).filter(field => field !== 'description')
        .every(field => item[field])
    );

    const isValidProject = projectsInfo.every(item =>
      Object.keys(emptyProject)
        .filter(field => field === 'title')
        .every(field => item[field])
    );

    if (!isValidExperience || !isValidProject) {
      setIsValidForm(false);

      return;
    }

    map(experienceDataUI, item => {
      const startDate = new Date();

      startDate.setMonth(new Date(Date.parse(item.startDateMonth + ' 1')).getMonth());
      startDate.setFullYear(Number(item.startDateYear));

      item.startDate = startDate.toISOString();
      const endDate = new Date();

      if (item.endDateMonth !== 'Present' && item.endDateYear !== 'Present') {
        endDate.setMonth(new Date(Date.parse(item.endDateMonth + ' 1')).getMonth());
        endDate.setFullYear(Number(item.endDateYear));
        item.endDate = endDate.toISOString();
      } else {
        item.endDate = 'Present';
      }
    });


    const newRelevantExperience = map(Object.values(experienceDataUI), obj => {
      // eslint-disable-next-line no-unused-vars
      const {startDateYear, startDateMonth, endDateMonth, endDateYear, ...rest} = obj;

      return rest;
    });

    // eslint-disable-next-line no-unused-vars
    const {joinDate, ...newBitstoneInfo} = bitstoneInfo;

    await props.editUser({
      id       : props.userInfo.id,
      role     : props.userInfo.role.id,
      joinDate : new Date(bitstoneInfo.joinDate),
      extras   : {
        bitstoneInfo       : newBitstoneInfo,
        relevantExperience : newRelevantExperience,
        personalInfo       : {
          ...personalInfo,
          birthdate,
          education,
          dietaryRestrictions
        },
        projectsInfo
      }
    });

    props.setUserInfo({
      ...props.userInfo,
      joinDate : new Date(bitstoneInfo.joinDate),
      extras   : {
        bitstoneInfo       : newBitstoneInfo,
        relevantExperience : newRelevantExperience,
        personalInfo       : {
          ...personalInfo,
          birthdate,
          education,
          dietaryRestrictions
        },
        projectsInfo
      }
    });
    props.setDisplayExtraFields(false);
  };

  const deleteExperience = index => {
    setExperienceDataUI(prevData => prevData.filter((item, idx) => idx !== index));
  };

  const deleteProject = index => {
    setProjectsInfo(prevData => prevData.filter((item, idx) => idx !== index));
  };

  const addNewExperience = () => {
    const currentExperiences = Object.values(experienceDataUI);

    currentExperiences.push(emptyExperience);

    setExperienceDataUI(currentExperiences);
  };

  const addNewEducation = () => {
    setEducation(prevEducation => [...prevEducation, '']);
  };

  const extraFieldsDialogFooter = (
    <>
      <SaveButton onClick={saveChanges}>Save changes</SaveButton>
    </>
  );

  const saveNewSkillsInDb = async () => {
    await props.createSkills({
      name : otherSkills
    });

    setOpenSkillsModal(false);
  };

  const handleInputChangeBistoneInfo = (fieldName, value) => {
    setBitstoneInfo(prevState => ({
      ...prevState,
      [fieldName] : value
    }));
  };

  const handleInputChangeRelevantInfo = (index, fieldName, value) => {
    setExperienceDataUI(prevState => {
      const newData = [...prevState];

      newData[index][fieldName] = value;

      return newData;
    });


    if (fieldName === 'startDateYear' && value !== 'Present') {
      const currentYear = new Date().getFullYear();

      const newYearOptions = getRecentYearsParam(currentYear, parseInt(value, 10));
      const present = {label : 'Present',
        value : 'Present'};

      newYearOptions.unshift(present);

      setYearOptions(newYearOptions);
    }
  };

  const handleInputChangeProjectsInfo = (index, fieldName, value) => {
    setProjectsInfo(prevState => {
      const newData = [...prevState];

      newData[index][fieldName] = value;

      return newData;
    });
  };

  const onProjectTitleChange = (index, fieldName) => event => {
    const value = event.value;

    setProjectsInfo(prevState => {
      const newData = [...prevState];

      newData[index][fieldName] = value;

      return newData;
    });
  };
  const handleInputChangePersonalInfo = (fieldName, value) => {
    setPersonalInfo(prevState => ({
      ...prevState,
      [fieldName] : value
    }));
  };

  const handleOpenProjectsModal = () => {
    const currentProjects = Object.values(projectsInfo);

    currentProjects.push(emptyProject);

    setProjectsInfo(currentProjects);
  };

  const skillsDialogFooter = (
    <>
      <SaveButton onClick={saveNewSkillsInDb}>Save new skills in database</SaveButton>
    </>
  );

  return (
    <>
      <Toast ref={toastRef} />
      <Dialog
        blockScroll="true"
        className="userExtraDialog p-fluid"
        footer={extraFieldsDialogFooter}
        header="Edit Extra Details" modal
        onHide={hideExtraInfoDialog}
        style={{width : '600px'}}
        visible={props.displayExtraFields}
      >
        <Accordion multiple>
          <AccordionTab disabled={!userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_BITSTONE_INFORMATION.key)} header="Bitstone Information" >
            <div className="modal-bitstone-info">
              <label>Start date</label>
              <div className="double-row">
                <Calendar
                  id="icon"
                  onChange={event => handleInputChangeBistoneInfo('joinDate', event.target.value)}
                  placeholder="Choose date"
                  showIcon
                  value={new Date(bitstoneInfo.joinDate)}
                />
              </div>
              <label>Benefits</label>
              <MultiSelect
                display="text"
                onChange={event => handleInputChangeBistoneInfo('benefits', event.target.value)}
                options={BENEFITS}
                placeholder="Choose benefits"
                value={bitstoneInfo.benefits}
              />
              <div className="triple-row">
                <div className="column">
                  <label>Teambuildings</label>
                  <InputText
                    onChange={event => handleInputChangeBistoneInfo('teamBuildings', event.target.value)}
                    type="number" value={bitstoneInfo?.teamBuildings}
                  />
                </div>
                <div className="column">
                  <label>Learning & development</label>
                  <InputText
                    onChange={event => handleInputChangeBistoneInfo('learningAndDev', event.target.value)}
                    type="number" value={bitstoneInfo.learningAndDev ? bitstoneInfo.learningAndDev : 0}
                  />
                </div>
              </div>
              <label>Current role</label>
              <div className="area">
                <InputTextarea
                  onChange={event => handleInputChangeBistoneInfo('currentRole', event.target.value)}
                  rows={5}
                  value={bitstoneInfo?.currentRole}
                />
              </div>
              <div className="area">
                <label>About</label>
                <InputTextarea
                  onChange={event => handleInputChangeBistoneInfo('about', event.target.value)}
                  rows={5}
                  value={bitstoneInfo?.about}
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            disabled={!userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_RELEVANT_EXPERIENCE.key)} header="Relevant Experience"
          >
            {map(experienceDataUI, (item, index) => <div className="modal-relevant-experience-info" key={index}>
              <label>Start date *</label>

              <div className="double-row">
                <div className="flex-1">
                  <Dropdown
                    id="icon"
                    onChange={event => handleInputChangeRelevantInfo(index, 'startDateMonth', event.value)}
                    options={monthOptions}
                    placeholder={item.startDate === null ? 'Choose month' : item.startDateMonth}
                    required value={item.startDateMonth}
                  />
                  {props.submitted && !item.startDateMonth && <small className="p-error">Field is required.</small>}
                </div>
                <div className="flex-1">
                  <Dropdown
                    id="icon"
                    onChange={event => handleInputChangeRelevantInfo(index, 'startDateYear', event.value)}
                    options={yearFullOptions}
                    placeholder={item.startDate === null ? 'Choose year' : item.startDateYear}
                    required value={item.startDateYear}
                  />
                  {props.submitted && !item.startDateYear && <small className="p-error">Field is required.</small>}
                </div>
              </div>

              <label>End date *</label>
              <div className="double-row">
                <div className="flex-1">
                  <Dropdown
                    id="icon"
                    onChange={event => handleInputChangeRelevantInfo(index, 'endDateMonth', event.value)}
                    options={monthOptions}
                    placeholder={item.endDate === null ? 'Choose month' : item.endDateMonth}
                    required value={item.endDateMonth}
                  />
                  {props.submitted && !item.endDateMonth && <small className="p-error">Field is required.</small>}
                </div>
                <div className="flex-1">
                  <Dropdown
                    id="icon"
                    onChange={event => handleInputChangeRelevantInfo(index, 'endDateYear', event.value)}
                    options={yearOptions}
                    placeholder={item.endDate === null ? 'Choose year' : item.endDateYear}
                    required value={item.endDateYear}
                  />
                  {props.submitted && !item.endDateYear && <small className="p-error">Field is required.</small>}
                </div>
              </div>
              <label>Company *</label>
              <InputText onChange={event => handleInputChangeRelevantInfo(index, 'company', event.target.value)} required value={item.company} />
              {props.submitted && !item.company && <small className="p-error">Company is required.</small>}
              <label>Position *</label>
              <InputText onChange={event => handleInputChangeRelevantInfo(index, 'position', event.target.value)} required value={item.position} />
              {props.submitted && !item.position && <small className="p-error">Position is required.</small>}
              <label>Responsabilities (optional)</label>
              <div className="area">
                <InputTextarea
                  onChange={event => handleInputChangeRelevantInfo(index, 'description', event.target.value)} rows={5}
                  value={item.description}
                />
              </div>
              <p className="delete-exp" onClick={() => deleteExperience(index)}>Delete experience</p>
              <div className="line" />
            </div>
            )}
            <div className="button">
              <AddNewExperienceButton onClick={addNewExperience}>
                <AddNewExperienceIcon />
              </AddNewExperienceButton>
            </div>
          </AccordionTab>

          <AccordionTab
            disabled={!userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PROJECTS_EXPERIENCE.key) &&
            !props.canTeamLead && !props.isLoggedUserProfile} header="Project Experience"
          >
            {map(projectsInfo, (item, index) =>
              <div className="project-container" key={index}>
                <div className="row">
                  <label>Title</label>
                  <Dropdown
                    editable
                    onChange={onProjectTitleChange(index, 'title')}
                    options={[...Object.values(props.projects)]}
                    placeholder={item.title ? item.title : 'Type new title if it\'s not in the list'}
                    value={item.title}
                  />
                  {/* <InputText onChange={event => handleInputChangeProjectsInfo(index, 'title', event.target.value)} required value={item.title} /> */}
                  {props.submitted && !item.title && <small className="p-error">Project title is required.</small>}
                </div>
                <div className="row">
                  <label>Main goal</label>
                  <InputTextarea onChange={event => handleInputChangeProjectsInfo(index, 'mainGoal', event.target.value)} value={item.mainGoal} />
                </div>
                <div className="row">
                  <label>Responsabilities</label>
                  <InputTextarea onChange={event => handleInputChangeProjectsInfo(index, 'responsabilities', event.target.value)} value={item.responsabilities} />
                </div>
                <div className="row">
                  <label>Used technical skills (front-end)</label>
                  <InputTextarea onChange={event => handleInputChangeProjectsInfo(index, 'technicalSkills', event.target.value)} value={item.technicalSkills} />
                </div>
                <p className="delete-exp" onClick={() => deleteProject(index)}>Delete project</p>
                <div className="line" />
              </div>
            )}
            <div className="button">
              <AddNewExperienceButton onClick={handleOpenProjectsModal} >
                <AddNewExperienceIcon />
              </AddNewExperienceButton>
            </div>
          </AccordionTab>

          <AccordionTab
            disabled={!props.isLoggedUserProfile &&
            !userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PERSONAL_INFO.key) && !props.canTeamLead} header="Personal Information"
          >
            <div className="modal-personal-info">
              {userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key) &&
              <>
                <label>Official name</label>
                <InputText
                  onChange={event => handleInputChangePersonalInfo('officialName', event.target.value)}
                  value={personalInfo?.officialName}
                />
                <label htmlFor="birthday">Birth date</label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  id="icon"
                  onChange={event => setBirthdate(event.value)}
                  showIcon
                  value={birthdate ? new Date(birthdate) : ''}
                />
                <label>Address</label>
                <InputText
                  onChange={event => handleInputChangePersonalInfo('currentAddress', event.target.value)}
                  value={personalInfo?.currentAddress}
                />
                <label>Personal email address</label>
                <InputText
                  onChange={event => handleInputChangePersonalInfo('personalEmailAddress', event.target.value)}
                  value={personalInfo?.personalEmailAddress}
                />
              </>
              }
              <label>Hometown</label>
              <InputText
                onChange={event => handleInputChangePersonalInfo('hometown', event.target.value)}
                value={personalInfo?.hometown}
              />
              <label>Education</label>
              {education.length > 0 && education.map((ed, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="education-input" key={index}>
                  <InputText
                    className="education-input-text"
                    onChange={event => {
                      const newEducation = [...education];

                      newEducation[index] = event.target.value;
                      setEducation(newEducation);
                    }}
                    value={ed}
                  />
                  <IconButton
                    aria-label="delete"
                    className="delete-education"
                    onClick={() => {
                      const newEducation = [...education];

                      newEducation.splice(index, 1);
                      setEducation(newEducation);
                    }}
                    size="small"
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </div>
              ))}

            </div>
            <div className="button">
              <AddNewExperienceButton onClick={addNewEducation}>
                <AddNewExperienceIcon />
              </AddNewExperienceButton>
            </div>
            <div className="modal-personal-info">
              <div className={props.isLoggedUserProfile ||
            userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PERSONAL_INFO.key) || props.canTeamLead ? 'skills' : 'hidden'}
              >
                <div className="key">
                  <label>Skills</label>
                  <div className="exclamation-icon">
                    <Button
                      aria-label="Info" icon="pi pi-exclamation-circle" rounded
                    />
                    <div className="tooltip">If you don&#39;t find your skills in list, you can add them manually by pressing the add button. After each skill press enter.</div>
                  </div>
                </div>
                <MultiSelect
                  display="chip" onChange={event => handleInputChangePersonalInfo('skills', event.target.value)} optionLabel="name"
                  options={Object.values(props.skills)}
                  placeholder="Choose skills" value={personalInfo?.skills}
                />
                <div className="skill-button">
                  <AddNewExperienceButton onClick={() => setOpenSkillsModal(true)}>
                    <AddNewExperienceIcon />
                  </AddNewExperienceButton>
                </div>
              </div>
              <label>Languages</label>
              <Chips
                onChange={event => handleInputChangePersonalInfo('languages', event.target.value)}
                placeholder="Hit enter after each language" value={personalInfo?.languages}
              />
              <label>Hobbies</label>
              <Chips
                onChange={event => handleInputChangePersonalInfo('hobbies', event.target.value)}
                placeholder="Hit enter after each hobby" value={personalInfo?.hobbies}
              />
              <label>Dietary restrictions</label>
              <textarea className="dietary" onChange={event => handleInputChangePersonalInfo('isVegan', event.target.value)} value={personalInfo?.isVegan} />
            </div>
          </AccordionTab>
        </Accordion>
      </Dialog>

      <Dialog
        className="skillsDialog p-fluid"
        footer={skillsDialogFooter}
        header="New skills" modal
        onHide={hideSkillsModal}
        visible={openSkillsModal}
      >
        <Chips
          onChange={event => setOtherSkills(event.value)} placeholder="Hit enter after each skill" value={otherSkills}
        />
      </Dialog>

      <Dialog
        className="warningDialog p-fluid"
        header="Action denied" modal
        onHide={() => setIsValidForm(true)}
        visible={!isValidForm}
      >
        <p>You have required fields unfilled.</p>
      </Dialog>
    </>
  );
};

ExtraFieldsComponent.displayName = 'ExtraFieldsComponent';
ExtraFieldsComponent.propTypes = {
  isLoggedUserProfile    : bool.isRequired,
  setSubmitted           : func.isRequired,
  submitted              : bool.isRequired,
  userInfo               : object.isRequired,
  capabilities           : oneOfType([object, array]).isRequired,
  projects               : oneOfType([object, array]).isRequired,
  setUserInfo            : func.isRequired,
  editUser               : func.isRequired,
  getSkills              : func.isRequired,
  setDisplayExtraFields  : func.isRequired,
  createSkills           : func.isRequired,
  displayExtraFields     : bool.isRequired,
  addNewSkillsSuccessful : bool.isRequired,
  canTeamLead            : bool.isRequired,
  skills                 : object.isRequired
};
export default ExtraFieldsComponent;
