/* eslint-disable complexity */
import React, {useState, useEffect, Fragment, useRef} from 'react';
import {classNames} from 'primereact/utils';
import {func, bool, object, oneOfType, array} from 'prop-types';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import {userCan, emptyUser} from '../../utils/user';
import {CAPABILITIES} from '../../constant';
import {map} from 'lodash';
import {showSuccessfulToast} from '../../utils/utils';
import {SaveButton} from '../StyledButtons/StyledButtons';
import './UserModal.scss';

/**
 * Modal which shows when adding new employee and
 * also when editing an employee -> no duplicated code
 */
const UserModal = props => {
  const toast = useRef(null);
  const [user, setUser] = useState({});
  const [date, setDate] = useState(null);
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const userProps = {...props.userProps};

  useEffect(() => {
    if (props.userDialog === true) {
      setUser(userProps.userInfo);
      setIsEditModeOn(userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key) || props.canTeamLeadAction);
    }
  }, [props.userDialog]);

  const hideUserDialog = () => {
    setDate(null);
    setUser(emptyUser);

    props.setSubmitted(false);
    props.setUserDialog(false);
  };

  const onInputChange = (event, name) => {
    const val = event.target?.value || '';

    setUser(prevUser => ({
      ...prevUser,
      [name] : val
    }));
  };
  const onDropdownChange = (event, name) => {
    const {value} = event.target;

    const updatedUser = {
      ...user,
      [name] : value
    };

    setUser(updatedUser);
  };

  const handleUser = async isEditing => {
    props.setSubmitted(true);

    const userTeamDb = map(user.team, team => team.id);

    if (user.name && user.phone && user.email) {
      if (isEditing) {
        const userPrevTeamDb = map(user.previousTeam, team => team.id);

        await props.editUser({
          id           : user.id,
          name         : user.name,
          username     : user.username,
          email        : user.email,
          job          : user.job.id,
          phone        : user.phone,
          team         : userTeamDb,
          previousTeam : userPrevTeamDb,
          status       : user.status,
          role         : user.role.id,
          joinDate     : user.joinDate,
          extras       : user.extras,
          teamlead     : user.teamlead.id
        });

        showSuccessfulToast(toast, 'User updated');
        props.setUserDialog(false);
      } else if (user.name && user.email && user.job && user.phone && userTeamDb && date) {
        await props.addUser({
          name         : user.name,
          username     : user.username,
          email        : user.email,
          job          : user.job.id,
          phone        : user.phone,
          team         : userTeamDb,
          previousTeam : [],
          // eslint-disable-next-line camelcase
          join_date    : date
        });
        showSuccessfulToast(toast, 'New user added');
        setDate(null);
        props.setUserDialog(false);
      }
    }
  };

  const userDialogFooter = (
    <Fragment>
      <SaveButton onClick={() => handleUser(user.id !== null)}>{user.id === null ? 'Add member' : 'Save changes'}</SaveButton>
    </Fragment>
  );

  const isUserOptionDisabled = option => option.name === 'User' && user.role.name === 'User';

  return (
    <>
      <Toast ref={toast} />
      {(user.id || user.id === null) &&
      <Dialog
        blockScroll="true"
        className="userDialog p-fluid" footer={userDialogFooter}
        header={user.id === null ? 'New employee' : 'Edit User Info'}
        modal onHide={hideUserDialog}
        visible={props.userDialog}
      >
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            autoFocus
            className={classNames('input', {'p-invalid' : props.submitted && !user.name})}
            id="name"
            onChange={event => onInputChange(event, 'name')} required value={user.name}
          />
          {props.submitted && !user.name && <small className="p-error">Name is required.</small>}
        </div>
        <div className="double-row">
          <div className="field">
            <label htmlFor="team">Team</label>
            <MultiSelect
              disabled={!isEditModeOn}
              display="text"
              onChange={event => onDropdownChange(event, 'team')}
              optionLabel="name"
              options={[...Object.values(props.teams).filter(team => team.status === 'active')]}
              placeholder={user.team === '' ? 'Select a team' : ''}
              required
              value={Object.values(user.team)}
            />
            {props.submitted && !user.team && <small className="p-error">Team is required.</small>}
          </div>
          <div className="field">
            <label htmlFor="job">Role</label>
            <Dropdown
              className={classNames({'p-invalid' : props.submitted && !user.job})}
              disabled={!isEditModeOn}
              onChange={event => onDropdownChange(event, 'job')}
              optionLabel="name"
              options={Object.values(props.jobs)}
              placeholder={user.job === '' ? 'Select a job' : user?.job?.name}
              required
              value={user.job.name}
            />
            {props.submitted && !user.job && <small className="p-error">Job is required.</small>}
          </div>
        </div>
        {user.id !== null &&
        <div className="field">
          <label htmlFor="teamlead">Direct Manager</label>
          <Dropdown
            className={classNames({'p-invalid' : props.submitted && !user.teamlead})}
            disabled={!isEditModeOn}
            id="teamlead"
            onChange={event => onDropdownChange(event, 'teamlead')}
            optionLabel="name"
            options={Object.values(props.teamLeads)}
            placeholder={user.teamlead === '' ? 'Select a teamlead' : user?.teamlead?.name}
            required
            value={user?.teamlead?.name}
          />
        </div>
        }
        <div className="double-row">
          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText
              className={classNames('input', {'p-invalid' : props.submitted && !user.email})}
              cols={20}
              disabled={!isEditModeOn} id="email" onChange={event => onInputChange(event, 'email')}
              required rows={3} value={user.email}
            />
            {props.submitted && !user.email && <small className="p-error">Email is required.</small>}
          </div>

          <div className="field">
            <label htmlFor="phone">Phone no.</label>
            <InputText
              className={classNames('input', {'p-invalid' : props.submitted && !user.phone})}
              cols={20} id="phone" onChange={event => onInputChange(event, 'phone')}
              required rows={3} value={user.phone}
            />
            {props.submitted && !user.phone && <small className="p-error">Phone number is required.</small>}
          </div>
        </div>

        {userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key) &&
        <div className="double-row">
          {user.id !== null &&
          <div className="field">
            <label htmlFor="previousTeam">Previous team</label>
            <MultiSelect
              disabled={!userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key)}
              display="text"
              onChange={event => onDropdownChange(event, 'previousTeam')}
              optionLabel="name"
              options={Object.values(props.teams)}
              placeholder={Object.values(user.previousTeam) === '' ? 'Team' : 'No previous team'}
              required
              value={Object.values(user.previousTeam)}
            />
          </div>
          }

          {user.id !== null &&
          <div className="field">
            <label htmlFor="roles">Access</label>
            <Dropdown
              disabled={!userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key)}
              display="text"
              onChange={event => onDropdownChange(event, 'role')}
              optionDisabled={option => isUserOptionDisabled(option)}
              optionLabel="name"
              options={Object.values(props.roles).filter(role => role.status === 'active')}
              placeholder={user.role === '' ? 'Roles' : user?.role?.name}
              required
              value={user.role.name}
            />
          </div>
          }
        </div>
        }

        {user.id === null &&
        <div className="calendar">
          <label htmlFor="joinDate">Join date</label>
          <Calendar
            className={classNames({'p-invalid' : props.submitted && date === null})}
            id="icon"
            onChange={event => setDate(event.value)}
            showIcon
            value={date}
          />
          {props.submitted && date === null && <small className="p-error">Join date is required.</small>}
        </div>
        }
      </Dialog >
      }
    </>

  );
};

UserModal.displayName = 'UserModal';
UserModal.propTypes = {
  teamLeads         : object.isRequired,
  jobs              : oneOfType([array, object]).isRequired,
  teams             : object.isRequired,
  capabilities      : oneOfType([array, object]).isRequired,
  setSubmitted      : func.isRequired,
  setUserDialog     : func.isRequired,
  userDialog        : bool.isRequired,
  canTeamLeadAction : bool.isRequired,
  submitted         : bool.isRequired,
  addUser           : func.isRequired,
  roles             : object.isRequired,
  editUser          : func.isRequired,
  userProps         : object.isRequired

};
export default UserModal;

