/* eslint-disable id-length */
import XLSX from 'sheetjs-style';
import {boldHeaders} from '../../utils/utils';
import {map} from 'lodash';
import {formatDate} from '../../utils/user';

// eslint-disable-next-line max-statements, complexity
export const exportToExcel = (user, excelData, fileName) => {
  let columnNumberMainSheet = 0;

  const userData = {
    Name         : user.name,
    Username     : user.username,
    Phone        : user.phone,
    Email        : user.email,
    Job          : user.job.name,
    Status       : user.status,
    Role         : user.role.name,
    Team         : map(Object.values(user.team), team => team.name).join(', '),
    PreviousTeam : map(Object.values(user?.previousTeam ?? {}), previousTeam => previousTeam?.name).join(', '),
    JoinDate     : formatDate(new Date(user.joinDate))
  };

  let header = Object.keys(userData);

  columnNumberMainSheet += header.length;
  const wsMain = XLSX.utils.json_to_sheet([userData]);

  const columns = 24;

  wsMain['!cols'] = Array(columns).fill({wch : 20});

  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, wsMain, 'Main');

  if (excelData.extras) {
    if (excelData.extras.relevantExperience) {
      const wbRelevantExperience = map(excelData.extras.relevantExperience, exp => ({
        Name        : excelData.name,
        Company     : exp.company,
        Position    : exp.position,
        Description : exp.description,
        StartDate   : formatDate(new Date(exp.startDate)),
        EndDate     : formatDate(new Date(exp.endDate))
      }));

      const wsRelevantExperience = XLSX.utils.json_to_sheet(wbRelevantExperience);

      XLSX.utils.book_append_sheet(wb, wsRelevantExperience, 'Relevant Experience');

      wsRelevantExperience['!cols'] = Array(Object.keys(wsRelevantExperience).length).fill({wch : 20});
      boldHeaders(wsRelevantExperience, 'FFFFFF00');
    }
    if (excelData.extras.projectsInfo) {
      const wbProjectsInfo = map(excelData.extras.projectsInfo, proj => ({
        Name             : excelData.name,
        Title            : proj.title,
        MainGoal         : proj.mainGoal,
        Responsabilities : proj.responsabilities,
        TechnicalSkills  : proj.technicalSkills
      }));

      const wsProjectsInfo = XLSX.utils.json_to_sheet(wbProjectsInfo);

      XLSX.utils.book_append_sheet(wb, wsProjectsInfo, 'Projects Experience');

      wsProjectsInfo['!cols'] = Array(Object.keys(wsProjectsInfo).length).fill({wch : 20});
      boldHeaders(wsProjectsInfo, 'FFFFFF00');
    }
    if (excelData.extras.bitstoneInfo) {
      const bitstoneInfo = {
        Benefits                : excelData.extras?.bitstoneInfo?.benefits?.join(', '),
        LearningAndDevelopement : excelData.extras?.bitstoneInfo?.learningAndDev,
        TeamBuildings           : excelData.extras?.bitstoneInfo?.teamBuildings,
        Holidays                : excelData.extras?.bitstoneInfo?.holidays,
        CurrentRole             : user.extras?.bitstoneInfo?.currentRole,
        About                   : user.extras?.bitstoneInfo?.about
      };


      XLSX.utils.sheet_add_json(wsMain, [bitstoneInfo], {skipHeader : false,
        origin     : {r : 0,
          c : columnNumberMainSheet}});
      header = Object.keys(bitstoneInfo);
      columnNumberMainSheet += header.length;
    }
    if (excelData.extras.personalInfo) {
      const personalInfo = {
        Hometown       : user.extras?.personalInfo?.hometown,
        CurrentAddress : user.extras?.personalInfo?.currentAddress,
        Education      : user.extras?.personalInfo?.education?.join(', '),
        Skils          : map(Object.values(user.extras?.personalInfo?.skills), skill => skill.name).join(', '),
        Languages      : user.extras?.personalInfo?.languages?.join(', '),
        Hobbies        : user.extras?.personalInfo?.hobbies?.join(', '),
        IsVegan        : user.extras?.personalInfo?.isVegan
      };

      XLSX.utils.sheet_add_json(wsMain, [personalInfo], {skipHeader : false,
        origin     : {r : 0,
          c : columnNumberMainSheet}});
    }
  }
  boldHeaders(wsMain, 'FFFF0000');

  XLSX.writeFile(wb, fileName);
};
