import {handleActions} from 'redux-actions';
import {ActionTypes} from 'action/auth';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';

const initialState = {
  isAuthenticated : false
};

const setTokenReducer = (state, {payload : accessToken}) => ({
  ...state,
  accessToken,
  isAuthenticated : true
});
const cleanupReducer = () => initialState;

const setLoginReducer = (state, {payload : firstLogin}) => ({
  ...state,
  firstLogin
});

const authReducer = handleActions({
  [ActionTypes.SET_TOKEN]      : setTokenReducer,
  [ActionTypes.SET_LOGIN_FLAG] : setLoginReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default authReducer;
