/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
import React from 'react';
import {STATUS} from 'constant';
import './ActionBar.scss';

import {
  Button,
  ClickAwayListener,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {bool, string, object, array, oneOfType, func} from 'prop-types';
import {Add, DeleteTwoTone, FilterAltTwoTone, Public, SortRounded} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {If} from 'components';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import CreateObjectiveDialog from '../CreateObjectiveDialog';

const sortBy = ['category', 'creation date', 'deadline', 'title'];

const useStyles = makeStyles({
  root : {
    position : 'relative',
    display  : 'block',
    width    : '100%'
  },
  display : {
    position      : 'absolute',
    top           : 2,
    left          : 0,
    bottom        : 2,
    background    : 'white',
    pointerEvents : 'none',
    right         : 50,
    display       : 'flex',
    alignItems    : 'center',
    width         : '100%',
    overflow      : 'hidden'
  },
  input : {}
});

function InputComponent ({defaultValue, inputRef, ...props}: any) {
  const [value, setValue] = React.useState(() => props.value || defaultValue);
  const classes = useStyles();

  const handleChange = event => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.display}>{moment(value).format('DD/MM/YYYY')}</div>
      <input
        className={classes.input}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}

InputComponent.displayName = 'InputComponent';

const ActionBar = props => {
  const [addObjectiveModal, setAddObjectiveModal] = React.useState(false);
  const [filtersVisibility, setFiltersVisibility] = React.useState(false);

  React.useEffect(() => {
    if (props.createObjectiveSuccessfull) {
      setAddObjectiveModal(false);
    }
  }, [props.createObjectiveSuccessfull]);

  React.useEffect(() => {
    if (props.isOwner || props.isTeamsManager) {
      props.getTeams();
    }
  }, [props.isOwner, props.isTeamsManager]);

  const getAllTeamsUsers = () => uniqBy(filter(reduce(props.teamMembers, (acc, value) => [ //eslint-disable-line
    ...acc,
    ...map(value, member => member)
  ], []), item => item.id), item => item.id);

  // eslint-disable-next-line complexity
  const getAuthorItems = team => {
    const teamMembers = getAllTeamsUsers();


    if (props.isOwner || props.isTeamsManager) {
      // eslint-disable-next-line no-negated-condition
      if (team !== undefined && team !== 'all') {
        return [
          <MenuItem key="all" value="all">All</MenuItem>,
          ...map(teamMembers, member => member.registered && member.team.includes(team) && <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>)
        ];
      }

      return [
        <MenuItem key="all" value="all">All</MenuItem>,
        ...map(teamMembers, member => {
          if (member.registered) {
            return <MenuItem key={member.id} style={{paddingLeft : 24}} value={member.id}>{member.name}</MenuItem>;
          // eslint-disable-next-line no-else-return
          } else {
            return;
          }
        })
      ];
    } else if (props.isTeamLead) {
      return [
        <MenuItem key="all" value="all">All</MenuItem>,
        ...map(uniqBy(Object.values(props.teamMembers), 'id'), member => member.registered && <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>)
      ];
    }

    return [
      <MenuItem key="all" value="all">All</MenuItem>,
      <MenuItem key={props.teamLead?.id} value={props.teamLead?.id}>{props.teamLead?.name}</MenuItem>,
      <MenuItem key={props.currentUserId} value={props.currentUserId}>{props.currentUserName}</MenuItem>
    ];
  };

  const getFilters = () => (
    <div className={props.isNewObjectButtonHidden ? 'action-bar__content__filter__floating action-bar__content__filter__floating-end' : 'action-bar__content__filter__floating'}>
      <If condition={props.isOwner || props.isTeamsManager}>
        <FormControl className="filter">
          <InputLabel id="team-label">Team</InputLabel>
          <Select
            defaultValue="all"
            id="team-select"
            labelId="team-label"
            onChange={event => {
              props.setFilters({
                ...props.filters,
                team    : event.target.value,
                ownerId : 'all'
              });
            }}
            value={props.filters.team}
            variant="standard"
          >
            <MenuItem value="all">All</MenuItem>
            {
              map(props.teams, (team, index) => (<MenuItem key={index.toString()} value={team}>{capitalize(team)}</MenuItem>))
            }
          </Select>
        </FormControl>
      </If>
      <If condition={props.isTeamLead || props.isTeamsManager}>
        <FormControl className="filter">
          <InputLabel id="assignee-select-label">Assignee</InputLabel>
          <Select
            id="assignee-select"
            labelId="assignee-select-label"
            onChange={event => props.setFilters({
              ...props.filters,
              ownerId : event.target.value
            })}
            value={props.filters.ownerId}
            variant="standard"
          >
            {getAuthorItems(props.filters.team)}
          </Select>
        </FormControl>
      </If>
      <FormControl className="filter">
        <InputLabel id="filter-author">Author</InputLabel>
        <Select
          id="filter-author-select"
          labelId="filter-author"
          name="author"
          onChange={event => props.setFilters({
            ...props.filters,
            authorId : event.target.value})}
          value={props.filters.authorId}
          variant="standard"
        >
          {getAuthorItems(props.filters.team)}
        </Select>
      </FormControl>
      <FormControl className="filter">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          defaultValue="all"
          id="status-select"
          labelId="status-select-label"
          onChange={event => props.setFilters({
            ...props.filters,
            status : event.target.value
          })}
          value={props.filters.status}
          variant="standard"
        >
          <MenuItem value="all">All</MenuItem>
          {
            map(STATUS, (status, index) => status !== STATUS.DELETED && (<MenuItem key={index.toString()} value={status}>{capitalize(status)}</MenuItem>))
          }
        </Select>
      </FormControl>

      <If condition={props.isTeamLead || props.isOwner}>
        <FormControl className="filter">
          <InputLabel id="job-title-label">Job Title</InputLabel>
          <Select
            defaultValue="all"
            id="job-title-select"
            labelId="job-title-label"
            onChange={event => props.setFilters({
              ...props.filters,
              job : event.target.value
            })}
            value={props.filters.job}
            variant="standard"
          >
            <MenuItem value="all">All</MenuItem>
            {
              map(props.jobs, (job, index) => (<MenuItem key={index.toString()} value={job}>{capitalize(job)}</MenuItem>))
            }
          </Select>
        </FormControl>
      </If>

      <FormControl className="filter">
        <TextField
          InputLabelProps={{shrink : true}}
          InputProps={{
            inputComponent : InputComponent
          }}
          id="creation-date"
          label="Creation Date"
          onChange={event => props.setFilters({
            ...props.filters,
            createdAt : event.target.value
          })}
          type="date"
          value={props.filters.createdAt}
          variant="standard"
        />
      </FormControl>

    </div>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleSortCriterionSelection = event => {
    const {myValue} = event.currentTarget.dataset;

    props.setSortCriterion(myValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="action-bar">
      <div className="action-bar__content">
        <If condition={!props.isNewObjectButtonHidden}>
          <div className="action-bar__content__sort">
            <Button
              aria-controls="basic-menu"
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              id="basic-button"
              onClick={handleClick}
              size="small"
              startIcon={<SortRounded />}
              variant="outlined"
            >
              Sort by
            </Button>
            <Menu
              MenuListProps={{
                'aria-labelledby' : 'basic-button'
              }}
              anchorEl={anchorEl}
              id="basic-menu"
              onClose={handleClose}
              open={open}
            >
              {
              // eslint-disable-next-line max-len
                map(sortBy, (criterion, index) => (
                  <MenuItem
                    data-my-value={criterion === 'creation date' ? 'createdAt' : criterion} key={index.toString()} onClick={handleSortCriterionSelection}
                    // eslint-disable-next-line
                    style={((criterion === 'creation date' ? 'createdAt' : criterion) === props.sortCriterion) ? {backgroundColor : 'rgba(251, 243, 243, 2)',
                      borderRadius    : '5px'} : {}}
                  >{capitalize(criterion)}</MenuItem>))
              }
            </Menu>
          </div>
        </If>
        <div className="action-bar__content__filter">
          <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => setFiltersVisibility(false)}>
            <div>

              <Button
                className="filters-button" color="primary" onClick={() => setFiltersVisibility(!filtersVisibility)}
                size="small"
                startIcon={<FilterAltTwoTone />}
                variant="outlined"
              >
                Filters
              </Button>
              {filtersVisibility && getFilters()}
            </div>

          </ClickAwayListener>
          <Button
            color="error"
            onClick={() => {
              if (props.isPublicSet) {
                props.setFilters({
                  ownerId  : 'all',
                  authorId : 'all',
                  privacy  : true
                });
              } else {
                props.setFilters({
                  ownerId  : props.isTeamLead || props.isOwner || props.isTeamsManager ? 'all' : props.currentUserId,
                  authorId : 'all'
                });
              }
            }}
            size="small"
            startIcon={<DeleteTwoTone />}
            variant="outlined"
          >
            Clear Filters
          </Button>

          <If condition={!props.isNewObjectButtonHidden}>
            <Button
              color="info"
              onClick={event => props.handlePublicObjectives(event)}
              size="small"
              startIcon={<Public />}
              variant="outlined"
            >{props.showPublicText}
            </Button>
            <Button
              className="new-objective-button" color="secondary" onClick={() => setAddObjectiveModal(true)}
              size="small"
              startIcon={<Add />}
              variant="contained"
            >
              New Objective
            </Button>
          </If>
        </div>
      </div>


      <CreateObjectiveDialog
        currentUserId={props.currentUserId}
        currentUserName={props.currentUserName}
        currentUserTeam={props.currentUserTeam}
        isTeamLead={props.isTeamLead}
        modalText="Complete the following form to create a new objective"
        modalTitle="New objective"
        setVisible={setAddObjectiveModal}
        teamMembers={props.teamMembers}
        visible={addObjectiveModal}
      />
    </div>
  );
};


ActionBar.displayName = 'ActionBar';
ActionBar.propTypes = {
  isTeamLead                 : bool.isRequired,
  isOwner                    : bool.isRequired,
  isTeamsManager             : bool.isRequired,
  currentUserName            : string.isRequired,
  currentUserId              : string.isRequired,
  currentUserTeam            : string.isRequired,
  setFilters                 : func.isRequired,
  getTeams                   : func.isRequired,
  filters                    : object.isRequired,
  teamLead                   : object.isRequired,
  isNewObjectButtonHidden    : bool,
  createObjectiveSuccessfull : bool.isRequired,
  teamMembers                : oneOfType([array, object]).isRequired,

  sortCriterion    : string.isRequired,
  setSortCriterion : func.isRequired,

  jobs  : oneOfType([array, object]).isRequired,
  teams : oneOfType([array, object]).isRequired,

  isPublicSet            : bool,
  handlePublicObjectives : func.isRequired,
  showPublicText         : string.isRequired

};

ActionBar.defaultProps = {
  isNewObjectButtonHidden : false,
  isPublicSet             : false
};

export default ActionBar;
