import {connect} from 'react-redux';
import ExtraFieldsComponent from './ExtraFieldsComponent';
import {getHasCallSucceeded} from 'selector/app';
import {apiRequestAction} from 'action/apiResource';
import {getUserCapabilities, getAllUsers, getProjectsTitle} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getJobs} from '../../store/selector/job';
import {getSkills} from '../../store/selector/skill';

const mapStateToProps = state => ({
  jobs                   : getJobs(state),
  teams                  : getTeams(state),
  capabilities           : getUserCapabilities(state),
  users                  : getAllUsers(state),
  skills                 : getSkills(state),
  projects               : getProjectsTitle(state),
  addNewSkillsSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.skills.createSkills.requestTracker
  })
});

const mapDispatchToProps = {
  getObjectives : apiRequestAction.objective.getObjectives({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.user.getTeams({
    skipEntityIdCheck : true
  }),
  deleteUser : apiRequestAction.user.deleteUser({
    skipEntityIdCheck : true
  }),
  restoreUser : apiRequestAction.user.restoreUser({
    skipEntityIdCheck : true
  }),
  editUser : apiRequestAction.user.editUser({
    skipEntityIdCheck : true
  }),
  createSkills : apiRequestAction.skills.createSkills({
    skipEntityIdCheck : true
  }),
  getSkills : apiRequestAction.skills.getSkills({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFieldsComponent);
