import React, {useEffect} from 'react';
import {object, array, oneOfType, string, func} from 'prop-types';
import {InputText} from 'primereact/inputtext';
import Box from '@mui/material/Box';
import {filter} from 'lodash';
import './SearchBar.scss';

/**
 * Search bar component
 */
const SearchBar = props => {
  useEffect(() => {
    const filterAll = filter(props.allMembers, member => member.status === 'active');

    const search = filter(filterAll, member =>
      member.name.toLowerCase().includes(props.searchInput.toLowerCase()));

    props.setFilteredMembers(search);
  }, [props.searchInput]);

  const handleOnChange = event => {
    props.setSearchInput(event.target.value);
  };

  return (
    <div className="search">
      <Box className="search-bar">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            onChange={handleOnChange}
            placeholder="Search..."
            type="search"
            value={props.searchInput}
          />
        </span>
      </Box>
    </div>
  );
};

SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = {
  setSearchInput       : func.isRequired,
  searchInput          : string.isRequired,
  allMembers           : oneOfType([array, object]).isRequired,
  filteredMembers      : oneOfType([array, object]).isRequired,
  setFilteredMembers   : func.isRequired,
  setCurrentTeamFilter : func.isRequired,
  setCurrentJobFilter  : func.isRequired,
  setStatusFilter      : func.isRequired
};
export default SearchBar;
