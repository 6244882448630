const ROUTE_PATH = {
  LOGIN              : '/login',
  OBJECTIVES         : '/objective-tracking/objectives',
  PLANS              : '/objective-tracking/plans',
  OBJECTIVE_DETAILS  : '/objective-tracking/objective-details',
  COMPLETIONAPPROVAL : '/objective-tracking/approval',
  TIMELINE           : '/objective-tracking/timeline',
  EMPLOYEE_DETAILS   : '/objective-tracking/employee-details',
  STATISTICS         : '/objective-tracking/statistics',
  APPROVALS          : '/objective-tracking/approvals',
  TEAM               : '/super-awesome-team',
  DASHBOARD          : '/dashboard',
  TIMETRACKING       : '/timetracking',
  HOLIDAYS           : '/holidays',
  ADMIN_USERS        : '/admin',
  PROFILE            : '/profile'
};

export {
  ROUTE_PATH
};
