import React from 'react';
import {object, string} from 'prop-types';
import './CVDocument.scss';
import {ReactComponent as LogoBit} from '../assets/icons/logo-alb-bit.svg';


const CVDocument = props => (
  <div id="pdf-content">
    {props &&
      <div className="cv-container">
        <div className="left-side" style={{backgroundImage : 'url(red-waves.png)'}}>
          <div className="logo">
            <LogoBit />
          </div>
          <div className="profile-image">
            <div className="image">
              <div
                className="img" style={{backgroundImage    : `url(${props.imageUrl})`,
                  backgroundSize     : 'cover',
                  backgroundPosition : 'center',
                  width              : '203px',
                  height             : '175px'
                }}
              />
            </div>
            <p className="title">{props.user.name}</p>
            <p className="job">{props.user.job.name}</p>
          </div>
          {props.user?.extras?.personalInfo?.skills &&
          <div className="skills">
            <h4 style={{fontWeight : 'bold'}}>KEY COMPETENCIES</h4>
            <ul>
              {Object.values(props.user?.extras?.personalInfo?.skills).map(skill => (
                <li key={skill.id}>{skill.name}</li>
              ))}
            </ul>
          </div>
          }
          {props.user?.extras?.personalInfo?.education &&
          <div className="education">
            <h4 style={{fontWeight : 'bold'}}>EDUCATION</h4>
            <ul>
              {(props.user?.extras?.personalInfo?.education).map(education => (
                <li key={education}>{education}</li>
              ))}
            </ul>
          </div>
          }
          {props.user?.extras?.personalInfo?.languages &&
          <div className="languages">
            <h4 style={{fontWeight : 'bold'}}>LANGUAGES</h4>
            <ul>
              {(props.user?.extras?.personalInfo?.languages).map(lang => (
                <li key={lang}>{lang}</li>
              ))}
            </ul>
          </div>
          }
        </div>
        <div className="right-side">
          <div className="section">
            <div className="headings">
              <div className="line" />
              <label style={{fontWeight : 'bold'}}>ABOUT</label>
            </div>
            <p>{props.user?.extras?.bitstoneInfo?.about}</p>
          </div>
          <div className="section">
            <div className="headings">
              <div className="line" />
              <label style={{fontWeight : 'bold'}}>CURRENT ROLE</label>
            </div>
            <p>{props.user?.extras?.bitstoneInfo?.currentRole}</p>
          </div>
          <div className="section">
            <div className="headings">
              <div className="line" />
              <label style={{fontWeight : 'bold'}}>PROJECT EXPERIENCE</label>
            </div>
            {props.user?.extras?.projectsInfo && Object.values(props.user.extras.projectsInfo).map(project => (
              <div className="project" key={project.title}>
                <h3>{project.title}</h3>
                <p><span className={project.mainGoal ? '' : 'hidden'}>Main Goal:</span> {project.mainGoal}</p>
                <p><span className={project.responsabilities ? '' : 'hidden'}>Responsibilities:</span> {project.responsabilities}</p>
                <p><span className={project.technicalSkills ? '' : 'hidden'}>Technical Skills:</span> {project.technicalSkills}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    }
  </div>
);

CVDocument.displayName = 'CVDocument';
CVDocument.propTypes = {
  user     : object.isRequired,
  imageUrl : string.isRequired
};
export default CVDocument;

