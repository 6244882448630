import React from 'react';
import {Card, CardContent, LinearProgress, Typography} from '@mui/material';
import {string, number, func, bool} from 'prop-types';
import './OverviewCard.scss';
import {ROUTE_PATH} from 'constant';
import {useHistory} from 'react-router-dom';

const OverviewCard = ({userName, userId, userEmail, userTeam, completed, handleCardClick, overdue, total, myCard}) => {
  const history = useHistory();

  return (
    <div className="overview-card" onClick={() => handleCardClick(userEmail)} >
      <Card className={(myCard ? 'my-card' : '')}>
        <CardContent>
          <Typography
            className="overview-card__name"
            component="div" onClick={() => {
              if (!history.location.pathname.includes('employee-details')) {
                history.push({
                  pathname : ROUTE_PATH.EMPLOYEE_DETAILS,
                  search   : `?id=${userId}`,
                  state    : {
                    userId
                  }
                });
              }
            }} variant="h6"
          >
            {userName}
          </Typography>
          <Typography
            className="overview-card__email"
            gutterBottom
            variant="h6"
          >
            <em>
              {userEmail}
            </em>
          </Typography>
          <Typography
            className="overview-card__team"
          >
            {userTeam && Array.isArray(userTeam) && userTeam.map(team => (
              <p key={team}> { team } </p>
            )) || <p> {userTeam || 'No team'} </p>}
          </Typography>
          <section className="card__progress card__progress--no-padding-horizontal">
            <div className="card__progress__bar">
              <span>{completed} of {total} Objectives completed</span>
              <LinearProgress value={completed / total * 100} variant="determinate" /> { /* eslint-disable-line no-magic-numbers */}
            </div>
          </section>
          <section className="card__progress card__progress--no-padding-horizontal">
            <div className="card__progress__bar">
              <span>{overdue} of {total} Objectives overdue</span>
              <LinearProgress value={overdue / total * 100} variant="determinate" /> { /* eslint-disable-line no-magic-numbers */}
            </div>
          </section>
        </CardContent>
      </Card>
    </div>
  );
};

OverviewCard.displayName = 'Card';
OverviewCard.propTypes = {
  userName        : string.isRequired,
  userEmail       : string.isRequired,
  userTeam        : string.isRequired,
  userId          : string.isRequired,
  completed       : number.isRequired,
  overdue         : number.isRequired,
  total           : number.isRequired,
  myCard          : bool.isRequired,
  handleCardClick : func.isRequired

};

export default OverviewCard;
