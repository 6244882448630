import JobsTable from './JobsTable';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress} from 'selector/app';
import {getAllUsers, getUserCapabilities} from 'selector/user';
import {getJobs} from '../../store/selector/job';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  capabilities : getUserCapabilities(state),
  jobs         : getJobs(state),
  users        : getAllUsers(state)
});

const mapDispatchToProps = {
  getJobs : apiRequestAction.job.getJobs({
    skipEntityIdCheck : true
  }),
  updateJob : apiRequestAction.job.updateJob({
    skipEntityIdCheck : true
  }),
  createJob : apiRequestAction.job.createJob({
    skipEntityIdCheck : true
  }),
  deleteJob : apiRequestAction.job.deleteJob({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsTable);
