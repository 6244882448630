import React from 'react';
import {connect} from 'react-redux';
import {setSearchInputValue} from '../store/action/setSearchInputValue';
import {func, object} from 'prop-types';
import {InputText} from 'primereact/inputtext';
import {getSearchInputValue} from '../store/selector/searchInputValue';

const SearchRedux = props => {
  const handleSearchInputChange = event => {
    const value = event.target.value;

    props.setSearchInputValue(value);
  };

  return (
    <div className="search-bar">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          onChange={handleSearchInputChange}
          placeholder="Search"
          type="search"
          value={props.searchInputValue.searchInputValue}
        />
      </span>
    </div>
  );
};

SearchRedux.displayName = 'SearchRedux';

SearchRedux.propTypes = {
  setSearchInputValue : func.isRequired,
  searchInputValue    : object.isRequired
};

const mapStateToProps = state => ({
  searchInputValue : getSearchInputValue(state)

});

export default connect(mapStateToProps, {setSearchInputValue})(SearchRedux);
