import * as React from 'react';
import './NotFound.scss';

const NotFound = () => (
  <div className="not-found-page" id="NotFound">
    <h1> 404 </h1>
    <h2> Page not found </h2>

  </div>
);

NotFound.displayName = 'NotFound';

export default NotFound;
