import ViewHolidays from './ViewHolidays';
import {connect} from 'react-redux';
import {getTeams} from '../../store/selector/team';
import {getAllUsers} from 'selector/user';
import {apiRequestAction} from 'action/apiResource';
import {getDaysLeft, getAllHolidays} from '../../store/selector/holidays';
import {getUser} from '../../store/selector/user';
import {getSearchInputValue} from '../../store/selector/searchInputValue';


const mapStateToProps = state => ({
  searchInputValue : getSearchInputValue(state),
  user             : getUser(state),
  teams            : getTeams(state),
  users            : getAllUsers(state),
  daysLeft         : getDaysLeft(state),
  allHolidays      : getAllHolidays(state)
});

const mapDispatchToProps = {
  getDaysLeft : apiRequestAction.holidays.getDaysLeft({
    skipEntityIdCheck : true
  }),
  getAllHolidays : apiRequestAction.holidays.getAllHolidays({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHolidays);
