import Login from './Login';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress, getHasCallFailed, getHasCallSucceeded} from 'selector/app';
import {getApiResponseError} from 'selector/apiResponse';
import map from 'lodash/map';
import {getTeamMembers} from '../../store/selector/teamMembers';
import {isCurrentUserTeamLead, isCurrentUserOwner} from '../../store/selector/user';
import {getUser} from 'selector/user';
import {getFirstLogin} from 'selector/auth';

const mapStateToProps = state => {
  const errors = getApiResponseError(state, {
    requestTrackerError : apiRequestAction.auth.login.requestTracker
  });

  const formSubmitErrors = map(errors, error => error.apiErrorMessage).join(', ');

  return {
    user       : getUser(state),
    firstLogin : getFirstLogin(state),
    isLoading  : getIsCallInProgress(state, {
      requestTracker : apiRequestAction.auth.login.requestTracker
    }) || getIsCallInProgress(state, {
      requestTracker : apiRequestAction.user.getTeamMembers.requestTracker
    }),
    iscallFailed : getHasCallFailed(state, {
      requestTracker : apiRequestAction.auth.login.requestTracker
    }),
    hasLoggedInSuccessfully : getHasCallSucceeded(state, {
      requestTracker : apiRequestAction.auth.login.requestTracker
    }),
    wasInviteSuccessful : getHasCallSucceeded(state, {
      requestTracker : apiRequestAction.user.inviteTeamMember.requestTracker
    }),
    hasGetTeamMembersSucceeded : getHasCallSucceeded(state, {
      requestTracker : apiRequestAction.user.getTeamMembers.requestTracker
    }),
    teamMembers : getTeamMembers(state),
    isTeamLead  : isCurrentUserTeamLead(state) && !isCurrentUserOwner(state),
    formSubmitErrors
  };
};

const mapDispatchToProps = {
  login            : apiRequestAction.auth.login(),
  getTeamMembers   : apiRequestAction.user.getTeamMembers(),
  inviteTeamMember : apiRequestAction.user.inviteTeamMember()
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
