import {createSelector} from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';

const objectiveSelector = state => state.objective;
const objectiveIdSelector = (state, id) => id;

export const getObjectives = createSelector(
  [objectiveSelector],
  objectives => get(objectives, 'items', [])
);

export const getTotalObjectives = createSelector(
  [objectiveSelector],
  objectives => get(objectives, 'total', 0)
);

export const getSingleObjective = createSelector(
  [objectiveSelector],
  objectiveState => get(objectiveState, 'objective', {})
);

export const getObjectiveById = createSelector(
  [getObjectives, objectiveIdSelector],
  (objectives, id) => find(objectives, item => item.id === id)
);
