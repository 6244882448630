import React from 'react';
import {Button, Modal} from '@mui/material';
import {func, bool} from 'prop-types';
import './Modal.scss';


const ModalLogin = props => {
  const modalBody = (
    <div className="container-modal">
      <div className="modalDiv">
        <p>Contact DevOps or Isa to change the password.</p>
        <Button color="primary" onClick={props.handleClose} variant="contained">Ok</Button>
      </div>
    </div>
  );

  return (
    <Modal
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      onClose={props.handleClose}
      open={props.openModal}
    >
      {modalBody}
    </Modal>
  );
};

ModalLogin.displayName = 'Modal';
ModalLogin.propTypes = {
  openModal   : bool.isRequired,
  handleClose : func.isRequired
};

export default ModalLogin;
