import {createSelector} from 'reselect';
import get from 'lodash/get';

const roleSelector = state => state.role;

export const getRoles = createSelector(
  [roleSelector],
  roles => get(roles, 'roles', {})
);

