import {createSelector} from 'reselect';
import get from 'lodash/get';
import {REQUEST_STATUS} from 'constant';

const requestTrackerSelector = (state, {requestTracker}) => requestTracker;

export const getApp = state => state.app;

export const getRequestTracker = createSelector(
  getApp,
  app => get(app, 'requestTracker', {})
);

export const getRequestTrackerStatus = createSelector(
  [getRequestTracker, requestTrackerSelector],
  (requestTracker, requestId) => get(requestTracker, requestId, '')
);

export const getHasCallSucceeded = createSelector(
  [getRequestTrackerStatus],
  status => status === REQUEST_STATUS.SUCCEEDED
);

export const getHasCallFailed = createSelector(
  [getRequestTrackerStatus],
  status => status === REQUEST_STATUS.FAILED
);

export const getIsCallInProgress = createSelector(
  [getRequestTrackerStatus],
  status => status === REQUEST_STATUS.IN_PROGRESS
);
