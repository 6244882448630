import Timeline from './Timeline';
import {connect} from 'react-redux';
import {getObjectives} from 'selector/objective';
import {apiRequestAction} from 'action/apiResource';
import {isCurrentUserTeamLead, getUserId, getUserName, getTeamLeader, isCurrentUserOwner, getUser} from 'selector/user';
import {getTeamMembers} from 'selector/teamMembers';
import {getIsCallInProgress} from 'selector/app';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.getObjectives.requestTracker
  }),
  objectives      : getObjectives(state),
  isTeamLead      : isCurrentUserTeamLead(state),
  isTeamsManager  : getUser(state).isTeamsManager,
  isOwner         : isCurrentUserOwner(state),
  currentUserId   : getUserId(state),
  currentUserName : getUserName(state),
  teamLeader      : getTeamLeader(state),
  teamMembers     : getTeamMembers(state)
});
const mapDispatchToProps = {
  getObjectives : apiRequestAction.objective.getObjectives(),
  getTeamLead   : apiRequestAction.user.getTeamLeader()
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
