import React from 'react';

import {
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {string, func, bool} from 'prop-types';


const Modal = ({
  title,
  text,
  modalContent,
  modalActions,
  handleClose,
  open
}) => (
  <div>
    <Dialog aria-labelledby="form-dialog-title" onClose={handleClose} open={open}>
      <DialogTitle id="form-dialog-title">{title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position : 'absolute',
              right    : 8,
              top      : 8,
              color    : theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {Boolean(text) && <DialogContentText> {text} </DialogContentText>}
        {modalContent()}
      </DialogContent>
      <DialogActions style={{margin : '0 10px 10px 0'}}>
        {Boolean(modalActions()) && modalActions() || <Button onClick={handleClose}> Ok </Button>}
      </DialogActions>
    </Dialog>
  </div>
);

Modal.displayName = 'Modal';
Modal.propTypes = {
  title       : string.isRequired,
  handleClose : func.isRequired,
  open        : bool.isRequired,

  modalContent : func,
  modalActions : func,
  text         : string
};

Modal.defaultProps = {
  text         : '',
  modalContent : () => '',
  modalActions : () => '' //eslint-disable-line
};

export default Modal;
