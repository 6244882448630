import React from 'react';
import TextField from '@mui/material/TextField';
import './StatisticsFilter.scss';
import {object, array, oneOfType, func, bool} from 'prop-types';
import {InputAdornment, MenuItem} from '@mui/material';
import {Event} from '@mui/icons-material';
import {If} from 'components';

const StatisticsFilter = props => (
  <div className="statistics-wrapper">
    <div className="statistics-filters">
      <If condition={props.isOwner}>
        <TextField
          className="statistics-filters__filter"
          label="Team"
          onChange={event => props.setFilters({
            ...props.filters,
            team : event.target.value
          })}
          select
          style={{margin : '0 6px 0 10px'}}
          value={props.filters.team}
          variant="standard"
        >
          <MenuItem key="all" value="all">All</MenuItem>
          {props.teams.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </If>
      <TextField
        className="statistics-filters__filter"
        label="Job"
        onChange={event => props.setFilters({
          ...props.filters,
          job : event.target.value
        })}
        select
        style={{margin : '0 6px'}}
        value={props.filters.job}
        variant="standard"
      >
        <MenuItem key="all" value="all">All</MenuItem>
        {props.jobs.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        InputLabelProps={{shrink : true}}
        InputProps={{
          endAdornment : (
            <InputAdornment position="end">
              <Event fontSize="small" />
            </InputAdornment>
          )
        }}
        className="statistics-filters__filter"
        label="From"
        onChange={event => props.setFilters({
          ...props.filters,
          from : event.target.value
        })}

        style={{margin : '0 6px 0 12px'}}
        type="date"
        value={props.filters.from}
        variant="standard"
      />
      <TextField
        InputLabelProps={{shrink : true}}
        InputProps={{
          endAdornment : (
            <InputAdornment position="end">
              <Event fontSize="small" />
            </InputAdornment>
          )
        }}

        className="statistics-filters__filter"
        label="To"
        onChange={event => props.setFilters({
          ...props.filters,
          to : event.target.value
        })}
        style={{margin : '0 10px 0 6px'}}
        type="date"
        value={props.filters.to}
        variant="standard"
      />
    </div>
  </div>
);


StatisticsFilter.displayName = 'StatisticsFilter';
StatisticsFilter.propTypes = {

  jobs       : oneOfType([array, object]).isRequired,
  teams      : oneOfType([array, object]).isRequired,
  setFilters : func.isRequired,
  filters    : object.isRequired,
  isOwner    : bool.isRequired

};

export default StatisticsFilter;
