import {Button} from '@mui/material';
import * as React from 'react';
import {func, bool} from 'prop-types';
import './CompletionApproval.scss';
import {STATUS} from '../../constant/models';
import {Loader, If} from 'components';

// eslint-disable-next-line complexity
const CompletionApproval = ({editObjective, editKeyResult, editObjectiveSuccessfull, isLoading, editKeyResultSuccessfull, ...props}) => {
    const search = props.location.search; // eslint-disable-line
  const params = new URLSearchParams(search);
  const objectiveId = params.get('objectiveId');
  const keyResultId = params.get('keyResultId');
  const deleteItem = params.get('delete');
  const missingIds = !(objectiveId || keyResultId);


  const handleApproveCompletion = () => {
    if (deleteItem) {
      objectiveId ? editObjective({ entityId: objectiveId, status: STATUS.DELETED }) : editKeyResult({ entityId: keyResultId, status: STATUS.DELETED });// eslint-disable-line
    } else {
      objectiveId ? editObjective({ entityId: objectiveId, status: STATUS.COMPLETED }) : editKeyResult({ entityId: keyResultId, status: STATUS.COMPLETED });// eslint-disable-line
    }
  };

  // eslint-disable-next-line complexity
  const defaultContent = () => (
    <div className="completion-approval">
      {!deleteItem && <div className="completion-approval__text">
        {objectiveId && 'Someone wants to complete an objective.'}
        {keyResultId && 'Someone wants to complete a key result.'}
      </div>}
      {deleteItem && <div className="completion-approval__text">
        {objectiveId && 'Someone wants to delete an objective.'}
        {keyResultId && 'Someone wants to delete a key result.'}
      </div>}
      <div className="completion-approval__actions">
        <Button color="primary" onClick={handleApproveCompletion} variant="outlined">
          Approve
        </Button>
      </div>
    </div>
  );

  // eslint-disable-next-line complexity
  const acceptContent = () => (
    <div className="completion-approval" >
      {!deleteItem && <div className="completion-approval__message">
        You have successfully approved the {objectiveId && 'objective'} {keyResultId && 'key result'} completion.
      </div>}
      {deleteItem && <div className="completion-approval__message">
        You have successfully approved the {objectiveId && 'objective'} {keyResultId && 'key result'} deletion.
      </div>}
    </div>
  );

  const missingIdsContent = () => (
    <div className="completion-approval" >
      <div className="completion-approval__error">
        <b>Something went wrong</b>
        <p>There is no objective or key result completion or deletion to approve. </p>
      </div>
    </div>
  );

  return (

    <Loader isLoading={isLoading} >
      <If
        condition={!props.isCallFailed}
        otherwise={() => (
          <div className="completion-approval">
            <b>Approval couldn&#39;t complete. </b>
            <p>The objective or key result doesn&#39;t exist.</p>
          </div>
        )}
      >
        {!missingIds && !editObjectiveSuccessfull && !editKeyResultSuccessfull && defaultContent()}
        {missingIds && missingIdsContent()}
        {(editObjectiveSuccessfull || editKeyResultSuccessfull) && acceptContent()}
      </If>
    </Loader>

  );
};

CompletionApproval.displayName = 'CompletionApproval';
CompletionApproval.propTypes = {
  editObjective : func.isRequired,
  editKeyResult : func.isRequired,

  isLoading                : bool.isRequired,
  isCallFailed             : bool.isRequired,
  editObjectiveSuccessfull : bool.isRequired,
  editKeyResultSuccessfull : bool.isRequired
};

export default CompletionApproval;
