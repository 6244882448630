import UsersTable from './UsersTable';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress} from 'selector/app';
import {getAllUsers, getUserCapabilities} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getRoles} from '../../store/selector/role';
import {getUser} from '../../store/selector/user';
import {getJobs} from '../../store/selector/job';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  users        : getAllUsers(state),
  capabilities : getUserCapabilities(state),
  teams        : getTeams(state),
  jobs         : getJobs(state),
  roles        : getRoles(state),
  user         : getUser(state)
});

const mapDispatchToProps = {
  getAllUsers : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  }),
  deleteUser : apiRequestAction.user.deleteUser({
    skipEntityIdCheck : true
  }),
  restoreUser : apiRequestAction.user.restoreUser({
    skipEntityIdCheck : true
  }),
  addUser : apiRequestAction.user.addUser({
    skipEntityIdCheck : true
  }),
  editUser : apiRequestAction.user.editUser({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
