import omit from 'lodash/omit';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';

import BuildConfig from 'service/BuildConfig';
import ReduxSagaMiddleware from './ReduxSagaMiddleware';
import {
  API_METHOD,
  OBJECTIVE_ENTITY_ID_REGEX
} from 'constant';

import {
  auth,
  notAuthorized,
  payloadFormatter,
  toJson
} from 'service/api/middleware';

export const fetchWrap = new ReduxSagaMiddleware(BuildConfig.API_ENDPOINT)
  .use(auth())
  .use(notAuthorized())
  .use(toJson())
  .use(payloadFormatter());

const ENTITY_ID_WHITELIST = [API_METHOD.GET, API_METHOD.UPDATE, API_METHOD.DELETE];
const ERROR_LOG = 'background: #FF0000; color: #FFFFFF; font-size: 20px;';

// eslint-disable-next-line complexity, max-statements
const request = ({payload = {}, meta}) => {
  const {
    url,
    method,
    resourceMethod,
    skipEntityIdCheck = false
  } = meta;
  const {entityId, clientEntityId} = payload;

  let finalUrl = url;

  if (includes(ENTITY_ID_WHITELIST, resourceMethod) && !skipEntityIdCheck) {
    if (isUndefined(entityId)) {
      console.error('%c You must provide entityId in the payload', ERROR_LOG); //eslint-disable-line

      return false;
    }

    finalUrl = `${finalUrl}/${entityId}`;
  } else if (resourceMethod === API_METHOD.CREATE && entityId && !skipEntityIdCheck) {
    finalUrl = `${finalUrl}/${entityId}`;
  }

  if (OBJECTIVE_ENTITY_ID_REGEX.test(finalUrl)) {
    if (isUndefined(clientEntityId)) {
      console.error('%c You must provide objectiveEntityId in the action payload!', ERROR_LOG); //eslint-disable-line
    }

    finalUrl = finalUrl.replace(OBJECTIVE_ENTITY_ID_REGEX, clientEntityId);
  }


  const OMIT_PROPS = ['entityId', 'objectiveEntityId'];
  const finalPayload = payload instanceof FormData ? payload : omit(payload, OMIT_PROPS);

  return fetchWrap[toLower(method)](finalUrl, finalPayload);
};

export default request;
