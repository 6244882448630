/* global __VAR__, __DEVELOPMENT__*/

class BuildConfig {
  static get API_ENDPOINT () {
    return __VAR__.API_ENDPOINT;
  }

  static get SECURITY_TOKEN () {
    return __VAR__.SECURITY_TOKEN;
  }

  static get isDevelopment () {
    return __DEVELOPMENT__;
  }
}

export default BuildConfig;
