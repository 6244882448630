import {Button, SwipeableDrawer} from '@mui/material';
import * as React from 'react';
import {useHistory} from 'react-router';
import {func, bool, string, object} from 'prop-types';
import {Loader} from 'components';
import {ROUTE_PATH} from 'constant';
import {getUserImageUrl} from '../../utils/user';

import './Drawers.scss';

const UserDetailsDrawer = ({
  open,
  toggleDrawer,
  name,
  email,
  logout,
  isLoading,
  user
}) => {
  const history = useHistory();

  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    const imageUrlTemp = getUserImageUrl(user);

    setImageUrl(imageUrlTemp);
  }, [user]);


  return (
    <Loader className="drawer" isLoading={isLoading} >
      <SwipeableDrawer
        anchor={'right'}
        onClose={toggleDrawer(false, false)}
        onOpen={toggleDrawer(false, true)}
        open={open}
      >
        <div className="drawer__content">
          <div className="profile-info">
            <div className="my-account-img">
              <img src={imageUrl} />
            </div>
            <div className="user-info">
              <p className="p-name">{name}</p>
              <p className="p-email">{email}</p>
            </div>
          </div>

          <Button
            color="primary" onClick={() => {
              history.push({
                pathname : ROUTE_PATH.PROFILE + `/${user.id}`
              });
            }} size="small"
            variant="outlined"
          >View profile</Button>

        </div>
        <div className="drawer__footer">
          <Button
            color="primary" onClick={() => logout()}
            variant="contained"
          >Logout</Button>
        </div>
      </SwipeableDrawer>
    </Loader>
  );
};

UserDetailsDrawer.propTypes = {
  isLoading    : bool.isRequired,
  open         : bool.isRequired,
  name         : string.isRequired,
  email        : string.isRequired,
  toggleDrawer : func.isRequired,
  logout       : func.isRequired,
  user         : object.isRequired
};
UserDetailsDrawer.displayName = 'UserDetailsDrawer';

export default UserDetailsDrawer
;
