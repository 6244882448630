import React, {useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import map from 'lodash/map';
import {filter, size} from 'lodash';
import uniqBy from 'lodash/uniqBy';
import reduce from 'lodash/reduce';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import {CATEGORY, PRIORITY, SUBCATEGORY} from 'constant';
import {Modal} from 'components';

import moment from 'moment';
import {bool, func, object, array, string, oneOfType} from 'prop-types';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import './CreateObjectiveDialog.scss';
import {
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';

// eslint-disable-next-line complexity
const CreateObjectiveDialog = ({
  isTeamLead,
  currentUserName,
  currentUserId,
  currentUserTeam,
  teamMembers,
  createObjective,
  editObjective,
  visible,
  setVisible,
  modalTitle,
  modalText,
  isEditMode,
  initialValues,
  ...props
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const validationSchema = yup.object({
    title       : yup.string().required('Title is required'),
    team        : yup.string().required('Team is required'),
    assignee    : yup.string().required('Assignee is required'),
    description : yup.string().required('Description is required'),
    category    : yup.string().required('Category is required'),
    subcategory : yup.string().required('Subcategory is required')
      .nullable(),
    priority : yup.string().required('Priority is required'),
    deadline : yup.string()
  });
  const [isPublic, setIsPublic] = React.useState(Boolean(initialValues.privacy));

  const newObjectForm = useFormik({
    initialValues : {
      ...initialValues,
      assignee : (isTeamLead || props.isOwner || props.isTeamsManager) ? (isEditMode ? initialValues.assignee : '') : currentUserName, //eslint-disable-line
      team     : props.isOwner || props.isTeamsManager ? initialValues.team && initialValues.team[0] : Array.isArray(currentUserTeam) && currentUserTeam[0] || currentUserTeam
    },
    validationSchema,
    enableReinitialize : true,

    onSubmit : (values, {resetForm}) => {
      const authorId = currentUserId;

      if (isEditMode) {
        editObjective({
          entityId : initialValues.id,
          ...values,
          privacy  : isPublic
        });
      } else {
        createObjective({
          ...values,
          deadline : values.deadline ? values.deadline : moment().format('YYYY-MM-DD'),
          ownerId  : isTeamLead || props.isOwner || props.isTeamsManager ? values.assignee : currentUserId,
          authorId,
          privacy  : isPublic
        });
        resetForm({});
      }

      setVisible(false);
    }
  });

  const renderModalActions = () => (
    <div className="form__actions">
      <Button
        color="primary" onClick={() => {
          setVisible(false);
          newObjectForm.resetForm({});
        }} size="small"
        variant="contained"
      > Cancel </Button>
      <Button
        color="secondary"
        onClick={() => {
          newObjectForm.handleSubmit();
        }}
        size="small"
        type="submit"
        variant="contained"
      > Save </Button>
    </div>
  );

  const getTextField = ({name, label, children, required = true, select = false, isDisabled, type = 'text', onChange = () => null, ...rest}) => (
    <TextField
      className="modal-forms-input"
      disabled={isDisabled}
      error={newObjectForm.touched[name] && Boolean(newObjectForm.errors[name])}
      helperText={newObjectForm.touched[name] && newObjectForm.errors[name]}
      id={name}
      label={label}
      name={name}
      onChange={event => {
        newObjectForm.handleChange(event);
        onChange();
      }}
      required={required}
      select={select}
      style={{
        width  : 'calc(50% - 20px)',
        margin : '10px 10px'
      }}
      type={type}
      value={newObjectForm.values[name]}
      variant="standard"
      {...rest}
    >
      {
        select &&
        size(children) === 0 ? <MenuItem disabled value=""> No items </MenuItem> : children
      }
    </TextField>
  );

  const getTeamUsers = (items) => uniqBy(reduce(items, (acc, value) => [ //eslint-disable-line
    ...acc,
    ...map(value, member => member)
  ], []), 'id');

  const includesTeam = (teams, team) => filter(teams, item => camelCase(item).toLowerCase() === camelCase(team).toLowerCase()).length;

  const getAssignees = team => {
    if (props.isOwner || props.isTeamsManager) {
      return map(filter(getTeamUsers(teamMembers), item => item.registered && includesTeam(item.team, team)), member =>
        <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>);
    } else if (isTeamLead) {
      return map(uniqBy(Object.values(teamMembers), 'id'), member => member.registered && <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>);
    }

    return [];
  };

  const getTeams = () => {
    if (props.isOwner || props.isTeamsManager) {
      return map(props.teams, (team, index) => (<MenuItem key={index.toString()} value={team}>{capitalize(team)}</MenuItem>));
    }

    return [];
  };

  // eslint-disable-next-line complexity
  const renderFormContent = () => (
    <form autoComplete="off" onSubmit={newObjectForm.handleSubmit} >
      {getTextField({
        name      : 'title',
        label     : 'Title',
        fullWidth : true,
        style     : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        }
      })}

      {getTextField({
        name      : 'description',
        label     : 'Description',
        multiline : true,
        fullWidth : true,
        style     : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows    : 3,
        variant : 'outlined'
      })}

      {props.isOwner || props.isTeamsManager ? getTextField({
        name     : 'team',
        label    : 'Team',
        onChange : () => newObjectForm.setFieldValue('assignee', ''),
        select   : true,
        children : getTeams()
      }) : getTextField({
        name       : 'team',
        label      : 'Team',
        isDisabled : true,
        select     : false,
        children   : []
      })}

      {getTextField({
        name       : 'assignee',
        label      : 'Assignee',
        isDisabled : !newObjectForm.values.team || !(isTeamLead || props.isTeamsManager || props.isOwner),
        select     : props.isOwner || isTeamLead || props.isTeamsManager,
        children   : (isTeamLead || props.isOwner || props.isTeamsManager) && getAssignees(newObjectForm?.values?.team)
      })}

      {getTextField({
        name     : 'category',
        label    : 'Category',
        onChange : () => newObjectForm.setFieldValue('subcategory', ''),
        children : map(CATEGORY, category => (<MenuItem key={category} value={category}>{category}</MenuItem>)),
        select   : true
      })}

      {size(filter(SUBCATEGORY, subcategory => subcategory.categoryName === newObjectForm.values.category)) > 0 ? getTextField({
        name     : 'subcategory',
        label    : 'Subcategory',
        children : filter(SUBCATEGORY, subcategory => subcategory.categoryName === newObjectForm.values.category)[0]
          .subcategory.map(subcategory =>
            (<MenuItem key={subcategory} value={subcategory}>{subcategory}</MenuItem>)
          ),
        select : true
      }) : getTextField({
        name       : 'subcategory',
        label      : 'Subcategory',
        isDisabled : true
      })}

      {getTextField({
        name     : 'priority',
        label    : 'Priority',
        children : map(PRIORITY, priority => (<MenuItem key={priority} value={priority}>{priority}</MenuItem>)),
        select   : true
      })}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          desktopModeMediaQuery="@media (pointer)"
          inputFormat="YYYY-MM-DD"
          inputProps={{readOnly : true}}
          label="Deadline"
          minDate={moment()}
          onChange={newValue => newObjectForm.handleChange({
            target : {
              name  : 'deadline',
              value : moment(newValue).format('YYYY-MM-DD')
            }
          })}
          onClose={() => setIsCalendarOpen(false)}
          open={isCalendarOpen}
          renderInput={params => getTextField({
            name            : 'deadline',
            label           : 'Deadline',
            type            : 'text',
            InputLabelProps : {shrink : true},
            onChange        : () => null,
            ...params,
            onClick         : () => setIsCalendarOpen(true)
          })}
          value={newObjectForm.values.deadline || moment().format('YYYY-MM-DD')}
        />
      </LocalizationProvider>


      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={Boolean(initialValues.privacy)} onChange={event => {
              setIsPublic(event.target.checked);
            }}
          />}
        label="Make this objective public" name="privacy" style={{
          margin    : '10px 10px 10px 0',
          fontStyle : 'italic'
        }}
      />

    </form>
  );

  return (
    visible &&
    <Modal
      handleClose={() => setVisible(false)}
      modalActions={() => renderModalActions()}
      modalContent={() => renderFormContent()}
      open={visible}
      text={modalText}
      title={modalTitle}
    />
  );
};

CreateObjectiveDialog.displayName = 'CreateObjectiveDialog';
CreateObjectiveDialog.propTypes = {
  isTeamLead      : bool.isRequired,
  isTeamsManager  : bool.isRequired,
  isOwner         : bool.isRequired,
  currentUserName : string.isRequired,
  currentUserId   : string.isRequired,
  currentUserTeam : string.isRequired,
  teamMembers     : oneOfType([array, object]).isRequired,
  teams           : oneOfType([array, object]).isRequired,
  createObjective : func.isRequired,
  editObjective   : func.isRequired,
  visible         : bool.isRequired,
  setVisible      : func.isRequired,
  modalText       : string.isRequired,
  modalTitle      : string.isRequired,
  initialValues   : object.isRequired,
  objectiveById   : func.isRequired,
  isEditMode      : bool
};

CreateObjectiveDialog.defaultProps = {
  initialValues : {
    title       : '',
    description : '',
    category    : '',
    subcategory : '',
    priority    : '',
    deadline    : ''
  },
  isEditMode : false
};

export default CreateObjectiveDialog;
