import {apiRequestAction} from 'action/apiResource';
import Timetracking from './Timetracking';
import {connect} from 'react-redux';
import {getReports} from '../../store/selector/timetracking';
import {getAccessToken} from '../../store/selector/auth';

const mapStateToProps = state => ({
  reports     : getReports(state),
  accessToken : getAccessToken(state)
});

const mapDispatchToProps = {
  getReports : apiRequestAction.timetracking.getReports({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Timetracking);
