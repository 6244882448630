import {connect} from 'react-redux';
import UserDetails from './UserDetails';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress} from 'selector/app';
import {getUserId, getUser} from 'selector/user';

const mapStateToProps = state => ({
  currentUserId : getUserId(state),
  isLoading     : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.auth.logout.requestTracker
  }) ||
  getIsCallInProgress(state, {
    requestTracker : apiRequestAction.user.updateUser.requestTracker
  }),
  user : getUser(state)
});

const mapDispatchToProps = {
  logout     : apiRequestAction.auth.logout(),
  updateUser : apiRequestAction.user.updateUser(),
  getUser    : apiRequestAction.user.getUser()
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
