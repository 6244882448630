import React, {useState} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
  Button
} from '@mui/material';

import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import moment from 'moment';

import {ACTION, STATUS, ROUTE_PATH, CATEGORY_COLORS} from 'constant';
import './ObjectiveCard.scss';
import CreateObjectiveDialog from '../CreateObjectiveDialog';
import CreateKeyResultDialog from '../CreateKeyResultDialog';
import {ConfirmationDialog, Modal, If} from 'components';
import {useHistory} from 'react-router-dom';

// eslint-disable-next-line complexity
const Card = ({
  title,
  description,
  status,
  currentUserId,
  ownerId: owner,
  authorId: author,
  category,
  priority,
  keyResults,
  createdAt,
  lastModificationDate,
  deadline,
  subcategory,
  ...props}) => { //eslint-disable-line

  const history = useHistory();
  const [anchorElement, setAnchorElement] = useState(null);
  const [editObjectiveModal, setEditObjectiveModal] = useState(false);
  const [mailSentModal, setMailSentModal] = useState(false);
  const [addKeyResultModal, setAddKeyResultModal] = useState(false);
  const [confirmationDialogOption, setConfirmationDialogOption] = useState({});

  const [options, setOptions] = props.isTeamLead && currentUserId !== owner.id ? useState({
    DELETE : {
      name      : ACTION.DELETE,
      condition : true
    }
  }) : React.useState({
    COMPLETE : {
      name      : ACTION.COMPLETE,
      condition : status !== STATUS.COMPLETED
    },
    DELETE : {
      name      : ACTION.DELETE,
      condition : true
    }
  });

  const [completedKeyResults] = React.useState(() => {
    let completed = 0;

    map(keyResults, keyResult => {
      if (keyResult.status === 'completed') {
        completed += 1;
      }
    });

    return completed;
  });
  const open = Boolean(anchorElement);
  const numberOfKeyResults = size(keyResults);


  React.useEffect(() => {
    if (status === STATUS.COMPLETED) {
      setOptions({
        ARCHIVE : {
          name      : ACTION.ARCHIVE,
          condition : status !== STATUS.ARCHIVED
        },
        DELETE : {
          name      : ACTION.DELETE,
          condition : true
        }
      });
    } else if (status === STATUS.ARCHIVED) {
      setOptions({
        RESTORE : {
          name      : ACTION.RESTORE,
          condition : true
        },
        DELETE : {
          name      : ACTION.DELETE,
          condition : true
        }
      });
    } else if (status === STATUS.WAITING_FOR_APPROVAL) {
      setOptions({
        COMPLETE : {
          name      : ACTION.COMPLETE,
          condition : status !== STATUS.COMPLETED
        },
        DELETE : {
          name      : ACTION.DELETE,
          condition : true
        }
      });
    }
  }, [status]);

  const cardBorderTopColor = (cssRule, cardCategory) => ({[cssRule] : CATEGORY_COLORS[cardCategory]});

  const getHumanReadableDate = value => moment(value).format('DD-MM-YYYY HH:mm');

  const daysLeft = () => {
    const deadlineMoment = moment(deadline);
    const currentDate = moment(new Date());

    return deadlineMoment.diff(currentDate, 'days');
  };

  const daysLeftPercent = () => {
    const createdAtM = moment(createdAt);
    const deadlineM = moment(deadline);

    const totalDays = deadlineM.diff(createdAtM, 'days');
    const left = daysLeft();

    return 100 - left / totalDays * 100; // eslint-disable-line no-magic-numbers
  };

  const handleSettingsMenuClick = value => {
    switch (value) {
      case ACTION.COMPLETE : {
        props.editObjective({
          entityId   : props.id,
          status     : STATUS.COMPLETED,
          nextStatus : STATUS.COMPLETED
        });
        break;
      }
      case ACTION.ARCHIVE : {
        props.editObjective({
          entityId   : props.id,
          status     : STATUS.ARCHIVED,
          nextStatus : status
        });
        break;
      }
      case ACTION.RESTORE : {
        props.editObjective({
          entityId : props.id,
          status   : props.nextStatus || STATUS.IN_PROGRESS
        });
        break;
      }
      case ACTION.DELETE : {
        props.deleteObjective({
          entityId : props.id
        });
        break;
      }
      default : {
        break;
      }
    }
  };

  const hasAccess = () => props.isTeamLead || props.isTeamsManager || props.isOwner;

  return (
    <div className="card" style={cardBorderTopColor('borderTopColor', category)}>
      <section className={'card__header' + (props.privacy ? ' card__header-public' : '')}>
        <div className="card__header__title">
          <div>
            <p alt={title} className="card__header__title--name">{title}</p>
            <span className="card__header__title--status"> - {status}</span>
          </div>
          <span className="card__header__title--assignee">{owner?.name}</span>
        </div>

        <div className="card__header__menu">
          <If condition={!props.isReadOnly}>
            <IconButton
              aria-controls="status-menu"
              aria-haspopup="true"
              aria-label="more"
              onClick={event => setAnchorElement(event.currentTarget)}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElement}
              id="status-menu"
              keepMounted
              onClose={() => setAnchorElement(null)}
              open={open}
            >
              {map(options, option => option.condition && (
                <MenuItem
                  key={option.name}
                  onClick={event => {
                    if (status === STATUS.WAITING_FOR_APPROVAL && !hasAccess()) {
                      setConfirmationDialogOption({
                        action : event.currentTarget.textContent
                      });
                      setMailSentModal(true);
                    } else {
                      setConfirmationDialogOption({
                        visible : true,
                        action  : event.currentTarget.textContent
                      });
                      setAnchorElement(null);
                    }
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </If>
        </div>
      </section>

      <section className="card__progress">
        <div className="card__progress__info">
          <span className="card__progress__info--label">Author</span>
          <span className="card__progress__info--name">{author?.name}</span>
        </div>

        <div className="card__progress__bar">
          <span>{completedKeyResults} of {numberOfKeyResults} Key Results completed</span>
          <LinearProgress value={completedKeyResults / numberOfKeyResults * 100} variant="determinate" /> { /* eslint-disable-line no-magic-numbers */}
        </div>
      </section>

      <section className="card__progress">
        <div className="card__progress__info">
          <span className="card__progress__info--label">Last Modified</span>
          <span className="card__progress__info--name">{getHumanReadableDate(lastModificationDate)}</span>
        </div>

        <div className="card__progress__bar">
          <span>
            {/*  eslint-disable-next-line */}
            {daysLeft() < 0 && status !== STATUS.COMPLETED ?
              <b style={{ color: '#f54752' }}>{-daysLeft() + ' days overdue '}</b> : // eslint-disable-line
              status === STATUS.COMPLETED ? 'Completed' : daysLeft() + ' days left'
            }</span>
          <LinearProgress thickness={20} value={daysLeftPercent()} variant="determinate" />
        </div>
      </section>

      <div className="card__footer">
        <Button
          onClick={() => {
            history.push({
              pathname : ROUTE_PATH.OBJECTIVE_DETAILS,
              search   : `?id=${props.id}`,
              state    : {
                objectiveId : props.id,
                isReadOnly  : props.isReadOnly
              }
            });
          }}
          size="small"
          style={cardBorderTopColor('backgroundColor', category)}
          variant="contained"
        >Details
        </Button>
        <If condition={!props.isReadOnly}>
          <Button
            color="primary"
            disabled={moment(deadline).isBefore(moment().subtract(1, 'd')) || status === STATUS.ARCHIVED}
            onClick={() => setAddKeyResultModal(true)}
            size="small"
            variant="outlined"
          >Add KeyResult
          </Button>
          <Button
            color="primary" onClick={() => setEditObjectiveModal(true)}
            size="small"
            variant="outlined"
          >Edit
          </Button>
        </If>
      </div>


      <CreateKeyResultDialog
        currentUserId={currentUserId}
        deadline={deadline}
        modalText="Complete the following form to create a new key result for the selected objective"
        modalTitle="New Key Result"
        objectiveId={props.id}
        objectivePriority={priority}
        ownerId={owner.id}
        setVisible={setAddKeyResultModal}
        visible={addKeyResultModal}
      />

      <CreateObjectiveDialog
        currentUserId={currentUserId}
        currentUserName={props.currentUserName}
        currentUserTeam={props.currentUserTeam}
        initialValues={{
          id       : props.id,
          privacy  : Boolean(props.privacy),
          title,
          assignee : owner?.id,
          team     : owner?.team,
          description,
          category,
          subcategory,
          priority,
          deadline : moment(deadline).format('YYYY-MM-DD')
        }}
        isEditMode
        isTeamLead={props.isTeamLead}
        modalText="Complete the following form with new data for the selected objective"
        modalTitle="Edit objective"
        setVisible={setEditObjectiveModal}
        teamMembers={props.teamMembers}
        visible={editObjectiveModal}
      />

      <Modal
        handleClose={() => setMailSentModal(false)}
        modalActions={() => (
          <div style={{
            display : 'flex',
            gap     : '15px'
          }}
          >
            <Button
              color="primary" onClick={() => {
                setMailSentModal(false);
              }} size="small"
              variant="contained"
            > Cancel </Button>
            <Button
              color="secondary"
              onClick={() => {
                handleSettingsMenuClick(confirmationDialogOption.action);
              }}
              size="small"
              type="submit"
              variant="contained"
            > Confirm </Button>
          </div>
        )}
        open={mailSentModal}
        text="You&apos;ve already sent an approval request. Are you sure you want to send another one?"
        title="Confirmation approval request"
      />

      <ConfirmationDialog
        action={confirmationDialogOption.action}
        complete={() => handleSettingsMenuClick(confirmationDialogOption.action)}
        handleClose={() => setConfirmationDialogOption({
          ...confirmationDialogOption,
          visible : false
        })}
        model={'objective'}
        open={confirmationDialogOption.visible}
      />

    </div>
  );
};

Card.displayName = 'ObjectiveCard';
Card.propTypes = {
  isTeamLead      : PropTypes.bool.isRequired,
  isOwner         : PropTypes.bool,
  isTeamsManager  : PropTypes.bool,
  ownerId         : PropTypes.object.isRequired,
  authorId        : PropTypes.object.isRequired,
  title           : PropTypes.string.isRequired,
  description     : PropTypes.string.isRequired,
  category        : PropTypes.string.isRequired,
  priority        : PropTypes.string.isRequired,
  subcategory     : PropTypes.string.isRequired,
  status          : PropTypes.string.isRequired,
  nextStatus      : PropTypes.string.isRequired,
  currentUserName : PropTypes.string.isRequired,
  id              : PropTypes.string.isRequired,
  currentUserId   : PropTypes.string.isRequired,
  currentUserTeam : PropTypes.string.isRequired,
  editObjective   : PropTypes.func.isRequired,
  deleteObjective : PropTypes.func.isRequired,
  teamMembers     : PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  keyResults      : PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,

  deadline             : PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  createdAt            : PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  lastModificationDate : PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  privacy              : PropTypes.bool,
  isReadOnly           : PropTypes.bool.isRequired

};

Card.defaultProps = {
  privacy        : false,
  isOwner        : false,
  isTeamsManager : false
};

export default Card;
