import LinksTable from './LinksTable';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getHasCallSucceeded, getIsCallInProgress} from 'selector/app';
import {getUserCapabilities} from 'selector/user';
import {getLinks} from '../../store/selector/link';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  capabilities                : getUserCapabilities(state),
  links                       : getLinks(state),
  updateLinkPictureSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.updateLinkPicture.requestTracker
  }),
  updateLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.updateLink.requestTracker
  }),
  createLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.createLink.requestTracker
  }),
  deleteLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.deleteLink.requestTracker
  }),
  restoreLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.restoreLink.requestTracker
  })
});

const mapDispatchToProps = {
  getLinks : apiRequestAction.link.getLinks({
    skipEntityIdCheck : true
  }),
  createLink : apiRequestAction.link.createLink({
    skipEntityIdCheck : true
  }),
  updateLink : apiRequestAction.link.updateLink({
    skipEntityIdCheck : true
  }),
  deleteLink : apiRequestAction.link.deleteLink({
    skipEntityIdCheck : true
  }),
  restoreLink : apiRequestAction.link.restoreLink({
    skipEntityIdCheck : true
  }),
  updateLinkPicture : apiRequestAction.link.updateLinkPicture({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksTable);
