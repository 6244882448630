import {API_ENDPOINT, getParts} from 'constant';
import {buildActionType} from 'action/apiResource';
import {all, takeEvery, call, put} from 'redux-saga/effects';
import {setUser} from 'action/user';
import LocalStorage from 'service/LocalStorage';

const UPDATE_USER_SUCCESS = buildActionType(getParts(API_ENDPOINT.USER.UPDATE_USER));
const UPDATE_USER_IMAGE_SUCCESS = buildActionType(getParts(API_ENDPOINT.USER.UPDATE_USER_IMAGE));

function* updateSuccessSaga (action) {
  const {payload: {data : {user}}} = action;

  yield call(LocalStorage.set, 'user', JSON.stringify(user));

  yield put(setUser(user));
}


function* updateImageSuccessSaga (action) {
  const {payload: {data}} = action;

  yield call(LocalStorage.set, 'user', JSON.stringify(data));

  yield put(setUser(data));
}


const manageUserUpdateSaga = function* manageUserUpdateSaga () {
  yield all([
    takeEvery(UPDATE_USER_SUCCESS, updateSuccessSaga),
    takeEvery(UPDATE_USER_IMAGE_SUCCESS, updateImageSuccessSaga)
  ]);
};

export {
  manageUserUpdateSaga
};
