import React, {useState, useEffect, useRef} from 'react';
import {object, array, oneOfType, func} from 'prop-types';
import {LinkCard, SideMenu} from '../../components';
import {map, filter, isEmpty} from 'lodash';
import {MultiSelect} from 'primereact/multiselect';
import './Dashboard.scss';
import ScrollButton from '../../utils/scrollButton';

const Dashboard = props => {
  const [selectedLinks, setSelectedLinks] = useState();
  const [filteredCategories, setFilteredcategories] = useState({});

  const linksDivRef = useRef(null);

  useEffect(() => {
    setFilteredcategories(props.links);
  }, [props.links]);

  const handleOnChangeLink = event => {
    setSelectedLinks(event.value);
    const filteredCategoriesTemp = [];

    if (event.value && event.value.length) {
      map(props.links, link => {
        if (event.value.includes(link.category)) {
          filteredCategoriesTemp.push(link);
        }
      });

      setFilteredcategories(filteredCategoriesTemp);
    } else {
      setFilteredcategories(props.links);
    }
  };

  useEffect(() => {
    let result = [];

    map(props.links, category => {
      let matches = filter(category.values, item =>
        item.name.toLowerCase().includes(props.searchInputValue.searchInputValue.toLowerCase())
      );

      if (matches.length > 0) {
        result.push({
          category : category.category,
          values   : matches
        });
      }
    });

    setFilteredcategories(result);

    return result;
  }, [props.searchInputValue]);

  useEffect(() => {
    if (isEmpty(props.links)) {
      props.getLinks();
    }
  }, []);

  if (isEmpty(props.links)) {
    return (<div className="loading">
      <span className="loader" />
    </div>);
  }


  return (
    <div className="dashboard">
      {props.links &&
      <div className="h-full w-screen flex dashboard-container">
        <div className="hidden md:block dashboard__sideMenu">
          <SideMenu />
        </div>
        <div className="max-h-full w-screen dashboard__content">
          <div className="w-full flex align-items-center gap-3 dashboard__content-top">
            <p>Dashboard</p>
            <div className="ml-auto dashboard__content-top__dropdown">
              <MultiSelect
                className="flex align-items-center"
                onChange={event => handleOnChangeLink(event)}
                options={Object.values(props.links).map(item => item.category)}
                placeholder="All"
                showClear
                value={selectedLinks}
              />
            </div>
          </div>
          <div className="flex flex-column pb-6 dashboard__content-links" ref={linksDivRef}>
            {map(filteredCategories, (link, index) =>
              <div key={index}>
                <p className="p-dashboard">{link.category.toUpperCase()}</p>
                <div className="each-category-links">
                  {map(link.values, eachLink =>
                    <LinkCard key={eachLink.id} link={eachLink} />
                  )}
                </div>
              </div>
            )}
            <ScrollButton />
          </div>
        </div>
      </div>
      }
    </div>
  );
};

Dashboard.displayName = 'Dashboard';
Dashboard.propTypes = {
  links            : oneOfType([array, object]).isRequired,
  searchInputValue : object.isRequired,
  getLinks         : func.isRequired
};
export default Dashboard;
