import {connect} from 'react-redux';
import CreateObjectiveDialog from './CreateObjectiveDialog';
import {getObjectiveById} from 'selector/objective';
import {apiRequestAction} from 'action/apiResource';
import {isCurrentUserOwner, getTeams, getUser} from 'selector/user';

const mapStateToProps = state => ({
  objectiveById  : id => getObjectiveById(state, id),
  isOwner        : isCurrentUserOwner(state),
  isTeamsManager : getUser(state).isTeamsManager,
  teams          : getTeams(state)
});

const mapDispatchToProps = {
  editObjective   : apiRequestAction.objective.editObjective(),
  createObjective : apiRequestAction.objective.createObjective()
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateObjectiveDialog);
