/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useEffect, useRef} from 'react';
import {EditButton} from '../../components/StyledButtons/StyledButtons';
import {userCan, getUserImageUrl} from '../../utils/user';
import ReactCrop from 'react-image-crop';
import {Dialog} from 'primereact/dialog';
import {object, func, bool, array, oneOfType} from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import CropIcon from '@mui/icons-material/Crop';
import {ReactComponent as EditIcon} from '../../assets/icons/edit-button-icon-grey.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import {CAPABILITIES} from 'constant';
import 'react-image-crop/dist/ReactCrop.css';
import './ProfilePicture.scss';

const ProfilePicture = props => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [image, setImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [isUpdateImageVisible, setIsUpdateImageVisible] = useState(false);
  const [isChooseImageAvailable, setIsChooseImageAvailable] = useState(true);
  const fileInput = useRef(null);
  const [imageUrl, setImageUrl] = useState('');

  const [crop, setCrop] = useState();
  const [cropDialog, setCropDialog] = useState(false);
  const [completedCrop, setCompletedCrop] = useState();
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [aspect, setAspect] = useState(null);

  useEffect(() => {
    const widthApectRatio = 4;
    const heightApectRatio = 5;
    const ASPECT = widthApectRatio / heightApectRatio;

    setAspect(ASPECT);
  }, []);

  useEffect(() => {
    const imageUrlTemp = getUserImageUrl(props.user);

    setImageUrl(imageUrlTemp);
  }, [props.user]);

  useEffect(() => {
    if (isSuccess) {
      setImage(fileInput.current.files[0]);
      setIsUpdateImageVisible(true);
      setIsChooseImageAvailable(false);
    } else {
      setIsUpdateImageVisible(false);
      setIsChooseImageAvailable(true);
    }
  }, [isSuccess]);

  const resizeImage = (file, targetWidth, targetHeight) => {
    const img = document.createElement('img');

    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');

      // Calculate scale and position to simulate "object-fit: cover"
      const imgAspectRatio = img.width / img.height;
      const targetAspectRatio = targetWidth / targetHeight;

      let drawWidth = 0;

      let drawHeight = 0;

      let drawX = 0;

      let drawY = 0;

      if (imgAspectRatio > targetAspectRatio) {
        drawHeight = targetHeight;
        drawWidth = drawHeight * imgAspectRatio;
        drawX = (targetWidth - drawWidth) * 0.5;
        drawY = 0;
      } else {
        drawWidth = targetWidth;
        drawHeight = drawWidth / imgAspectRatio;
        drawX = 0;
        drawY = (targetHeight - drawHeight) * 0.5;
      }

      ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);

      canvas.toBlob(resizedImageBlob => {
        setResizedImage(resizedImageBlob);
      }, file.type);
    };
  };

  const validateSelectedFile = imageFile => {
    const MIN_FILE_SIZE = 0;
    const MAX_FILE_SIZE = 1024;
    const ALLOWED_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!imageFile) {
      setErrorMsg('Please choose a file');
      setIsSuccess(false);

      return;
    }

    if (!ALLOWED_TYPES.includes(imageFile.type)) {
      setErrorMsg('Invalid file type. Only jpeg, jpg, png are allowed');
      setIsSuccess(false);

      return;
    }

    const fileSizeKiloBytes = imageFile.size / MAX_FILE_SIZE;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg('File size is less than minimum limit');
      setIsSuccess(false);

      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg('File size is greater than maximum limit of 1MB');
      setIsSuccess(false);

      return;
    }

    setErrorMsg('');
    setIsSuccess(true);
    resizeImage(imageFile, 400, 600);
  };

  const handleFileUpload = async () => {
    const file = croppedImageBlob || fileInput.current.files[0];

    const formData = new FormData();

    formData.append('image', file);

    await props.updateUserImage(formData);

    setIsSuccess(false);
    setCroppedImageBlob(null);
  };

  useEffect(() => {
    const shouldFetch = [props.updateUserImageSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getAllUsers();
    }
  }, [props.updateUserImageSuccessful]);


  const handleImageChange = event => {
    const selectedImage = event.target.files[0];

    validateSelectedFile(selectedImage);

    if (isSuccess) {
      setIsUpdateImageVisible(true);
      setIsChooseImageAvailable(false);
    }
  };

  const cropImageNow = async () => {
    const imageElement = document.createElement('img');

    imageElement.src = URL.createObjectURL(image);
    await imageElement.decode();

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    let {x: xaxis, y: yaxis, width, height} = completedCrop;

    xaxis = Math.max(xaxis, 0);
    yaxis = Math.max(yaxis, 0);
    width = Math.min(width, imageElement.width - xaxis);
    height = Math.min(height, imageElement.height - yaxis);

    canvas.width = width;
    canvas.height = height;

    context.drawImage(
      imageElement,
      xaxis, yaxis, width, height,
      0, 0, width, height
    );

    canvas.toBlob(blob => {
      if (!blob) {
        window.error('Canvas is empty');

        return;
      }

      const file = new File([blob], 'croppedImage.jpg', {type : 'image/jpeg'});

      setCroppedImageBlob(blob);
      setImage(file);

      setCropDialog(false);
    }, 'image/jpeg');
  };

  const openCropDialog = () => {
    if (resizedImage) {
      setImage(resizedImage);
      setCropDialog(true);
    }
  };

  const handleCancelUpload = () => {
    setErrorMsg(false);
    setIsSuccess(false);
    setImage(null);
    setCroppedImageBlob(null);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };


  return (
    <div className={`image__container ${isUpdateImageVisible ? 'edit-mode' : ''}`}>
      {image ? (
        <img alt={props.user.name} src={URL.createObjectURL(image)} />
      ) : (
        <img alt={props.user.name} src={imageUrl} />
      )}
      {(userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key) || props.isLoggedUserProfile) &&
      <div className="buttons">
        <input
          onChange={handleImageChange} ref={fileInput} style={{display : 'none'}}
          type="file"
        />
        {isChooseImageAvailable && props.isLoggedUserProfile &&
        <EditButton
          color="primary"
          disableRipple
          onClick={() => fileInput.current.click()}
          startIcon={<EditIcon />}
          variant="contained"
        >
          Edit
        </EditButton>
        }
        {isUpdateImageVisible && props.isLoggedUserProfile &&
        <>
          <EditButton
            color="secondary"
            disableRipple
            onClick={handleFileUpload}
            startIcon={<DoneIcon />}
            variant="contained"
          >
            Save
          </EditButton>
          <EditButton
            color="secondary"
            disableRipple
            onClick={handleCancelUpload}
            startIcon={<CancelIcon />}
            variant="contained"
          >
            Cancel
          </EditButton>
        </>
        }
        <Dialog
          header="Error Message" modal onHide={() => setErrorMsg(false)}
          visible={Boolean(errorMsg)}
        >
          <p className="error-message">{errorMsg}</p>
        </Dialog>
        {image && isSuccess &&
        <EditButton
          color="secondary"
          onClick={openCropDialog}
          startIcon={<CropIcon />}
          variant="contained"
        >
          Crop
        </EditButton>
        }
        <Dialog
          autoFocus="false" className="crop-dialog" header="Crop Image"
          modal
          onHide={() => setCropDialog(false)}
          visible={cropDialog}
        >
          {image && (
            <div className="crop-container">
              <ReactCrop
                aspect={aspect}
                crop={crop} onChange={crp => setCrop(crp)} onComplete={crp => setCompletedCrop(crp)}
                ruleOfThirds
              >
                <img src={URL.createObjectURL(image)} />
              </ReactCrop>
              <br />
              <button className="crop-button" onClick={cropImageNow}>Apply Crop</button>
              <br />
              <br />
            </div>
          )}
        </Dialog>
      </div>
      }
    </div>
  );
};

ProfilePicture.displayName = 'ProfilePicture';
ProfilePicture.propTypes = {
  isLoggedUserProfile       : bool.isRequired,
  user                      : object.isRequired,
  capabilities              : oneOfType([array, object]).isRequired,
  updateUserImage           : func.isRequired,
  getAllUsers               : func.isRequired,
  updateUserImageSuccessful : bool.isRequired
};

export default ProfilePicture;
