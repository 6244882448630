import {connect} from 'react-redux';
import {getUser, getPlans, getUserCapabilities} from 'selector/user';
import Header from './Header';
import {getHasCallSucceeded} from 'selector/app';
import {apiRequestAction} from 'action/apiResource';
import {getSearchInputValue} from '../../store/selector/searchInputValue';

const mapStateToProps = state => ({
  searchInputValue     : getSearchInputValue(state),
  user                 : getUser(state),
  plans                : getPlans(state),
  updateUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.updateUser.requestTracker
  }),
  capabilities : getUserCapabilities(state)
});

const mapDispatchToProps = {
  getUser      : apiRequestAction.user.getUser(),
  getUserPlans : apiRequestAction.user.getUserPlans({
    skipEntityIdCheck : true
  }),
  getUserCapabilities : apiRequestAction.user.getUserCapabilities({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
