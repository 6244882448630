/* eslint-disable max-statements */
import {func, array, object, oneOfType} from 'prop-types';
import {isEmpty, map} from 'lodash';
import React, {useState, useRef, Fragment, useEffect} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {formatDate, emptyUser, getUserImageUrl} from '../../utils/user';
import {UserModal} from '../../components';
import {showSuccessfulToast} from '../../utils/utils';
import {dialogFooter, header, rowsPerPageOptions} from '../RolesTable/utils';
import './UsersTable.scss';

/**
 * Table in Admin page for CRUD on users
 */
const UsersTable = props => {
  const [user, setUser] = useState(emptyUser);
  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [restoreUserDialog, setRestoreUserDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userTeamLead, setUserTeamLead] = useState('');
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    setUserTeamLead(map(user.teamLead, teamLead => teamLead.name));
  }, [user]);

  const openNewUser = () => {
    setUser(emptyUser);
    setSubmitted(false);
    setUserDialog(true);
  };

  const confirmDeleteUser = currentUser => {
    setUser(currentUser);
    setDeleteUserDialog(true);
  };

  const confirmRestoreUser = currentUser => {
    setUser(currentUser);
    setRestoreUserDialog(true);
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideRestoreUserDialog = () => {
    setRestoreUserDialog(false);
  };

  const editUser = currentUser => {
    setUser({...currentUser});
    setUserDialog(true);
  };

  const deleteUser = async () => {
    await props.deleteUser({id : user.id});

    setDeleteUserDialog(false);
    setUser(emptyUser);
    showSuccessfulToast(toast, 'User archived');
  };

  const restoreUser = async () => {
    await props.restoreUser({id : user.id});

    setRestoreUserDialog(false);
    setUser(emptyUser);
    showSuccessfulToast(toast, 'User restored');
  };

  const actionBodyTemplate = rowData => (
    <Fragment>
      <Button className="p-button-rounded p-button-success mr-2" icon="pi pi-pencil" onClick={() => editUser(rowData)} />
      { rowData.status === 'active' &&
        <Button className="p-button-rounded p-button-danger" icon="pi pi-trash" onClick={() => confirmDeleteUser(rowData)} />
      }
      { rowData.status === 'deleted' &&
      <Button className="p-button-rounded p-button-warning" icon="pi pi-refresh" onClick={() => confirmRestoreUser(rowData)} />
      }
    </Fragment>
  );

  const handleSearchInput = event => {
    setGlobalFilter(event.target.value);
  };

  const dateBodyTemplate = rowData => formatDate(new Date(rowData.joinDate));

  const teamBodyTemplate = rowData => {
    if (!isEmpty(rowData.team)) {
      return Object.values(rowData.team).map(teamObj => teamObj.name)
        .join(', ');
    }

    return 'No Team';
  };

  const roleBodyTemplate = rowData => {
    if (rowData.role) {
      return rowData.role.name;
    }

    return 'No role';
  };

  const jobBodyTemplate = rowData => {
    if (rowData.job) {
      return rowData.job.name;
    }

    return 'No job';
  };

  const phoneBodyTemplate = rowData => {
    if (rowData.phone) {
      return rowData.phone;
    }

    return 'Not set';
  };

  const emailBodyTemplate = rowData => {
    if (rowData.email) {
      return (
        <>
          {rowData.email.split('@')[0]}
          <br />
          @
          {rowData.email.split('@')[1]}
        </>
      );
    }

    return 'No email';
  };

  const nameBodyTemplate = rowData => {
    let bgColor = '';

    if (rowData.status === 'deleted') {
      bgColor = '#ffe4e4';
    } else if (rowData.status === 'active') {
      bgColor = '#e6f9e6';
    }

    const imageUrl = getUserImageUrl(rowData);

    return (
      <div className="flex align-items-center gap-2" style={{backgroundColor : bgColor}}>
        <img
          alt={rowData.name}
          className={`logo logo-${rowData.name}`}
          src={imageUrl}
          style={{
            width        : '25px',
            height       : '25px',
            marginRight  : '5px',
            borderRadius : '50%',
            objectFit    : 'contain'
          }}
        />
        <span>{rowData.name}</span>
      </div>
    );
  };

  const userProps = {
    setUserInfo : setUser,
    userInfo    : user,
    userTeamLead
  };

  return (
    <div className="datatable-crud-users">
      <div className="users-container">
        <Toast ref={toast} />
        <DataTable
          className="table"
          columnResizeMode="fit"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
          dataKey="id" globalFilter={globalFilter}
          header={header(openNewUser, handleSearchInput)}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          ref={dt} resizableColumns
          rows={5}
          rowsPerPageOptions={rowsPerPageOptions}
          showGridlines
          value={Object.values(props.users)}
        >
          <Column
            body={nameBodyTemplate} field="name" header="Name"
            sortable
            style={{width      : '40%',
              whiteSpace : 'break-spaces'}}
          />
          <Column
            body={phoneBodyTemplate}
            field="phone" header="Phone"
            style={{width      : '35%',
              whiteSpace : 'break-spaces'}}
          />
          <Column
            body={emailBodyTemplate}
            field="email" header="Email"
            style={{width : '40%'}}
          />
          <Column
            body={teamBodyTemplate}
            field="team" header="Team"
            style={{width      : '30%',
              whiteSpace : 'break-spaces'}}
          />
          <Column
            body={jobBodyTemplate}
            field="job" header="Role"
            style={{width      : '35%',
              whiteSpace : 'break-spaces'}}
          />
          <Column
            body={roleBodyTemplate}
            field="role" header="Access"
            style={{width      : '20%',
              whiteSpace : 'break-spaces'
            }}
          />
          <Column
            body={dateBodyTemplate}
            dataType="date" field="joinDate" header="Join date"
            sortable
            style={{width : '30%'}}
          />
          <Column
            body={actionBodyTemplate} className="actions" exportable={false}
            style={{width : '30%'}}
          />
        </DataTable>
      </div>

      <UserModal
        setSubmitted={setSubmitted} setUserDialog={setUserDialog}
        submitted={submitted}
        userDialog={userDialog}
        userProps={userProps}
      />

      <Dialog
        className="deleteModal" footer={dialogFooter(hideDeleteUserDialog, deleteUser)} header="Confirm"
        modal
        onHide={hideDeleteUserDialog} style={{width : '450px'}} visible={deleteUserDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
          {user && <span>Are you sure you want to delete <b>{user.name}</b>?</span>}
        </div>
      </Dialog>

      <Dialog
        className="restoreModal" footer={dialogFooter(hideRestoreUserDialog, restoreUser)} header="Confirm"
        modal
        onHide={hideRestoreUserDialog} style={{width : '450px'}} visible={restoreUserDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
          {user && <span>Are you sure you want to restore <b>{user.name}</b>?</span>}
        </div>
      </Dialog>
    </div>
  );
};

UsersTable.displayName = 'UsersTable';
UsersTable.propTypes = {
  users        : object.isRequired,
  capabilities : oneOfType([array, object]).isRequired,
  teams        : object.isRequired,
  getAllUsers  : func.isRequired,
  jobs         : object.isRequired,
  roles        : object.isRequired,
  user         : object.isRequired,
  addUser      : func.isRequired,
  deleteUser   : func.isRequired,
  restoreUser  : func.isRequired,
  editUser     : func.isRequired
};
export default UsersTable;
