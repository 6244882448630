import Objectives from './Objectives';
import {connect} from 'react-redux';
import {getObjectives, getTotalObjectives} from 'selector/objective';
import {apiRequestAction} from 'action/apiResource';
import {getUserName, getUserId, isCurrentUserTeamLead, getTeamLeader, isCurrentUserOwner, getUserTeam, getUser} from 'selector/user';
import {getIsCallInProgress, getHasCallSucceeded} from 'selector/app';
import {getTeamMembers} from 'selector/teamMembers';
import {getApiResponseError} from 'selector/apiResponse';
import {clearApiResponseErrors} from 'action/apiResponse';
import map from 'lodash/map';

const mapStateToProps = state => ({
  objectives      : getObjectives(state),
  isUserTeamLead  : isCurrentUserTeamLead(state),
  isOwner         : isCurrentUserOwner(state),
  isTeamsManager  : getUser(state).isTeamsManager,
  currentUserName : getUserName(state),
  teamLeader      : getTeamLeader(state),
  totalObjectives : getTotalObjectives(state),
  currentUserId   : getUserId(state),
  teamMembers     : getTeamMembers(state),
  currentUserTeam : getUserTeam(state),
  errors          : map(getApiResponseError(state, {
    requestTrackerError : apiRequestAction.objective.editObjective.requestTracker
  }), error => error.apiErrorMessage).join(', '),

  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.getObjectives.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.createObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.user.getTeamLeader.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.deleteObjective.requestTracker
  }),

  createObjectiveSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.createObjective.requestTracker
  }),
  editObjectiveSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.deleteObjective.requestTracker
  }),
  addKeySuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.addKeyResultToObjective.requestTracker
  }),
  editUserSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.updateUser.requestTracker
  })
});

const mapDispatchToProps = {
  getTeamLead     : apiRequestAction.user.getTeamLeader(),
  getObjectives   : apiRequestAction.objective.getObjectives(),
  editObjective   : apiRequestAction.objective.editObjective(),
  deleteObjective : apiRequestAction.objective.deleteObjective(),
  getTeamMembers  : apiRequestAction.user.getTeamMembers(),
  clearApiResponseErrors

};

export default connect(mapStateToProps, mapDispatchToProps)(Objectives);
