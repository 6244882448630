import {fork, all} from 'redux-saga/effects';
import api from './api';
import apiResponseSaga from './apiResponse';
import {manageAuthTokenSaga, restoreAccessToken} from './auth';
import {manageUserUpdateSaga} from './user';

const appSagas = function* appSagas () {
  yield all([
    fork(api),
    fork(apiResponseSaga),
    fork(manageAuthTokenSaga),
    fork(restoreAccessToken),
    fork(manageUserUpdateSaga)
  ]);
};

const sagas = [
  appSagas
];

export default sagas;
