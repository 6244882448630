const PREFIX = 'beot';

class LocalStorage {
  static get (key) {
    return localStorage.getItem(`${PREFIX}${key}`);
  }

  static set (key, value) {
    localStorage.setItem(`${PREFIX}${key}`, value);
  }

  static remove (key) {
    localStorage.removeItem(key);
  }

  static destroy () {
    localStorage.clear();
  }
}

export default LocalStorage;
