import * as React from 'react';
import InfoPopUp from '../InfoPopUp';
import './Footer.scss';

const Footer = () => {
  const [openInfo, setOpenInfo] = React.useState(false);

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const getCurrentYear = () => new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-component">
        <nav>
          <ul className="footer-menu">
            <li>
              <p className="footer-link">
                Copyright © {getCurrentYear()} BitStone SRL <span>&nbsp;&nbsp;</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
      <InfoPopUp
        isOpen={openInfo}
        onClose={handleCloseInfo}
      />
    </div>
  );
};

Footer.displayName = 'Footer';

export default Footer;
