import {handleActions} from 'redux-actions';
import toUpper from 'lodash/toUpper';
import {ActionTypes} from 'action/api';
import {ActionTypes as ApiResponseActionTypes} from 'action/apiResponse';

export const defineIf = (condition, propName, value) => (
  condition ? {[propName] : value} : {}
);

const initialState = {};

const requestTrackerReducer = (state, {payload : {meta, status, entityId, uniqueId}}) => {
  const {resourceName, resourceMethod} = meta;

  return {
    ...state,
    requestTracker : {
      ...state.requestTracker,
      [`${toUpper(resourceName)}_${toUpper(resourceMethod)}`] : status,
      ...defineIf(
        entityId,
        `${toUpper(resourceName)}_${toUpper(resourceMethod)}_${entityId}`,
        status
      ),
      ...defineIf(
        uniqueId,
        `${toUpper(resourceName)}_${toUpper(resourceMethod)}_${uniqueId}`,
        status
      )
    }
  };
};

const cleanupRequestTrackerReducer = state => ({
  ...state,
  requestTracker : {}
});

const cleanupReducer = () => initialState;

const appReducer = handleActions({
  [ActionTypes.REQUEST_TRACKER]                      : requestTrackerReducer,
  [ApiResponseActionTypes.CLEAR_API_RESPONSE_ERRORS] : cleanupRequestTrackerReducer,
  [ApiResponseActionTypes.APP_CLEANUP]               : cleanupReducer
}, initialState);

export default appReducer;
