/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useRef, Fragment} from 'react';
import {func, object} from 'prop-types';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {showSuccessfulToast} from '../../utils/utils';
import './JobsTable.scss';
import {dialogFooter, header} from '../RolesTable/utils';

/**
 * Table in Admin page for CRUD on jobs
 */
const JobsTable = props => {
  let emptyJob = {
    id   : null,
    name : ''
  };

  // eslint-disable-next-line no-magic-numbers
  const rowPersPage = [5, 10, 15];

  const [job, setJob] = useState(emptyJob);
  const [submitted, setSubmitted] = useState(false);
  const [newJobDialog, setNewJobDialog] = useState(false);
  const [canNotDeleteJobDialog, setCanNotDeleteJobDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [jobDialog, setJobDialog] = useState(false);
  const [deleteJobDialog, setDeleteJobDialog] = useState(false);
  const [isJobNameUnique, setIsJobNameUnique] = useState(true);
  const dt = useRef(null);
  const toast = useRef(null);

  const doesJobExist = (jobName, jobs) =>
    jobs.some(valJob => valJob.name === jobName);

  const onInputChange = (event, name) => {
    const val = event.target && event.target.value || '';

    if (name === 'name') {
      if (val) {
        const exists = doesJobExist(val, Object.values(props.jobs));

        setIsJobNameUnique(!exists);
      } else {
        setIsJobNameUnique(true);
      }
    }

    let _job = {...job};

    _job[`${name}`] = val;
    setJob(_job);
  };

  const openNewTeam = () => {
    setJob(emptyJob);
    setSubmitted(false);
    setNewJobDialog(true);
  };

  const editJob = currentTeam => {
    setJob({...currentTeam});
    setJobDialog(true);
  };

  const confirmDeleteJob = currJob => {
    setJob(currJob);

    let canBeDeleted = false;

    canBeDeleted = Object.values(props.users).some(user => user.job.id === currJob.id);

    if (canBeDeleted) {
      setCanNotDeleteJobDialog(true);
    } else {
      setDeleteJobDialog(true);
    }
  };

  const handleSearchInput = event => {
    setGlobalFilter(event.target.value);
  };

  const hideEditJobDialog = () => {
    setSubmitted(false);
    setJobDialog(false);
  };

  const hideNewJobDialog = () => {
    setSubmitted(false);
    setNewJobDialog(false);
  };

  const hideDeleteTeamDialog = () => {
    setDeleteJobDialog(false);
  };

  const hideCanNotDeleteTeamDialog = () => {
    setCanNotDeleteJobDialog(false);
  };

  const saveJob = async () => {
    setSubmitted(true);
    if (job.name.trim() && isJobNameUnique) {
      await props.updateJob({
        id   : job.id,
        name : job.name
      });

      setJobDialog(false);
      setJob(emptyJob);
      showSuccessfulToast(toast, 'Job updated');
    }
  };

  const addNewJob = async () => {
    setSubmitted(true);
    if (job.name && isJobNameUnique) {
      await props.createJob({
        name : job.name});

      setNewJobDialog(false);
      setJob(emptyJob);
      showSuccessfulToast(toast, 'New job added');
    }
  };

  const deleteJob = async () => {
    await props.deleteJob({_id : job.id});

    setDeleteJobDialog(false);
    setJob(emptyJob);
    showSuccessfulToast(toast, 'Job deleted permanently');
  };


  const actionBodyTemplate = rowData => (
    <Fragment>
      <Button className="p-button-rounded p-button-success mr-2" icon="pi pi-pencil" onClick={() => editJob(rowData)} />
      <Button className="p-button-rounded p-button-danger" icon="pi pi-trash" onClick={() => confirmDeleteJob(rowData)} />
    </Fragment>
  );

  return (
    <div className="datatable-crud-teams">
      <>
        <Toast ref={toast} />
        <div className="teams-container">
          <DataTable
            columnResizeMode="fit" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teams" dataKey="id"
            globalFilter={globalFilter} header={header(openNewTeam, handleSearchInput)}
            modal
            paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            ref={dt}
            resizableColumns
            rows={5}
            rowsPerPageOptions={rowPersPage}
            showGridlines value={Object.values(props.jobs)}
          >
            <Column
              field="name" header="Name" sortable
              style={{width : '25%'}}
            />
            <Column
              body={actionBodyTemplate} className="actions" exportable={false}
              style={{width : '25%'}}
            />
          </DataTable>
        </div>

        <Dialog
          blockScroll="true"
          className="teamsDialog p-fluid"
          footer={job.id === null ? dialogFooter(hideNewJobDialog, addNewJob) : dialogFooter(hideEditJobDialog, saveJob)}
          header={job.id === null ? 'New job' : 'Job details'}
          onHide={job.id === null ? hideNewJobDialog : hideEditJobDialog} style={{width : '450px'}}
          visible={job.id === null ? newJobDialog : jobDialog}
        >
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText
              autoFocus className={classNames({'p-invalid' : submitted && !job.name})} id="name"
              onChange={event => onInputChange(event, 'name')} required value={job.name}
            />
            {submitted && !job.name && <small className="p-error">Name is required.</small>}
            {submitted && job.name && !isJobNameUnique && <small className="p-error">Team name already exists.</small>}
          </div>
        </Dialog >

        <Dialog
          className="team-modals" footer={dialogFooter(hideDeleteTeamDialog, deleteJob)} header="Confirm"
          modal
          onHide={hideDeleteTeamDialog} style={{width : '450px'}} visible={deleteJobDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {job && <span>Are you sure you want to delete <b>{job.name}</b>?</span>}
          </div>
        </Dialog>

        <Dialog
          className="team-modals" header="Action denied" modal
          onHide={hideCanNotDeleteTeamDialog} style={{width : '450px'}} visible={canNotDeleteJobDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {job && <span>This action can not be performed because there are active members with <b>{job.name} job</b>.
              <br />Please remove the job from all members.</span>}
          </div>
        </Dialog>
      </>
    </div >
  );
};


JobsTable.displayName = 'JobsTable';
JobsTable.propTypes = {
  jobs      : object.isRequired,
  users     : object.isRequired,
  getJobs   : func.isRequired,
  createJob : func.isRequired,
  updateJob : func.isRequired,
  deleteJob : func.isRequired
};
export default JobsTable;
