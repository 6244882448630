import StatisticsTable from './StatisticsTable';
import {connect} from 'react-redux';

const mapStateToProps = state => ({
  state
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTable);
