import _ from 'lodash';

const CASE_TYPES = {
  CAMEL : 'CAMEL',
  SNAKE : 'SNAKE'
};

const isArrayOfPrimitives = array => !_.some(array, _.isObject);

_.mixin({
  deeply : map => (obj, fn) => map(_.mapValues(obj, val => {
    if (!_.isNull(val) && (_.isPlainObject(val) || _.isArray(val) && !isArrayOfPrimitives(val))) {
      return _.deeply(map)(val, fn);
    }

    return val;
  }), fn)
});

const toCase = (caseType, data) => {
  const method = caseType === CASE_TYPES.CAMEL ? 'camelCase' : 'snakeCase';

  return _.deeply(_.mapKeys)(data, (val, key) => _[method](key));
};

const toCamelCase = _.partial(toCase, CASE_TYPES.CAMEL);
const toSnakeCase = _.partial(toCase, CASE_TYPES.SNAKE);

export {
  toCamelCase,
  toSnakeCase
};
