/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */
/* eslint-disable complexity */
import React from 'react';
import './StatisticsCharts.scss';
import {object} from 'prop-types';
import {PieChart, Pie, Cell, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Bar, BarChart, ResponsiveContainer} from 'recharts';
import {Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import {capitalize} from 'lodash-es';
import {STATUS, PRIORITY_COLORS} from 'constant';
import useWindowSize from 'service/utils/useWindowSize';

const StatisticsCharts = props => {
  const {width} = useWindowSize();
  const COLORS = ['#303262', '#006260', '#008d6a', PRIORITY_COLORS.LOW, '#72a62c', '#b7ab00', PRIORITY_COLORS.MEDIUM];
  const COLORS1 = [PRIORITY_COLORS.MEDIUM, '#f97130', PRIORITY_COLORS.HIGH, PRIORITY_COLORS.HIGHEST];
  const COLORS2 = ['#036316', '#990d03'];

  const statusCategoryTable = data => {
    const dataArray = data.data;
    const total = data.total;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small" >
          <TableHead>
            <TableRow >
              { Object.keys(dataArray[0]).map((value, index) =>
                // eslint-disable-next-line
                <TableCell align={index === 0 ? 'inherit' : 'center'} key={value}>{value === 'inTime' ? 'In Time' : value === 'percent' ? capitalize(value) + '(%)' : capitalize(value)}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(dataArray).map((row, index) => (
              <TableRow
                key={row.status ? row.status : row.category}
                sx={{'&:last-child td, &:last-child th' : {border : 0},
                  '& > *'                            : {backgroundColor : index % 2 === 0 ? '#f0f2f577' : 'white'
                  }}}
              >
                <TableCell component="th" scope="row">
                  {row.status ? row.status : row.category}
                </TableCell>
                <TableCell align="center">{row.inTime}</TableCell>
                <TableCell align="center">{row.overdue}</TableCell>
                <TableCell align="center">{row.percent.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow sx={{'&:last-child td, &:last-child th' : {border : 0}}}>
              <TableCell>TOTAL</TableCell>
              <TableCell align="center">{total.inTime}</TableCell>
              <TableCell align="center">{total.overdue}</TableCell>
              <TableCell align="center">{total.percent}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        dominantBaseline="central"
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        x={x}
        y={y}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  const statusCategoryChart = data => {
    const data01 = [];
    const data02 = [];

    Object.values(data.data).map(item => {
      data01.push({value : item.inTime + item.overdue,
        name  : item.status ? item.status : item.category});
      data02.push({value : item.inTime,
        name  : 'In Time'});
      data02.push({value : item.overdue,
        name  : 'Overdue'});

      return {};
    });

    return (
      <PieChart height={280} width={300}>
        <Pie
          cx={140}
          cy={140}
          data={data01}
          dataKey="value"
          label={renderCustomizedLabel}
          labelLine={false}
          outerRadius={90}
        >
          {data01.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
          ))}
        </Pie>
        <Pie
          cx={140}
          cy={140}
          data={data02}
          dataKey="value"
          innerRadius={90}
          label
          outerRadius={100}
        >
          {data02.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell fill={COLORS2[index % 2]} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };

  const teamTable = data => {
    const dataArray = data.data;
    const total = data.total;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small"stickyHeader >
          <TableHead >
            <TableRow >
              <TableCell rowSpan={2}>Team</TableCell>
              <TableCell align="center" colSpan={Object.keys(STATUS).length - 1}>Objectives</TableCell>
              <TableCell align="center" rowSpan={2}>Percent(%)</TableCell>
            </TableRow>
            <TableRow >
              {
                Object.values(STATUS).filter(value => value !== 'deleted')
                  .map(value =>
                    <TableCell
                      align="center" key={value} rowSpan={2}
                    >{capitalize(value)}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.values(dataArray).map((value, index) =>
                (
                  <TableRow
                    key={value.team} sx={{
                      '& > *' : {
                        backgroundColor : index % 2 === 0 ? '#f0f2f577' : 'white'
                      }}}
                  >
                    <TableCell>{value.team}</TableCell>
                    { Object.values(value.statusDistribution).map(status => (
                      <TableCell align="center" key={status.status}>{status.inTime + status.overdue}</TableCell>
                    ))}
                    <TableCell align="center">{value.total.percent.toFixed(2)}</TableCell>
                  </TableRow>)
              )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>TOTAL</TableCell>
              <TableCell align="center"colSpan={Object.keys(STATUS).length - 1}>{total.number}</TableCell>
              <TableCell align="center">{total.percent}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  };

  const teamChart = teamData => {
    const data = Object.values(teamData.data).map(item => {
      let team = {};

      team.name = item.team;
      const statusDistribution = Object.values(item.statusDistribution).reduce((returnObject, statusData) => {
        returnObject['In time ' + statusData.status] = statusData.inTime;
        returnObject['Overdue ' + statusData.status] = statusData.overdue;

        return returnObject;
      }, {});

      team = {
        ...team,
        ...statusDistribution
      };

      return team;
    });

    return (
      <ResponsiveContainer height={400} width="95%">
        <BarChart
          data={data}
          margin={{
            top    : 20,
            right  : 30,
            bottom : 20
          }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} tickCount={10} />
          <Tooltip />
          {
            width > 800 &&
            <Legend align="left" layout="horizontal" width="90%" />
          }
          {
            Object.keys(data[0]).filter(item => item !== 'name')
              .map((item, index) => (
                <Bar
                  dataKey={item} fill={item.includes('In time') ? COLORS[parseInt(index / 2, 10)] : COLORS1[parseInt(index / 2, 10)]} key={item}
                  stackId={item.includes('In time') ? 'In time' : 'overdue'}
                />
              ))
          }
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="statistics-charts" >
      <div className="statistics-charts__byCriterion">
        <h3>Statistics by Status</h3>
        <div className="statistics-charts__byCriterion__chart">
          {props.statisticsData.statistics ? statusCategoryChart(props.statisticsData.statistics.byStatus) : ''}
        </div>
        <div className="statistics-charts__byCriterion__table">
          {props.statisticsData.statistics ? statusCategoryTable(props.statisticsData.statistics.byStatus) : ''}
        </div>
      </div>
      <div className="statistics-charts__byCriterion">
        <h3>Statistics by Category</h3>
        <div className="statistics-charts__byCriteriony__chart">
          {props.statisticsData.statistics ? statusCategoryChart(props.statisticsData.statistics.byCategory) : ''}
        </div>
        <div className="statistics-charts__byCriterion__table">
          {props.statisticsData.statistics ? statusCategoryTable(props.statisticsData.statistics.byCategory) : ''}
        </div>
      </div>
      <div className="statistics-charts__byCriterion">
        <h3>Statistics by Team</h3>
        <div className="statistics-charts__byCriterion__chart">
          {props.statisticsData.statistics ? teamChart(props.statisticsData.statistics.byTeam) : ''}
        </div>
        <div className="statistics-charts__byCriterion__table">
          {props.statisticsData.statistics ? teamTable(props.statisticsData.statistics.byTeam) : ''}
        </div>
      </div>
    </div>
  );
};


StatisticsCharts.displayName = 'StatisticsCharts';
StatisticsCharts.propTypes = {
  statisticsData : object.isRequired


};

export default StatisticsCharts;
