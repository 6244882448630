/* eslint-disable capitalized-comments */
// import {Button, SwipeableDrawer} from '@mui/material';
import * as React from 'react';

// import {func, bool, array, object, oneOfType} from 'prop-types';
import './Drawers.scss';

// import {Visibility} from '@mui/icons-material';
// import {ROUTE_PATH} from 'constant';
// import map from 'lodash/map';
// import {If, Invite} from 'components';
// import {useHistory, useLocation} from 'react-router-dom';

const TeamManagementDrawer = (

  // {open, toggleDrawer, teamMembers, getTeamMembers, isTeamLead, isOwner, inviteTeamMember, setOpen, wasInviteSuccessful}
) =>

// const history = useHistory();
// const [teams, setTeams] = React.useState([]); //eslint-disable-line

// const search = useLocation().search;
// const currentdUserId = new URLSearchParams(search).get('id');

// React.useEffect(() => {
//   if (isTeamLead || isOwner) {
//     getTeamMembers();
//   }
// }, []);

// React.useEffect(() => {
//   if (isOwner && teamMembers) {
//     setTeams(map(teamMembers, (value, key) => key));
//   }
// }, [teamMembers]);

// React.useEffect(() => {
//   setOpen({team : false,
//     user : false});
// }, [currentdUserId]);

// const handleView = memberId => {
//   history.push({
//     pathname : ROUTE_PATH.EMPLOYEE_DETAILS,
//     search   : `?id=${memberId}`,
//     state    : {
//       userId : memberId
//     }
//   });
// };

// const handleInvite = email => {
//   inviteTeamMember({
//     teamMemberEmail : email
//   });
// };


  (
    <div className="drawer">
      {/* <SwipeableDrawer
        anchor={'right'}
        onClose={toggleDrawer(false, false)}
        onOpen={toggleDrawer(true, false)}
        open={open}
      >
        <div className="drawer__name">
          <h4>Team Management</h4>
        </div>
        <div className="drawer__content">
          <If
            condition={isOwner} otherwise={() =>
              map(teamMembers, member => (
                <div className="drawer__content__list-item" key={member.id}>
                  <span className="user-name" >{member.name}</span>
                  {member.registered ? (
                    <Button
                      onClick={() => handleView(member.id)} size="small"
                      startIcon={<Visibility />}
                      variant="outlined"
                    >
                      View
                    </Button>) : (<Invite isInviteFinished={wasInviteSuccessful} onButtonClick={() => handleInvite(member.email)} />)
                  }
                </div>
              ))}
          >

            {
              map(teams, team => (
                <div key={team}>
                  <span className="team-name">{team}</span>
                  {
                    map(teamMembers[team], member => (
                      <div className="drawer__content__list-item" key={member.id}>
                        <span className="user-name" >{member.name}</span>
                        {member.registered ? (
                          <Button
                            onClick={() => handleView(member.id)} size="small"
                            startIcon={<Visibility />}
                            variant="outlined"
                          >
                            View
                          </Button>) : (<Invite isInviteFinished={wasInviteSuccessful} onButtonClick={() => handleInvite(member.email)} />
                        )}
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </If>
        </div>
      </SwipeableDrawer> */}
    </div>
  );

// TeamManagementDrawer.propTypes = {
// toggleDrawer        : func.isRequired,
// open                : bool.isRequired,
// setOpen             : func.isRequired,
// getTeamMembers      : func.isRequired,
// teamMembers         : oneOfType([array, object]).isRequired,
// isTeamLead          : bool.isRequired,
// isOwner             : bool.isRequired,
// inviteTeamMember    : func.isRequired,
// wasInviteSuccessful : bool.isRequired
// };


TeamManagementDrawer.displayName = 'TeamManagementDrawer';

export default TeamManagementDrawer;
