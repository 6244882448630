import React, {useEffect} from 'react';
import {isEmpty} from 'lodash';
import {bool, func, object} from 'prop-types';
import {TabView, TabPanel} from 'primereact/tabview';
import {UsersTable, TeamsTable, RolesTable, LinksTable, JobsTable, SideMenu} from '../../components';
import './Admin.scss';

/**
 * Admin page, visible only for users who have the required capabilities
 * Manages CRUD op. on Users, Teams, Links (not yet implemented), Roles and their capabilities
 */
const Admin = props => {
  useEffect(() => {
    if (isEmpty(props.jobs)) {
      props.getJobs();
    }
    if (isEmpty(props.users)) {
      props.getAllUsers();
    }
    if (isEmpty(props.links)) {
      props.getLinks();
    }
    if (isEmpty(props.teams)) {
      props.getTeams();
    }
    if (isEmpty(props.roles)) {
      props.getRoles();
    }
  }, []);

  useEffect(() => {
    const shouldFetch = [props.deleteUserSuccessful, props.editUserSuccessful, props.restoreUserSuccessful, props.addUserSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getAllUsers();
    }
  }, [props.deleteUserSuccessful, props.restoreUserSuccessful,
    props.addUserSuccessful, props.editUserSuccessful]);

  useEffect(() => {
    const shouldFetch = [props.createTeamSuccessful, props.deleteTeamSuccessful, props.updateTeamSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getTeams();
      props.getAllUsers();
    }
  }, [props.createTeamSuccessful, props.deleteTeamSuccessful, props.updateTeamSuccessful]);

  useEffect(() => {
    const shouldFetch = [props.createRoleSuccessful, props.updateRoleSuccessful, props.deleteRoleSuccessful, props.restoreRoleSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getRoles();
      props.getAllUsers();
      props.getUserCapabilities();
    }
  }, [props.createRoleSuccessful, props.updateRoleSuccessful, props.deleteRoleSuccessful, props.restoreRoleSuccessful]);

  useEffect(() => {
    const shouldFetch = [props.createLinkSuccessful, props.updateLinkSuccessful, props.deleteLinkSuccessful, props.restoreLinkSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getLinks();
    }
  }, [props.createLinkSuccessful, props.updateLinkSuccessful, props.deleteLinkSuccessful, props.restoreLinkSuccessful]);

  useEffect(() => {
    const shouldFetch = [props.createJobSuccessful, props.updateJobSuccessful, props.deleteJobSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getJobs();
      props.getAllUsers();
    }
  }, [props.createJobSuccessful, props.updateJobSuccessful, props.deleteJobSuccessful]);

  return (
    <div className="admin">
      <div className="admin-container">
        <div className="sidemenu">
          <SideMenu />
        </div>
        <TabView className="tabview-header-admin">
          <TabPanel className="users-tab-view" header="Users" leftIcon="pi pi-user">
            <UsersTable />
          </TabPanel>
          <TabPanel className="teams-tab-view" header="Teams" leftIcon="pi pi-users">
            <TeamsTable />
          </TabPanel>
          <TabPanel className="jobs-tab-view" header="Roles">
            <JobsTable />
          </TabPanel>
          <TabPanel className="links-tab-view" header="Links" leftIcon="pi pi-link">
            <LinksTable />
          </TabPanel>
          <TabPanel className="roles-tab-view" header="Access" leftIcon="pi pi-lock">
            <RolesTable />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

Admin.displayName = 'Admin';
Admin.propTypes = {
  jobs                  : object.isRequired,
  links                 : object.isRequired,
  users                 : object.isRequired,
  teams                 : object.isRequired,
  roles                 : object.isRequired,
  getLinks              : func.isRequired,
  getTeams              : func.isRequired,
  getAllUsers           : func.isRequired,
  getRoles              : func.isRequired,
  getJobs               : func.isRequired,
  getUserCapabilities   : func.isRequired,
  deleteUserSuccessful  : bool.isRequired,
  addUserSuccessful     : bool.isRequired,
  restoreUserSuccessful : bool.isRequired,
  editUserSuccessful    : bool.isRequired,
  updateTeamSuccessful  : bool.isRequired,
  createTeamSuccessful  : bool.isRequired,
  deleteTeamSuccessful  : bool.isRequired,
  createRoleSuccessful  : bool.isRequired,
  updateRoleSuccessful  : bool.isRequired,
  deleteRoleSuccessful  : bool.isRequired,
  restoreRoleSuccessful : bool.isRequired,
  createLinkSuccessful  : bool.isRequired,
  updateLinkSuccessful  : bool.isRequired,
  deleteLinkSuccessful  : bool.isRequired,
  restoreLinkSuccessful : bool.isRequired,
  createJobSuccessful   : bool.isRequired,
  updateJobSuccessful   : bool.isRequired,
  deleteJobSuccessful   : bool.isRequired
};
export default Admin;
