/* eslint-disable max-statements */
import React, {useEffect, useState} from 'react';
import {object, array, oneOfType, func} from 'prop-types';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {SideMenu} from '../../components';
import './ViewHolidays.scss';
import {MultiSelect} from 'primereact/multiselect';
import {AddMemberButton} from '../../components/StyledButtons/StyledButtons';
import {getPublicHolidays} from './publicHolidays';
import {isEmpty} from 'lodash';
import {Button} from 'primereact/button';


moment.locale('en-GB');
const localizer = momentLocalizer(moment);

const ViewHolidays = props => {
  const [eventsList, setEventsList] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState(null);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);

  const holidayTypes = [
    {key   : 'De odihna',
      label : 'Vacation Day',
      color : '#6B46FF'},
    {key   : 'national_holiday',
      label : 'National Day',
      color : '#00CA5E'},
    {key   : 'Medical',
      label : 'Medical',
      color : '#FE9C08'},
    {key   : 'Donator sange',
      label : 'Blood donation',
      color : '#D21219'},
    {key   : 'Concediu fara plata pentru rezolvarea unei situatii personale',
      label : 'Unpaid leave',
      color : '#8894AA'},
    {key   : 'Birthday',
      label : 'Birthday',
      color : '#CA00BD'}
  ];

  useEffect(() => {
    if (isEmpty(props.daysLeft)) {
      props.getDaysLeft();
    }
    if (isEmpty(props.teams)) {
      props.getTeams();
    }
    if (isEmpty(props.allHolidays)) {
      props.getAllHolidays();
    }
    if (isEmpty(props.teams)) {
      props.getTeams();
    }
  }, []);

  useEffect(() => {
    const team = props.user.team;

    const activeFilter = Object.values(props.teams).filter(tm => team.includes(tm.id));

    setSelectedTeams(activeFilter);

    setSelectedTeamIds(team);
  }, [props.user, props.teams]);

  useEffect(() => {
    getPublicHolidays(currentYear, 'RO').then(data => {
      const transformedHolidays = data.map(holiday => ({
        title : holiday.localName,
        start : new Date(holiday.date),
        end   : new Date(holiday.date),
        type  : 'national_holiday'
      }));

      setPublicHolidays([...transformedHolidays]);
    });
  }, [currentYear]);

  useEffect(() => {
    if (eventsList.length > 0) {
      const filteredHolidays = eventsList.filter(holiday =>
        holiday?.title && holiday.title.toLowerCase().includes(props.searchInputValue.searchInputValue)
      );

      setFilteredEvents(filteredHolidays);
    }

    if (props.searchInputValue.searchInputValue.length === 0) {
      props.getAllHolidays();
    }
  }, [props.searchInputValue]);

  useEffect(() => {
    const transformedTeamHolidays = Object.values(props.allHolidays).map(event => ({
      ...event,
      start : new Date(event.start),
      end   : new Date(event.end)
    }));

    setEventsList([...transformedTeamHolidays]);
  }, [props.allHolidays]);

  useEffect(() => {
    const filteredEventsTemp = eventsList.filter(event => {
      if (selectedTeamIds.length === 0) {
        return true;
      }
      if (!event.team) {
        return false;
      }

      return event.team.some(teamId => selectedTeamIds.includes(teamId));
    });

    setFilteredEvents(filteredEventsTemp);
  }, [eventsList, selectedTeamIds]);

  const handleNavigation = newDate => {
    const newYear = moment(newDate).year();

    if (newYear !== currentYear) {
      setCurrentYear(newYear);
    }
  };

  const eventStyleGetter = event => {
    const foundType = holidayTypes.find(type => type.key === event.type);
    const backgroundColor = foundType ? foundType.color : '#6B46FF';

    const style = {
      backgroundColor,
      borderRadius : '2px',
      opacity      : 1,
      color        : '#fff',
      border       : '0px',
      display      : 'block',
      margin       : '1px 0'
    };

    return {
      style
    };
  };

  const handleOnChangeTeam = event => {
    setSelectedTeams(event.value);
    const selectedTeamIdsTemp = event.value.map(team => team.id);

    setSelectedTeamIds(selectedTeamIdsTemp);
  };

  if (isEmpty(props.allHolidays)) {
    return (<div className="loading">
      <span className="loader" />
    </div>);
  }

  return (
    <div className="holidays-page">
      <div className="holidays-container">
        <div className="holidays__sideMenu">
          <SideMenu />
        </div>
        <div className="holidays__content">
          <section className="header">
            <div className="flex-1">
              <p>Holidays</p>
              <MultiSelect
                className="teams-dropdown"
                filter
                onChange={handleOnChangeTeam}
                optionLabel="name"
                options={[...Object.values(props.teams).filter(team => team.status === 'active')]}
                placeholder="All Members"
                value={selectedTeams}
              />
            </div>
            <div className="flex-2">
              <p>{props.daysLeft.daysLeft >= 0 ? `${props.daysLeft.daysLeft} days left` : 'No information yet'}</p>
              <a
                className="go-to-holidays-app" href="https://holidays.bitstone.eu" rel="noopener noreferrer"
                target="_blank"
              >
                <AddMemberButton>Open Holidays App</AddMemberButton>
              </a>
            </div>
          </section>
          {eventsList &&
          <section className="calendar">
            <Calendar
              className="calendar-container"
              components={{
                toolbar : ({onNavigate, label}) => (
                  <div className="custom-toolbar">
                    <div className="tb-buttons">
                      <Button icon="pi pi-angle-left" onClick={() => onNavigate('PREV')} rounded />
                      <Button icon="pi pi-angle-right" onClick={() => onNavigate('NEXT')} rounded />
                    </div>
                    <span className="tb-text">{label}</span>
                    <Button className="this-month-button" onClick={() => onNavigate('TODAY')}>
                      This Month
                    </Button>
                  </div>
                )
              }}
              culture="en-GB"
              defaultDate={new Date()}
              defaultView="month"
              endAccessor="end"
              eventPropGetter={eventStyleGetter}
              events={filteredEvents.concat(publicHolidays)}
              localizer={localizer}
              onNavigate={handleNavigation}
              popup
              startAccessor="start"
              views={['month']}
            />
          </section>
          }
          <section className="legenda">
            {holidayTypes.map(holiday => (
              <div className="each-type" key={holiday.label}>
                <div
                  className="circle"
                  style={{
                    backgroundColor : holiday.color,
                    borderColor     : holiday.color
                  }}
                />
                <span>{holiday.label}</span>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

ViewHolidays.displayName = 'ViewHolidays';
ViewHolidays.propTypes = {
  teams            : oneOfType([array, object]).isRequired,
  users            : oneOfType([array, object]).isRequired,
  getDaysLeft      : func.isRequired,
  getAllHolidays   : func.isRequired,
  getTeams         : func.isRequired,
  daysLeft         : object.isRequired,
  allHolidays      : object.isRequired,
  user             : object.isRequired,
  searchInputValue : object.isRequired
};
export default ViewHolidays;
