import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_JOBS = buildActionType(getParts(API_ENDPOINT.JOB.GET_JOBS));


const setJobsReducer = (state, {payload: {data : {jobs}}}) => ({
  ...state,
  jobs
});


const cleanupReducer = () => initialState;

const jobReducer = handleActions({
  [GET_JOBS]                   : setJobsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default jobReducer;
