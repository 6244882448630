/* eslint-disable no-underscore-dangle */
import {handleActions} from 'redux-actions';
import {ActionTypes} from 'action/user';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';

import {buildActionType} from 'action/apiResource';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_TEAM_LEADER = buildActionType(getParts(API_ENDPOINT.USER.GET_TEAM_LEADER));
const GET_USER_DETAILS = buildActionType(getParts(API_ENDPOINT.USER.GET_USER_DETAILS));
const GET_JOB_TITLES = buildActionType(getParts(API_ENDPOINT.USER.GET_JOB_TITLES));
const GET_TEAMS = buildActionType(getParts(API_ENDPOINT.USER.GET_TEAMS));
const GET_ALL_USERS = buildActionType(getParts(API_ENDPOINT.USER.GET_ALL_USERS));
const GET_USER_CAPABILITIES = buildActionType(getParts(API_ENDPOINT.USER.GET_USER_CAPABILITIES));
const GET_TEAMLEADS = buildActionType(getParts(API_ENDPOINT.USER.GET_TEAMLEADS));
const GET_PROJECTS_TITLE = buildActionType(getParts(API_ENDPOINT.USER.GET_PROJECTS_TITLE));

const setAllUsers = (state, {payload: data}) => ({
  ...state,
  users : data.data
});

const setAllProjects = (state, {payload: data}) => ({
  ...state,
  projects : data.data
});

const setAllTeamLeads = (state, {payload: data}) => ({
  ...state,
  teamLeads : data.data
});

const setUserReducer = (state, {payload: user}) => ({
  ...state,
  user
});

const setUserCapabilities = (state, {payload: data}) => ({
  ...state,
  capabilities : data.data.capabilities
});

const setUserDetails = (state, {payload: {data: userDetails}}) => ({
  ...state,
  userDetails
});

const setTeamLeader = (state, {payload: {data: {user}}}) => ({
  ...state,
  teamLeader : user
});

const setJobTitles = (state, {payload: {data: {jobTitles:jobs}}}) => ({
  ...state,
  jobs
});

const setTeams = (state, {payload: {data: {teams}}}) => ({
  ...state,
  teams
});

const cleanupReducer = () => initialState;

const userReducer = handleActions({
  [GET_TEAM_LEADER]            : setTeamLeader,
  [GET_USER_DETAILS]           : setUserDetails,
  [GET_USER_CAPABILITIES]      : setUserCapabilities,
  [GET_JOB_TITLES]             : setJobTitles,
  [GET_TEAMS]                  : setTeams,
  [GET_ALL_USERS]              : setAllUsers,
  [GET_TEAMLEADS]              : setAllTeamLeads,
  [GET_PROJECTS_TITLE]         : setAllProjects,
  [ActionTypes.SET_USER]       : setUserReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default userReducer;
