const CATEGORY = {
  PERSONAL_STUFF : 'Personal',
  TECHNOLOGIES   : 'Technology',
  SOFT_SKILLS    : 'Soft Skills',
  MANAGEMENT     : 'Management'
};

const PRIORITY_COLORS = {
  LOWEST  : '#CDFF90',
  LOW     : '#5eb136',
  MEDIUM  : '#fcec30',
  HIGH    : '#F28B82',
  HIGHEST : '#d8363d'
};

const PRIORITY_COLORS_LIGHT = {
  LOWEST  : '#CDFF9030',
  LOW     : '#5eb13630',
  MEDIUM  : '#fcec3030',
  HIGH    : '#F28B8230',
  HIGHEST : '#d8363d30'
};

const CATEGORY_COLORS = {
  [CATEGORY.PERSONAL_STUFF] : '#932432',
  [CATEGORY.TECHNOLOGIES]   : '#3C1874',
  [CATEGORY.SOFT_SKILLS]    : '#006260',
  [CATEGORY.MANAGEMENT]     : '#004C7C'
};

const CATEGORY_COLORS_LIGHT = {
  [CATEGORY.PERSONAL_STUFF] : '#93243280',
  [CATEGORY.TECHNOLOGIES]   : '#3C187480',
  [CATEGORY.SOFT_SKILLS]    : '#00626080',
  [CATEGORY.MANAGEMENT]     : '#004C7C80'
};

export {
  PRIORITY_COLORS,
  PRIORITY_COLORS_LIGHT,
  CATEGORY_COLORS,
  CATEGORY_COLORS_LIGHT
};
