import {getHasCallSucceeded} from 'selector/app';

import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import TeamManagementDrawer from './TeamManagement';
import {getTeamMembers} from '../../store/selector/teamMembers';
import {isCurrentUserTeamLead, getUser} from '../../store/selector/user';

const mapStateToProps = state => ({
  teamMembers         : getTeamMembers(state),
  isTeamLead          : isCurrentUserTeamLead(state),
  isOwner             : getUser(state).isOwner || getUser(state).isTeamsManager,
  wasInviteSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.inviteTeamMember.requestTracker
  })
});

const mapDispatchToProps = {
  getTeamMembers   : apiRequestAction.user.getTeamMembers(),
  inviteTeamMember : apiRequestAction.user.inviteTeamMember()
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagementDrawer);
