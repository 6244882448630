import {call} from 'redux-saga/effects';
import {toCamelCase} from 'service/utils';

export const formatter = req => new Promise(async resolve => {
  const {method, url, headers} = req;
  const clone = req.clone();

  try {
    const body = await req.json();
    const apiReadyBody = toCamelCase(body);

    const newRequest = new Request(url, {
      headers,
      method,
      body : JSON.stringify(apiReadyBody)
    });

    resolve(newRequest);
  } catch (ex) {
    resolve(clone);
  }
});

const middleware = () => function* payloadFormatterMiddleware (req, next) {
  const newRequest = yield call(formatter, req);
  const response = yield next(newRequest);

  return response;
};

export default middleware;
