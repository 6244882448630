/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useRef, Fragment, useEffect} from 'react';
import {map} from 'lodash';
import {func, object} from 'prop-types';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {showSuccessfulToast} from '../../utils/utils';
import './TeamsTable.scss';
import {dialogFooter, header} from '../RolesTable/utils';

/**
 * Table in Admin page for CRUD on teams
 */
const TeamsTable = props => {
  let emptyTeam = {
    id         : null,
    name       : '',
    technology : ''
  };

  // eslint-disable-next-line no-magic-numbers
  const rowPersPage = [5, 10, 15];

  const [team, setTeam] = useState(emptyTeam);
  const [technologies, setTechnologies] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [newTeamDialog, setNewTeamDialog] = useState(false);
  const [canNotDeleteTeamDialog, setCanNotDeleteTeamDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [teamDialog, setTeamDialog] = useState(false);
  const [deleteTeamDialog, setDeleteTeamDialog] = useState(false);
  const [isTeamNameUnique, setIsTeamNameUnique] = useState(true);
  const dt = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    const temp = [];

    map(props.teams, currTeam => {
      temp.push(currTeam.technology);
    });
    setTechnologies([...new Set(temp)]);
  }, [props.teams]);

  const doesTeamExist = (teamName, teams) =>
    teams.some(valTeam => valTeam.name === teamName);

  const onInputChange = (event, name) => {
    const val = event.target && event.target.value || '';

    if (name === 'name') {
      if (val) {
        const exists = doesTeamExist(val, Object.values(props.teams));

        setIsTeamNameUnique(!exists);
      } else {
        setIsTeamNameUnique(true);
      }
    }

    let _team = {...team};

    _team[`${name}`] = val;
    setTeam(_team);
  };

  const onTechnologyChange = event => {
    let _team = {...team};

    _team.technology = event.value;
    setTeam(_team);

    setSelectedTechnology(event.value);
  };

  const openNewTeam = () => {
    setTeam(emptyTeam);
    setSubmitted(false);
    setNewTeamDialog(true);
  };

  const editTeam = currentTeam => {
    setTeam({...currentTeam});
    setTeamDialog(true);
  };

  const confirmDeleteTeam = currTeam => {
    setTeam(currTeam);

    const canBeDeleted = !Object.values(props.users).some(user =>
      Object.values(user.team).some(userTeam => userTeam.id === currTeam.id) && user.status !== 'deleted'
    );

    if (canBeDeleted) {
      setDeleteTeamDialog(true);
    } else {
      setCanNotDeleteTeamDialog(true);
    }
  };


  const handleSearchInput = event => {
    setGlobalFilter(event.target.value);
  };

  const hideEditTeamDialog = () => {
    setSubmitted(false);
    setTeamDialog(false);
  };

  const hideNewTeamDialog = () => {
    setSubmitted(false);
    setNewTeamDialog(false);
    setSelectedTechnology(null);
  };

  const hideDeleteTeamDialog = () => {
    setDeleteTeamDialog(false);
  };

  const hideCanNotDeleteTeamDialog = () => {
    setCanNotDeleteTeamDialog(false);
  };

  const saveTeam = async () => {
    setSubmitted(true);
    if (team.name.trim() && isTeamNameUnique) {
      await props.updateTeam({
        id         : team.id,
        name       : team.name,
        technology : team.technology
      });

      setTeamDialog(false);
      setTeam(emptyTeam);
      showSuccessfulToast(toast, 'Team updated');
    }
  };

  const addNewTeam = async () => {
    setSubmitted(true);
    if (team.name && isTeamNameUnique) {
      await props.createTeam({
        name       : team.name,
        technology : team.technology});

      setNewTeamDialog(false);
      setTeam(emptyTeam);
      setSelectedTechnology('');
      showSuccessfulToast(toast, 'New team added');
    }
  };

  const deleteTeam = async () => {
    await props.deleteTeam({_id : team.id});

    setDeleteTeamDialog(false);
    setTeam(emptyTeam);
    showSuccessfulToast(toast, 'Team deleted');
  };


  const actionBodyTemplate = rowData => (
    <Fragment>
      <Button className="p-button-rounded p-button-success mr-2" icon="pi pi-pencil" onClick={() => editTeam(rowData)} />
      <Button className="p-button-rounded p-button-danger" icon="pi pi-trash" onClick={() => confirmDeleteTeam(rowData)} />
    </Fragment>
  );

  return (
    <div className="datatable-crud-teams">
      <>
        <Toast ref={toast} />
        <div className="teams-container">
          <DataTable
            columnResizeMode="fit" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teams" dataKey="id"
            globalFilter={globalFilter} header={header(openNewTeam, handleSearchInput)}
            modal
            onSelectionChange={event => setSelectedTeams(event.value)}
            paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            ref={dt}
            resizableColumns
            rows={5}
            rowsPerPageOptions={rowPersPage}
            selection={selectedTeams}
            showGridlines value={Object.values(props.teams)}
          >
            <Column
              field="name" header="Name" sortable
              style={{width : '25%'}}
            />
            <Column
              field="technology" header="Technology" sortable
              style={{width : '25%'}}
            />
            <Column
              field="status" header="Status" sortable
              style={{width : '25%'}}
            />
            <Column
              body={actionBodyTemplate} className="actions" exportable={false}
              style={{width : '25%'}}
            />
          </DataTable>
        </div>

        <Dialog
          blockScroll="true"
          className="teamsDialog p-fluid"
          footer={team.id === null ? dialogFooter(hideNewTeamDialog, addNewTeam) : dialogFooter(hideEditTeamDialog, saveTeam)}
          header={team.id === null ? 'New team' : 'Team details'}
          onHide={team.id === null ? hideNewTeamDialog : hideEditTeamDialog} style={{width : '450px'}}
          visible={team.id === null ? newTeamDialog : teamDialog}
        >
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText
              autoFocus className={classNames({'p-invalid' : submitted && !team.name})} id="name"
              onChange={event => onInputChange(event, 'name')} required value={team.name}
            />
            {submitted && !team.name && <small className="p-error">Name is required.</small>}
            {submitted && team.name && !isTeamNameUnique && <small className="p-error">Team name already exists.</small>}
          </div>
          <div className="field">
            <label htmlFor="technology">Technology</label>
            <Dropdown
              editable onChange={onTechnologyChange} options={technologies}
              placeholder={team.technology ? team.technology : 'Type new technology if it\'s not in the list'} value={selectedTechnology}
            />
          </div>
        </Dialog >

        <Dialog
          className="team-modals" footer={dialogFooter(hideDeleteTeamDialog, deleteTeam)} header="Confirm"
          modal
          onHide={hideDeleteTeamDialog} style={{width : '450px'}} visible={deleteTeamDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {team && <span>Are you sure you want to delete <b>{team.name}</b> permanently?</span>}
          </div>
        </Dialog>

        <Dialog
          className="team-modals" header="Action denied" modal
          onHide={hideCanNotDeleteTeamDialog} style={{width : '450px'}} visible={canNotDeleteTeamDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {team && <span>This action can not be performed because there are active members in team <b>{team.name}</b>.
              <br />Please remove the team from all members.</span>}
          </div>
        </Dialog>

      </>
    </div >
  );
};


TeamsTable.displayName = 'TeamsTable';
TeamsTable.propTypes = {
  teams      : object.isRequired,
  users      : object.isRequired,
  getTeams   : func.isRequired,
  createTeam : func.isRequired,
  updateTeam : func.isRequired,
  deleteTeam : func.isRequired
};
export default TeamsTable;
