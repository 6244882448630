import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {CSS_VARIABLES} from '../../constant';

export const BootstrapButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 18,
  padding         : '6px 14px',
  border          : '1px solid',
  lineHeight      : 0,
  backgroundColor : 'transparent',
  borderColor     : 'transparent',
  color           : '#fff',
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '280px',
  alignItems      : 'center',
  height          : '50px',
  textDecoration  : 'none',
  '& path'        : {
    fill : '#fff'
  },
  '.css-1d6wzja-MuiButton-startIcon' : {
    marginRight : 0
  },
  '&:hover' : {
    boxShadow       : 'none',
    color           : '#fff',
    backgroundColor : 'rgba(70, 70, 70, 0.8)',
    '& path'        : {
      fill        : '#fff',
      fontWeight  : 'bold',
      marginRight : '-10px'
    }
  }
});

export const BootstrapMenuButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  textDecoration  : 'none',
  fontSize        : 18,
  padding         : '6px 12px',
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : 'transparent',
  borderColor     : 'transparent',
  color           : `${CSS_VARIABLES.BITSTONE_RED}`,
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '280px',
  alignItems      : 'center',
  height          : '50px',
  '& path'        : {
    fill       : `${CSS_VARIABLES.BITSTONE_RED}`,
    fontWeight : 'bold'
  },
  '&:hover' : {
    backgroundColor : `${CSS_VARIABLES.BITSTONE_RED}`,
    borderColor     : `${CSS_VARIABLES.BITSTONE_RED}`,
    boxShadow       : 'none',
    color           : '#fff',
    fontWeight      : 'bold',
    borderRadius    : 0,
    '& path'        : {
      fill       : '#fff',
      fontWeight : 'bold'
    }
  }
}
);

export const ExportButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 17,
  padding         : '6px 12px',
  border          : '0px solid',
  lineHeight      : '26px',
  backgroundColor : '#fff',
  borderColor     : `${CSS_VARIABLES.BITSTONE_RED}`,
  color           : `${CSS_VARIABLES.BITSTONE_RED}`,
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '175px',
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '4px',
  cursor          : 'pointer',
  marginLeft      : '12px',
  '&:hover'       : {
    backgroundColor : '#FFDDDE',
    borderColor     : `${CSS_VARIABLES.BACKGROUND_COLOR}`,
    boxShadow       : 'none',
    color           : `${CSS_VARIABLES.BITSTONE_RED}`
  }
});

export const ExportPDFButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'center',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 17,
  padding         : '6px 12px',
  border          : 'none',
  lineHeight      : '26px',
  backgroundColor : '#fff',
  borderColor     : 'none',
  color           : `${CSS_VARIABLES.TEXT_COLOR}`,
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '140px',
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '4px',
  cursor          : 'pointer',
  marginLeft      : '12px',
  '&:hover'       : {
    boxShadow : 'none'
  }
});

export const GoBackButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  padding         : '6px 0px',
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : 'none',
  borderColor     : 'transparent',
  color           : '#000',
  minWidth        : '35px',
  height          : '40px',
  cursor          : 'pointer',
  '&:hover'       : {
    backgroundColor : 'transparent',
    borderColor     : 'transparent',
    color           : `${CSS_VARIABLES.TEXT_COLOR}`,
    boxShadow       : 'none',
    '& path'        : {
      fill     : `${CSS_VARIABLES.TEXT_COLOR}`,
      fontSize : '25px'
    }
  }
});

export const DeleteRestoreButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  padding         : '6px 0px',
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : 'none',
  borderColor     : 'transparent',
  color           : '#000',
  minWidth        : '35px',
  height          : '40px',
  cursor          : 'pointer',
  '& path'        : {
    fill : `${CSS_VARIABLES.TEXT_COLOR}`
  },
  '&:hover' : {
    backgroundColor : 'transparent',
    borderColor     : 'transparent',
    color           : `${CSS_VARIABLES.TEXT_COLOR}`,
    boxShadow       : 'none',
    '& path'        : {
      fill      : `${CSS_VARIABLES.TEXT_COLOR}`,
      transform : 'scale(1.1)'
    }
  }
});

export const EditButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 16,
  padding         : '6px 12px',
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : 'transparent',
  borderColor     : 'transparent',
  color           : '#7f8692',
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '4px',
  cursor          : 'pointer',
  transition      : '0s',
  '&:hover'       : {
    backgroundColor : 'transparent',
    borderColor     : 'transparent',
    color           : `${CSS_VARIABLES.TEXT_COLOR}`,
    boxShadow       : 'none',
    '& path'        : {
      fill : `${CSS_VARIABLES.TEXT_COLOR}`
    }
  }
});

export const SaveButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'flex-start',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 17,
  padding         : '6px 12px',
  border          : '0px solid',
  lineHeight      : '26px',
  backgroundColor : `${CSS_VARIABLES.BITSTONE_RED}`,
  color           : '#fff',
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '116px',
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '4px',
  cursor          : 'pointer',
  '&:hover'       : {
    backgroundColor : `${CSS_VARIABLES.BACKGROUND_COLOR}`,
    borderColor     : `${CSS_VARIABLES.BACKGROUND_COLOR}`,
    boxShadow       : 'none',
    color           : '#fff'
  }
});

export const AddNewExperienceButton = styled(IconButton)({
  marginTop       : '1rem',
  display         : 'flex',
  justifyContent  : 'center',
  textTransform   : 'none',
  padding         : '6px 12px',
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : '#f1f3f6',
  borderColor     : '#f1f3f6',
  color           : '#fff',
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  minWidth        : '40px',
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '20px',
  cursor          : 'pointer',
  '&:hover'       : {
    backgroundColor : '#e7e9eb',
    borderColor     : '#e7e9eb',
    boxShadow       : 'none',
    color           : '#fff'
  }
});

export const AddMemberButton = styled(Button)({
  display         : 'flex',
  justifyContent  : 'center',
  flexDirection   : 'row',
  boxShadow       : 'none',
  textTransform   : 'none',
  fontSize        : 17,
  border          : '1px solid',
  lineHeight      : '26px',
  backgroundColor : `${CSS_VARIABLES.BITSTONE_RED}`,
  borderColor     : `${CSS_VARIABLES.BITSTONE_RED}`,
  color           : '#fff',
  fontFamily      : `${CSS_VARIABLES.FONT_FAMILY}`,
  width           : '155px',
  alignItems      : 'center',
  height          : '40px',
  textDecoration  : 'none',
  borderRadius    : '4px',
  cursor          : 'pointer',
  marginLeft      : '12px',
  '&:hover'       : {
    backgroundColor : `${CSS_VARIABLES.BACKGROUND_COLOR}`,
    borderColor     : `${CSS_VARIABLES.BACKGROUND_COLOR}`,
    boxShadow       : 'none',
    color           : '#fff'
  },
  '& path' : {
    fill : '#fff'
  }
});
