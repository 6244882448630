import {connect} from 'react-redux';
import CreateKeyResultDialog from './CreateKeyResultDialog';
import {apiRequestAction} from 'action/apiResource';

// Const mapStateToProps = state => ({
// });

const mapDispatchToProps = {
  addKeyResultToObjective : apiRequestAction.objective.addKeyResultToObjective(),
  editKeyResult           : apiRequestAction.keyResult.editKeyResult()
};

export default connect(null, mapDispatchToProps)(CreateKeyResultDialog);
