import {connect} from 'react-redux';
import CreateEvaluation from './CreateEvaluationDialog';
import {apiRequestAction} from 'action/apiResource';
import {isCurrentUserOwner, isCurrentUserTeamLead, getUser} from 'selector/user';
import {getTeamMembers} from 'selector/teamMembers';

const mapStateToProps = state => ({
  isOwner     : isCurrentUserOwner(state),
  isTeamLead  : isCurrentUserTeamLead(state),
  teamMembers : getTeamMembers(state),
  currentUser : getUser(state)
});

const mapDispatchToProps = {
  createEvaluation : apiRequestAction.evaluation.createEvaluation(),
  editEvaluation   : apiRequestAction.evaluation.editEvaluation()
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvaluation);
