import * as React from 'react';
import {Loader, ConfirmationDialog} from 'components';
import './Approvals.scss';
import Paper from '@mui/material/Paper';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {Box} from '@mui/system';
import {func, array, object, string, bool, number, oneOfType} from 'prop-types';
import {STATUS} from 'constant';


// eslint-disable-next-line
const Approvals = props => {
  const [objectivesToDisplay, setObjectivesToDisplay] = React.useState(
    Object.values(props.plans.items).filter(objective => objective.status === 'completed' || objective.status === 'deleted'));
  const [page] = React.useState(0);

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState({
    open : false
  });

  React.useEffect(() => {
    props.getUserPlans();
  }, []);

  React.useEffect(() => {
    if (props.editSuccessfull || props.deleteSuccessfull) {
      props.getUserPlans();
    }
  }, [props.editSuccessfull, props.deleteSuccessfull]);

  React.useEffect(() => {
    if (props.plans) {
      setObjectivesToDisplay(
        Object.values(props.plans.items).filter(objective => objective.status === STATUS.WAITING_FOR_APPROVAL));
    }
  }, [props.plans, props.user]);

  const completeAction = (model, id) => { //eslint-disable-line
    props.editObjective({
      entityId : id,
      status   : STATUS.COMPLETED
    });
  };

  const deleteItem = (model, id) => { //eslint-disable-line
    props.deleteObjective({
      entityId : id
    });
  };


  const handleApproval = (id, nextStatus) => { //eslint-disable-line
    if (nextStatus === STATUS.DELETED) {
      props.deleteObjective({
        entityId : id
      });// eslint-disable-line
    } else {
      props.editObjective({
        entityId : id,
        status : STATUS.COMPLETED });// eslint-disable-line
    }
  };

  const handleRemoval = id => { //eslint-disable-line
    props.editObjective({
      entityId : id,
      status : STATUS.IN_PROGRESS });// eslint-disable-line
  };

  const Row = data => {
    const {row} = data;
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{
          '& > *' : {
            borderBottom : 'unset'
          }
        }}
        >
          <TableCell align="center">{row.ownerId.name}</TableCell>
          <TableCell align="center">
            {
              Array.isArray(row.ownerId.team) &&
              row.ownerId.team.length > 1 &&
              row.ownerId.team.toString().replaceAll(',', ', ') || row.ownerId.team
            }
          </TableCell>
          <TableCell align="center" scope="row" >
            {row.title}
          </TableCell>
          <TableCell align="center">{row.status + ' - ' + row.nextStatus }</TableCell>
          <TableCell>
            <Box
              className="approval-buttons"
              component="span"
              m={1}
            >
              <Button
                color="secondary"
                onClick={() => handleApproval(row.id, row.nextStatus)} variant="contained"
              >
                Approve
              </Button>
              <Button
                color="error"
                onClick={() => handleRemoval(row.id)} variant="contained"
              >
                Remove
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  Row.displayName = 'Row';

  const emptyRows = page > 0 ? Math.max(0, 1 + page - Object.values(objectivesToDisplay || props.plans.items).length) : 0;

  return (
    <Loader isLoading={props.isLoading}>
      <div className="approvals">
        <div className="approvals-container">
          <Box sx={{flexGrow : 1}}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div className="approvals-container__table">
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Name</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Team</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Objective</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Status</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {

                          // eslint-disable-next-line max-len
                          Object.values(objectivesToDisplay || props.plans.items).map(row => (
                            <Row key={row.name} row={row} />
                          ))}

                        {emptyRows > 0 && (
                          // eslint-disable-next-line no-magic-numbers
                          <TableRow style={{height : 50 * emptyRows}}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {Object.values(objectivesToDisplay).length === 0 && <p> There are no Objectives waiting for approval. </p>}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <ConfirmationDialog
        action={openConfirmationDialog.action}
        complete={() => completeAction(openConfirmationDialog.model, openConfirmationDialog.id)}
        deleteItem={() => deleteItem(openConfirmationDialog.model, openConfirmationDialog.id)}
        handleClose={() => setOpenConfirmationDialog({
          ...openConfirmationDialog,
          open : false
        })}
        model={openConfirmationDialog.model}
        open={openConfirmationDialog.open}
      />

    </Loader>
  );
};

Approvals.displayName = 'Approvals';
Approvals.propTypes = {
  getUserPlans      : func.isRequired,
  currentUserName   : string.isRequired,
  currentUserEmail  : string.isRequired,
  currentUserId     : string.isRequired,
  totalObjectives   : number.isRequired,
  isUserTeamLead    : bool.isRequired,
  isLoading         : bool.isRequired,
  deleteObjective   : func.isRequired,
  editObjective     : func.isRequired,
  deleteKeyResult   : func.isRequired,
  teamMembers       : oneOfType([array, object]).isRequired,
  plans             : oneOfType([array, object]).isRequired,
  user              : object.isRequired,
  editSuccessfull   : bool.isRequired,
  deleteSuccessfull : bool.isRequired

};

export default Approvals;
