/* eslint-disable no-magic-numbers */
import React, {useEffect, useState} from 'react';
import {object, array, oneOfType} from 'prop-types';
import {ColumnDirective, ColumnsDirective, GanttComponent, Inject, Toolbar, Selection, Filter} from '@syncfusion/ej2-react-gantt';
import './GanttChart.scss';
import {STATUS, ROUTE_PATH, CATEGORY_COLORS, CATEGORY_COLORS_LIGHT} from 'constant';

import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {CheckCircleOutline, DonutLargeOutlined} from '@mui/icons-material';

const GanttChart = props => {
  const [ganttData, setGanttData] = useState([]);
  const history = useHistory();
  const taskFields = {
    id        : 'TaskID',
    name      : 'TaskName',
    startDate : 'StartDate',
    endDate   : 'EndDate',
    progress  : 'Progress',
    category  : 'Category',
    child     : 'subtasks'
  };

  const labelSettings = {
    leftLabel : 'TaskName'
    // eslint-disable-next-line no-template-curly-in-string
    // TaskLabel : '${Progress}%'
  };

  useEffect(() => {
    setGanttData(Object.values(props.objectives).map(objective => ({
      TaskID    : objective.id,
      TaskName  : objective.title,
      StartDate : objective.createdAt,
      Category  : objective.category,
      Progress  : objective.status === STATUS.COMPLETED ? 100 : 0,
      EndDate   : moment(objective.deadline).add(1, 'days')
        .format('YYYY-MM-DD'),
      subtasks : Object.values(objective.keyResults).map(keyResult => ({
        TaskID    : objective.id,
        TaskName  : keyResult.title,
        StartDate : keyResult.createdAt,
        Category  : objective.category,
        EndDate   : moment(keyResult.deadline)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        Progress : keyResult.status === STATUS.COMPLETED ? 100 : 10

      }))
    })));
  }, [props.objectives]);


  const timelineSettings = {
    showTooltip         : true,
    timelineUnitSize    : 65,
    timelineViewMode    : 'Month',
    updateTimescaleView : true,
    weekStartDay        : 1,
    weekendBackground   : 'rgb(255, 245, 245)',
    topTier             : {
      format : 'MMM, yyyy',
      unit   : 'Week'
    },
    bottomTier : {
      unit   : 'Day',
      format : 'EEE, dd',
      count  : 1
    }
  };

  const queryTaskbarInfo = args => {
    args.progressBarBgColor = CATEGORY_COLORS[args.data.Category];
    args.taskbarBgColor = CATEGORY_COLORS_LIGHT[args.data.Category];
  };

  const toolbarOptions = ['ZoomToFit', 'ZoomIn', 'ZoomOut', 'CollapseAll', 'ExpandAll'];

  const handleRowSelected = args => {
    history.push({
      pathname : ROUTE_PATH.OBJECTIVE_DETAILS,
      search   : `?id=${args.data.TaskID}`,
      state    : {
        objectiveId : args.data.TaskID
      }
    });
  };

  const customNameColumn = args => {
    if (args.Progress === 100) {
      return (
        <span>
          <span id="timeline-name-column">{args.TaskName} <CheckCircleOutline color="success" fontSize="12px" id="timeline-name-column__icon" /></span>
        </span>);
    }

    return (
      <span>
        <span id="timeline-name-column">{args.TaskName}<DonutLargeOutlined color="primary" fontSize="12px" id="timeline-name-column__icon" /></span>
      </span>);
  };

  const customZoomingLevels = [{
    topTier : {unit   : 'Month',
      format : 'MMM, yy',
      count  : 1},
    bottomTier : {unit   : 'Week',
      format : 'dd',
      count  : 1},
    timelineUnitSize    : 15,
    level               : 0,
    timelineViewMode    : 'Month',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  }, {
    topTier : {unit   : 'Month',
      format : 'MMM, yy',
      count  : 1},
    bottomTier : {unit   : 'Week',
      format : 'dd',
      count  : 1},
    timelineUnitSize    : 33,
    level               : 1,
    timelineViewMode    : 'Month',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  },
  {
    topTier : {unit   : 'Month',
      format : 'MMM, yyyy',
      count  : 1},
    bottomTier : {unit   : 'Week',
      format : 'dd MMM',
      count  : 1},
    timelineUnitSize    : 66,
    level               : 2,
    timelineViewMode    : 'Month',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  },
  {
    topTier : {unit   : 'Month',
      format : 'MMM, yyyy',
      count  : 1},
    bottomTier : {unit   : 'Week',
      format : 'dd MMM',
      count  : 1},
    timelineUnitSize    : 99,
    level               : 3,
    timelineViewMode    : 'Month',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  },
  {
    topTier : {unit   : 'Week',
      format : 'MMM dd, yyyy',
      count  : 1},
    bottomTier : {unit   : 'Day',
      format : 'd',
      count  : 1},
    timelineUnitSize    : 33,
    level               : 4,
    timelineViewMode    : 'Week',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  },
  {
    topTier : {unit   : 'Week',
      format : 'MMM dd, yyyy',
      count  : 1},
    bottomTier : {unit   : 'Day',
      format : 'd',
      count  : 1},
    timelineUnitSize    : 66,
    level               : 5,
    timelineViewMode    : 'Week',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  },
  {
    topTier : {unit   : 'Week',
      format : 'MMM dd, yyyy',
      count  : 1},
    bottomTier : {unit   : 'Day',
      format : 'd',
      count  : 1},
    timelineUnitSize    : 99,
    level               : 6,
    timelineViewMode    : 'Week',
    weekStartDay        : 0,
    updateTimescaleView : true,
    weekendBackground   : null,
    showTooltip         : true
  }
  ];

  let ganttInstance = {};
  const dataBound = () => {
    ganttInstance.zoomingLevels = customZoomingLevels;
  };


  return (
    <div className="gantt-chart">
      <div className="control-pane">
        <div className="control-section">
          <GanttComponent
            allowFiltering
            allowSelection
            dataBound={dataBound}
            dataSource={ganttData}
            gridLines="Both"
            id="Default"
            labelSettings={labelSettings}
            queryTaskbarInfo={data => queryTaskbarInfo(data)}
            ref={gantt => {
              ganttInstance = gantt;
            }}
            rowSelected={data => handleRowSelected(data)}
            taskFields={taskFields}
            taskMode="Manual"
            timelineSettings={timelineSettings}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="TaskName" headerText="Name" template={args => customNameColumn(args)}
                width={250}
              />
              <ColumnDirective field="StartDate" />
              <ColumnDirective field="EndDate" />
              <ColumnDirective field="Category" />
            </ColumnsDirective>
            <Inject services={[Selection, Filter, Toolbar]} />
          </GanttComponent>
        </div>
      </div>
    </div>
  );
};

GanttChart.displayName = 'GanttChart';
GanttChart.propTypes = {

  objectives : oneOfType([array, object]).isRequired

};

export default GanttChart;
