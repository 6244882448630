import ObjectiveDetails from './ObjectiveDetails';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getSingleObjective} from 'selector/objective';
import {getIsCallInProgress, getHasCallSucceeded} from 'selector/app';
import {getUserId, getUserName, getUserTeam, isCurrentUserTeamLead, isCurrentUserOwner} from 'selector/user';
import {getTeamMembers} from 'selector/teamMembers';
import {clearApiResponseErrors} from 'action/apiResponse';
import {getApiResponseError} from 'selector/apiResponse';
import map from 'lodash/map';

// eslint-disable-next-line
const mapStateToProps = state => ({
  objective       : getSingleObjective(state),
  currentUserName : getUserName(state),
  currentUserId   : getUserId(state),
  currentUserTeam : getUserTeam(state),
  isTeamLead      : isCurrentUserTeamLead(state),
  isUserOwner     : isCurrentUserOwner(state),
  teamMembers     : getTeamMembers(state),
  errors          : map(getApiResponseError(state, {
    requestTrackerError : apiRequestAction.objective.editObjective.requestTracker
  }), error => error.apiErrorMessage).join(', '),


  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.getObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.addKeyResultToObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  }),
  hasKeyResultSucceeded : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.keyResult.deleteKeyResult.requestTracker
  }),

  hasEditObjectiveSucceeded : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.deleteObjective.requestTracker
  }) || getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.addKeyResultToObjective.requestTracker
  })
});

const mapDispatchToProps = {
  getObjective    : apiRequestAction.objective.getObjective(),
  editObjective   : apiRequestAction.objective.editObjective(),
  deleteObjective : apiRequestAction.objective.deleteObjective(),
  deleteKeyResult : apiRequestAction.keyResult.deleteKeyResult(),
  editKeyResult   : apiRequestAction.keyResult.editKeyResult(),
  getTeamMembers  : apiRequestAction.user.getTeamMembers(),
  clearApiResponseErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveDetails);
