import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_LINKS = buildActionType(getParts(API_ENDPOINT.LINK.GET_LINKS));

const getLinkReducer = (state, {payload}) => ({
  ...state,
  links : payload.data
});

const cleanupReducer = () => initialState;

const link = handleActions({
  [GET_LINKS]                  : getLinkReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default link;
