import {select, call} from 'redux-saga/effects';
import {getAccessToken} from 'selector/auth';
import BuildConfig from 'service/BuildConfig';
import LocalStorage from 'service/LocalStorage';

export const configureHeaders = ({req = {}, accessToken = ''}) => {
  const headers = req.headers || new Headers();

  if (BuildConfig.SECURITY_TOKEN) {
    headers.set('security-token', BuildConfig.SECURITY_TOKEN);
  }

  if (accessToken.length) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  } else if (LocalStorage.get('accessToken')) {
    headers.set('auth-token', `Bearer ${LocalStorage.get('accessToken')}`);
  }

  return headers;
};

const middleware = () => function* authMiddleware (req, next) {
  const accessToken = yield select(getAccessToken);
  const headers = yield call(configureHeaders, {
    req,
    accessToken
  });
  const response = yield next(new Request(req, {headers}));

  return response;
};

export default middleware;
