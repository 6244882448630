import {connect} from 'react-redux';
import EmployeeCard from './EmployeeCard';
import {apiRequestAction} from 'action/apiResource';
import {getUserCapabilities, getAllUsers} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getJobs} from '../../store/selector/job';

const mapStateToProps = state => ({
  jobs         : getJobs(state),
  teams        : getTeams(state),
  capabilities : getUserCapabilities(state),
  users        : getAllUsers(state)
});

const mapDispatchToProps = {
  getObjectives : apiRequestAction.objective.getObjectives({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.user.getTeams({
    skipEntityIdCheck : true
  }),
  deleteUser : apiRequestAction.user.deleteUser({
    skipEntityIdCheck : true
  }),
  restoreUser : apiRequestAction.user.restoreUser({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCard);
