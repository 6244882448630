import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_TEAM_DETAILS = buildActionType(getParts(API_ENDPOINT.TEAM_DETAILS.GET_TEAM_DETAILS));
const GET_TEAM_DETAIL = buildActionType(getParts(API_ENDPOINT.TEAM_DETAILS.GET_TEAM_DETAIL));

const getTeamDetailsReducer = (state, {payload: {data}}) => ({
  ...state,
  teamDetails : data
});

const getTeamDetailReducer = (state, {payload: {data}}) => ({
  ...state,
  teamDetail : data
});


const cleanupReducer = () => initialState;

const teamDetails = handleActions({
  [GET_TEAM_DETAILS]           : getTeamDetailsReducer,
  [GET_TEAM_DETAIL]            : getTeamDetailReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default teamDetails;
