import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const PLANS_GET_ALL_SUCCESS = buildActionType(getParts(API_ENDPOINT.USER.GET_USER_PLANS));

const getUsersPlansReducer = (state, {payload: {data}}) => ({
  ...state,
  plans : data
});

const cleanupReducer = () => initialState;

const objectiveReducer = handleActions({
  [PLANS_GET_ALL_SUCCESS]      : getUsersPlansReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default objectiveReducer;
