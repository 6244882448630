import {createAction} from 'redux-actions';

const PREFIX = 'user::';

export const ActionTypes = {
  SET_USER              : `${PREFIX}SET_USER`,
  SET_TEAM_LEADER       : `${PREFIX}SET_TEAM_LEADER`,
  SET_ALL_USERS         : `${PREFIX}SET_ALL_USERS`,
  SET_USER_CAPABILITIES : `${PREFIX}SET_USER_CAPABILITIES`
};

export const setUser = createAction(ActionTypes.SET_USER);
