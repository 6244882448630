import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {object, array, oneOfType} from 'prop-types';
import {Button} from 'primereact/button';
import {ENV_URL} from 'constant';
import './LinkCard.scss';

// eslint-disable-next-line complexity
const LinkCard = props => {
  const [isHovering, setIsHovering] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const history = useHistory();

  const goToURL = () => {
    const link = props.link.url;

    if (link.startsWith('https://') || link.startsWith('http://')) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  useEffect(() => {
    setImageUrl(`${ENV_URL.ENV_URL}${props.link.picture}`);
  }, [props.link]);


  return (
    <>
      {props.link.status === 'active' &&
      <div
        className="flex flex-column align-items-center relative cardLink-container"
        onClick={goToURL}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Button className="btn-arrow" icon="pi pi-arrow-up-right" />
        <div className="cardLink-icon">
          <img alt="link-icon" src={imageUrl} />
        </div>
        <div className="flex align-items-center link-name">
          <p>{props.link.name}</p>
          <div className={props.link.description === '' || props.link.description === null ? 'hidden' : 'span'}>
            <Button
              className="btn-arrow-down"
              icon="pi pi-angle-down"
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          </div>
        </div>
        {isHovering && props.link.description !== '' &&
        <div className="description" onClick={event => event.stopPropagation()}>
          {props.link.description.split('\n').map(line => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </div>
        }
      </div>
      }
    </>
  );
};

LinkCard.displayName = 'LinkCard';
LinkCard.propTypes = {
  link : oneOfType([array, object]).isRequired
};
export default LinkCard;
