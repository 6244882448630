import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_TEAMS = buildActionType(getParts(API_ENDPOINT.TEAM.GET_TEAMS));

const setTeamsReducer = (state, {payload: {data : {teams}}}) => ({
  ...state,
  teams
});

const cleanupReducer = () => initialState;

const teamReducer = handleActions({
  [GET_TEAMS]                  : setTeamsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default teamReducer;
