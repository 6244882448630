import {connect} from 'react-redux';
import {getIsAuthenticated} from 'selector/auth';
import {clearApiResponseErrors} from 'action/apiResponse';
import {getUserName, getUserId, isCurrentUserTeamLead, isCurrentUserOwner, getTeamLeader, getUserRole} from 'selector/user';
import {getTeamMembers} from 'selector/teamMembers';
import PrivateRoute from './PrivateRoute';
import {getUser, getUserCapabilities} from '../../store/selector/user';

const mapStateToProps = state => ({
  isAuthenticated : getIsAuthenticated(state),
  isUserTeamLead  : isCurrentUserTeamLead(state),
  isUserOwner     : isCurrentUserOwner(state),
  currentUserName : getUserName(state),
  userRole        : getUserRole(state),
  teamLeader      : getTeamLeader(state),
  currentUserId   : getUserId(state),
  teamMembers     : getTeamMembers(state),
  capabilities    : getUserCapabilities(state),
  user            : getUser(state)
});

const mapDispatchToProps = {
  clearApiResponseErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
