import endsWith from 'lodash/endsWith';

export const joinPaths = (...paths) => {
  const ONE = 1;

  let trailingSlash = endsWith(paths[paths.length - ONE], '/');

  let joinedPath = paths.map(path => path.replace(/(^\/|\/$)/g, '')).join('/');

  return trailingSlash ? `${joinedPath}/` : joinedPath;
};

export default joinPaths;
