import {handleActions} from 'redux-actions';
import {ActionTypes} from 'action/api';
import {getNameMethodPair} from 'action/apiResource';
import {ActionTypes as ApiResponseActionTypes} from 'action/apiResponse';

import map from 'lodash/map';

const initialState = {};

const apiRequestFailureReducer = (state, {meta, payload}) => {
  const key = getNameMethodPair(meta);


  if (typeof payload === 'object') {
    const {error : {error : {error : message}} = {}} = payload;

    return {
      ...state,
      [key] : map(message, errorMessage => ({
        apiErrorFromCode : '',
        apiErrorMessage  : errorMessage
      }))
    };
  } else if (typeof payload === 'string') {
    return {
      ...state,
      [key] : payload
    };
  }

  return state;
};

const cleanupReducer = () => ({
  ...initialState
});

const apiResponseReducer = handleActions({
  [ActionTypes.API_REQUEST_FAILURE]                  : apiRequestFailureReducer,
  [ApiResponseActionTypes.CLEAR_API_RESPONSE_ERRORS] : cleanupReducer,
  [ApiResponseActionTypes.APP_CLEANUP]               : cleanupReducer
}, initialState);

export default apiResponseReducer;
