/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useRef, Fragment} from 'react';
import {array, func, object} from 'prop-types';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {showSuccessfulToast} from '../../utils/utils';
import './RolesTable.scss';
import {CAPABILITIES} from '../../constant/roleCapabilities';
import {dialogFooter, header} from './utils';
import {rowsPerPageOptions} from '../RolesTable/utils';

/**
 * Table in Admin page for CRUD on roles
 */
const RolesTable = props => {
  let emptyRole = {
    id           : null,
    name         : '',
    capabilities : ''
  };

  const [globalFilter, setGlobalFilter] = useState(null);
  const [role, setRole] = useState(emptyRole);
  const [roleDialog, setRoleDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedCapabilities, setSelectedCapabilities] = useState([]);
  const [newRoleDialog, setNewRoleDialog] = useState(false);
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
  const [restoreRolesDialog, setRestoreRolesDialog] = useState(false);
  const dt = useRef(null);
  const toast = useRef(null);

  const openNewRole = () => {
    setRole(emptyRole);
    setSelectedCapabilities([]);
    setSubmitted(false);
    setNewRoleDialog(true);
  };

  const addNewRole = async () => {
    setSubmitted(true);
    if (role.name.trim()) {
      await props.createRole({
        name         : role.name,
        capabilities : selectedCapabilities});

      setNewRoleDialog(false);
      setRole(emptyRole);
      setSelectedCapabilities([]);
      showSuccessfulToast(toast, 'New role added');
    }
  };

  const saveRole = async () => {
    setSubmitted(true);
    if (role.name.trim()) {
      await props.updateRole({
        _id          : role.id,
        name         : role.name,
        capabilities : selectedCapabilities});

      setRoleDialog(false);
      setRole(emptyRole);
      showSuccessfulToast(toast, 'Role updated');
    }
  };

  const deleteRole = async () => {
    await props.deleteRole({entityId : role.id});

    setDeleteRoleDialog(false);
    setRole(emptyRole);
    showSuccessfulToast(toast, 'Role deleted');
  };

  const editRole = currentRole => {
    setRole({...currentRole});
    setSelectedCapabilities(currentRole.capabilities);
    setRoleDialog(true);
  };

  const restoreRole = async () => {
    await props.restoreRole({
      _id : role.id
    });

    setRestoreRolesDialog(false);
    setRole(emptyRole);
    showSuccessfulToast(toast, 'Role restored');
  };

  const handleSearchInput = event => {
    setGlobalFilter(event.target.value);
  };

  const hideNewRoleDialog = () => {
    setSubmitted(false);
    setNewRoleDialog(false);
    setSelectedCapabilities([]);
  };

  const hideEditRoleDialog = () => {
    setSubmitted(false);
    setRoleDialog(false);
  };

  const hideDeleteRoleDialog = () => {
    setDeleteRoleDialog(false);
  };

  const hideRestoreRolesDialog = () => {
    setRestoreRolesDialog(false);
  };

  const confirmDeleteRole = currRole => {
    setRole(currRole);
    setDeleteRoleDialog(true);
  };

  const confirmRestoreRole = currRole => {
    setRole(currRole);
    setRestoreRolesDialog(true);
  };

  const onInputChange = (event, name) => {
    const val = event.target && event.target.value || '';

    let _role = {...role};

    _role[`${name}`] = val;
    setRole(_role);
  };

  const onCapabilityChange = event => {
    let _selected = [...selectedCapabilities];

    if (event.checked) {
      _selected.push(event.value);
    } else {
      _selected = _selected.filter(item => item !== event.value);
    }

    setSelectedCapabilities(_selected);
  };

  const actionBodyTemplate = rowData => (
    <Fragment>
      <Button
        className="p-button-rounded p-button-success mr-2" icon="pi pi-pencil"
        onClick={() => editRole(rowData)}
      />

      { rowData.status === 'active' &&
      <Button
        className="p-button-rounded p-button-danger" disabled={rowData.name === 'User'} icon="pi pi-trash"
        onClick={() => confirmDeleteRole(rowData)}
      />
      }
      { rowData.status === 'deleted' &&
      <Button className="p-button-rounded p-button-warning" icon="pi pi-refresh" onClick={() => confirmRestoreRole(rowData)} />
      }
    </Fragment>
  );

  const renderCapabilitiesSection = (sectionKey, capabilities, index) => {
    const capabilitiesList = Object.keys(capabilities[sectionKey]);

    return (
      <div className="field" key={index}>
        <label htmlFor="capabilities"><b>{sectionKey.replaceAll('_', ' ')}</b></label>
        {capabilitiesList.length > 0 && capabilitiesList.map(capabilityKey => {
          const capability = capabilities[sectionKey][capabilityKey];

          return (
            <div className="field-checkbox" key={capabilityKey}>
              <Checkbox
                checked={selectedCapabilities.includes(capabilityKey)}
                inputId={capabilityKey}
                name="capability"
                onChange={onCapabilityChange}
                value={capability.key}
              />
              <InputText
                className="label" readOnly
                tooltip={capability?.tooltip} value={capability?.label}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="datatable-crud-roles">
      <Toast ref={toast} />
      <div className="roles-container">
        <DataTable
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} roles" dataKey="id"
          globalFilter={globalFilter}
          header={header(openNewRole, handleSearchInput)}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          ref={dt}
          resizableColumns
          rows={5}
          rowsPerPageOptions={rowsPerPageOptions}
          showGridlines
          value={Object.values(props.roles)}
        >
          <Column
            field="name" header="Name" sortable
            style={{width : '30%'}}
          />
          <Column body={actionBodyTemplate} className="actions" exportable={false} />
        </DataTable>
      </div>

      <Dialog
        blockScroll="true"
        className="rolesDialog p-fluid"
        footer={role.id === null ? dialogFooter(hideNewRoleDialog, addNewRole) : dialogFooter(hideEditRoleDialog, saveRole)}
        header={role.id === null ? 'New role' : 'Role details'}
        modal onHide={role.id === null ? hideNewRoleDialog : hideEditRoleDialog} style={{width : '450px'}}
        visible={role.id === null ? newRoleDialog : roleDialog}
      >
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            autoFocus className={classNames({'p-invalid' : submitted && !role.name})} id="name"
            onChange={event => onInputChange(event, 'name')} required value={role.name}
          />
          {submitted && !role.name && <small className="p-error">Name is required.</small>}
        </div>

        {Object.keys(CAPABILITIES).map((sectionKey, index) =>
          renderCapabilitiesSection(sectionKey, CAPABILITIES, index)
        )}
      </Dialog >

      <Dialog
        className="delete-restore-action-modal"
        footer={dialogFooter(hideDeleteRoleDialog, deleteRole)} header="Confirm" modal
        onHide={hideDeleteRoleDialog} visible={deleteRoleDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
          {role && <span>Are you sure you want to delete <b>{role.name}</b>?</span>}
        </div>
      </Dialog>

      <Dialog
        className="delete-restore-action-modal"
        footer={dialogFooter(hideRestoreRolesDialog, restoreRole)} header="Confirm" modal
        onHide={hideRestoreRolesDialog} style={{width : '450px'}} visible={restoreRolesDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
          {role && <span>Are you sure you want to restore <b>{role.name}</b>?</span>}
        </div>
      </Dialog>
    </div>
  );
};

RolesTable.displayName = 'RolesTable';
RolesTable.propTypes = {
  capabilities : array.isRequired,
  roles        : object.isRequired,
  getRoles     : func.isRequired,
  createRole   : func.isRequired,
  updateRole   : func.isRequired,
  deleteRole   : func.isRequired,
  restoreRole  : func.isRequired
};
export default RolesTable;
