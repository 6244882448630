import Admin from './Admin';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getHasCallSucceeded} from 'selector/app';
import {getJobs} from '../../store/selector/job';
import {getAllUsers} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getRoles} from '../../store/selector/role';
import {getLinks} from '../../store/selector/link';

const mapStateToProps = state => ({
  deleteUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.deleteUser.requestTracker
  }),
  restoreUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.restoreUser.requestTracker
  }),
  addUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.addUser.requestTracker
  }),
  editUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.editUser.requestTracker
  }),
  updateTeamSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.team.updateTeam.requestTracker
  }),
  createTeamSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.team.createTeam.requestTracker
  }),
  deleteTeamSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.team.deleteTeam.requestTracker
  }),
  updateJobSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.job.updateJob.requestTracker
  }),
  createJobSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.job.createJob.requestTracker
  }),
  deleteJobSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.job.deleteJob.requestTracker
  }),
  createRoleSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.role.createRole.requestTracker
  }),
  updateRoleSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.role.updateRole.requestTracker
  }),
  deleteRoleSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.role.deleteRole.requestTracker
  }),
  restoreRoleSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.role.restoreRole.requestTracker
  }),
  createLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.createLink.requestTracker
  }),
  updateLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.updateLink.requestTracker
  }),
  deleteLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.deleteLink.requestTracker
  }),
  restoreLinkSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.restoreLink.requestTracker
  }),
  updateLinkPictureSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.link.updateLinkPicture.requestTracker
  }),
  jobs  : getJobs(state),
  users : getAllUsers(state),
  teams : getTeams(state),
  roles : getRoles(state),
  links : getLinks(state)
});

const mapDispatchToProps = {
  getLinks : apiRequestAction.link.getLinks({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  }),
  getAllUsers : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  }),
  getRoles : apiRequestAction.role.getRoles({
    skipEntityIdCheck : true
  }),
  getJobs : apiRequestAction.job.getJobs({
    skipEntityIdCheck : true
  }),
  getUserCapabilities : apiRequestAction.user.getUserCapabilities({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
