import React, {Component as ReactComponent} from 'react';
import {bool, object, func, oneOfType} from 'prop-types';
import {Route, Redirect} from 'react-router';
import {ROUTE_PATH} from 'constant';

class PublicRoute extends ReactComponent {
  static propTypes = {
    isAuthenticated        : bool.isRequired,
    clearApiResponseErrors : func.isRequired,
    component              : oneOfType([object, func]).isRequired
  };


  render () {
    const {component : Component, isAuthenticated, firstLogin, clearApiResponseErrors, ...rest} = this.props;

    clearApiResponseErrors();

    return (
      <Route
        {...rest}
        render={props => {
          if (!isAuthenticated || firstLogin === "" || firstLogin === true) { // eslint-disable-line
            return (
              <Component {...props} />
            );
          }

          const search = props.location.search;
          const params = new URLSearchParams(search);
          const ret = params.get('ret');
          const pathname = ret ? ret : ROUTE_PATH.TEAM;

          params.delete('ret');

          return (
            <Redirect to={{
              pathname,
              search : params.toString(),
              state  : {
                from : props.location
              }}}
            />
          );
        }}
      />
    );
  }
}

export default PublicRoute;
