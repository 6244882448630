/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import {object, array, oneOfType, func} from 'prop-types';
import {filter, isEmpty} from 'lodash';
import {userCan} from '../../utils/user';
import {Dropdown} from 'primereact/dropdown';
import {CAPABILITIES} from '../../constant';
import {MultiSelect} from 'primereact/multiselect';
import {Checkbox} from 'primereact/checkbox';
import './Filter.scss';

/**
 * Compontent that filters the employees by teams, jobs and their status
 */
// eslint-disable-next-line max-statements
const Filter = props => {
  const statusType = [
    {name : 'Active (default)',
      key  : 'active'},
    {name : 'Deleted',
      key  : 'deleted'}
  ];

  const {currentProjectFilter, currentJobFilter, currentTeamFilter, statusFilter,
    setCurrentTeamFilter, setCurrentProjectFilter, setCurrentJobFilter, setStatusFilter,
    showTeamleads, setShowTeamleads} = {...props.filterOptions};

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const filterByTeam = (member, currentTeamFilterTemp) => {
    if (currentTeamFilterTemp === null || currentTeamFilterTemp?.length === 0) {
      return true;
    }

    return currentTeamFilterTemp?.some(currentFilter => Object.values(member.team).some(team => team.id === currentFilter.id));
  };

  const filterByJob = (member, currentJobFilterTemp) => {
    if (currentJobFilterTemp === null || currentJobFilterTemp?.length === 0) {
      return true;
    }

    return currentJobFilterTemp?.some(currentFilter => [member.job].some(job => job.id === currentFilter.id));
  };

  const filterByStatus = (member, statusFilterTemp) => {
    if (statusFilterTemp === null) {
      return true;
    }

    return member.status === statusFilterTemp;
  };

  const filterByTeamleads = (member, teamleadsFilterTemp) => {
    if (teamleadsFilterTemp === false) {
      return true;
    }

    return member.role.name === 'Team lead';
  };

  const filterByProjects = (member, currentProjectFilterTemp) => {
    if (currentProjectFilterTemp === null || currentProjectFilterTemp?.length === 0) {
      return true;
    }

    if (!isEmpty(member?.extras?.projectsInfo)) {
      return Object.values(member?.extras?.projectsInfo).some(proj => currentProjectFilterTemp.includes(proj.title));
    }

    return false;
  };

  const handleFilters = () => {
    let filteredMembers = filter(props.users, member =>
      member.team !== null &&
      filterByTeam(member, currentTeamFilter) &&
      filterByJob(member, currentJobFilter) &&
      filterByStatus(member, statusFilter) &&
      filterByProjects(member, currentProjectFilter) &&
      filterByTeamleads(member, showTeamleads)
    );

    props.setFilteredMembers(filteredMembers);
  };


  useEffect(() => handleFilters(), [currentTeamFilter, currentJobFilter, statusFilter, currentProjectFilter, showTeamleads]);

  const handleOnChangeTeam = event => {
    setCurrentTeamFilter(event.value);
    setSelectedTeam(event.value);
  };

  const handleOnChangeJob = event => {
    setCurrentJobFilter(event.value);
    setSelectedJob(event.value);
  };

  const handleOnChangeProject = event => {
    setCurrentProjectFilter(event.value);
    setSelectedProject(event.value);
  };

  const handleOnChangeStatus = event => {
    setStatusFilter(event.value.key);
    setSelectedType(event.value);
  };

  const handleClearFilters = () => {
    setCurrentJobFilter(null);
    setCurrentTeamFilter(null);
    setCurrentProjectFilter(null);
    setStatusFilter('active');
    setSelectedTeam(null);
    setSelectedJob(null);
    setSelectedType(null);
    setSelectedProject(null);
    setShowTeamleads(false);
    props.setSearchInputValue('');
  };

  return (
    <div className="filter-container">
      <div className="dropdown-component">
        <MultiSelect
          onChange={handleOnChangeTeam}
          optionLabel="name"
          options={[...Object.values(props.teams).filter(team => team.status === 'active')]}
          placeholder="Teams"
          value={selectedTeam}
        />
      </div>
      <div className="dropdown-component">
        <MultiSelect
          onChange={handleOnChangeJob}
          optionLabel="name"
          options={[...Object.values(props.jobs)]}
          placeholder="Roles"
          value={selectedJob}
        />
      </div>
      <div className="dropdown-component">
        <MultiSelect
          onChange={handleOnChangeProject}
          options={[...Object.values(props.projects)]}
          placeholder="Projects"
          value={selectedProject}
        />
      </div>
      {userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.VIEW_ARCHIVED_USERS.key) &&
      <div className="dropdown-component">
        <Dropdown
          onChange={handleOnChangeStatus}
          optionLabel="name"
          options={statusType}
          placeholder="Status"
          value={selectedType}
        />
      </div>
      }
      <div className="flex align-items-center checkbox">
        <Checkbox checked={showTeamleads} className="teamlead-check" onChange={event => setShowTeamleads(event.checked)} />
        <label className="ml-2 mr-4" htmlFor="teamleads">Team leads</label>
      </div>
      <div className="clear-filters" onClick={handleClearFilters}>
        <span className="icon"><i className="pi pi-times" /></span>
        <span className="text">Clear</span>
      </div>

    </div>
  );
};

Filter.displayName = 'Filter';
Filter.propTypes = {
  teams               : oneOfType([array, object]).isRequired,
  jobs                : oneOfType([array, object]).isRequired,
  users               : oneOfType([array, object]).isRequired,
  projects            : oneOfType([array, object]).isRequired,
  filteredMembers     : oneOfType([array, object]).isRequired,
  setFilteredMembers  : func.isRequired,
  setSearchInputValue : func.isRequired,
  capabilities        : oneOfType([array, object]).isRequired,
  filterOptions       : object.isRequired
};

export default Filter;
