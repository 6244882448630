import React, {Component as ReactComponent} from 'react';
import {bool, func, object, oneOfType} from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {Header, Footer} from 'components';
import {ROUTE_PATH} from 'constant';

const checkPermissions = (disallow, userRole) => {
  let permission = true;

  if (disallow !== undefined) {
    disallow.forEach(role => {
      if (role === userRole) {
        permission = false;
      }
    });
  }

  return permission;
};

class PrivateRoute extends ReactComponent {
  static propTypes = {
    isAuthenticated        : bool.isRequired,
    clearApiResponseErrors : func.isRequired,
    component              : oneOfType([object, func]).isRequired,
    componentProps         : object
  };

  static defaultProps = {
    componentProps : {},
    hideMenu       : false
  };

  displayName = 'PrivateRoute';

  state = {}

  componentWillReceiveProps ({isAuthenticated}) {
    if (this.props.isAuthenticated && !isAuthenticated) {
      this.setState({
        wasAuthenticated : true
      });
    }
  }

  render () {
    const {
      component : Component,
      componentProps = {},
      disallow,
      isAuthenticated,
      userRole,
      clearApiResponseErrors,
      ...rest
    } = this.props;

    clearApiResponseErrors();

    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated) {
            if (checkPermissions(disallow, userRole)) {
              return (
                <React.Fragment>
                  <Header />
                  <Component {...props} {...componentProps} />
                  <Footer />
                </React.Fragment>
              );
            }

            return (
              <Redirect to={{
                pathname : ROUTE_PATH.TEAM,
                state    : {
                  from : props.location
                }}}
              />
            );
          }

          return (
            <Redirect to={{
              pathname : ROUTE_PATH.LOGIN,
              search   : this.state.wasAuthenticated ? null : `ret=${props.location.pathname}${props.location.search.split('?').join('&')}`,
              state    : {
                from : props.location
              }}}
            />
          );
        }}
      />
    );
  }
}

export default PrivateRoute;
