import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_PATH} from 'constant';
import {TeamManagementDrawer, UserDetailsDrawer} from '../Drawers';
import {object, bool, func, array, oneOfType} from 'prop-types';
import {ReactComponent as BitstoneLogoIcon} from '../../assets/icons/logo-bit-hub.svg';
import {ReactComponent as ClosedMenuIcon} from '../../assets/icons/closed-menu-icon.svg';
import SearchRedux from '../SearchRedux';
import {getUserImageUrl, userCan} from '../../utils/user';
import {Group} from '@mui/icons-material';
import {ReactComponent as DashboardIcon} from '../../assets/icons/dashboard-white.svg';
import {ReactComponent as TimeTrackingIcon} from '../../assets/icons/time-icon-white.svg';
import {ReactComponent as HolidaysIcon} from '../../assets/icons/holidays-icon-white.svg';
import {ReactComponent as AdminIcon} from '../../assets/icons/admin-icon-white.svg';
import {ReactComponent as TeamIcon} from '../../assets/icons/team-icon-white.svg';
import {ReactComponent as ObjectiveTrackingIcon} from '../../assets/icons/objective-icon-white.svg';
import {BootstrapButton} from '../StyledButtons/StyledButtons';
import './Header.scss';

import classNames from 'classnames';
import {CAPABILITIES} from '../../constant';
import {isEmpty} from 'lodash';


/**
 * Component that renders the header
 * The application state is set almost entirely in this component -> single source of truth
 * Not updated entirely to the new structure of data and logic
 */
const Header = ({user, updateUserSuccessful, getUser, getUserCapabilities, capabilities}) => {
  const history = useHistory();
  const menuRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const [open, setOpen] = React.useState({
    team : false,
    user : false
  });

  React.useEffect(() => {
    function handleOutsideClick (event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDrawer = (teamDrawer, userDrawer) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen({team : teamDrawer,
      user : userDrawer});
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const sideMenuOptions = [
    {
      text  : 'Dashboard',
      route : ROUTE_PATH.DASHBOARD,
      icon  : <DashboardIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.DASHBOARD) || history.location.pathname === '/'}
      ),
      isAllowed : true
    },
    {
      text  : 'Super-Awesome Team',
      route : ROUTE_PATH.TEAM,
      icon  : <TeamIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.TEAM)}
      ),
      isAllowed : true
    }, {
      text  : 'Time Tracking',
      route : ROUTE_PATH.TIMETRACKING,
      icon  : <TimeTrackingIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.TIMETRACKING)}
      ),
      isAllowed : true
    },
    {
      text  : 'Holidays',
      route : ROUTE_PATH.HOLIDAYS,
      icon  : <HolidaysIcon />,
      class : classNames(
        'sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.HOLIDAYS)}
      ),
      isAllowed : true
    },
    {
      text  : 'Admin',
      route : ROUTE_PATH.ADMIN_USERS,
      icon  : <AdminIcon />,
      class : classNames(
        'sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.ADMIN_USERS)}
      ),
      isAllowed : userCan(capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key)
    }
  ];

  React.useEffect(() => {
    if (isEmpty(capabilities)) {
      getUserCapabilities();
    }
  }, []);

  React.useEffect(() => {
    if (updateUserSuccessful) {
      getUser({
        entityId : user.id
      });
      setOpen({
        team : false,
        user : false
      });
      getUserCapabilities();
    }
  }, [updateUserSuccessful]);

  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    const imageUrlTemp = getUserImageUrl(user);

    setImageUrl(imageUrlTemp);
  }, [user]);

  return (
    <div className="header-container">
      <>
        <div className="header-component">
          <div className="header-menu--large_screen">
            <div
              className="header-logo" onClick={() => {
                history.push({
                  pathname : ROUTE_PATH.DASHBOARD
                });
              }}
            >
              <div className="logo">
                {<BitstoneLogoIcon />}
              </div>
            </div>
            <div
              className="menu-logo"
              onClick={toggleMenu}
            >
              <div className="logo">
                {<ClosedMenuIcon />}
              </div>
            </div>
            {menuOpen &&
            <div className="side-menu" ref={menuRef}>
              <div className="side-menu-section">
                {sideMenuOptions.map(option =>
                  option.isAllowed &&
                  <div className={option.class} key={option.text} onClick={() => history.push(option.route)}>
                    {option.icon}
                    {option.text}
                  </div>
                )}
                <div className="sideMenu-option">
                  <a href="https://objective-tracking.bitstone.eu/" rel="noopener noreferrer" target="_blank">
                    <BootstrapButton
                      className="obj-btn"
                      startIcon={<ObjectiveTrackingIcon />}
                    >
                      Objective Tracking
                    </BootstrapButton>
                  </a>
                </div>
              </div>
            </div>
            }
          </div>
          <div className="header-flex-2">
            <div className="header-search">
              <SearchRedux />
            </div>
            <div className="header-user-actions">
              <div className="user-info">
                <p className="user-info__name">{user.name}</p>
              </div>
              {(user.isTeamLead || user.isOwner || user.isTeamsManager) &&
              <div className="my-team" onClick={toggleDrawer(true, false)}>
                <Group />
              </div>}

              <div className="my-account" onClick={toggleDrawer(false, true)}>
                <img src={imageUrl} />
              </div>
            </div>
          </div>
        </div>
        <TeamManagementDrawer open={open.team} setOpen={setOpen} toggleDrawer={toggleDrawer} />
        <UserDetailsDrawer
          email={user.email} name={user.name} open={open.user}
          team={user.team} toggleDrawer={toggleDrawer}
        />
      </>
    </div>
  );
};


Header.propTypes = {
  user                 : object.isRequired,
  getUser              : func.isRequired,
  updateUserSuccessful : bool.isRequired,
  capabilities         : oneOfType([array, object]).isRequired,
  getUserCapabilities  : func.isRequired
};

Header.displayName = 'Header';

export default Header;
