import React from 'react';
import {object, bool, func, array, oneOfType} from 'prop-types';

import {ReactComponent as DashboardIcon} from '../../assets/icons/dashboard-white.svg';
import {ReactComponent as TimeTrackingIcon} from '../../assets/icons/time-icon-white.svg';
import {ReactComponent as HolidaysIcon} from '../../assets/icons/holidays-icon-white.svg';
import {ReactComponent as AdminIcon} from '../../assets/icons/admin-icon-white.svg';
import {ReactComponent as TeamIcon} from '../../assets/icons/team-icon-white.svg';
import {ReactComponent as ObjectiveTrackingIcon} from '../../assets/icons/objective-icon-white.svg';
import {Link, useHistory} from 'react-router-dom';
import {ROUTE_PATH} from 'constant';
import classNames from 'classnames';
import {userCan} from '../../utils/user';
import {CAPABILITIES} from '../../constant';
import {BootstrapButton} from '../StyledButtons/StyledButtons';
import {makeStyles} from '@mui/styles';

import './SideMenu.scss';

const useStyles = makeStyles({
  startIcon : {
    marginRight : '15px',
    marginLeft  : '0px'
  },
  endIcon : {
    marginRight : '0px',
    marginLeft  : '22px'
  }
});


const SideMenu = props => {
  const history = useHistory();

  const classes = useStyles();
  const sideMenuOptions = [
    {
      text  : 'Dashboard',
      route : ROUTE_PATH.DASHBOARD,
      icon  : <DashboardIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.DASHBOARD) || history.location.pathname === '/'}
      ),
      isAllowed : true
    },
    {
      text  : 'Super-Awesome Team',
      route : ROUTE_PATH.TEAM,
      icon  : <TeamIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.TEAM)}
      ),
      isAllowed : true
    }, {
      text  : 'Time Tracking',
      route : ROUTE_PATH.TIMETRACKING,
      icon  : <TimeTrackingIcon />,
      class : classNames('sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.TIMETRACKING)}
      ),
      isAllowed : true
    },
    {
      text  : 'Holidays',
      route : ROUTE_PATH.HOLIDAYS,
      icon  : <HolidaysIcon />,
      class : classNames(
        'sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.HOLIDAYS)}
      ),
      isAllowed : true
    },
    {
      text  : 'Admin',
      route : ROUTE_PATH.ADMIN_USERS,
      icon  : <AdminIcon />,
      class : classNames(
        'sideMenu-option',
        {active : history.location.pathname.includes(ROUTE_PATH.ADMIN_USERS)}
      ),
      isAllowed : userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key)
    }
  ];

  return (
    <div className="container hidden md:block">
      <div className="flex flex-column align-items-start sideMenu">
        {
          sideMenuOptions.map(sideMenuItem => {
            if (sideMenuItem.isAllowed) {
              return (
                <Link
                  className={sideMenuItem.class} key={sideMenuItem.text}
                  to={{
                    pathname : sideMenuItem.route
                  }}
                >
                  <BootstrapButton
                    onClick={sideMenuItem?.onClick}
                    startIcon={<span className={classes.startIcon}>{sideMenuItem.icon}</span>}
                  >
                    {sideMenuItem.text}
                  </BootstrapButton>
                </Link>
              );
            }

            return null;
          })}
        <a href="https://objective-tracking.bitstone.eu/" rel="noopener noreferrer" target="_blank">
          <BootstrapButton
            className="obj-btn"
            startIcon={<ObjectiveTrackingIcon />}
          >
            Objective Tracking
          </BootstrapButton>
        </a>
      </div>
    </div >
  );
};


SideMenu.displayName = 'SideMenu';
SideMenu.propTypes = {
  user                 : object.isRequired,
  getUser              : func.isRequired,
  getRoles             : func.isRequired,
  getAllUsers          : func.isRequired,
  updateUserSuccessful : bool.isRequired,
  getJobs              : func.isRequired,
  getTeams             : func.isRequired,
  plans                : object.isRequired,
  getUserPlans         : func.isRequired,
  capabilities         : oneOfType([array, object]).isRequired,
  getUserCapabilities  : func.isRequired,
  getLinks             : func.isRequired,
  getTeamLeader        : func.isRequired
};
export default SideMenu;
