import React from 'react';
import App from './App';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import configureStore from 'store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import sagas from 'saga';

import reportWebVitals from './reportWebVitals';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

const store = configureStore({});

sagas.map(saga => store.runSaga(saga));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
