import React from 'react';
import * as yup from 'yup';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import {useFormik} from 'formik';
import reduce from 'lodash/reduce';
import {Modal} from 'components';
import {useLocation} from 'react-router-dom';
import {bool, func, object, array, string, oneOfType} from 'prop-types';
import './CreateEvaluationDialog.scss';
import {
  TextField,
  Button
} from '@mui/material';

const CreateEvaluationDialog = ({
  visible,
  setVisible,
  modalTitle,
  modalText,
  isEditMode,
  initialValues,
  editEvaluation,
  createEvaluation,
  currentUser,
  isViewMode
}) => {
  const validationSchema = yup.object({
    technicalExpertise : yup.string().required('Technical expertise is required'),
    teamwork           : yup.string().required('Teamwork is required'),
    flexibility        : yup.string().required('Flexibility is required'),
    resultsFocus       : yup.string().required('Results focus is required'),
    languages          : yup.string().required('Languages is required'),
    continualLearning  : yup.string().required('Continual learning is required'),
    safetyFocus        : yup.string().required('Safety focus is required'),
    communication      : yup.string().required('Communication is required'),
    initiative         : yup.string().required('Initiative is required'),
    openDiscussion     : yup.string().required('Open discussion is required'),
    targets            : yup.string().required('Targes is required')
  });

  const search = useLocation().search;
  const evaluatedUserId = new URLSearchParams(search).get('id');

  const newObjectForm = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize : true,

    onSubmit : (values, {resetForm}) => {
      values.authorId = currentUser.id;
      if (isEditMode) {
        values.userId = initialValues.userId;
        editEvaluation({
          entityId : values.id,
          values
        });
      } else {
        values.userId = evaluatedUserId;
        createEvaluation({
          values,
          ownerId : values.userId
        });
        resetForm({});
      }

      setVisible(false);
    }
  });

  const renderModalActions = () => (
    <div className="form__actions">
      {
        !isViewMode &&
        <div>
          <Button
            color="primary" onClick={() => {
              setVisible(false);
              newObjectForm.resetForm({});
            }} size="small"
            variant="contained"
          > Cancel </Button>
          <Button
            color="secondary"
            onClick={() => {
              newObjectForm.handleSubmit();
            }}
            size="small"
            type="submit"
            variant="contained"
          > Save </Button>
        </div>
      }
    </div>
  );

  const getTextField = ({name, label, children, required = true, select = false, isDisabled = false, type = 'text', ...rest}) => (
    <TextField
      className="modal-forms-input"
      disabled={isDisabled}
      error={newObjectForm.touched[name] && Boolean(newObjectForm.errors[name])}
      helperText={newObjectForm.touched[name] && newObjectForm.errors[name]}
      id={name}
      label={label}
      name={name}
      onChange={newObjectForm.handleChange}
      required={required}
      select={select}
      style={{
        width  : 'calc(50% - 20px)',
        margin : '10px 10px'
      }}
      type={type}
      value={newObjectForm.values[name]}
      variant="outlined"
      {...rest}
    >
      {children}
    </TextField>
  );

  const getTeamUsers = (items) => uniqBy(reduce(items, (acc, value) => [ //eslint-disable-line
    ...acc,
    ...map(value, member => member)
  ], []), 'id');

  // eslint-disable-next-line complexity
  const renderFormContent = () => (
    <form autoComplete="off" onSubmit={newObjectForm.handleSubmit} >

      {getTextField({
        name       : 'technicalExpertise',
        label      : 'Technical expertise',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}

      {getTextField({
        name       : 'teamwork',
        label      : 'Teamwork',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}

      {getTextField({
        name       : 'flexibility',
        label      : 'Flexibility',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'resultsFocus',
        label      : 'Results focus',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'languages',
        label      : 'Languages',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'continualLearning',
        label      : 'Continual learning',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'safetyFocus',
        label      : 'Safety focus',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'communication',
        label      : 'Communication',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'initiative',
        label      : 'Initiative',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}

      {getTextField({
        name       : 'openDiscussion',
        label      : 'Open discussion',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}


      {getTextField({
        name       : 'targets',
        label      : 'Conclusion',
        multiline  : true,
        fullWidth  : true,
        isDisabled : isViewMode,
        style      : {
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        },
        rows : 3
      })}

    </form>
  );

  return (
    visible &&
    <Modal
      handleClose={() => setVisible(false)}
      modalActions={() => renderModalActions()}
      modalContent={() => renderFormContent()}
      open={visible}
      text={modalText}
      title={modalTitle}
    />
  );
};

CreateEvaluationDialog.displayName = 'CreateEvaluationDialog';
CreateEvaluationDialog.propTypes = {
  isTeamLead      : bool.isRequired,
  isOwner         : bool.isRequired,
  currentUserName : string.isRequired,
  currentUserId   : string.isRequired,
  teamMembers     : oneOfType([array, object]).isRequired,
  visible         : bool.isRequired,
  setVisible      : func.isRequired,
  modalText       : string.isRequired,
  modalTitle      : string.isRequired,
  initialValues   : object.isRequired,
  editEvaluation  : func.isRequired,
  currentUser     : object.isRequired,
  isEditMode      : bool,
  isViewMode      : bool,

  createEvaluation : func.isRequired
};

CreateEvaluationDialog.defaultProps = {
  initialValues : {
    userId             : '',
    technicalExpertise : '',
    teamwork           : '',
    flexibility        : '',
    resultsFocus       : '',
    languages          : '',
    continualLearning  : '',
    safetyFocus        : '',
    communication      : '',
    initiative         : '',
    openDiscussion     : '',
    targets            : ''
  },
  isEditMode : false,
  isViewMode : false
};

export default CreateEvaluationDialog;
