/* eslint-disable max-lines */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import {object, func, string, bool, oneOfType, array} from 'prop-types';
import './ObjectiveDetails.scss';
import {ACTION, CATEGORY, STATUS, CATEGORY_COLORS, ROUTE_PATH} from 'constant';
import {Loader, If, CreateKeyResultDialog, CreateObjectiveDialog, ConfirmationDialog} from 'components';
import {Button} from '@mui/material';
import map from 'lodash/map';
import size from 'lodash/size';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {colors as priorityColors} from 'service/utils/priorityColors';
import {floor} from 'lodash-es';

// eslint-disable-next-line
const ObjectiveDetails = props => {
  const history = useHistory();
  const [objectiveId, setObjectiveId] = React.useState('');
  const [addKeyResultModal, setAddKeyResultModal] = React.useState(false);
  const [isReadOnly, setIsReadOnly] = React.useState(false);
  const [editObjectiveModal, setEditObjectiveModal] = React.useState(false);
  const [keyResultInitialValues, setKeyResultInitialValues] = React.useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState({
    open : false
  });
  const [keyResultModalText, setKetResultModalText] = React.useState({modalText  : '',
    modalTitle : ''});
  const [keyResultPercentage, setKeyResultPercentage] = React.useState(0);

  React.useEffect(() => {
    if (objectiveId) {
      props.getObjective({
        entityId : objectiveId
      });

      if (props.isTeamLead) {
        props.getTeamMembers();
      }
    }
  }, [objectiveId]);

  React.useEffect(() => {
    if (props.location.search) {
      setObjectiveId(props.location.search.split('id=')[1]);
    }
    if (props.location.state) {
      setIsReadOnly(props.location.state.isReadOnly);
    }
  }, [props.location]);

  React.useEffect(() => {
    if ((props.hasKeyResultSucceeded || props.hasEditObjectiveSucceeded) && objectiveId) {
      props.getObjective({
        entityId : objectiveId
      });

      props.clearApiResponseErrors();
    }
  }, [props.hasKeyResultSucceeded, props.hasEditObjectiveSucceeded]);

  const getCategoryColor = attribute => {
    switch (props.objective.category) {
      case CATEGORY.PERSONAL_STUFF: return {[attribute] : CATEGORY_COLORS[CATEGORY.PERSONAL_STUFF]};
      case CATEGORY.TECHNOLOGIES: return {[attribute] : CATEGORY_COLORS[CATEGORY.TECHNOLOGIES]};
      case CATEGORY.SOFT_SKILLS: return {[attribute] : CATEGORY_COLORS[CATEGORY.SOFT_SKILLS]};
      case CATEGORY.MANAGEMENT: return {[attribute] : CATEGORY_COLORS[CATEGORY.MANAGEMENT]};
      default: return {[attribute] : 'gray'};
    }
  };


  const getHumanReadableDate = value => moment(value).format('DD/MM/YYYY');

  const computeRemainingDays = date => {
    const now = moment();
    const deadline = moment(date).endOf('day');
    const duration = moment.duration(deadline.diff(now));

    const days = floor(duration.asDays());
    const hours = duration.hours();

    if (days < 0) {
      return 0;
    }

    if (days === 0) {
      return hours + ' Hours ';
    }

    return days + ' Days, ' + hours + ' Hours ';
  };

  const completeAction = (action, model, id) => { //eslint-disable-line
    switch (action) {
      case ACTION.COMPLETE :
        if (model === 'objective') {
          if (props.isTeamLead) {
            props.editObjective({
              entityId   : id,
              status     : STATUS.COMPLETED,
              nextStatus : STATUS.COMPLETED
            });
          } else {
            props.editObjective({
              entityId   : id,
              status     : STATUS.COMPLETED,
              nextStatus : STATUS.COMPLETED
            });
          }
        } else {
          props.editKeyResult({
            entityId   : id,
            status     : STATUS.COMPLETED,
            percentage : keyResultPercentage
          });
          setKeyResultPercentage(0);
        }
        break;
      case ACTION.ARCHIVE :
        props.editObjective({
          entityId : id,
          status   : STATUS.ARCHIVED
        });
        break;
      case ACTION.RESTORE :
        props.editObjective({
          entityId   : id,
          status     : STATUS.IN_PROGRESS,
          nextStatus : STATUS.COMPLETED
        });
        break;
      default :
        break;
    }
  };

  const deleteItem = (model, id) => { //eslint-disable-line
    if (model === 'objective') {
      props.deleteObjective({
        entityId : id
      });
    } else {
      props.deleteKeyResult({
        entityId : id
      });
    }
  };

  const handleKeyResultAction = action => {
    if (action === 'edit') {
      setKetResultModalText({modalText  : 'Complete the following form with new data for the selected key Result',
        modalTitle : 'Edit Key Result'});
    } else {
      setKetResultModalText({modalText  : 'Complete the following form to create a new key result for the selected objective',
        modalTitle : 'New Key Result'});
    }

    setAddKeyResultModal(true);
  };

  return (
    <Loader isLoading={props.isLoading}>
      <If condition={!props.isLoading}>
        <div className="objective">
          <div className="objective--details">
            <div className="objective--details--wrapper">
              <div className="objective--details--container" style={getCategoryColor('borderTopColor')}>
                <div className="objective--details--header">
                  <p id="title"><span style={getCategoryColor('backgroundColor')} />{props.objective.title}</p>
                  <div className="persons">
                    <p>Author <span>{props.objective.authorId?.name}</span></p>
                    <p>Owner <span>{props.objective.ownerId?.name}</span></p>
                  </div>
                </div>

                <p>Description <span>{props.objective.description}</span></p>

                <div className="objective--details--dates">
                  <p>Last Modified <span>{moment(props.objective.lastModificationDate).format('DD/MM/YYYY HH:mm')}</span></p>
                  <p>Created at <span>{getHumanReadableDate(props.objective.createdAt)}</span></p>
                  <p>Deadline <span>{getHumanReadableDate(props.objective.deadline)}</span></p>
                </div>

                <div className="objective--details--info">
                  <p>Category <span>{props.objective.category}</span></p>
                  <p>Subcategory <span>{props.objective.subcategory}</span></p>
                  <p>Status <span>{props.objective.status}</span></p>
                  <p>Priority
                    <span style={{fontWeight : 'bold',
                      color      : priorityColors[props.objective.priority]}}
                    >{props.objective.priority}</span></p>
                </div>
                <If condition={!isReadOnly}>
                  <div className="objective--details--actions">
                    {
                      // eslint-disable-next-line
                    !(moment(props.objective.deadline).isBefore(moment().subtract(1, 'd'))) && props.objective.status !== STATUS.ARCHIVED && <Button
                        color="info"
                        onClick={() => handleKeyResultAction('add')}
                        variant="contained"
                      // eslint-disable-next-line react/jsx-closing-bracket-location
                      >Add Key Result</Button>
                    }
                    {
                    // eslint-disable-next-line
                      props.objective.ownerId !== undefined && props.isUserOwner === true && props.objective.ownerId.id !== props.currentUserId ? null : props.objective.status === STATUS.COMPLETED ?
                        <Button
                          color="secondary"
                          onClick={() => setOpenConfirmationDialog({
                            open   : true,
                            action : ACTION.ARCHIVE,
                            model  : 'objective',
                            id     : objectiveId
                          })}
                          variant="contained"
                        // eslint-disable-next-line no-negated-condition
                        >Archive</Button> : null
                    }
                    {
                    // eslint-disable-next-line
                      props.objective.status === STATUS.ARCHIVED &&
                        <Button
                          color="secondary"
                          onClick={() => setOpenConfirmationDialog({
                            open   : true,
                            action : ACTION.RESTORE,
                            model  : 'objective',
                            id     : objectiveId
                          })}
                          variant="contained"
                        // eslint-disable-next-line no-negated-condition
                        >Restore</Button>
                    }
                    {
                      // eslint-disable-next-line
                      (props.objective.ownerId !== undefined && props.objective.ownerId.id !== props.currentUserId ||
                      // eslint-disable-next-line operator-linebreak
                      props.objective.status === STATUS.IN_PROGRESS) &&
                        <Button
                          color="secondary"
                          onClick={() => setOpenConfirmationDialog({
                            open   : true,
                            action : ACTION.COMPLETE,
                            model  : 'objective',
                            id     : objectiveId
                          })}
                          variant="contained"
                        >Complete</Button>
                    }
                    {
                      // eslint-disable-next-line
                      props.objective.status !== STATUS.ARCHIVED &&
                        <Button
                          color="primary"
                          onClick={() => setEditObjectiveModal(true)} size="small"
                          variant="contained"
                        >Edit</Button>
                    }
                    {
                      props.objective.ownerId !== undefined && props.isUserOwner === true && props.objective.ownerId.id !== props.currentUserId ? null : <Button
                        color="error"
                        onClick={() => {
                          setOpenConfirmationDialog({
                            open   : true,
                            action : 'delete',
                            model  : 'objective',
                            id     : objectiveId
                          });
                        }}
                        variant="contained"
                      // eslint-disable-next-line react/jsx-closing-bracket-location
                      >Delete</Button>
                    }
                  </div>
                </If>
              </div>
              { props.errors && <p className="messages--error">{props.errors}</p> }
            </div>

            <div className="objective--details--key-result">
              <If
                condition={size(props.objective.keyResults) > 0} otherwise={() => (
                  <div className="no-data-found">
                    <span>This objective does not have any key result</span>
                  </div>
                )}
              >
                {// eslint-disable-next-line
                  map(props.objective.keyResults, keyResult => (
                    <div className="objective--details--key-result__card" style={getCategoryColor('borderTopColor')}>
                      <h3>{keyResult.title}</h3>
                      <p>Description <span>{keyResult.description}</span></p>
                      <div className="objective--details--key-result__card__info">
                        <p>Priority
                          <span style={{
                            fontWeight : 'bold',
                            color      : priorityColors[keyResult.priority]
                          }}
                          >{keyResult.priority}</span></p>
                        <p>Status <span>{keyResult.status}</span></p>
                        <p>Deadline
                          <span>{getHumanReadableDate(keyResult.deadline)}
                          </span>
                        </p>
                        <p>Remaining Time
                          <span>{computeRemainingDays(keyResult.deadline)}
                          </span>
                        </p>
                        {
                          keyResult.status === STATUS.COMPLETED &&
                          <p>Completion score (%)
                            <span>
                              {keyResult.percentage === undefined ? 100 : keyResult.percentage}
                            </span>
                          </p>
                        }
                      </div>
                      <If condition={!isReadOnly}>
                        <div className="objective--details--key-result__card--actions">
                          {
                            keyResult.status !== STATUS.COMPLETED &&
                            (props.isUserOwner !== true && props.currentUserId === keyResult.ownerId) &&
                            <Button
                              color="secondary"
                              onClick={() => setOpenConfirmationDialog({
                                open   : true,
                                action : ACTION.COMPLETE,
                                model  : 'key result',
                                id     : keyResult.id
                              })}
                              size="small"
                              variant="contained"
                            >Complete</Button>
                          }
                          {
                            props.objective.status !== STATUS.ARCHIVED &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  setKeyResultInitialValues(keyResult);
                                  setOpenConfirmationDialog({
                                    ...openConfirmationDialog,
                                    isEditModeKeyResult : true
                                  });
                                  handleKeyResultAction('edit');
                                }} size="small" variant="contained"
                              >Edit</Button>
                          }
                          {
                            (props.isUserOwner !== true || props.currentUserId === keyResult.ownerId) &&
                            <Button
                              color="error"
                              onClick={() => {
                                setOpenConfirmationDialog({
                                  open   : true,
                                  action : 'delete',
                                  model  : 'key result',
                                  id     : keyResult.id
                                });
                              }
                              }
                              size="small"
                              variant="contained"
                            >Delete</Button>
                          }
                        </div>
                      </If>
                    </div>
                  ))}
              </If>
            </div>
          </div>
        </div>
      </If>

      <CreateKeyResultDialog
        currentUserId={props.currentUserId}
        deadline={props.objective.deadline}
        initialValues={openConfirmationDialog.isEditModeKeyResult && {...keyResultInitialValues}}
        isEditMode={openConfirmationDialog.isEditModeKeyResult}
        modalText={keyResultModalText.modalText}
        modalTitle={keyResultModalText.modalTitle}
        objectiveId={props.objective.id}
        objectivePriority={props.objective.priority}
        ownerId={props.objective.ownerId?.id}
        setVisible={value => {
          setAddKeyResultModal(value);
          setOpenConfirmationDialog({
            ...openConfirmationDialog,
            isEditModeKeyResult : false
          });
        }}
        visible={addKeyResultModal}
      />

      <CreateObjectiveDialog
        currentUserId={props.currentUserId}
        currentUserName={props.currentUserName}
        currentUserTeam={props.currentUserTeam}
        initialValues={{
          id          : props.objective.id,
          title       : props.objective.title,
          assignee    : props.objective.ownerId?.id,
          team        : props.objective.ownerId?.team,
          description : props.objective.description,
          category    : props.objective.category,
          subcategory : props.objective.subcategory,
          priority    : props.objective.priority,
          privacy     : Boolean(props.objective.privacy),
          deadline    : moment(props.objective.deadline).format('YYYY-MM-DD')
        }}
        isEditMode
        isTeamLead={props.isTeamLead}
        modalText="Complete the following form with new data for the selected objective"
        modalTitle="Edit objective"
        setVisible={setEditObjectiveModal}
        teamMembers={props.teamMembers}
        visible={editObjectiveModal}
      />

      <ConfirmationDialog
        action={openConfirmationDialog.action}
        complete={() => {
          completeAction(openConfirmationDialog.action, openConfirmationDialog.model, openConfirmationDialog.id);
        }}
        deleteItem={() => {
          deleteItem(openConfirmationDialog.model, openConfirmationDialog.id);
          if (openConfirmationDialog.model === 'objective') {
            history.push({
              pathname : ROUTE_PATH.OBJECTIVES
            });
          }
        }}
        handleClose={() => setOpenConfirmationDialog({
          ...openConfirmationDialog,
          open : false
        })}
        model={openConfirmationDialog.model}
        open={openConfirmationDialog.open}
        updatePercentage={setKeyResultPercentage}
      />
    </Loader>
  );
};

ObjectiveDetails.propTypes = {
  isLoading              : bool.isRequired,
  isTeamLead             : bool.isRequired,
  isUserOwner            : bool.isRequired,
  hasCallFailed          : bool.isRequired,
  currentUserName        : string.isRequired,
  currentUserId          : string.isRequired,
  currentUserTeam        : string.isRequired,
  errors                 : string.isRequired,
  location               : object.isRequired,
  objective              : object.isRequired,
  getObjective           : func.isRequired,
  getTeamMembers         : func.isRequired,
  editObjective          : func.isRequired,
  editKeyResult          : func.isRequired,
  deleteObjective        : func.isRequired,
  deleteKeyResult        : func.isRequired,
  clearApiResponseErrors : func.isRequired,
  teamMembers            : oneOfType([object, array]).isRequired,

  hasKeyResultSucceeded     : bool.isRequired,
  hasEditObjectiveSucceeded : bool.isRequired
};
ObjectiveDetails.displayName = 'ObjectiveDetails';

export default ObjectiveDetails;
