import React from 'react';
import './Loader.scss';
import {bool, object} from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({isLoading, children}) => {
  if (isLoading) {
    return (
      <div className="relative">
        <div className="loader">
          <div className="loader-spinner">
            <CircularProgress color="warning" />
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div className="relative">
      {children}
    </div>
  );
};

Loader.displayName = 'Loader';
Loader.propTypes = {
  isLoading : bool.isRequired,
  children  : object.isRequired
};
export default Loader;

