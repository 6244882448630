import {any, bool, func, oneOfType, node} from 'prop-types';
import isFunction from 'lodash/isFunction';

const If = ({children, condition, render, otherwise}) => {
  const finalCondition = isFunction(condition) ? condition() : Boolean(condition);

  if (finalCondition) {
    return render ? render() : children;
  } else if (otherwise) {
    return otherwise();
  }

  return null;
};

If.displayName = 'If';
If.propTypes = {
  children  : node.isRequired,
  condition : oneOfType([any, bool, func]).isRequired,
  otherwise : func.isRequired,
  render    : func.isRequired
};
export default If;
