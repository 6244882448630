import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent, Divider, IconButton} from '@mui/material';
import './InfoPopUp.scss';
import {map} from 'lodash-es';
import CloseIcon from '@mui/icons-material/Close';

const team = [
  {
    image : '/team/bora.vlad.jpeg',
    name  : 'Vlad Bora'
  },
  {
    image : '/team/georgian.nechifor.jpeg',
    name  : 'Georgian Nechifor'
  },
  {
    image : '/team/claudia.pop.jpeg',
    name  : 'Claudia Pop'
  }
];


const InfoPopUp = props => {
  const {onClose, isOpen} = props;
  const [randomTeam, setRandomTeam] = React.useState(team);

  React.useEffect(() => {
    if (!isOpen) {
      // eslint-disable-next-line no-magic-numbers
      const random = randomTeam.sort(() => 0.5 - Math.random());

      setRandomTeam(random);
    }
  }, [isOpen]);


  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog maxWidth={'md'} onClose={handleClose} open={isOpen}>
      <DialogTitle className="info__title">
        <h3>Objective tracking</h3>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position : 'absolute',
              right    : 8,
              top      : 8,
              color    : theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <br />
        <Divider>
          <b>About</b>
        </Divider>
        <section className="info__about">
          <h4 className="info__about__subtitle">What are Objectives and Key Results?</h4>
          <p>Objectives and Key results (OKR) is a goal-setting methodology
            driven by outcomes. Using OKRs encourages alignment, transparency
            and accountability through measurable goals.
          </p>
          <h4 className="info__about__subtitle">Who created Objectives and Key Results?</h4>
          <p>Although popularized by Google’s John Doerr, OKRs were actually
            created and implemented decades ago before by Intel. In recent
            years OKRs have been adopted by growing companies like Google,
            Twitter, and LinkedIn, and are used widely across the technology
            sector and beyond.
          </p>
          <h4 className="info__about__subtitle">How are Objectives and Key Results created?</h4>
          <p>Objectives are outcomes that reflect current business priorities. They should:</p>
          <ul>
            <li>Be time-based (i.e., with a due date), non-numeric (qualitative) and aspirational</li>
            <li>Answer the question, “What am I working towards?”</li>
            <li>Empower and promote collaboration and cross-functionality</li>
            <li>Have a clear subject, object and corresponding journey</li>
          </ul>
          <p>Every objective has corresponding key results, which should:</p>
          <ul>
            <li>Be measurably numerically, with a clear start or end point</li>
            <li>Utilize a threshold or delta to measure change (i.e., from x to y)</li>
            <li>Be the end result of a series of tasks, but not the tasks themselves</li>
            <li>Answer the question, “How do I know if I have achieved my outcome?”</li>
          </ul>
          <p>Every key result has supporting projects that have their own to-do tasks but are separate from OKRs.</p>
          <h4 className="info__about__subtitle">OKRs Best Practices</h4>
          <ul>
            <li><strong>Keep it Simple </strong><br />
              Focus on objectives you know you can achieve in the given timeframe.
              Many employees think they need to contribute to every department objective
              and end up spreading themselves too thin. Prioritize your objectives according
              to what the business needs most. Remember, there’s no magic number for how many
              objectives you should have—it depends how complex they are, as well as the time
              and resources available.</li>
            <li><strong>Be Specific </strong><br />
              When setting objectives, brainstorm different ways you can reach the end result.
              Draw out your action plan to be more specific on how to achieve your objective.
              For each key result, think about how performance can be evaluated. The more specific
              you are, the clearer expectations will be. Concise objectives mean you’ll know exactly
              what’s needed to complete your goals.</li>
            <li><strong>Cascade Your Objectives </strong><br />
              A common problem for employees setting objectives is struggling to see how their
              jobs contribute to overall organizational goals and success. How does a payroll clerk
              help their company reach 10,000 users? The solution is to cascade your objectives: from
              an organization level, to the department level and finally to an individual level.</li>
            <li><strong>Make it Measurable </strong><br />
              Key results need a unit of measurement. It doesn’t matter what: it could be to
              write 10 blogs per month or to reach $10,000 in revenue. What you’re trying to avoid
              is a situation where you’re not sure whether a goal has really been reached or not.
              Managers and employees can meet 1:1 to add metrics to quantifiable goals.</li>
            <li><strong>Celebrate and Recognize </strong><br />
              Reward and recognize yourself and others when a milestone has been reached.
              Positive reinforcement helps sustain best practice. Don’t wait until the very
              end of an objective to recognize effort—celebrate incremental progress too. Encourage
              all co-workers to share their OKRs publicly and create a support system within your team.</li>
          </ul>
        </section>
        <Divider>
          <b>Credits</b>
        </Divider>
        <section className="info__credits">
          {
            map(randomTeam, member => (<div className="info__credits__member" key="member.name"><img className="info__credits__member__image" src={member.image} /><label>{member.name}</label></div>))// eslint-disable-line max-len
          }
        </section>
      </DialogContent>
    </Dialog>
  );
};

InfoPopUp.propTypes = {
  onClose : PropTypes.func.isRequired,
  isOpen  : PropTypes.bool.isRequired
};


InfoPopUp.displayName = 'InfoPopUp';

export default InfoPopUp;
