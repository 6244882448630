/* eslint-disable max-len */
const createCapability = (key, label, tooltip) => ({key,
  label,
  tooltip});

const CAPABILITIES = {
  GENERAL_CAPABILITIES : {
    EXPORT_USERS        : createCapability('EXPORT_USERS', 'Export employees', 'This capability allows you to export employees data in an excel file, individually and grouped'),
    ADD_USERS           : createCapability('ADD_USERS', 'Add employees', 'This capability allows you to add new employee directly from Super Awesome Team'),
    REMOVE_USERS        : createCapability('REMOVE_USERS', 'Archive employees', 'This capability allows you to archive an employee directly from Profile Page'),
    VIEW_ARCHIVED_USERS : createCapability('VIEW_ARCHIVED_USERS', 'View archived employees', 'This capability allows you to view archived employees directly from Super Awesome Team'),
    DOWNLOAD_CV         : createCapability('DOWNLOAD_CV', 'Download CV', 'This capability allows you to download any employee CV from their profile')

  },
  SIDEBAR_CAPABILITIES : {
    VIEW_ADMIN : createCapability('VIEW_ADMIN', 'View admin page', 'This capability gives you access to admin panel')
  },
  USER_INFO_CAPABILITIES : {
    VIEW_ALL_USER_INFO : createCapability('VIEW_ALL_USER_INFO', 'View entire info of the employee', 'This capability allows you to view all available fields for an employee, in addition to those already available for all employees, including role and previous team fields'),
    EDIT_ALL_FIELDS    : createCapability('EDIT_ALL_FIELDS', 'Edit entire info of the user', 'This capability allows you to edit all available fields for an employee, in addition to those already available for all employees, including role and previous team fields')
  },
  EXTRA_INFO_CAPABILITIES : {
    VIEW_BITSTONE_INFORMATION : createCapability('VIEW_BITSTONE_INFORMATION', 'View Bitstone Information', 'This capability allows you to view an employee\'s financial situation and insights about their position in the company.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    EDIT_BITSTONE_INFORMATION : createCapability('EDIT_BITSTONE_INFORMATION', 'Edit Bitstone Information', 'This capability allows you to edit an employee\'s financial situation and insights about their position in the company.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    VIEW_RELEVANT_EXPERIENCE  : createCapability('VIEW_RELEVANT_EXPERIENCE', 'View Relevant Experience', 'This capability allows you to view an employee\'s relevant experience (i.e. jobs before Bitstone).\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    EDIT_RELEVANT_EXPERIENCE  : createCapability('EDIT_RELEVANT_EXPERIENCE', 'Edit Relevant Experience', 'This capability allows you to edit an employee\'s relevant experience (i.e. jobs before Bitstone).\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    VIEW_PROJECTS_EXPERIENCE  : createCapability('VIEW_PROJECTS_EXPERIENCE', 'View Projects Experience', 'This capability allows you to view an employee\'s projects experience.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    EDIT_PROJECTS_EXPERIENCE  : createCapability('EDIT_PROJECTS_EXPERIENCE', 'Edit Projects Experience', 'This capability allows you to edit an employee\'s projects experience.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    VIEW_PERSONAL_INFO        : createCapability('VIEW_PERSONAL_INFO', 'View Personal Information', 'This capability allows you to view an employee\'s personal experience.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.'),
    EDIT_PERSONAL_INFO        : createCapability('EDIT_PERSONAL_INFO', 'Edit Personal Information', 'This capability allows you to edit an employee\'s personal experience.\nNote: While this is also available for the team lead role, it\'s limited to their specific team.')

    // VIEW_CV_FIELDS           : createCapability('VIEW_CV_FIELDS', 'View CV fields: language, skills and projects', 'This capability allows you to view an employee\'s information which  experience.\nNote: While this is available for the team lead role, it\'s limited to their specific team.'),)
  }
};

export {
  CAPABILITIES
};

