import React from 'react';
import {RoutingProvider} from 'routing';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './App.scss';
import '../src/assets/fonts/FuturaBookFont.ttf';

const theme = createTheme({
  palette : {
    primary : {
      main : '#004C7C'
    },
    secondary : {
      main : '#006260'
    },
    info : {
      main : '#3C1874'
    }
  }
});

function App () {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <RoutingProvider />
      </ThemeProvider>
    </div>
  );
}

App.displayName = 'App';

export default App;
