/* eslint-disable no-magic-numbers */
import React from 'react';
import './StatisticsTable.scss';
import {object} from 'prop-types';
import map from 'lodash/map';
import intersectionBy from 'lodash/intersectionBy';
import camelCase from 'lodash/camelCase';

const intersection = (t1, t2) => intersectionBy(t1, t2, item => camelCase(item).toLowerCase());


import {Table, TableHead, TableRow, TableCell, TableBody, TableContainer} from '@mui/material';


const StatisticsTable = ({statisticsData}) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  let timeout = false;
  const delay = 200;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleResize, delay);
    });
  }, []);

  return (
    <div className="statistics-emplotee-table">
      <h3>Statistics by Employee</h3>
      {
        statisticsData.statistics !== undefined &&
        <TableContainer sx={{maxHeight : 600}}>
          <Table
            aria-label="sticky table"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  Name
                </TableCell>
                <TableCell rowSpan={2}>
                  Team
                </TableCell>
                <TableCell align="center"rowSpan={2}>
                  No. of Objectives
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Completed Objectives
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  No. of Key Results
                </TableCell>

                <TableCell align="center" colSpan={2}>
                  Completed Key Results
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={width < 983 ? {top : 60} : {top : 37}}>
                  In Time
                </TableCell>
                <TableCell align="center" style={width < 983 ? {top : 60} : {top : 37}}>
                  Overdue
                </TableCell>
                <TableCell align="center" style={width < 983 ? {top : 60} : {top : 37}}>
                  In Time
                </TableCell>
                <TableCell align="center" style={width < 983 ? {top : 60} : {top : 37}}>
                  Overdue
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                map(Object.values(statisticsData.statistics.byEmployee).sort((empA, empB) => intersection(empA.team, empB.team)), (row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      '& > *' : {
                        backgroundColor : index % 2 === 0 ? '#f0f2f577' : 'white'
                      }}}
                  >
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {
                        Array.isArray(row.team) &&
                        row.team.length > 1 &&
                        row.team.toString().replaceAll(',', ', ') || row.team
                      }
                    </TableCell>
                    <TableCell align="center">
                      {row.objectivesNumber}
                    </TableCell>
                    <TableCell align="center">
                      {row.completedObjectives.inTime}
                    </TableCell>
                    <TableCell align="center">
                      {row.completedObjectives.overdue}
                    </TableCell>
                    <TableCell align="center">
                      {row.keyResultsNumber}
                    </TableCell>
                    <TableCell align="center">
                      {row.completedKeyResults.inTime}
                    </TableCell>
                    <TableCell align="center">
                      {row.completedKeyResults.overdue}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  );
};


StatisticsTable.displayName = 'StatisticsTable';
StatisticsTable.propTypes = {
  statisticsData : object.isRequired
};
export default StatisticsTable;
