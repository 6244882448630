import {ENV_URL} from '../../constant';

export async function getPublicHolidays (year) {
  const url = `${ENV_URL.HOLIDAY_API}${year}/RO`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();


    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was a problem fetching the public holidays:', error);
  }

  return null;
}

