import {connect} from 'react-redux';
import Filter from './Filter';
import {apiRequestAction} from 'action/apiResource';
import {getUserCapabilities, getAllUsers, getProjectsTitle} from 'selector/user';
import {setSearchInputValue} from '../../store/action/setSearchInputValue';
import {getTeams} from '../../store/selector/team';
import {getJobs} from '../../store/selector/job';
import {getSearchInputValue} from '../../store/selector/searchInputValue';


const mapStateToProps = state => ({
  jobs             : getJobs(state),
  teams            : getTeams(state),
  capabilities     : getUserCapabilities(state),
  users            : getAllUsers(state),
  projects         : getProjectsTitle(state),
  searchInputValue : getSearchInputValue(state)
});

const mapDispatchToProps = {
  getObjectives : apiRequestAction.objective.getObjectives(),
  getTeams      : apiRequestAction.user.getTeams(),
  setSearchInputValue
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
