import React from 'react';
import './EvaluationFilter.scss';
import {Add} from '@mui/icons-material';
import {Button} from '@mui/material';
import {CreateEvaluationDialog} from 'components';
import {object, array, oneOfType} from 'prop-types';


const EvaluationFilter = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div className="evaluations-wrapper">
      <div className="evaluations-filters">
        <Button
          className="new-objective-button" color="secondary" onClick={() => setVisible(true)}
          size="small"
          startIcon={<Add />}
          variant="contained"
        >
          New Evaluation
        </Button>
        <CreateEvaluationDialog
          currentUserId={null}
          currentUserName={null}
          modalText="Complete the following form to create a new evaluation"
          modalTitle="New evaluation"
          setVisible={setVisible}
          visible={visible}
        />
      </div>
    </div>);
};

EvaluationFilter.displayName = 'EvaluationFilter';
EvaluationFilter.propTypes = {
  teamMembers : oneOfType([array, object]).isRequired
};
export default EvaluationFilter;
