import RolesTable from './RolesTable';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress} from 'selector/app';
import {getUserCapabilities} from 'selector/user';
import {getRoles} from '../../store/selector/role';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  capabilities : getUserCapabilities(state),
  roles        : getRoles(state)
});

const mapDispatchToProps = {
  getRoles : apiRequestAction.role.getRoles({
    skipEntityIdCheck : true
  }),
  createRole : apiRequestAction.role.createRole({
    skipEntityIdCheck : true
  }),
  updateRole : apiRequestAction.role.updateRole({
    skipEntityIdCheck : true
  }),
  deleteRole  : apiRequestAction.role.deleteRole(),
  restoreRole : apiRequestAction.role.restoreRole()
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesTable);
