import HTTP_METHOD_REQUEST from './httpMethodRequest';

export const API_METHOD = {
  GET_ALL : 'getAll',
  GET     : 'get',
  CREATE  : 'post',
  UPDATE  : 'put',
  DELETE  : 'delete',
  PATCH   : 'patch'
};

export const API_METHOD_TO_HTTP_METHOD_MAP = {
  [API_METHOD.GET_ALL] : HTTP_METHOD_REQUEST.GET,
  [API_METHOD.GET]     : HTTP_METHOD_REQUEST.GET,
  [API_METHOD.CREATE]  : HTTP_METHOD_REQUEST.POST,
  [API_METHOD.UPDATE]  : HTTP_METHOD_REQUEST.PUT,
  [API_METHOD.DELETE]  : HTTP_METHOD_REQUEST.DELETE,
  [API_METHOD.PATCH]   : HTTP_METHOD_REQUEST.PATCH
};
