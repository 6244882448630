import {createAction} from 'redux-actions';

const PREFIX = 'auth::';

export const ActionTypes = {
  SET_TOKEN      : `${PREFIX}SET_TOKEN`,
  SET_LOGIN_FLAG : `${PREFIX}SET_LOGIN_FLAG`,
  LOGOUT         : `${PREFIX}LOGOUT`
};

export const setToken = createAction(ActionTypes.SET_TOKEN);
export const setLoginFlag = createAction(ActionTypes.SET_LOGIN_FLAG);
export const logout = createAction(ActionTypes.LOGOUT);
