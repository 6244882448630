const API_RESOURCE = {
  AUTH : 'auth',
  USER : 'user',

  KEY_RESULT : 'keyResult',
  OBJECTIVE  : 'objective',
  STATISTICS : 'statistics',
  EVALUATION : 'evaluation',

  TEAM_DETAILS : 'teamDetails',
  LINK         : 'link',
  TEAM         : 'team',
  ROLE         : 'role',
  JOB          : 'job',
  SKILLS       : 'skills',
  TIMETRACKING : 'timetracking',
  HOLIDAYS     : 'holidays'
};

export default API_RESOURCE;
