import {connect} from 'react-redux';
import ProfilePicture from './ProfilePicture';
import {getUserCapabilities} from 'selector/user';
import {apiRequestAction} from 'action/apiResource';
import {getHasCallSucceeded} from 'selector/app';

const mapStateToProps = state => ({
  capabilities              : getUserCapabilities(state),
  updateUserImageSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.updateUserImage.requestTracker
  })
});

const mapDispatchToProps = {
  getAllUsers : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
