import {PRIORITY} from 'constant';

const colors = {
  [PRIORITY.LOWEST]  : '#CDFF90',
  [PRIORITY.LOW]     : '#5EB136',
  [PRIORITY.MEDIUM]  : '#FCEC30',
  [PRIORITY.HIGH]    : '#F28B82',
  [PRIORITY.HIGHEST] : '#D8363D'
};

export {
  colors
};
