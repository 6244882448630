const CSS_VARIABLES = {
  BITSTONE_RED     : '#ec1c24',
  FONT_FAMILY      : '\'Futura Book\', sans-serif',
  BACKGROUND_COLOR : '#D21219',
  TEXT_COLOR       : '#262626'
};

export {
  CSS_VARIABLES
};
