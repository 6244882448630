/* eslint prefer-promise-reject-errors : off */

import {call} from 'redux-saga/effects';
import isObject from 'lodash/isObject';
import {isJSON, toCamelCase} from 'service/utils';
import {HTTP_STATUS, RESPONSE_STATUS} from 'constant';

export const promisify = result => new Promise(async (resolve, reject) => { // eslint-disable-line
  if (HTTP_STATUS.SERVER_ERROR.test(result.status)) {
    reject({
      isServerError : true
    });
  } else if (!HTTP_STATUS.OK.test(result.status)) {
    try {
      const error = await result.json();

      reject({
        status : RESPONSE_STATUS.ERROR,
        url    : result.url,
        error
      });
    } catch (ex) {
      reject({
        status : RESPONSE_STATUS.ERROR,
        error  : result.statusText,
        url    : result.url
      });
    }
  }

  try {
    const responseAsText = await result.text();

    if (!isJSON(responseAsText)) {
      resolve({
        status : RESPONSE_STATUS.SUCCESS,
        data   : {},
        url    : result.url
      });
    }

    try {
      const response = JSON.parse(responseAsText);
      const {data} = response;
      const transformed = isObject(data) ? toCamelCase(data) : data;

      resolve({
        status : RESPONSE_STATUS.SUCCESS,
        data   : transformed,
        url    : result.url
      });
    } catch (error) {
      reject({
        status : RESPONSE_STATUS.ERROR,
        url    : result.url,
        error
      });
    }
  } catch (error) {
    reject({
      status : RESPONSE_STATUS.ERROR,
      url    : result.url,
      error
    });
  }
});

const toJson = () => function* convertToJson (req, next) {
  const response = yield next(new Request(req));

  return yield call(promisify, response);
};

export default toJson;
