/* eslint-disable capitalized-comments */
/* eslint-disable lines-around-comment */
/* eslint-disable max-lines */
import * as React from 'react';
import {OverviewCard, Loader, ConfirmationDialog, CreateKeyResultDialog, CreateObjectiveDialog, If} from 'components';
import './Plans.scss';
import Paper from '@mui/material/Paper';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableFooter,
  TablePagination
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box} from '@mui/system';
import {func, array, object, string, bool, number, oneOfType} from 'prop-types';
import moment from 'moment';
import {DeleteTwoTone, EditTwoTone, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, VisibilityTwoTone} from '@mui/icons-material';
import {STATUS, PRIORITY_COLORS_LIGHT, ROUTE_PATH} from 'constant';
import {useHistory} from 'react-router-dom';
import {floor} from 'lodash-es';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import filter from 'lodash/filter';
import upperFirst from 'lodash/upperFirst';
import {useTheme} from '@mui/material/styles';
import ReactExport from 'react-data-export';
import intersectionBy from 'lodash/intersectionBy';
import camelCase from 'lodash/camelCase';

const intersection = (t1, t2) => intersectionBy(t1, t2, item => camelCase(item).toLowerCase());

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function TablePaginationActions (props) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink : 0,
      ml         : 2.5}}
    >
      <IconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.displayName = 'TablePaginationActions';
TablePaginationActions.propTypes = {
  count        : number.isRequired,
  onPageChange : func.isRequired,
  page         : number.isRequired,
  rowsPerPage  : number.isRequired
};

// eslint-disable-next-line
const Plans = props => {
  const history = useHistory();
  const [objectivesToDisplay, setObjectivesToDisplay] = React.useState(props.plans.items);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-magic-numbers
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editObjectiveModal, setEditObjectiveModal] = React.useState(false);
  const [addKeyResultModal, setAddKeyResultModal] = React.useState(false);
  const [objectiveToEdit, setObjectiveToEdit] = React.useState({});
  const [keyResultInitialValues, setKeyResultInitialValues] = React.useState({});
  const [selectedTeam, setSelectedTeam] = React.useState('all');
  const [displayedPlans, setDisplayedPlans] = React.useState([]);
  const [plansForExport, setPlansForExport] = React.useState([]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState({
    open : false
  });

  React.useEffect(() => {
    props.getUserPlans();
  }, []);

  React.useEffect(() => {
    if (props.editSuccessfull || props.deleteSuccessfull) {
      props.getUserPlans();
    }
  }, [props.editSuccessfull, props.deleteSuccessfull]);

  const createExportableObject = () => {
    const columns = [
      {title : 'Nr. crt.'},
      {title : 'Objective or Key Result',
        width : {wch : 40}
      },
      {title : 'Status',
        width : {wch : 20}},
      {title : 'Priority'},
      {title : 'Deadline'},
      {title : 'Assignee',
        width : {wch : 20}}
    ];

    const data = Object.values(objectivesToDisplay).map((objective, index) => {
      const border = {style : {border : {bottom : {style : 'thin',
        color : {rgb : 'A3C3E1'}},
      right : {style : 'thin',
        color : {rgb : 'A3C3E1'}}},
      fill : {patternType : 'solid',
        fgColor     : {rgb : 'E0EBF5'}}}
      };
      const borderObjective = {style : {border : {bottom : {style : 'thin',
        color : {rgb : 'A3C3E1'}},
      right : {style : 'thin',
        color : {rgb : 'A3C3E1'}}},
      fill : {patternType : 'solid',
        fgColor     : {rgb : 'BDD4E7'}}}
      };

      const objectiveRow = [[
        {value : index + 1 + '.',
          ...borderObjective},
        {value : objective.title,
          ...borderObjective},
        {value : objective.status,
          ...borderObjective},
        {value : objective.priority,
          ...borderObjective},
        {value : moment(objective.deadline).format('DD-MM-YYYY'),
          ...borderObjective},
        {value : objective.ownerId.name,
          ...borderObjective}
      ]];

      const keyData = Object.values(objective.keyResults).map(key =>
        [
          {value : ''},
          {value : key.title,
            ...border},
          {value : key.status ? key.status : ' ',
            ...border},
          {value : key.priority,
            ...border},
          {value : moment(key.deadline).format('DD-MM-YYYY'),
            ...border},
          {value : objective.ownerId.name,
            ...border}

        ]
      );

      const completeObjective = objectiveRow.concat(keyData);

      return completeObjective;
    });

    const finalData = data.flat(1);
    const plansToExport = [
      {
        columns,
        data : finalData
      }

    ];

    setPlansForExport(plansToExport);
  };

  React.useEffect(() => {
    createExportableObject();
  }, [objectivesToDisplay]);

  const handleEditObjective = objective => {
    setObjectiveToEdit({
      id          : objective.id,
      title       : objective.title,
      assignee    : objective.ownerId?.id,
      team        : objective.ownerId?.team,
      description : objective.description,
      category    : objective.category,
      subcategory : objective.subcategory,
      priority    : objective.priority,
      privacy     : objective.privacy,
      deadline    : moment(objective.deadline).format('YYYY-MM-DD')
    });
    setEditObjectiveModal(true);
  };

  const computeDaysLeft = date => {
    const now = moment();
    const deadline = moment(date).endOf('day');
    const duration = moment.duration(deadline.diff(now));

    const days = floor(duration.asDays());
    const hours = duration.hours();

    if (days < 0) {
      return 0;
    }

    if (days === 0) {
      return hours + ' Hours';
    }

    return days + ' Days, ' + hours + ' Hours';
  };

  const handleCardClick = memberEmail => {
    setObjectivesToDisplay(Object.values(props.plans.items).filter(objective => objective.ownerId.email === memberEmail));
    setPage(0);
  };

  const completeAction = (model, id) => { //eslint-disable-line
    if (model === 'objective') {
      props.editObjective({
        entityId : id,
        status   : STATUS.COMPLETED
      });
    } else {
      props.editKeyResult({
        entityId : id,
        status   : openConfirmationDialog.action === 'start' ? STATUS.IN_PROGRESS : STATUS.COMPLETED
      });
    }
  };

  const deleteItem = (model, id) => { //eslint-disable-line
    if (model === 'objective') {
      props.deleteObjective({
        entityId : id
      });
    } else {
      props.deleteKeyResult({
        entityId : id
      });
    }
  };
  const Row = data => {
    const {row} = data;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{
          '& > *' : {
            borderBottom    : 'unset',
            backgroundColor : PRIORITY_COLORS_LIGHT[row.priority.toUpperCase()]
          }
        }}
        >
          <TableCell >
            <IconButton
              aria-label="expand row"
              onClick={() => setOpen(!open)}
              size="small"
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {row.title + ' (' + Object.values(row.keyResults).length + ')'}
          </TableCell>
          <TableCell align="center">{row.status}</TableCell>
          <TableCell align="center" >{row.priority}</TableCell>
          {/* <TableCell align="center">{moment(row.deadline).format('DD-MM-YYYY')}</TableCell> */}
          <TableCell align="center">{computeDaysLeft(row.deadline)}</TableCell>
          <TableCell align="center">{row.ownerId.name}</TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="upload picture" color="success" component="span"
              onClick={() => {
                history.push({
                  pathname : ROUTE_PATH.OBJECTIVE_DETAILS,
                  search   : `?id=${row.id}`,
                  state    : {
                    objectiveId : row.id
                  }
                });
              }}
              style={{boxShadow : 'inset 34px 0px 0px 0px rgb(255 255 255 / 90%)'}}
            >
              <VisibilityTwoTone color="secondary" />
            </IconButton>
            <IconButton
              aria-label="upload picture" color="info" component="span"
              onClick={() => handleEditObjective(row)}
              style={{boxShadow : 'inset 34px 0px 0px 0px rgb(255 255 255 / 90%)'}}
            >
              <EditTwoTone color="primary" />
            </IconButton>
            <IconButton
              aria-label="upload picture" color="error" component="span"
              onClick={() => setOpenConfirmationDialog({
                open   : true,
                action : 'delete',
                model  : 'objective',
                id     : row.id
              })
              }
              style={{boxShadow : 'inset 34px 0px 0px 0px rgb(255 255 255 / 90%)'}}
            >
              <DeleteTwoTone />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={7} style={{padding : 0}}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box >
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        width : 45
                      }}
                      />
                      <TableCell>Key Result</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Priority</TableCell>
                      <TableCell align="center">Time Left</TableCell>
                      <TableCell align="center">Assignee</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(row.keyResults).map(keyResult => (
                      <TableRow
                        key={keyResult.id} sx={{
                          '& > *' : {
                            backgroundColor : PRIORITY_COLORS_LIGHT[keyResult.priority.toUpperCase()]
                          }
                        }}
                      >
                        <TableCell />
                        <TableCell >{keyResult.title}</TableCell>
                        <TableCell align="center">{keyResult.status}</TableCell>
                        <TableCell align="center">{keyResult.priority}</TableCell>
                        <TableCell align="center">{computeDaysLeft(keyResult.deadline)}</TableCell>
                        <TableCell align="center">{row.ownerId.name}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="upload picture" component="span"
                            onClick={() => {
                              setKeyResultInitialValues({...keyResult,
                                objectiveId       : row.id,
                                objectiveDeadline : row.deadline,
                                ownerId           : row.ownerId.id});
                              setOpenConfirmationDialog({
                                ...openConfirmationDialog,
                                isEditModeKeyResult : true
                              });
                              setAddKeyResultModal(true);
                            }}
                            style={{boxShadow : 'inset 34px 0px 0px 0px rgb(255 255 255 / 90%)'}}
                          >
                            <EditTwoTone color="primary" />
                          </IconButton>
                          <IconButton
                            aria-label="upload picture" color="error" component="span"
                            onClick={() => setOpenConfirmationDialog({
                              open   : true,
                              action : 'delete',
                              model  : 'key result',
                              id     : keyResult.id
                            })}
                            style={{boxShadow : 'inset 34px 0px 0px 0px rgb(255 255 255 / 90%)'}}
                          >
                            <DeleteTwoTone />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                { Object.values(row.keyResults).length === 0 && <p>There are no Key Results for the selected objective. </p>}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  Row.displayName = 'Row';

  React.useEffect(() => {
    if (props.plans) {
      setObjectivesToDisplay(props.plans.items);
      if (props.user && props.user.isOwner || props.user.isTeamsManager) {
        setDisplayedPlans(reduce(props.plans.plans, (acc, value) => ({
          ...acc,
          ...value
        }), {}));
      } else if (!props.user.isOwner) {
        setDisplayedPlans(props.plans.plans);
      }
    }
  }, [props.plans, props.user]);

  React.useEffect(() => {
    if (selectedTeam) {
      if (selectedTeam === 'all') {
        setObjectivesToDisplay(props.plans.items);
        setDisplayedPlans(reduce(props.plans.plans, (acc, value) => ({
          ...acc,
          ...value
        }), {}));
      } else {
        const teamPlans = props.plans.plans[camelCase(selectedTeam)];

        const filtered = filter(props.plans.items, item => intersection(item.ownerId.team, [selectedTeam]).length > 0);

        setObjectivesToDisplay(filtered);
        setDisplayedPlans(teamPlans);
      }
    }
  }, [selectedTeam]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Object.values(objectivesToDisplay || props.plans.items).length) : 0;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Loader isLoading={props.isLoading}>
      <div className="plans">
        <div className="plans-container">
          <Box sx={{flexGrow : 1}}>
            <Grid container spacing={2}>
              <Grid item md={9} xs={12}>
                <div className="plans-container__table">
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell sx={{fontSize : '15px'}}>Objective</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Status</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Priority</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Time Left</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Assignee</TableCell>
                          <TableCell align="center" sx={{fontSize : '15px'}}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {

                          // eslint-disable-next-line max-len
                          (rowsPerPage > 0 ? Object.values(objectivesToDisplay).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : Object.values(objectivesToDisplay || props.plans.items)
                          ).map(row => (
                            <Row key={row.name} row={row} />
                          ))}

                        {emptyRows > 0 && (
                          // eslint-disable-next-line no-magic-numbers
                          <TableRow style={{height : 50 * emptyRows}}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            ActionsComponent={TablePaginationActions}
                            SelectProps={{
                              inputProps : {
                                'aria-label' : 'rows per page'
                              },
                              native : true
                            }}
                            colSpan={8}
                            count={Object.values(objectivesToDisplay).length}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            // eslint-disable-next-line no-magic-numbers
                            rowsPerPageOptions={[5, 10, 15, 25, {label : 'All',
                              value : -1}]}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
                {Object.values(objectivesToDisplay).length === 0 && <p> There are no Objectives for the selected user. </p>}
                <div className="plans-container__export">
                  <ExcelFile element={<Button color="secondary" size="small" variant="contained">Export</Button>} filename={'Plans_' + moment().format('YYYY-MM-DD_hh-mm-ss')}>
                    <ExcelSheet dataSet={plansForExport} name="Plans" />
                  </ExcelFile>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <If condition={(props.user.isOwner || props.user.isTeamsManager) && props.plans}>
                  <div className="filter">
                    <FormControl>
                      <InputLabel id="select-team-label">Team Name</InputLabel>
                      <Select
                        id="select-team-select"
                        labelId="select-team-label"
                        name="teamName"
                        onChange={event => setSelectedTeam(event.target.value)}
                        value={selectedTeam}
                        variant="standard"
                      >
                        <MenuItem value="all">All teams you manage</MenuItem>
                        {
                          map(props.user.team, value => <MenuItem value={value}>{upperFirst(value)}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                </If>
                {map(displayedPlans, (value, member) => (
                  <OverviewCard
                    completed={value.completedObjectives}
                    handleCardClick={handleCardClick}
                    key={value.userId}
                    member={member}
                    myCard={value.userId === props.currentUserId}
                    overdue={value.overdueObjectives}
                    total={value.totalObjectives}
                    userEmail={value.userId === props.currentUserId ? props.currentUserEmail : value.email}
                    userId={value.userId}
                    userName={value.userId === props.currentUserId ? props.currentUserName : value.name}
                    userTeam={value.team}
                  />
                )
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <CreateObjectiveDialog
        currentUserId={props.currentUserId}
        currentUserName={props.currentUserName}
        currentUserTeam={props.currentUserTeam}
        initialValues={objectiveToEdit}
        isEditMode
        isTeamLead={props.isUserTeamLead}
        modalText="Complete the following form with new data for the selected objective"
        modalTitle="Edit objective"
        setVisible={setEditObjectiveModal}
        teamMembers={props.teamMembers}
        visible={editObjectiveModal}
      />

      <CreateKeyResultDialog
        currentUserId={props.currentUserId}
        deadline={keyResultInitialValues.deadline}
        initialValues={openConfirmationDialog.isEditModeKeyResult && {...keyResultInitialValues}}
        isEditMode={openConfirmationDialog.isEditModeKeyResult}
        modalText="Complete the following form with new data for the selected key Result"
        modalTitle="Edit Key Result"
        objectiveId={keyResultInitialValues.id}
        ownerId={keyResultInitialValues.ownerId}
        setVisible={value => {
          setAddKeyResultModal(value);
          setOpenConfirmationDialog({
            ...openConfirmationDialog,
            isEditModeKeyResult : false
          });
        }}
        visible={addKeyResultModal}
      />


      <ConfirmationDialog
        action={openConfirmationDialog.action}
        complete={() => completeAction(openConfirmationDialog.model, openConfirmationDialog.id)}
        deleteItem={() => deleteItem(openConfirmationDialog.model, openConfirmationDialog.id)}
        handleClose={() => setOpenConfirmationDialog({
          ...openConfirmationDialog,
          open : false
        })}
        model={openConfirmationDialog.model}
        open={openConfirmationDialog.open}
      />

    </Loader>
  );
};

Plans.displayName = 'Plans';
Plans.propTypes = {
  getUserPlans     : func.isRequired,
  currentUserName  : string.isRequired,
  currentUserEmail : string.isRequired,
  currentUserId    : string.isRequired,
  currentUserTeam  : string.isRequired,
  totalObjectives  : number.isRequired,
  isUserTeamLead   : bool.isRequired,
  isLoading        : bool.isRequired,
  deleteObjective  : func.isRequired,

  editObjective   : func.isRequired,
  editKeyResult   : func.isRequired,
  deleteKeyResult : func.isRequired,

  teamMembers       : oneOfType([array, object]).isRequired,
  plans             : oneOfType([array, object]).isRequired,
  user              : object.isRequired,
  editSuccessfull   : bool.isRequired,
  deleteSuccessfull : bool.isRequired

};

export default Plans;
