import StatisticsFilter from './StatisticsFilter';
import {connect} from 'react-redux';
import {getJobs} from 'selector/user';


const mapStateToProps = state => ({
  jobs : getJobs(state)
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsFilter);
