import {connect} from 'react-redux';
import {getUser, getPlans, getUserCapabilities, getAllUsers} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import SideMenu from './SideMenu';
import {getHasCallSucceeded} from 'selector/app';
import {apiRequestAction} from 'action/apiResource';
import {getJobs} from '../../store/selector/job';

const mapStateToProps = state => ({
  user                 : getUser(state),
  users                : getAllUsers(state),
  plans                : getPlans(state),
  updateUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.updateUser.requestTracker
  }),
  capabilities : getUserCapabilities(state),
  teams        : getTeams(state),
  jobs         : getJobs(state)
});

const mapDispatchToProps = {
  getUser     : apiRequestAction.user.getUser(),
  getAllUsers : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  }),
  getJobs : apiRequestAction.job.getJobs({
    skipEntityIdCheck : true
  }),
  getUserPlans : apiRequestAction.user.getUserPlans({
    skipEntityIdCheck : true
  }),
  getUserCapabilities : apiRequestAction.user.getUserCapabilities({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  }),
  getRoles : apiRequestAction.role.getRoles({
    skipEntityIdCheck : true
  }),
  getLinks : apiRequestAction.link.getLinks({
    skipEntityIdCheck : true
  }),
  getTeamLeader : apiRequestAction.user.getTeamLeader({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
