import {handleAction} from 'redux-actions';
import {setSearchInputValue} from '../action/setSearchInputValue';

const initialState = {
  searchInputValue : ''
};

export default handleAction(
  setSearchInputValue,
  (state, action) => ({
    ...state,
    searchInputValue : action.payload
  }),
  initialState
);
