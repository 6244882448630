import React from 'react';
import {object} from 'prop-types';
import map from 'lodash/map';
import moment from 'moment';
import {Table, TableHead, TableRow, TableCell, TableBody, TableContainer} from '@mui/material';
import './StatisticsLoginTable.scss';

const StatisticsLoginTable = ({statisticsData}) => {
  const limitForTable = 20;

  return (
    <div className="statistics-login-table">
      <h3>Statistics by login date</h3>
      <TableContainer sx={{maxHeight : 600}}>
        <Table
          aria-label="sticky table"
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell align="center">
                Team
              </TableCell>
              <TableCell align="center">
                Login time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              map(Object.values(statisticsData).slice(0, limitForTable), (row, index) => (
                <TableRow
                  key={row.name}
                  sx={{
                    '& > *' : {
                      backgroundColor : index % 2 === 0 ? '#f0f2f577' : 'white'
                    }}}
                >
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    {
                      Array.isArray(row.team) &&
                        row.team.length > 1 &&
                        row.team.toString().replaceAll(',', ', ') || row.team
                    }
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.lastLogin).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

StatisticsLoginTable.displayName = 'StatisticsLoginTable';
StatisticsLoginTable.propTypes = {
  statisticsData : object.isRequired
};
export default StatisticsLoginTable;
