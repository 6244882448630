/* eslint-disable id-length */
import XLSX from 'sheetjs-style';
import {boldHeaders} from '../../utils/utils';
import {map} from 'lodash';
import {formatDate} from '../../utils/user';
import moment from 'moment';

// eslint-disable-next-line complexity
const beingEmployee = hireDate => {
  const hireMoment = moment(hireDate);
  const currentMoment = moment();
  const years = currentMoment.diff(hireMoment, 'years');

  hireMoment.add(years, 'years');
  const months = currentMoment.diff(hireMoment, 'months');

  let timeString = '';

  if (years === 0) {
    if (months === 0) {
      timeString = 'This month';
    } else {
      timeString = `${months} month${months > 1 ? 's' : ''}`;
    }
  } else {
    timeString = `${years} year${years > 1 ? 's' : ''} & ${months} month${months > 1 ? 's' : ''}`;
  }

  return timeString;
};

// eslint-disable-next-line max-statements
export const exportToExcelTeam = (excelData, fileName) => {
  const wb = XLSX.utils.book_new();

  let columnNumberMainSheet = 0;

  const mainSheetData = map(excelData, user => {
    const userData = {
      Name         : user.name,
      Username     : user.username,
      Phone        : user.phone,
      Email        : user.email,
      Job          : user.job.name,
      Status       : user.status,
      Role         : user.role.name,
      Team         : map(Object.values(user.team), team => team.name).join(', '),
      PreviousTeam : map(Object.values(user?.previousTeam ?? {}), previousTeam => previousTeam?.name).join(', '),
      JoinDate     : formatDate(new Date(user.joinDate)),
      Since        : beingEmployee(user.joinDate)
    };


    return userData;
  });

  let header = Object.keys(mainSheetData[0]);

  columnNumberMainSheet += header.length;
  const mainSheet = XLSX.utils.json_to_sheet(mainSheetData);

  XLSX.utils.book_append_sheet(wb, mainSheet, 'Main Information');

  const bitstoneInfo = map(excelData, user => ({
    Benefits                : user.extras?.bitstoneInfo?.benefits?.join(', '),
    LearningAndDevelopement : user.extras?.bitstoneInfo?.learningAndDev,
    TeamBuildings           : user.extras?.bitstoneInfo?.teamBuildings,
    Holidays                : user.extras?.bitstoneInfo?.holidays
  })
  );

  XLSX.utils.sheet_add_json(mainSheet, bitstoneInfo, {skipHeader : false,
    origin     : {r : 0,
      c : columnNumberMainSheet}});

  const personalInfo = map(excelData, user => {
    const skills = user.extras?.personalInfo?.skills;
    const skillsDisplay = Array.isArray(skills) ? skills.map(skill => skill.name).join(', ') : '';

    return {
      OfficialName   : user.extras?.personalInfo?.officialName,
      Birthdate      : user.extras?.personalInfo?.birthdate ? formatDate(new Date(user.extras.personalInfo.birthdate)) : 'No data',
      Hometown       : user.extras?.personalInfo?.hometown,
      CurrentAddress : user.extras?.personalInfo?.currentAddress,
      Education      : user.extras?.personalInfo?.education?.join(', '),
      Skils          : skillsDisplay,
      Languages      : user.extras?.personalInfo?.languages?.join(', '),
      CurrentRole    : user.extras?.personalInfo?.currentRole,
      About          : user.extras?.personalInfo?.about,
      Hobbies        : user.extras?.personalInfo?.hobbies,
      IsVegan        : user.extras?.personalInfo?.isVegan
    };
  });

  header = Object.keys(bitstoneInfo[0]);
  columnNumberMainSheet += header.length;

  XLSX.utils.sheet_add_json(mainSheet, personalInfo, {skipHeader : false,
    origin     : {r : 0,
      c : columnNumberMainSheet}});

  mainSheet['!cols'] = Array(columnNumberMainSheet + Object.keys(personalInfo[0]).length).fill({wch : 20});

  boldHeaders(mainSheet, 'FFFF0000');

  const relevantExperience = map(excelData, user => {
    const {name, extras} = user;
    const relevantExperienceArr = extras?.relevantExperience || [];

    return map(relevantExperienceArr, exp => ({
      Name        : name,
      Company     : exp.company,
      Position    : exp.position,
      Description : exp.description,
      StartDate   : formatDate(new Date(exp.startDate)),
      EndDate     : exp.endDate === 'Present' ? 'Present' : formatDate(new Date(exp.endDate))
    }));
  }).flat();
  const relevantExperienceSheet = XLSX.utils.json_to_sheet(relevantExperience);

  XLSX.utils.book_append_sheet(wb, relevantExperienceSheet, 'Relevant Information');

  if (relevantExperience.length > 0) {
    relevantExperienceSheet['!cols'] = Array(Object.keys(relevantExperience[0])?.length).fill({wch : 20});
    boldHeaders(relevantExperienceSheet, 'FFFFFF00');
  }

  const projectsInfo = map(excelData, user => {
    const {name, extras} = user;
    const projectsInfoArr = extras?.projectsInfo || [];

    return map(projectsInfoArr, proj => ({
      Name             : name,
      Title            : proj.title,
      MainGoal         : proj.mainGoal,
      Responsabilities : proj.responsabilities,
      TechnicalSkills  : proj.technicalSkills
    }));
  }).flat();
  const projectsInfoSheet = XLSX.utils.json_to_sheet(projectsInfo);

  XLSX.utils.book_append_sheet(wb, projectsInfoSheet, 'Projects Experience');

  if (projectsInfo.length > 0) {
    projectsInfoSheet['!cols'] = Array(Object.keys(projectsInfo[0])?.length).fill({wch : 20});
    boldHeaders(projectsInfoSheet, 'FFFFFF00');
  }
  XLSX.writeFile(wb, fileName);
};
