import {all, put, takeEvery} from 'redux-saga/effects';
import {REQUEST_STATUS} from 'constant';

import {
  ActionTypes,
  apiRequestFailure,
  apiRequestSuccess,
  requestTracker
} from 'action/api';

import fetchRequest from 'service/api/Api';

export const getUniqId = payload => {
  if (!payload) {
    return undefined;
  }

  if (payload instanceof FormData) {
    return payload.get('uniqueId');
  }

  return payload.uniqueId;
};

export const apiRequestSaga = function* apiRequestSaga (action) {
  const {payload, meta} = action;

  try {
    yield put(requestTracker({
      meta,
      status   : REQUEST_STATUS.IN_PROGRESS,
      entityId : payload ? payload.entityId : undefined,
      uniqueId : getUniqId(payload)
    }));

    const response = yield fetchRequest({
      payload,
      meta
    });


    yield put(apiRequestSuccess({...meta})({
      data            : response.data,
      originalPayload : payload
    }));

    return yield response.data;
  } catch (error) {
    yield put(apiRequestFailure({...meta})({
      error,
      originalPayload : payload
    }));
  }

  return yield {};
};

export default function* apiSaga () {
  yield all([
    takeEvery(ActionTypes.API_REQUEST, apiRequestSaga)
  ]);
}
