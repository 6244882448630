import Dashboard from './Dashboard';
import {connect} from 'react-redux';
import {getLinks} from '../../store/selector/link';
import {getSearchInputValue} from '../../store/selector/searchInputValue';
import {apiRequestAction} from 'action/apiResource';

const mapStateToProps = state => ({
  links            : getLinks(state),
  searchInputValue : getSearchInputValue(state)
});


const mapDispatchToProps = {
  getLinks : apiRequestAction.link.getLinks({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
