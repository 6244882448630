import React, {useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {PRIORITY} from 'constant';
import {Modal} from 'components';
import {bool, func, object, string} from 'prop-types';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import {
  MenuItem,
  TextField,
  Button
} from '@mui/material';
import moment from 'moment';
import {map} from 'lodash-es';

const CreateKeyResultDialog = ({
  currentUserId,
  visible,
  addKeyResultToObjective,
  editKeyResult,
  setVisible,
  modalTitle,
  ownerId,
  objectiveId,
  objectivePriority,
  modalText,
  deadline,
  isEditMode,
  initialValues
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const validationSchema = yup.object({
    title       : yup.string().required('Title is required'),
    description : yup.string().required('Description is required'),
    priority    : yup.string().required('Priority is required'),
    deadline    : yup.string().required('Deadline is required')
  });

  const newKeyResultForm = useFormik({
    initialValues : {
      ...initialValues,
      ...objectivePriority && !isEditMode && {priority : objectivePriority},
      deadline : moment(initialValues.deadline).format('YYYY-MM-DD')
    },
    validationSchema,
    enableReinitialize : true,

    onSubmit : (values, {resetForm}) => {
      if (isEditMode) {
        editKeyResult({
          entityId : initialValues.id,
          ...values
        });

        resetForm({});
      } else {
        addKeyResultToObjective({
          ...values,
          ownerId,
          clientEntityId : objectiveId,
          authorId       : currentUserId
        });
        resetForm({});
      }

      setVisible(false);
    }
  });


  const renderModalActions = () => (
    <div className="form__actions">
      <Button
        color="primary" onClick={() => {
          setVisible(false);
          newKeyResultForm.resetForm({});
        }} size="small"
        variant="contained"
      > Cancel </Button>
      <Button
        color="secondary"
        onClick={() => newKeyResultForm.handleSubmit()}
        size="small"
        type="submit"
        variant="contained"
      > Save</Button>
    </div>
  );

  const getTextField = ({name, label, inputProps = {}, children, required = true, select = false, isDisabled = false, type = 'text', ...rest}) => (
    <TextField
      InputProps={inputProps}
      className="modal-forms-input"
      disabled={isDisabled}
      error={newKeyResultForm.touched[name] && Boolean(newKeyResultForm.errors[name])}
      helperText={newKeyResultForm.touched[name] && newKeyResultForm.errors[name]}
      id={name}
      label={label}
      name={name}
      onChange={newKeyResultForm.handleChange}
      required={required}
      select={select}
      style={{
        width  : 'calc(50% - 20px)',
        margin : '10px 10px'
      }}
      type={type}
      value={newKeyResultForm.values[name]}
      variant="standard"
      {...rest}
    >
      {children}
    </TextField>
  );

  const renderFormContent = () => (
    <form onSubmit={newKeyResultForm.handleSubmit}>
      {getTextField({
        name  : 'title',
        label : 'Title'
      })}
      {getTextField({
        name     : 'priority',
        label    : 'Priority',
        children : map(PRIORITY, keyPriority => (<MenuItem key={keyPriority} value={keyPriority}>{keyPriority}</MenuItem>)),
        select   : true
      })}
      <TextField
        error={newKeyResultForm.touched.description && Boolean(newKeyResultForm.errors.description)}
        fullWidth
        helperText={newKeyResultForm.touched.description && newKeyResultForm.errors.description}
        id="description"
        label="Description"
        maxRows={5}
        multiline
        name="description"
        onChange={newKeyResultForm.handleChange}
        required
        rows={3}
        style={{
          width  : 'calc(100% - 20px)',
          margin : '10px 10px'
        }}
        value={newKeyResultForm.values.description}
        variant="outlined"
      />

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          desktopModeMediaQuery="@media (pointer)"
          disabled={moment(deadline).isBefore(moment())}
          inputFormat="YYYY-MM-DD"
          inputProps={{readOnly : true}}
          label="Deadline"
          mask="____-__-__"
          maxDate={moment(deadline)}
          minDate={moment()}
          onChange={newValue => newKeyResultForm.handleChange({
            target : {
              name  : 'deadline',
              value : moment(newValue).format('YYYY-MM-DD')
            }
          })}
          onClose={() => setIsCalendarOpen(false)}
          open={isCalendarOpen}
          renderInput={params => getTextField({
            name            : 'deadline',
            label           : 'Deadline',
            type            : 'text',
            InputLabelProps : {shrink : true},
            onChange        : () => null,
            ...params,
            onClick         : () => !moment(deadline).isBefore(moment()) && setIsCalendarOpen(true)
          })}
          value={newKeyResultForm.values.deadline}
        />
      </LocalizationProvider>
    </form>
  );

  return (
    <Modal
      handleClose={() => setVisible(false)}
      modalActions={() => renderModalActions()}
      modalContent={() => renderFormContent()}
      open={visible}
      text={modalText}
      title={modalTitle}
    />
  );
};

CreateKeyResultDialog.displayName = 'CreateKeyResultDialog';
CreateKeyResultDialog.propTypes = {
  currentUserId           : string.isRequired,
  ownerId                 : string.isRequired,
  objectiveId             : string.isRequired,
  addKeyResultToObjective : func.isRequired,
  editKeyResult           : func.isRequired,
  visible                 : bool.isRequired,
  setVisible              : func.isRequired,
  deadline                : string.isRequired,
  modalText               : string.isRequired,
  modalTitle              : string.isRequired,
  initialValues           : object.isRequired,
  isEditMode              : bool,

  objectivePriority : string.isRequired
};

CreateKeyResultDialog.defaultProps = {
  initialValues : {
    title       : '',
    description : '',
    priority    : '',
    deadline    : moment().format('YYYY-MM-DD')
  },
  isEditMode : false,
  visible    : false
};

export default CreateKeyResultDialog;
