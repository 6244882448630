import React from 'react';
import {Loader, ActionBar, GanttChart} from 'components';

import {object, array, oneOfType, func, string, bool} from 'prop-types';
import size from 'lodash/size';
import './Timeline.scss';

const Timeline = props => {
  const [filters, setFilters] = React.useState(localStorage.getItem('timeline-page-filters') ? JSON.parse(localStorage.getItem('timeline-page-filters')) : {});

  React.useEffect(() => {
    if (props.isTeamLead || props.isOwner || props.isTeamsManager) {
      props.getObjectives({
        ...filters
      });
    } else if (props.currentUserId) {
      props.getObjectives({
        ownerId : props.currentUserId,
        ...filters
      });
    }
  }, [filters, props.isTeamLead, props.isOwner, props.isTeamsManager, props.currentUserId]);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (props.currentUserId && !props.isTeamLead && !props.isOwner && !props.isTeamsManager) {
      props.getTeamLead();
    }

    setFilters({
      ...filters,
      // eslint-disable-next-line no-nested-ternary
      ownerId  : localStorage.getItem('timeline-page-filters') ? // eslint-disable-line
      JSON.parse(localStorage.getItem('timeline-page-filters')).ownerId : // eslint-disable-line
        props.isOwner || props.isTeamsManager ? 'all' : props.currentUserId,
      authorId : localStorage.getItem('timeline-page-filters') ? JSON.parse(localStorage.getItem('timeline-page-filters')).authorId : 'all'
    });
  }, [props.currentUserId]);

  React.useEffect(() => {
    if (size(filters) > 0) {
      let filtersToApply = filters;

      for (let obj in filtersToApply) {
        if (filtersToApply[obj] === '') {
          delete filtersToApply[obj];
        }
      }
    }
  }, [filters]);

  React.useEffect(() => {
    localStorage.setItem('timeline-page-filters', JSON.stringify(filters));
  }, [filters]);


  return (
    <Loader isLoading={props.isLoading}>
      <div className="timeline-container">
        <ActionBar
          currentUserId={props.currentUserId}
          currentUserName={props.currentUserName}
          filters={filters}
          isNewObjectButtonHidden
          isTeamLead={props.isTeamLead}
          setFilters={value => setFilters(value)}
          teamLead={props.teamLeader}
          teamMembers={props.teamMembers}
        />

        <GanttChart objectives={props.objectives} />
      </div></Loader>
  );
};

Timeline.displayName = 'Timeline';
Timeline.propTypes = {
  objectives      : oneOfType([array, object]).isRequired,
  isLoading       : bool.isRequired,
  getObjectives   : func.isRequired,
  isTeamLead      : bool.isRequired,
  isTeamsManager  : bool.isRequired,
  isOwner         : bool.isRequired,
  currentUserName : string.isRequired,
  teamMembers     : oneOfType([array, object]).isRequired,
  teamLeader      : object.isRequired,
  currentUserId   : string.isRequired,
  getTeamLead     : func.isRequired
};
export default Timeline;
