import React, {useEffect, useState} from 'react';
import {func, object, string} from 'prop-types';
import {SideMenu} from '../../components';
import {Button} from 'primereact/button';
import moment from 'moment';
import {ENV_URL} from '../../constant';
import {isEmpty} from 'lodash';
import './Timetracking.scss';

const Timetracking = props => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [groupedTasks, setGroupedTasks] = useState([]);
  const [previousMonths, setPreviousMonths] = useState(0);
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(true);

  const groupByDate = taskss => taskss.reduce((acc, task) => {
    const dateString = moment(task.date).format('D, dddd');

    if (!acc[dateString]) {
      acc[dateString] = [];
    }
    acc[dateString].push(task);

    return acc;
  }, {});

  useEffect(() => {
    if (isEmpty(props.reports)) {
      props.getReports();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (props.reports !== null) {
      setLoading(false);
      if (Object.keys(props.reports).length > 0) {
        setReports(props.reports);
      }
    }
  }, [props.reports]);

  useEffect(() => {
    if (!isEmpty(reports)) {
      const temp = Object.values(reports.tasks);
      const groupedTasksTemp = groupByDate(temp);

      setGroupedTasks(groupedTasksTemp);
    }
  }, [reports]);

  const fetchReports = async monthOffset => {
    const apiUrl = `${ENV_URL.API_ENDPOINT}/timetracking/reports?months=${monthOffset}`;

    try {
      const response = await fetch(apiUrl, {
        method  : 'GET',
        headers : {
          'Content-Type' : 'application/json',
          Authorization  : `Bearer ${props.accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const reportsAll = data.data.timetracking;

      return reportsAll;
    } catch (error) {
      throw new Error(`Failed fetching reports: ${error.message}`);
    }
  };

  const handleMonthChange = direction => {
    setCurrentMonth(prevMonth => prevMonth.clone().add(direction, 'months'));
    const updatedMonths = previousMonths - direction;

    setPreviousMonths(updatedMonths);

    fetchReports(updatedMonths).then(reportsAll => setReports(reportsAll))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(`Failed fetching reports: ${error.message}`);
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-magic-numbers
  const getTotalHoursPerGroup = date => groupedTasks[date].reduce((total, task) => total + parseFloat(task.hours, 10), 0);

  if (loading) {
    return (
      <div className="loading">
        <span className="loader" />
      </div>
    );
  }

  return (
    <div className="timetracking-page">
      <div className="timetracking-container">
        <div className="timetracking__sideMenu">
          <SideMenu />
        </div>
        {!props.reports &&
        <section className="noinfo-bar">
          <p className="no-tasks">This user has not been added into Time tracking database. <br /> Please contact Isabela Suhan.</p>
        </section>
        }
        {reports &&
        <div className="timetracking__content">
          <section className="info-bar">
            <p><b>Today:</b> Hours are re-imported every 5 minutes.<b>This month:</b> Hours are re-imported every 1h.</p>
          </section>
          <section className="header">
            <div className="buttons">
              <Button className="action-btn" icon="pi pi-angle-left" onClick={() => handleMonthChange(-1)} />
              <Button className="action-btn" icon="pi pi-angle-right" onClick={() => handleMonthChange(1)} />
            </div>
            <div className="date-time">
              <p className="date">{currentMonth.format('MMMM YYYY')}</p>
              <p className="time">Total: {parseFloat(reports.hours)}h</p>
            </div>
          </section>
          {props.reports && Object.keys(reports).length > 0 &&
          <section className="tasks">
            {Object.keys(groupedTasks)
              .map(date => (
                <div className="grouped-tasks" key={date}>
                  <label>{date}</label>
                  {groupedTasks[date].map(task => (
                    <div className="task" key={task.id}>
                      <p >{task.summary}</p>
                      <p>{task.hours}h</p>
                    </div>
                  ))}
                  <p className="total">Total {getTotalHoursPerGroup(date)}h</p>
                </div>
              ))}
          </section>
          }
        </div>
        }
      </div>
    </div>
  );
};

Timetracking.displayName = 'Timetracking';
Timetracking.propTypes = {
  getReports  : func.isRequired,
  reports     : object.isRequired,
  accessToken : string.isRequired
};
export default Timetracking;
