import {ROUTE_PATH, ROLES} from 'constant';
import {Login, Timeline, CompletionApproval, ObjectiveDetails, Admin, Plans, Objectives, EmployeeDetails, Statistics, Approvals, Team, Dashboard, ViewHolidays, Timetracking,
  ProfilePage} from 'pages';

export const PRIVATE_ROUTES = [{
  component : CompletionApproval,
  path      : ROUTE_PATH.COMPLETIONAPPROVAL,
  exact     : true
},
{
  component : ProfilePage,
  path      : ROUTE_PATH.PROFILE + '/:id',
  exact     : true
},
{
  component : Plans,
  path      : ROUTE_PATH.PLANS,
  exact     : true
},
{
  component : Team,
  path      : ROUTE_PATH.TEAM,
  exact     : true
},
{
  component : Dashboard,
  path      : '/',
  exact     : true
},
{
  component : Dashboard,
  path      : ROUTE_PATH.DASHBOARD,
  exact     : true
},
{
  component : ViewHolidays,
  path      : ROUTE_PATH.HOLIDAYS,
  exact     : true
},
{
  component : Admin,
  path      : ROUTE_PATH.ADMIN_USERS,
  exact     : true,
  disallow  : [ROLES.USER]
},
{
  component : Timetracking,
  path      : ROUTE_PATH.TIMETRACKING,
  exact     : true
},
{
  component : ObjectiveDetails,
  path      : ROUTE_PATH.OBJECTIVE_DETAILS,
  exact     : true
},
{
  component : EmployeeDetails,
  path      : ROUTE_PATH.EMPLOYEE_DETAILS,
  exact     : true
},
{
  component : Objectives,
  path      : ROUTE_PATH.OBJECTIVES,
  exact     : true
},
{
  component : Timeline,
  path      : ROUTE_PATH.TIMELINE,
  exact     : true
},
{
  component : Statistics,
  path      : ROUTE_PATH.STATISTICS,
  disallow  : [ROLES.USER],
  exact     : true
},
{
  component : Approvals,
  path      : ROUTE_PATH.APPROVALS,
  disallow  : [ROLES.USER],
  exact     : true
}
];

export const PUBLIC_ROUTES = [{
  component : Login,
  path      : ROUTE_PATH.LOGIN,
  exact     : true
}
];
