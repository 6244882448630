/* eslint-disable max-lines */
import * as React from 'react';
import './EmployeeDetails.scss';
import {func, bool, object, oneOfType, string, array} from 'prop-types';
import {
  ConfirmationDialog,
  CreateEvaluationDialog,
  EvaluationFilter,
  If,
  Loader,
  Modal,
  OverviewCard
} from 'components';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  Typography,
  Paper} from '@mui/material';
import {DeleteTwoTone, EditTwoTone, VisibilityTwoTone} from '@mui/icons-material';
import {Box} from '@mui/system';
import map from 'lodash/map';
import ReactExport from 'react-data-export';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function EvaluationTable (props) {
  const Row = () => (
    <React.Fragment>
      {map(props.evaluation, evaluation => (
        <TableRow key={evaluation.id}>
          <TableCell align="center">
            <Typography variant="body2">{evaluation.targets}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2">{new Date(Date.parse(evaluation.createdAt)).toLocaleDateString('ro-RO')}</Typography>
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="upload picture"
              color="success"
              component="span"
              onClick={() =>
                props.setDialog({
                  open          : true,
                  modalText     : 'In this form you can view the following data about the evaluation',
                  modalTitle    : 'Evaluation',
                  initialValues : {
                    ...evaluation,
                    userId : evaluation.userId.id,
                    name   : evaluation.userId.name
                  },
                  isView : true
                })
              }
            >
              <VisibilityTwoTone color="secondary" />
            </IconButton>
            <IconButton
              aria-label="upload picture"
              color="info"
              component="span"
              disabled={!(props.isTeamLead || props.isTeamsManager)}
              onClick={() =>
                props.setDialog({
                  open          : true,
                  modalText     : 'Complete the following form to edit the evaluation',
                  modalTitle    : 'Evaluation',
                  initialValues : {
                    ...evaluation,
                    userId : evaluation.userId.id,
                    name   : evaluation.userId.name
                  },
                  isEdit : true
                })
              }
            >
              <EditTwoTone />
            </IconButton>
            <IconButton
              aria-label="upload picture"
              color="error"
              component="span"
              disabled={!(props.isTeamLead || props.isTeamsManager)}
              onClick={() =>
                props.setOpenConfirmationDialog({
                  open   : true,
                  action : 'delete',
                  model  : 'evaluation',
                  id     : evaluation.id
                })
              }
            >
              <DeleteTwoTone />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );

  Row.displayName = 'Row';
  Row.propTypes = {
    isTeamLead : bool,
    evaluation : oneOfType([array, object]).isRequired
  };
  Row.defaultProps = {isTeamLead : false};

  const [evaluationForExport, setEvaluationForExport] = React.useState([]);

  React.useEffect(() => {
    if (props.evaluation !== undefined) {
      const columns = [
        {title : 'Nr. crt.'},
        {title : 'Name'},
        {title : 'Technical expertise',
          width : {wch : 20}},
        {title : 'Teamwork'},
        {title : 'Flexibility'},
        {title : 'Results focus'},
        {title : 'Languages'},
        {title : 'Continual learning',
          width : {wch : 20}},
        {title : 'Safety focus'},
        {title : 'Communication',
          width : {wch : 20}},
        {title : 'Initiative'},
        {title : 'Open discussion',
          width : {wch : 20}},
        {title : 'Conclusion'},
        {title : 'Evaluation date'}
      ];

      const data = Object.values(props.evaluation).map((evaluation, index) => {
        const borderEvaluation = {style : {border : {bottom : {style : 'thin',
          color : {rgb : 'A3C3E1'}},
        right : {style : 'thin',
          color : {rgb : 'A3C3E1'}}},
        fill : {patternType : 'solid',
          fgColor     : {rgb : 'BDD4E7'}}}
        };

        const evaluationRow = [
          {value : index + 1 + '.',
            ...borderEvaluation},
          {value : evaluation.userId.name,
            ...borderEvaluation},
          {value : evaluation.technicalExpertise,
            ...borderEvaluation},
          {value : evaluation.teamwork,
            ...borderEvaluation},
          {value : evaluation.flexibility,
            ...borderEvaluation},
          {value : evaluation.resultsFocus,
            ...borderEvaluation},
          {value : evaluation.languages,
            ...borderEvaluation},
          {value : evaluation.continualLearning,
            ...borderEvaluation},
          {value : evaluation.safetyFocus,
            ...borderEvaluation},
          {value : evaluation.communication,
            ...borderEvaluation},
          {value : evaluation.initiative,
            ...borderEvaluation},
          {value : evaluation.openDiscussion,
            ...borderEvaluation},
          {value : evaluation.targets,
            ...borderEvaluation},
          {value : moment(evaluation.createdAt).format('DD-MM-YYYY'),
            ...borderEvaluation}
        ];

        return evaluationRow;
      });

      const plansToExport = [
        {
          columns,
          data
        }
      ];

      setEvaluationForExport(plansToExport);
    }
  }, [props.evaluation]);

  return (
    <div className="user-details__table">
      <TableContainer component={Paper}>
        <Table sx={{maxWidth : '1400px'}}>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{width : '50%'}}>
                <Typography style={{fontWeight : 500}}>Conclusion</Typography>
              </TableCell>
              <TableCell align="center" style={{width : '20%'}}>
                <Typography style={{fontWeight : 500}}>Evaluation date</Typography>
              </TableCell>
              <TableCell align="center" style={{width : '30%'}}>
                <Typography style={{fontWeight : 500}}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              isTeamLead={props.isTeamLead} // eslint-disable-line react/prop-types
              setOpenConfirmationDialog={props.setOpenConfirmationDialog}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <div className="user-details__export">
        <ExcelFile element={<Button color="secondary" size="small" variant="contained">Export</Button>} filename={'Evaluation_' + moment().format('YYYY-MM-DD_hh-mm-ss')}>
          <ExcelSheet dataSet={evaluationForExport} name="Evaluation" />
        </ExcelFile>
      </div>
    </div>
  );
}

EvaluationTable.displayName = 'EvaluationTable';
EvaluationTable.propTypes = {
  setOpenConfirmationDialog : func.isRequired,
  setDialog                 : func.isRequired,
  isTeamsManager            : bool.isRequired,
  evaluation                : oneOfType([array, object]).isRequired
};

const EmployeeDetails = ({isLoading, teamMembers, evaluation, getEvaluations, deleteEvaluation, deleteInProgress, createInProgress, editInProgress, ...props}) => {
  const [userId, setUserId] = React.useState('');
  const [editUserModal, setEditUserModal] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState(props.userDetails?.user?.team || []);
  const [lineManager, setLineManager] = React.useState(props.userDetails?.user?.isTeamsManager || false);

  React.useEffect(() => {
    setSelectedTeam(props.userDetails?.user?.team || []);
    setLineManager(props.userDetails?.user?.isTeamsManager || false);
  }, [editUserModal]);

  React.useEffect(() => {
    if (userId) {
      getEvaluations({userId});
    }
  }, [deleteInProgress, createInProgress, editInProgress, userId]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState({
    open : false
  });

  const [dialog, setDialog] = React.useState({
    open : false
  });

  const deleteItem = id => {
    deleteEvaluation({entityId : id});
  };

  React.useEffect(() => {
    if (props.location.search) {
      setUserId(props.location.search.split('id=')[1]);
    }
  }, [props.location]);

  React.useEffect(() => {
    if (userId) {
      props.getUserDetails({userId});
      props.getTeams({allTeams : true});
    }
  }, [userId]);

  // eslint-disable-next-line no-empty-function
  const handleCardClick = () => {};

  // eslint-disable-next-line no-empty-function
  const onEditUser = () => {
    props.editUser({
      userId        : props.userDetails.user.id,
      teams         : selectedTeam,
      isLineManager : lineManager
    });
  };

  React.useEffect(() => {
    if (props.addTeamUserSuccess) {
      setEditUserModal(false);
      props.getUserDetails({userId});
    }
  }, [props.addTeamUserSuccess]);

  return (
    <Loader isLoading={isLoading}>
      <div className="user">
        <div className="user-details">
          <Box sx={{flexGrow : 1}}>
            <Grid container spacing={2}>
              <Grid item md={9} xs={12}>
                <div className="evaluation">
                  {props.isTeamLead || props.isTeamsManager ? <EvaluationFilter createFunction={props.createEvaluation} teamMembers={teamMembers} /> : null}
                  <EvaluationTable
                    evaluation={evaluation}
                    isTeamLead={props.isTeamLead}
                    isTeamsManager={props.isTeamsManager}
                    setDialog={setDialog}
                    setOpenConfirmationDialog={setOpenConfirmationDialog}
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <If condition={!props.isLoading}>
                  <OverviewCard
                    completed={props.userDetails.completedObjectives}
                    handleCardClick={handleCardClick}
                    key={props.userDetails.user.userId}
                    myCard
                    overdue={props.userDetails.overdueObjectives}
                    total={props.userDetails.totalObjectives}
                    userEmail={props.userDetails.user.email}
                    userId={props.userDetails.user.userId}
                    userName={props.userDetails.user.name}
                    userTeam={props.userDetails.user.team}
                  />
                  {
                    props.isOwner &&
                      <div className="user-details__edit">
                        <button onClick={() => setEditUserModal(true)}> Edit user </button>
                      </div>
                  }
                </If>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <ConfirmationDialog
        action={openConfirmationDialog.action}
        deleteItem={() => deleteItem(openConfirmationDialog.id)}
        handleClose={() =>
          setOpenConfirmationDialog({
            ...openConfirmationDialog,
            open : false
          })
        }
        model={openConfirmationDialog.model}
        open={openConfirmationDialog.open}
      />
      <CreateEvaluationDialog
        currentUserId={null}
        currentUserName={null}
        initialValues={dialog.initialValues}
        isEditMode={dialog.isEdit}
        isTeamLead={false}
        isViewMode={dialog.isView}
        modalText={dialog.modalText}
        modalTitle={dialog.modalTitle}
        setVisible={() =>
          setDialog({
            ...dialog,
            open : false
          })
        }
        teamMembers={teamMembers}
        visible={dialog.open}
      />
      <Modal
        handleClose={() => setEditUserModal(false)}
        modalActions={() => (
          <div className="form__actions">
            <Button
              color="primary" onClick={() => {
                setEditUserModal(false);
              }} size="small"
              variant="contained"
            > Cancel </Button>
            <Button
              color="secondary"
              onClick={() => {
                onEditUser();
              }}
              size="small"
              type="submit"
              variant="contained"
            > Save </Button>
          </div>
        )}
        modalContent={() => (
          <div className="user-details__edit--modal">
            <div className="manager">
              <label> Is this user line manager? </label>
              <input
                checked={lineManager}
                onChange={event => setLineManager(event.target.checked)}
                type="checkbox"
              />
            </div>

            <div className="teams">
              <div className="teams__select">
                <Select
                  displayEmpty
                  id="demo-simple-select"
                  label="Teams"
                  multiple
                  onChange={({target: {value}}) => setSelectedTeam(value)}
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Select Teams</em>;
                    }

                    return selected.join(', ');
                  }}
                  value={selectedTeam}
                >
                  <MenuItem disabled value="">
                    <em>Select teams</em>
                  </MenuItem>
                  {
                    map(props.teams, team => (
                      <MenuItem key={team} value={team}> { team }</MenuItem>
                    ))
                  }
                </Select>
              </div>
              <p className="error"> { props.addTeamError }</p>
            </div>
          </div>
        )}
        open={editUserModal}
        title={`Edit user ${props.userDetails.user.name}`}
      />
    </Loader>
  );
};

EmployeeDetails.propTypes = {
  location         : object.isRequired,
  getUserDetails   : func.isRequired,
  userDetails      : object.isRequired,
  addTeamError     : string.isRequired,
  createEvaluation : func.isRequired,
  getEvaluation    : func.isRequired,
  deleteEvaluation : func.isRequired,
  editUser         : func.isRequired,
  getTeams         : func.isRequired,
  getUserPlans     : func.isRequired,
  teamMembers      : oneOfType([array, object]).isRequired,
  teams            : array.isRequired,
  evaluation       : oneOfType([array, object]).isRequired,
  isLoading        : bool.isRequired,
  getEvaluations   : func.isRequired,
  createInProgress : bool.isRequired,
  editInProgress   : bool.isRequired,
  deleteInProgress : bool.isRequired,
  isTeamLead       : bool.isRequired,
  isOwner          : bool.isRequired,
  isTeamsManager   : bool.isRequired,

  addTeamUserSuccess : bool.isRequired
};
EmployeeDetails.displayName = 'EmployeeDetails';

export default EmployeeDetails;
