import {buildActionType} from 'action/apiResource';
import {getParts, API_ENDPOINT} from 'constant';
import {handleActions} from 'redux-actions';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';

const initialState = {};

const GET_EVALUATION = buildActionType(getParts(API_ENDPOINT.EVALUATION.GET_EVALUATION));
const GET_EVALUATIONS = buildActionType(getParts(API_ENDPOINT.EVALUATION.GET_EVALUATIONS));

const getEvaluationReducer = (state, {payload: {data : {evaluation}}}) => ({
  ...state,
  evaluation
});

// eslint-disable-next-line arrow-body-style
const getEvaluationsReducer = (state, {payload : {data : {userEvaluations}}}) => {
  return {
    ...state,
    // eslint-disable-next-line object-shorthand
    userEvaluations : userEvaluations};
};

const cleanupReducer = () => initialState;

const evaluationReducer = handleActions({
  [GET_EVALUATION]             : getEvaluationReducer,
  [GET_EVALUATIONS]            : getEvaluationsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default evaluationReducer;
