/* eslint-disable complexity */
import React, {useState, useEffect, useRef, useMemo} from 'react';
import {func, bool, object, oneOfType, array} from 'prop-types';
import {EmployeeCard, Filter, UserModal, SideMenu} from 'components';
import {map, filter, isEmpty} from 'lodash';
import {userCan, emptyUser} from '../../utils/user';
import {Toast} from 'primereact/toast';
import {CAPABILITIES} from '../../constant';
import {AddMemberButton, ExportButton} from '../../components/StyledButtons/StyledButtons';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus-icon-black.svg';
import {ReactComponent as ExportIcon} from '../../assets/icons/export-icon.svg';
import {useScroll} from '../../utils/scrollEffects';
import {exportToExcelTeam} from './exportToExcelTeam';
import ScrollButton from '../../utils/scrollButton';
import './Team.scss';


/**
 * Super awesome team page
 */
// eslint-disable-next-line max-statements
const Team = props => {
  const toast = useRef(null);
  const [filteredMembers, setFilteredMembers] = useState(null);
  const [currentTeamFilter, setCurrentTeamFilter] = useState(null);
  const [currentJobFilter, setCurrentJobFilter] = useState(null);
  const [currentProjectFilter, setCurrentProjectFilter] = useState(null);
  const [showTeamleads, setShowTeamleads] = useState(false);
  const [statusFilter, setStatusFilter] = useState('active');
  const [submitted, setSubmitted] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const userDivRef = useRef(null);

  const HIDE_FILTERS = 500;
  const filterVisibility = useScroll(HIDE_FILTERS, userDivRef);

  useEffect(() => {
    if (isEmpty(props.jobs)) {
      props.getJobs();
    }
    if (isEmpty(props.teams)) {
      props.getTeams();
    }
    if (isEmpty(props.roles)) {
      props.getRoles();
    }
    if (isEmpty(props.users)) {
      props.getAllUsers();
    }
    if (isEmpty(props.projects)) {
      props.getProjectsTitle();
    }
    if (isEmpty(props.teamLeads)) {
      props.getTeamleads();
    }
  }, []);

  useEffect(() => {
    const shouldFetch = [props.deleteUserSuccessful, props.editUserSuccessful, props.restoreUserSuccessful, props.addUserSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getAllUsers();
    }
  }, [props.deleteUserSuccessful, props.editUserSuccessful, props.restoreUserSuccessful, props.addUserSuccessful]);

  useMemo(() => {
    if (!isEmpty(props.users)) {
      const activeUsers = filter(props.users, member => member.status === 'active');

      setFilteredMembers(activeUsers);
    }
  }, [props.users]);

  useEffect(() => {
    const filterAll = filter(props.users, member => member.status === 'active');

    const search = filter(filterAll, member =>
      member.name.toLowerCase().includes(props.searchInputValue.searchInputValue.toLowerCase()));

    setFilteredMembers(search);
  }, [props.searchInputValue]);

  const filterOptions = {
    currentJobFilter,
    currentTeamFilter,
    currentProjectFilter,
    showTeamleads,
    statusFilter,
    setCurrentJobFilter,
    setCurrentTeamFilter,
    setCurrentProjectFilter,
    setStatusFilter,
    setShowTeamleads
  };

  const openNewUser = () => {
    setUserDialog(true);
  };

  const userProps = {
    userInfo : emptyUser
  };

  if (isEmpty(props.users)) {
    return (<div className="loading">
      <span className="loader" />
    </div>);
  }

  return (
    <div className="team">
      <div className="h-full w-screen flex team-container">
        <Toast ref={toast} />
        <div className="hidden md:block team__sideMenu">
          <SideMenu />
        </div>
        <div className="max-h-full w-screen team__main">
          <div className="team__main-top">
            <div className="text-container">
              <p>Super-Awesome Team</p>
            </div>
            <div className="buttons-container">
              <div className="buttons">
                <ExportButton
                  className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.EXPORT_USERS.key) ? 'export-btn' : 'hidden'}
                  onClick={() => exportToExcelTeam(filteredMembers, 'Bistone Users Information.xlsx')} startIcon={<ExportIcon />}
                >Export to excel</ExportButton>
                <AddMemberButton
                  className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.ADD_USERS.key) ? 'add-btn' : 'hidden'}
                  onClick={openNewUser} startIcon={<PlusIcon />}
                >Add member</AddMemberButton>
              </div>
            </div>
          </div>
          {!filterVisibility &&
          <div className={`team__main-filters ${filterVisibility ? 'hidden' : ''}`}>
            <Filter
              filterOptions={filterOptions}
              filteredMembers={filteredMembers}
              setFilteredMembers={setFilteredMembers}
            />
          </div>
          }
          <div className="team__main-users" ref={userDivRef}>
            {map(filteredMembers, member =>
              <EmployeeCard
                id={member.id}
                key={member.id}
                member={member}
              />
            )}
          </div>
          <ScrollButton />
        </div>
        {userDialog === true &&
        <UserModal
          setSubmitted={setSubmitted}
          setUserDialog={setUserDialog}
          submitted={submitted}
          userDialog={userDialog}
          userProps={userProps}
        />
        }
      </div>
    </div>

  );
};

Team.displayName = 'Team';
Team.propTypes = {
  users                 : object.isRequired,
  roles                 : object.isRequired,
  teams                 : object.isRequired,
  jobs                  : object.isRequired,
  capabilities          : oneOfType([array, object]).isRequired,
  projects              : oneOfType([array, object]).isRequired,
  teamLeads             : oneOfType([array, object]).isRequired,
  getTeamleads          : func.isRequired,
  getAllUsers           : func.isRequired,
  getProjectsTitle      : func.isRequired,
  getRoles              : func.isRequired,
  getJobs               : func.isRequired,
  getTeams              : func.isRequired,
  deleteUserSuccessful  : bool.isRequired,
  restoreUserSuccessful : bool.isRequired,
  editUserSuccessful    : bool.isRequired,
  addUserSuccessful     : bool.isRequired,
  searchInputValue      : object.isRequired
};
export default Team;
