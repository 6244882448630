import * as React from 'react';
import {ObjectiveCard, ActionBar, Loader, If} from 'components';
import map from 'lodash/map';
import filter from 'lodash/filter';
import camelCase from 'lodash/camelCase';
import size from 'lodash/size';
import Pagination from '@mui/material/Pagination';
import {func, array, object, string, bool, number, oneOfType} from 'prop-types';
import './Objectives.scss';

const PRIVACY_LABEL = {
  public  : 'Show mine',
  private : 'Show public'
};

const Objectives = props => {
  const [pagination, setPagination] = React.useState({
    page  : 1,
    limit : 9
  });
  const [maxPages, setMaxPages] = React.useState(1);
  const [showPublicText, setshowPublicText] = React.useState(JSON.parse(localStorage.getItem('objectives-page-filters'))?.privacy ? PRIVACY_LABEL.public : PRIVACY_LABEL.private);
  const [sortCriterion, setSortCriterion] = React.useState(localStorage.getItem('sortCriterion') ? localStorage.getItem('sortCriterion') : 'deadline');
  const [filters, setFilters] = React.useState(localStorage.getItem('objectives-page-filters') ? JSON.parse(localStorage.getItem('objectives-page-filters')) : {});

  React.useEffect(() => {
    props.clearApiResponseErrors();
  }, []);

  React.useEffect(() => {
    localStorage.setItem('sortCriterion', sortCriterion);
    props.getObjectives({
      ownerId : props.isOwner || props.isUserTeamLead || props.isTeamsManager ? 'all' : props.currentUserId,
      ...pagination,
      ...filters,
      sortCriterion
    });
  }, [sortCriterion]);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (props.currentUserId && !props.isUserTeamLead && !props.isOwner && !props.isTeamsManager) {
      props.getTeamLead();
    } else if (props.isUserTeamLead || props.isOwner || props.isTeamsManager) {
      props.getTeamMembers();
    }

    setFilters({
      ...filters,
      // eslint-disable-next-line no-nested-ternary
      ownerId  : localStorage.getItem('objectives-page-filters') ? // eslint-disable-line
      JSON.parse(localStorage.getItem('objectives-page-filters')).ownerId : // eslint-disable-line
        props.isUserTeamLead || props.isOwner || props.isTeamsManager ? 'all' : props.currentUserId, // eslint-disable-line max-len
      authorId : localStorage.getItem('objectives-page-filters') ? JSON.parse(localStorage.getItem('objectives-page-filters')).authorId : 'all'
    });
  }, [props.currentUserId, props.editUserSuccessfull]);

  React.useEffect(() => {
    if (props.totalObjectives > 0) {
      setMaxPages(Math.ceil(props.totalObjectives / pagination.limit));
    } else {
      setMaxPages(1);
    }
  }, [props.totalObjectives]);

  React.useEffect(() => {
    if (size(filters) > 0) {
      let filtersToApply = filters;

      for (let obj in filtersToApply) {
        if (filtersToApply[obj] === '') {
          delete filtersToApply[obj];
        }
      }

      if (props.currentUserId) {
        props.getObjectives({
          ...filtersToApply,
          ...pagination,
          sortCriterion
        });
      }
    }
  }, [filters, pagination]);

  React.useEffect(() => { //eslint-disable-line
    if (props.createObjectiveSuccessfull || props.addKeySuccessfull) {
      setPagination({
        page  : 1,
        limit : pagination.limit
      });

      setFilters({
        //  eslint-disable-next-line no-nested-ternary
        ownerId  : localStorage.getItem('objectives-page-filters') ? // eslint-disable-line
        JSON.parse(localStorage.getItem('objectives-page-filters')).ownerId : // eslint-disable-line
          props.isUserTeamLead || props.isOwner || props.isTeamsManager ? 'all' : props.currentUserId, // eslint-disable-line max-len
        authorId : localStorage.getItem('objectives-page-filters') ? JSON.parse(localStorage.getItem('objectives-page-filters')).authorId : 'all',
        status   : ''
      });
    }
  }, [props.createObjectiveSuccessfull, props.addKeySuccessfull]);

  React.useEffect(() => {
    if (props.editObjectiveSuccessfull || props.editUserSuccessfull) {
      props.getObjectives({
        ownerId : props.isOwner || props.isUserTeamLead || props.isTeamsManager ? 'all' : props.currentUserId,
        ...pagination,
        ...filters,
        sortCriterion
      });
    }
  }, [props.editObjectiveSuccessfull, props.editUserSuccessfull]);

  React.useEffect(() => {
    localStorage.setItem('objectives-page-filters', JSON.stringify(filters));
  }, [filters]);

  const handlePublicObjectives = () => {
    if (showPublicText === PRIVACY_LABEL.private) {
      setshowPublicText(PRIVACY_LABEL.public);
      setFilters({
        ownerId  : 'all',
        authorId : 'all',
        privacy  : true
      });
    } else {
      setshowPublicText(PRIVACY_LABEL.private);
      setFilters({
        ownerId  : props.isUserTeamLead || props.isOwner || props.isTeamsManager ? 'all' : props.currentUserId,
        authorId : 'all'
      });
    }
  };

  const includesTeam = (teams, team) => filter(teams, item => camelCase(item).toLowerCase() === camelCase(team).toLowerCase()).length;

  // eslint-disable-next-line
  const getPrivileges = objective => {
    if (props.isOwner) {
      return true;
    } else if (!props.isUserTeamLead && !props.isTeamsManager) {
      if (props.currentUserId !== objective.ownerId.id) {
        return true;
      }
    } else if (props.isTeamsManager) {
      if (includesTeam(props.currentUserTeam, objective.ownerId.team).length === 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <Loader isLoading={props.isLoading}>
      <div className="objectives-page" id="Objectives">
        <ActionBar
          currentUserId={props.currentUserId}
          currentUserName={props.currentUserName}
          currentUserTeam={props.currentUserTeam}
          filters={filters}
          handlePublicObjectives={() => handlePublicObjectives()}
          isPublicSet={showPublicText === PRIVACY_LABEL.public}
          isTeamLead={props.isUserTeamLead}
          setFilters={value => setFilters(value)}
          setSortCriterion={setSortCriterion}
          showPublicText={showPublicText}
          sortCriterion={sortCriterion}
          teamLead={props.teamLeader}
          teamMembers={props.teamMembers}
        />
        <If
          condition={size(props.objectives) > 0}
          otherwise={() => (
            <div className="no-data-found">
              <span>No items found</span>
            </div>
          )}
        >
          {props.errors ? <p className="messages--error">{props.errors}</p> : null}
          <div className="objectives-list">
            {
              map(props.objectives, objective => (
                <div className="objectives-list__item" key={objective.id}>

                  <ObjectiveCard
                    {...objective}
                    currentUserId={props.currentUserId}
                    currentUserName={props.currentUserName}
                    currentUserTeam={props.currentUserTeam}
                    deleteObjective={value => props.deleteObjective({...value})}
                    editObjective={value => props.editObjective({...value})}
                    isOwner={props.isOwner}
                    isReadOnly={getPrivileges(objective)}
                    isTeamLead={props.isUserTeamLead}
                    isTeamsManager={props.isTeamsManager}
                    teamMembers={props.teamMembers}
                  />
                </div>
              ))
            }
          </div>
        </If>

        <div className="objectives-pagination">
          <Pagination
            count={maxPages}
            onChange={(_, page) => setPagination({
              ...pagination,
              page
            })}
            page={pagination.page}
            variant="standard"
          />
        </div>
      </div>
    </Loader>
  );
};

Objectives.displayName = 'Objectives';
Objectives.propTypes = {
  getObjectives          : func.isRequired,
  getTeamLead            : func.isRequired,
  editObjective          : func.isRequired,
  errors                 : string.isRequired,
  deleteObjective        : func.isRequired,
  clearApiResponseErrors : func.isRequired,
  getTeamMembers         : func.isRequired,
  currentUserName        : string.isRequired,
  currentUserId          : string.isRequired,
  currentUserTeam        : string.isRequired,
  teamLeader             : object.isRequired,
  totalObjectives        : number.isRequired,
  isUserTeamLead         : bool.isRequired,
  isTeamsManager         : bool.isRequired,
  isOwner                : bool.isRequired,
  isLoading              : bool.isRequired,
  teamMembers            : oneOfType([array, object]).isRequired,
  objectives             : oneOfType([array, object]).isRequired,


  editObjectiveSuccessfull   : bool.isRequired,
  createObjectiveSuccessfull : bool.isRequired,
  addKeySuccessfull          : bool.isRequired,
  editUserSuccessfull        : bool.isRequired
};
export default Objectives;
