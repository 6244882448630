import Statistics from './Statistics';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getStatisticsData} from 'selector/statistics';
import {isCurrentUserTeamLead, isCurrentUserOwner, getUserTeam} from 'selector/user';
import {getIsCallInProgress} from 'selector/app';
const mapStateToProps = state => ({
  statisticsData  : getStatisticsData(state),
  isTeamLead      : isCurrentUserTeamLead(state),
  isOwner         : isCurrentUserOwner(state),
  currentUserTeam : getUserTeam(state),
  isLoading       : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.statistics.getStatisticsData.requestTracker
  })
});

const mapDispatchToProps = {
  getStatisticsData : apiRequestAction.statistics.getStatisticsData({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
