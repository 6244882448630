// Util. functions for user

import {ENV_URL} from '../constant';

/**
 * Checks if the logged user has a specific capability
 * avoids duplicated code
 * @param {Array} capabilities
 * @param {String} capability
 * @returns {boolean}
 */
export const userCan = (capabilities, capability) => {
  if (capabilities?.length > 0 && capabilities.includes(capability)) {
    return true;
  }

  return false;
};

/**
 * Set a date to a speficific format
 * @param {Date} value
 * @returns {String}
 */
export const formatDate = value => value.toLocaleDateString('en-GB', {
  day   : '2-digit',
  month : '2-digit',
  year  : 'numeric'
});

export const formatDateRelevantExpUI = value => {
  const options = {year  : 'numeric',
    month : 'short'};

  return value.toLocaleString('en-US', options).replace(',', '');
};
export const formatOnlyMonthDate = value => {
  const options = {
    month : 'long'};

  return value.toLocaleString('en-US', options).replace(',', '');
};
export const formatOnlyYearDate = value => {
  const options = {year : 'numeric'};

  return value.toLocaleString('en-US', options).replace(',', '');
};

/**
 * Used when creating a new user
 */
export const emptyUser = {
  id           : null,
  name         : '',
  username     : '',
  role         : '',
  phone        : '',
  email        : '',
  joinDate     : '',
  job          : '',
  status       : '',
  team         : '',
  previousTeam : ''
};

export const getUserImageUrl = member => {
  if (member.imagePath.startsWith('https')) {
    return member.imagePath;
  }

  return `${ENV_URL.ENV_URL}${member.imagePath}`;
};
