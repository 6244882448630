import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_REPORTS = buildActionType(getParts(API_ENDPOINT.TIMETRACKING.GET_REPORTS));

const setReportsReducer = (state, {payload: {data : {timetracking}}}) => ({
  ...state,
  timetracking
});

const cleanupReducer = () => initialState;

const timetrackingReducer = handleActions({
  [GET_REPORTS]                : setReportsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default timetrackingReducer;
