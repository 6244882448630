// https://github.com/douglascrockford/JSON-js/blob/master/json2.js#478
const rxOne = /^[\],:{}\s]*$/;
const rxTwo = /\\(?:["\\/bfnrt]|u[0-9a-fA-F]{4})/g;
const rxThree = /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g;
const rxFour = /(?:^|:|,)(?:\s*\[)+/g;
const isJSON = input => input.length && rxOne.test(
  input.replace(rxTwo, '@')
    .replace(rxThree, ']')
    .replace(rxFour, '')
);

export default isJSON;
