import TeamsTable from './TeamsTable';
import {connect} from 'react-redux';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress} from 'selector/app';
import {getAllUsers, getUserCapabilities} from 'selector/user';
import {getTeams} from '../../store/selector/team';

const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  capabilities : getUserCapabilities(state),
  teams        : getTeams(state),
  users        : getAllUsers(state)
});

const mapDispatchToProps = {
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  }),
  updateTeam : apiRequestAction.team.updateTeam({
    skipEntityIdCheck : true
  }),
  createTeam : apiRequestAction.team.createTeam({
    skipEntityIdCheck : true
  }),
  deleteTeam : apiRequestAction.team.deleteTeam({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTable);
