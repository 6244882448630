import ProfilePage from './ProfilePage';
import {connect} from 'react-redux';
import {getHasCallSucceeded} from 'selector/app';
import {apiRequestAction} from 'action/apiResource';
import {getUser, getAllUsers, getUserCapabilities, getAllTeamLeads} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getRoles} from '../../store/selector/role';
import {getJobs} from '../../store/selector/job';
import {getSkills} from '../../store/selector/skill';
import {getAllDaysLeft} from '../../store/selector/holidays';
import {getSearchInputValue} from '../../store/selector/searchInputValue';
import {getAccessToken} from '../../store/selector/auth';

const mapStateToProps = state => ({
  getLoggedUser      : getUser(state),
  users              : getAllUsers(state),
  capabilities       : getUserCapabilities(state),
  jobs               : getJobs(state),
  skills             : getSkills(state),
  teams              : getTeams(state),
  roles              : getRoles(state),
  teamLeads          : getAllTeamLeads(state),
  accessToken        : getAccessToken(state),
  editUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.editUser.requestTracker
  }),
  updateUserImageSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.updateUserImage.requestTracker
  }),
  deleteUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.deleteUser.requestTracker
  }),
  restoreUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.restoreUser.requestTracker
  }),
  allDaysLeft      : getAllDaysLeft(state),
  searchInputValue : getSearchInputValue(state)
});

const mapDispatchToProps = {
  downloadFile : apiRequestAction.user.downloadFile({
    skipEntityIdCheck : true
  }),
  getTeamleads : apiRequestAction.user.getTeamleads(),
  getAllUsers  : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  }),
  editUser : apiRequestAction.user.editUser({
    skipEntityIdCheck : true
  }),
  updateUserImage : apiRequestAction.user.updateUserImage({
    skipEntityIdCheck : true
  }),
  deleteUser : apiRequestAction.user.deleteUser({
    skipEntityIdCheck : true
  }),
  restoreUser : apiRequestAction.user.restoreUser({
    skipEntityIdCheck : true
  }),
  getJobs : apiRequestAction.job.getJobs({
    skipEntityIdCheck : true
  }),
  getSkills : apiRequestAction.skills.getSkills({
    skipEntityIdCheck : true
  }),
  getUserPlans : apiRequestAction.user.getUserPlans({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  }),
  getRoles : apiRequestAction.role.getRoles({
    skipEntityIdCheck : true
  }),
  getLinks : apiRequestAction.link.getLinks({
    skipEntityIdCheck : true
  }),
  getTeamLeader : apiRequestAction.user.getTeamLeader({
    skipEntityIdCheck : true
  }),
  getAllDaysLeft : apiRequestAction.holidays.getAllDaysLeft({
    skipEntityIdCheck : true
  }),
  getProjectsTitle : apiRequestAction.user.getProjectsTitle({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
