/* eslint-disable complexity */
import React, {useState, useRef} from 'react';
import {array, func, object} from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {EditButton} from '../../components/StyledButtons/StyledButtons';
import {ReactComponent as EditIcon} from '../../assets/icons/edit-button-icon-grey.svg';
import {Dropdown} from 'primereact/dropdown';
import {ENV_URL} from 'constant';
import {classNames} from 'primereact/utils';
import {showSuccessfulToast} from '../../utils/utils';
import {dialogFooter, header, rowsPerPageOptions} from '../RolesTable/utils';
import './LinksTable.scss';

// eslint-disable-next-line max-statements
const LinksTable = props => {
  const emptyLink = {
    id          : null,
    name        : '',
    picture     : '',
    url         : '',
    description : '',
    status      : '',
    category    : ''
  };

  const [globalFilter, setGlobalFilter] = useState(null);
  const [openEditLinkModal, setOpenEditLinkModal] = useState(false);
  const [openAddLinkModal, setOpenAddLinkModal] = useState(false);
  const [deleteLinkDialog, setDeleteLinkDialog] = useState(false);
  const [restoreLinkDialog, setRestoreLinkDialog] = useState(false);
  const [link, setLink] = useState(emptyLink);
  const [submitted, setSubmitted] = useState(false);
  const fileInput = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [image, setImage] = useState(null);
  const toast = useRef(null);

  const getLinkImageUrl = currentLink => `${ENV_URL.ENV_URL}${currentLink.picture}`;

  const openNewLink = () => {
    setLink(emptyLink);
    setImage(null);
    setImageUrl('');
    setSubmitted(false);
    setOpenAddLinkModal(true);
  };

  const editLink = currentLink => {
    setLink({...currentLink});
    setImageUrl(getLinkImageUrl(currentLink));
    setOpenEditLinkModal(true);
  };

  const saveLink = async () => {
    setSubmitted(true);
    if (link.name.trim() && link.category) {
      if (image) {
        const formData = new FormData();

        formData.append('image', image);
        formData.append('link', JSON.stringify(link));
        await props.updateLinkPicture(formData);
      }

      if (link.id) {
        await props.updateLink({
          _id         : link.id,
          name        : link.name,
          description : link.description,
          status      : link.status,
          category    : link.category,
          url         : link.url
        });

        setOpenEditLinkModal(false);
      } else {
        await props.createLink({
          name        : link.name,
          description : link.description,
          status      : link.status,
          category    : link.category,
          url         : link.url
        });
        setOpenAddLinkModal(false);
      }
      setLink(emptyLink);
      showSuccessfulToast(toast, 'Link updated');
    }
  };

  const deleteLink = async () => {
    await props.deleteLink({id : link.id});

    setDeleteLinkDialog(false);
    setLink(emptyLink);
    showSuccessfulToast(toast, 'Link deleted');
  };

  const restoreLink = async () => {
    await props.restoreLink({id : link.id});

    setRestoreLinkDialog(false);
    setLink(emptyLink);
    showSuccessfulToast(toast, 'Link restored');
  };

  const onInputChange = (event, name) => {
    const val = event.target && event.target.value || '';

    let _link = {...link};

    _link[`${name}`] = val;
    setLink(_link);
  };

  const handleSearchInput = event => {
    setGlobalFilter(event.target.value);
  };

  const handleImageChange = () => {
    const file = fileInput.current.files[0];

    setImage(file);
  };

  const hideEditLinkDialog = () => {
    setOpenEditLinkModal(false);
  };

  const hideNewLinkDialog = () => {
    setOpenAddLinkModal(false);
  };

  const hideDeleteLinkDialog = () => {
    setDeleteLinkDialog(false);
  };

  const hideRestoreLinkDialog = () => {
    setRestoreLinkDialog(false);
  };

  const confirmDeleteLink = currLink => {
    setLink(currLink);
    setDeleteLinkDialog(true);
  };

  const confirmRestoreLink = currLink => {
    setLink(currLink);
    setRestoreLinkDialog(true);
  };

  const actionBodyTemplate = rowData => (
    <>
      <Button
        className="p-button-rounded p-button-success mr-2" icon="pi pi-pencil"
        onClick={() => editLink(rowData)}
      />

      { rowData.status === 'active' &&
      <Button
        className="p-button-rounded p-button-danger" icon="pi pi-trash"
        onClick={() => confirmDeleteLink(rowData)}
      />
      }
      { rowData.status === 'deleted' &&
      <Button
        className="p-button-rounded p-button-warning" icon="pi pi-refresh"
        onClick={() => confirmRestoreLink(rowData)}
      />
      }
    </>
  );

  const descriptionBodyTemplate = rowData => {
    const maxLength = 100;

    if (!rowData.description) {
      return 'This link has no description';
    }

    return rowData.description.length > maxLength ? `${rowData.description.substring(0, maxLength)}...` : rowData.description;
  };

  const nameBodyTemplate = rowData => (
    <>
      {rowData &&
      <div className="flex align-items-center gap-2">
        <img
          alt={rowData.name} className={`logo logo-${rowData.name}`} src={`${ENV_URL.ENV_URL}${rowData.picture}`}
          style={{width       : '25px',
            marginRight : '5px'}}
        />
        <span>{rowData.name}</span>
      </div>
      }
    </>
  );

  return (
    <div className="datatable-crud-links">
      <div className="links-container">
        <Toast ref={toast} />
        <DataTable
          className="links-table"
          columnResizeMode="fit"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} links" dataKey="id"
          globalFilter={globalFilter}
          header={header(openNewLink, handleSearchInput)}
          paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={5}
          rowsPerPageOptions={rowsPerPageOptions}
          showGridlines
          value={Object.values(props?.links).flatMap(item => Object.values(item.values))
            .flat()}
        >
          <Column
            body={nameBodyTemplate} field="name" header="Name"
            sortable
            style={{width : '25%'}}
          />
          <Column
            field="category" header="Category" sortable
            style={{width : '25%'}}
          />
          <Column
            body={descriptionBodyTemplate} field="description" header="Description"
            style={{width : '25%'}}
          />
          <Column
            field="status" header="Status" sortable
            style={{width : '25%'}}
          />
          <Column
            body={actionBodyTemplate}
            className="actions"
            exportable={false}
            style={{width : '25%'}}
          />
        </DataTable>

        <Dialog
          blockScroll="true"
          className="linkDialog p-fluid"
          footer={link.id === null ? dialogFooter(hideNewLinkDialog, saveLink) : dialogFooter(hideEditLinkDialog, saveLink)}
          header={link.id === null ? 'New link' : 'Link details'}
          modal onHide={link.id === null ? hideNewLinkDialog : hideEditLinkDialog}
          visible={link.id === null ? openAddLinkModal : openEditLinkModal}
        >
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText
              autoFocus className={classNames({'p-invalid' : submitted && !link.name})} id="name"
              onChange={event => onInputChange(event, 'name')} required value={link.name}
            />
            {submitted && !link.name && <small className="p-error">Name is required.</small>}
          </div>
          <div className="field">
            <label htmlFor="url">URL</label>
            <InputText
              className={classNames({'p-invalid' : submitted && !link.url})} id="url"
              onChange={event => onInputChange(event, 'url')} required value={link.url}
            />
          </div>
          <div className="field">
            <label htmlFor="category">Category</label>
            <Dropdown
              className={classNames({'p-invalid' : submitted && !link.category})} id="category"
              onChange={event => onInputChange(event, 'category')} options={Object.values(props?.links).flatMap(item => item.category)} required
              value={link.category}
            />
            {submitted && !link.category && <small className="p-error">Category is required.</small>}
          </div>
          <div className="field">
            <label htmlFor="description">Description</label>
            <InputTextarea
              className={classNames({'p-invalid' : submitted && !link.description})} id="description"
              onChange={event => onInputChange(event, 'description')} required value={link.description}
            />
          </div>
          <div className="field">
            <label htmlFor="picture">Picture</label>
            <input
              onChange={handleImageChange} ref={fileInput} style={{display : 'none'}}
              type="file"
            />
            <div className="link-picture">
              {image ? (
                <img alt={link.name} src={URL.createObjectURL(image)} />
              ) : (
                <img alt={link.name} src={imageUrl} />
              )}
              <EditButton
                color="primary"
                disableRipple
                onClick={() => fileInput.current.click()}
                startIcon={<EditIcon />}
                variant="contained"
              >
                Edit
              </EditButton>
            </div>
          </div>
        </Dialog >

        <Dialog
          className="deleteDialog p-fluid"
          footer={dialogFooter(hideDeleteLinkDialog, deleteLink)} header="Confirm" modal
          onHide={hideDeleteLinkDialog} style={{width : '450px'}} visible={deleteLinkDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {link && <span>Are you sure you want to delete <b>{link.name}</b>?</span>}
          </div>
        </Dialog>

        <Dialog
          className="restoreDialog p-fluid"
          footer={dialogFooter(hideRestoreLinkDialog, restoreLink)} header="Confirm" modal
          onHide={hideRestoreLinkDialog} style={{width : '450px'}} visible={restoreLinkDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {link && <span>Are you sure you want to restore <b>{link.name}</b>?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

LinksTable.displayName = 'LinksTable';
LinksTable.propTypes = {
  capabilities      : array.isRequired,
  links             : object.isRequired,
  getLinks          : func.isRequired,
  createLink        : func.isRequired,
  updateLink        : func.isRequired,
  deleteLink        : func.isRequired,
  restoreLink       : func.isRequired,
  updateLinkPicture : func.isRequired
};
export default LinksTable;
