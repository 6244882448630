import Team from './Team';
import {connect} from 'react-redux';
import {getAllUsers, getUserCapabilities, getTeamLeader} from 'selector/user';
import {apiRequestAction} from 'action/apiResource';
import {getIsCallInProgress, getHasCallSucceeded} from 'selector/app';
import {getTeams} from '../../store/selector/team';
import {getUser, getProjectsTitle, getAllTeamLeads} from '../../store/selector/user';
import {getJobs} from 'selector/job';
import {getSearchInputValue} from '../../store/selector/searchInputValue';
import {getRoles} from '../../store/selector/role';


const mapStateToProps = state => ({
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.teamDetails.getTeamDetails.requestTracker
  }),
  searchInputValue     : getSearchInputValue(state),
  jobs                 : getJobs(state),
  users                : getAllUsers(state),
  teams                : getTeams(state),
  user                 : getUser(state),
  capabilities         : getUserCapabilities(state),
  teamLead             : getTeamLeader(state),
  roles                : getRoles(state),
  projects             : getProjectsTitle(state),
  deleteUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.deleteUser.requestTracker
  }),
  restoreUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.restoreUser.requestTracker
  }),
  addUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.addUser.requestTracker
  }),
  editUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.editUser.requestTracker
  }),
  teamLeads : getAllTeamLeads(state)
});

const mapDispatchToProps = {
  getAllUsers : apiRequestAction.user.getAllUsers({
    skipEntityIdCheck : true
  }),
  deleteUser : apiRequestAction.user.deleteUser({
    skipEntityIdCheck : true
  }),
  restoreUser : apiRequestAction.user.restoreUser({
    skipEntityIdCheck : true
  }),
  addUser : apiRequestAction.user.addUser({
    skipEntityIdCheck : true
  }),
  getTeams : apiRequestAction.team.getTeams({
    skipEntityIdCheck : true
  }),
  getTeamLeader : apiRequestAction.user.getTeamLeader({
    skipEntityIdCheck : true
  }),
  getJobs : apiRequestAction.job.getJobs({
    skipEntityIdCheck : true
  }),
  getUserCapabilities : apiRequestAction.user.getUserCapabilities({
    skipEntityIdCheck : true
  }),
  getRoles : apiRequestAction.role.getRoles({
    skipEntityIdCheck : true
  }),
  getProjectsTitle : apiRequestAction.user.getProjectsTitle({
    skipEntityIdCheck : true
  }),
  getTeamleads : apiRequestAction.user.getTeamleads()
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
