import {call} from 'redux-saga/effects';
import omit from 'lodash/omit';
import {toQueryString, joinPaths, isUrl} from 'service/utils';

export default class ReduxSagaMiddleware {
  constructor (baseUrl) {
    this.baseUrl = baseUrl;

    this.middlewares = [];
  }

  * applyMiddlewares (req) {
    const combinedMiddleware = this.middlewares.reduce((acc, next) => function* middleware (request) {
      return yield call(next, request, acc);
    }, fetch);

    return yield call(combinedMiddleware, req);
  }

  use (middleware) {
    this.middlewares.push(middleware);

    return this;
  }


  fetch (url, config) {
    let finalUrl = url;

    if (typeof url === 'string' && !isUrl(url) && this.baseUrl) {
      finalUrl = joinPaths(this.baseUrl, url);
    }

    return call(this.applyMiddlewares.bind(this), new Request(finalUrl, config));
  }

  request (url, method, params, payload, config = {}) { // eslint-disable-line
    let finalUrl = url;

    config.method = method;

    if (!Reflect.has(config, 'headers')) {
      config.headers = new Headers();
    }

    if (!config.headers.has('Content-Type')) {
      if (config.method === 'GET') {
        config.headers.set('Content-Type', 'text/plain;charset=UTF-8');
      } else if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(config.method) && !(payload instanceof FormData)) {
        config.headers.set('Content-Type', 'application/json');
      }
    }

    if (params && Object.keys(params).length) {
      finalUrl += `?${toQueryString(omit(params, ['entityId', 'uniqueId']))}`;
    }

    if (payload && !(payload instanceof FormData)) {
      config.body = JSON.stringify(payload);
    } else if (payload) {
      config.body = payload;
    }

    return this.fetch(finalUrl, config);
  }

  get (url, params, config) {
    return this.request(url, 'GET', params, null, config);
  }

  post (url, entity, config) {
    return this.request(url, 'POST', null, entity, config);
  }

  put (url, entity, config) {
    return this.request(url, 'PUT', null, entity, config);
  }

  patch (url, entity, config) {
    return this.request(url, 'PATCH', null, entity, config);
  }

  delete (url, params, config) {
    return this.request(url, 'DELETE', params, config);
  }
}
