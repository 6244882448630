import {createAction} from 'redux-actions';

const PREFIX = 'apiResponse::';

export const ActionTypes = {
  CLEAR_API_RESPONSE_ERRORS : `${PREFIX}CLEAR_API_RESPONSE_ERRORS`,
  CLEAR_API_DETAILS         : `${PREFIX}CLEAR_API_DETAILS`,
  APP_CLEANUP               : `${PREFIX}APP_CLEANUP`
};

export const clearApiResponseErrors = createAction(ActionTypes.CLEAR_API_RESPONSE_ERRORS);
export const clearApiResponseDetails = createAction(ActionTypes.CLEAR_API_DETAILS);

export const appCleanup = createAction(ActionTypes.APP_CLEANUP);
