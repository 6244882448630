import {createSelector} from 'reselect';
import get from 'lodash/get';

const holidaysSelector = state => state.holidays;

export const getDaysLeft = createSelector(
  [holidaysSelector],
  holidays => get(holidays, 'daysLeft', {})
);
export const getAllDaysLeft = createSelector(
  [holidaysSelector],
  holidays => get(holidays, 'allDaysLeft', {})
);
export const getAllHolidays = createSelector(
  [holidaysSelector],
  holidays => get(holidays, 'allHolidays', {})
);
