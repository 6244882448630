import {API_RESOURCE, API_METHOD, API_ENDPOINT, API_METHOD_TO_HTTP_METHOD_MAP} from 'constant';
import {reduce, camelCase, toUpper} from 'lodash';
import {apiRequest} from './api';

const CRUD_RESOURCE_API_ENDPOINT_MAP = {
};

const CUSTOM_RESOURCE_API_ENDPOINT_MAP = {
  [API_RESOURCE.AUTH]       : API_ENDPOINT.AUTH,
  [API_RESOURCE.USER]       : API_ENDPOINT.USER,
  [API_RESOURCE.OBJECTIVE]  : API_ENDPOINT.OBJECTIVE,
  [API_RESOURCE.KEY_RESULT] : API_ENDPOINT.KEY_RESULT,

  [API_RESOURCE.TEAM_DETAILS] : API_ENDPOINT.TEAM_DETAILS,
  [API_RESOURCE.STATISTICS]   : API_ENDPOINT.STATISTICS,
  [API_RESOURCE.EVALUATION]   : API_ENDPOINT.EVALUATION,
  [API_RESOURCE.LINK]         : API_ENDPOINT.LINK,
  [API_RESOURCE.TEAM]         : API_ENDPOINT.TEAM,
  [API_RESOURCE.ROLE]         : API_ENDPOINT.ROLE,
  [API_RESOURCE.JOB]          : API_ENDPOINT.JOB,
  [API_RESOURCE.SKILLS]       : API_ENDPOINT.SKILLS,
  [API_RESOURCE.TIMETRACKING] : API_ENDPOINT.TIMETRACKING,
  [API_RESOURCE.HOLIDAYS]     : API_ENDPOINT.HOLIDAYS
};

const ACTION_TYPE_SUFFIX = {
  SUCCESS : 'SUCCESS',
  FAILURE : 'FAILURE'
};

const DELIMITER = '_';

const throwIfMissing = param => {
  throw new Error(`Parameter ${param} is required`);
};

const buildActionType = ({
  resourceName = throwIfMissing('resourceName'),
  resourceMethod = throwIfMissing('resourceMethod'),
  isError = false
}) => [
  toUpper(resourceName),
  toUpper(resourceMethod),
  isError ? ACTION_TYPE_SUFFIX.FAILURE : ACTION_TYPE_SUFFIX.SUCCESS
].join(DELIMITER);

export const getNameMethodPair = ({
  resourceName = throwIfMissing('resourceName'),
  resourceMethod = throwIfMissing('resourceMethod')
}) => [
  toUpper(resourceName),
  toUpper(resourceMethod)
].join(DELIMITER);

const buildCustomActionCreators = () =>
  reduce(CUSTOM_RESOURCE_API_ENDPOINT_MAP, (acc, endpointsMap, resourceName) => {
    const resource = reduce(endpointsMap, (innerAcc, [url, method], actionName) => {
      const actionCreator = meta => apiRequest({
        url,
        resourceName   : actionName,
        method         : API_METHOD_TO_HTTP_METHOD_MAP[method],
        resourceMethod : method,
        ...meta
      });

      actionCreator.requestTracker = `${toUpper(actionName)}_${toUpper(method)}`;

      return {
        ...innerAcc,
        [camelCase(actionName)] : actionCreator
      };
    }, acc[resourceName] || {});

    return {
      ...acc,
      [resourceName] : resource
    };
  }, {});

const getResourceActionCreators = (url, resource) =>
  reduce(API_METHOD, (innerAcc, method) => {
    const actionCreator = meta => apiRequest({
      url,
      resourceName   : resource,
      method         : API_METHOD_TO_HTTP_METHOD_MAP[method],
      resourceMethod : method,
      ...meta
    });

    actionCreator.requestTracker = `${toUpper(resource)}_${toUpper(method)}`;

    return {
      ...innerAcc,
      [method] : actionCreator
    };
  }, {});

const buildStandardCRUDActionCreators = () =>
  reduce(CRUD_RESOURCE_API_ENDPOINT_MAP, (acc, url, resource) => ({
    ...acc,
    [resource] : getResourceActionCreators(url, resource)
  }), {});

const buildApiActionCreators = () => ({
  ...buildStandardCRUDActionCreators(),
  ...buildCustomActionCreators()
});

const apiRequestAction = buildApiActionCreators();


export {
  buildActionType,
  apiRequestAction,
  ACTION_TYPE_SUFFIX
};
