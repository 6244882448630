import React from 'react';
import './Statistics.scss';
import {func, object, bool, string} from 'prop-types';
import {StatisticsFilter, StatisticsTable, StatisticsCharts, Loader, StatisticsLoginTable} from 'components';
import moment from 'moment';

const Statistics = props => {
  const [filters, setFilters] = React.useState({team : props.isTeamLead && !props.isOwner ? props.currentUserTeam : 'all',
    job  : 'all',
    // eslint-disable-next-line no-magic-numbers
    from : moment().subtract(6, 'months')
      .format('yyyy-MM-DD'),
    to : moment().format('yyyy-MM-DD')});

  React.useEffect(() => {
    props.getStatisticsData({
      ...filters});
  }, [filters]);

  React.useEffect(() => {
    props.getStatisticsData({
      ...filters});
  }, []);

  return (
    <Loader isLoading={props.isLoading}>
      <StatisticsFilter
        filters={filters}
        isOwner={props.isOwner}
        setFilters={setFilters}
        teams={props.statisticsData.statistics ? Object.values(props.statisticsData.statistics.allTeams) : []}
      />
      <div className="statistics">
        <StatisticsCharts statisticsData={props.statisticsData} />

        <StatisticsTable statisticsData={props.statisticsData} />
        {props.statisticsData.statistics !== undefined && <StatisticsLoginTable statisticsData={props.statisticsData.statistics.byLogin} />}
      </div>
    </Loader>
  );
};

Statistics.displayName = 'Statistics';
Statistics.propTypes = {
  getStatisticsData : func.isRequired,
  statisticsData    : object.isRequired,
  isTeamLead        : bool.isRequired,
  isOwner           : bool.isRequired,
  isLoading         : bool.isRequired,
  currentUserTeam   : string.isRequired
};
export default Statistics;
