/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import {ROUTE_PATH} from 'constant';
import {object, string} from 'prop-types';
import {useHistory} from 'react-router';
import {ReactComponent as TeamLeaderIcon} from '../../assets/icons/team-leader-white-icon.svg';
import moment from 'moment';
import {getUserImageUrl} from '../../utils/user';
import './EmployeeCard.scss';

/**
 * This component represents each individual employee card from Super awesome team page
 */
const EmployeeCard = props => {
  const history = useHistory();

  /**
 * Calculates the time since an employee was hired
 * @returns {JSX}
 */
  const beingEmployee = hireDate => {
    const hireMoment = moment(hireDate);
    const currentMoment = moment();
    const years = currentMoment.diff(hireMoment, 'years');

    hireMoment.add(years, 'years');
    const months = currentMoment.diff(hireMoment, 'months');

    const sinceForever = 10;

    let timeString = '';

    if (years > sinceForever) {
      timeString = 'Forever';
    } else if (years === 0) {
      if (months === 0) {
        timeString = 'This month';
      } else {
        timeString = `${months} month${months > 1 ? 's' : ''}`;
      }
    } else {
      timeString = `${years} year${years > 1 ? 's' : ''} & ${months} month${months > 1 ? 's' : ''}`;
    }

    return (
      <p className="since">Since: {timeString}</p>
    );
  };

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const imageUrlTemp = getUserImageUrl(props.member);

    setImageUrl(imageUrlTemp);
  }, [props.member]);

  return (
    <div
      className="w-full flex flex-row sm:flex-column relative employee-card" onClick={() => {
        history.push({
          pathname : ROUTE_PATH.PROFILE + `/${props.member.id}`
        });
      }}
    >
      {props.member.role.name === 'Team lead' &&
      <div className="flex align-items-center justify-content-center absolute employee-card-teamLeader">
        <TeamLeaderIcon />
      </div>
      }
      <div className="w-full employee-image">
        <div className="w-full employee-img">
          <img alt={`${props.member.name}`} className="w-full" src={imageUrl} />
        </div>
      </div>
      <div className="flex flex-column user-info">
        <p className="name">{props.member.name}</p>
        <p className="job">{props.member.job ? props.member.job.name : 'Unknown job'}</p>
        <div className="since">
          {beingEmployee(props.member.joinDate)}
        </div>
      </div>
    </div>
  );
};

EmployeeCard.displayName = 'EmployeeCard';
EmployeeCard.propTypes = {
  member : object.isRequired,
  id     : string.isRequired
};
export default EmployeeCard;
