import {takeEvery, all, put} from 'redux-saga/effects';
import {buildActionType} from 'action/apiResource';
import {ActionTypes, requestTracker} from 'action/api';
import {REQUEST_STATUS} from 'constant';
import {getUniqId} from './api';

export const getNewAction = ({action, isError = false}) => {
  const {meta : {resourceName, resourceMethod}, payload} = action;
  const type = buildActionType({
    resourceName,
    resourceMethod,
    isError
  });

  return {
    type,
    payload,
    meta : action.meta
  };
};

export const apiResponse = function* apiResponse (action) {
  const {
    meta,
    payload : {
      originalPayload,
      originalPayload : {
        entityId
      } = {}
    } = {}
  } = action;
  const uniqueId = getUniqId(originalPayload);

  yield put(requestTracker({
    entityId,
    meta,
    status : REQUEST_STATUS.SUCCEEDED,
    uniqueId
  }));

  yield put(getNewAction({action}));
};

export const apiError = function* apiError (action) {
  const {
    meta,
    payload : {
      originalPayload,
      originalPayload : {
        entityId
      } = {}
    } = {}
  } = action;
  const uniqueId = getUniqId(originalPayload);

  yield put(requestTracker({
    entityId,
    meta,
    status : REQUEST_STATUS.FAILED,
    uniqueId
  }));

  yield put(getNewAction({
    action,
    isError : true
  }));
};

export default function* apiResponseSaga () {
  yield all([
    takeEvery(ActionTypes.API_REQUEST_SUCCESS, apiResponse),
    takeEvery(ActionTypes.API_REQUEST_FAILURE, apiError)
  ]);
}
