import {API_METHOD} from './apiMethod';
import nth from 'lodash/nth';
import reduce from 'lodash/reduce';
import toUpper from 'lodash/toUpper';
import isObject from 'lodash/isObject';

export const OBJECTIVE_ENTITY_ID = '{objectiveEntityId}';
export const ROLE_ENTITY_ID = '{roleEntityId}';
export const OBJECTIVE_ENTITY_ID_REGEX = /\{objectiveEntityId\}/gi;
export const ROLE_ENTITY_ID_REGEX = /\{roleId\}/gi;

const API_ENDPOINT_RAW = {
  AUTH : {
    LOGIN  : ['/auth/login', API_METHOD.CREATE],
    LOGOUT : ['/auth/logout', API_METHOD.CREATE]
  },
  USER : {
    GET_USER              : ['/user', API_METHOD.GET],
    UPDATE_USER           : ['/user', API_METHOD.UPDATE],
    GET_USER_PLANS        : ['/user/plans', API_METHOD.GET],
    GET_USER_DETAILS      : ['/user/plans', API_METHOD.CREATE],
    GET_TEAM_LEADER       : ['/user/team', API_METHOD.CREATE],
    ADD_USER              : ['/user', API_METHOD.CREATE],
    ADD_TEAM_USER         : ['/user', API_METHOD.PATCH],
    EDIT_USER             : ['/user', API_METHOD.UPDATE],
    GET_ALL_USERS         : ['/user', API_METHOD.GET],
    GET_TEAM_MEMBERS      : ['/user/team', API_METHOD.GET_ALL],
    DELETE_TEAM_MEMBER    : ['/user/team', API_METHOD.DELETE],
    INVITE_TEAM_MEMBER    : ['/user/invite-in-team', API_METHOD.CREATE],
    GET_JOB_TITLES        : ['/user/jobs', API_METHOD.GET],
    GET_TEAMS             : ['/user/teams', API_METHOD.CREATE],
    GET_USER_CAPABILITIES : ['/user/capabilities', API_METHOD.CREATE],
    DELETE_USER           : ['/user/remove-user', API_METHOD.UPDATE],
    RESTORE_USER          : ['/user/restore-user', API_METHOD.UPDATE],
    GET_TEAMLEADS         : ['/user/team-lead', API_METHOD.GET_ALL],
    UPDATE_USER_IMAGE     : ['/user/image', API_METHOD.CREATE],
    GET_PROJECTS_TITLE    : ['/user/projects', API_METHOD.GET],
    DOWNLOAD_FILE         : ['/user/download-opvn', API_METHOD.CREATE]
  },
  TIMETRACKING : {
    GET_REPORTS : ['/timetracking/reports', API_METHOD.GET_ALL]
  },
  HOLIDAYS : {
    GET_DAYS_LEFT     : ['/holidays//get-logged-user-holidays-left', API_METHOD.GET_ALL],
    GET_ALL_DAYS_LEFT : ['/holidays/get-all-holidays-left', API_METHOD.GET_ALL],
    GET_ALL_HOLIDAYS  : ['/holidays/get-all-holidays-events', API_METHOD.GET_ALL]
  },
  OBJECTIVE : {
    GET_OBJECTIVE               : ['/objective', API_METHOD.GET],
    GET_OBJECTIVES              : ['/objective', API_METHOD.GET_ALL],
    CREATE_OBJECTIVE            : ['/objective', API_METHOD.CREATE],
    EDIT_OBJECTIVE              : ['/objective', API_METHOD.UPDATE],
    DELETE_OBJECTIVE            : ['/objective', API_METHOD.DELETE],
    ADD_KEY_RESULT_TO_OBJECTIVE : ['/objective/{objectiveEntityId}', API_METHOD.CREATE]
  },
  ROLE : {
    GET_ROLES    : ['/role', API_METHOD.GET_ALL],
    CREATE_ROLE  : ['/role', API_METHOD.CREATE],
    UPDATE_ROLE  : ['/role', API_METHOD.UPDATE],
    DELETE_ROLE  : ['/role', API_METHOD.DELETE],
    RESTORE_ROLE : ['/role/restoreRole', API_METHOD.CREATE]
  },
  KEY_RESULT : {
    GET_KEY_RESULT    : ['/key-result', API_METHOD.GET],
    EDIT_KEY_RESULT   : ['/key-result', API_METHOD.UPDATE],
    DELETE_KEY_RESULT : ['/key-result', API_METHOD.DELETE]
  },
  STATISTICS : {
    GET_STATISTICS_DATA : ['/statistics', API_METHOD.GET]
  },
  TEAM_DETAILS : {
    GET_TEAM_DETAILS : ['/teamDetails', API_METHOD.GET],
    GET_TEAM_DETAIL  : ['/teamDetails/{userId}', API_METHOD.GET]
  },
  LINK : {
    GET_LINKS           : ['/link', API_METHOD.GET],
    CREATE_LINK         : ['/link', API_METHOD.CREATE],
    UPDATE_LINK         : ['/link', API_METHOD.UPDATE],
    DELETE_LINK         : ['/link/delete-link', API_METHOD.UPDATE],
    RESTORE_LINK        : ['/link/restore-link', API_METHOD.UPDATE],
    UPDATE_LINK_PICTURE : ['/link/image', API_METHOD.CREATE]
  },
  TEAM : {
    GET_TEAMS   : ['/teams', API_METHOD.GET],
    CREATE_TEAM : ['/teams', API_METHOD.CREATE],
    UPDATE_TEAM : ['/teams', API_METHOD.UPDATE],
    DELETE_TEAM : ['/teams/delete-team', API_METHOD.UPDATE]
  },
  JOB : {
    GET_JOBS   : ['/job', API_METHOD.GET],
    CREATE_JOB : ['/job', API_METHOD.CREATE],
    UPDATE_JOB : ['/job', API_METHOD.UPDATE],
    DELETE_JOB : ['/job/delete-job', API_METHOD.UPDATE]
  },
  SKILLS : {
    GET_SKILLS    : ['/skill', API_METHOD.GET],
    EDIT_SKILL    : ['/skill', API_METHOD.UPDATE],
    CREATE_SKILLS : ['/skill', API_METHOD.CREATE],
    DELETE_SKILL  : ['/skill/{linkId}', API_METHOD.DELETE]
  },
  EVALUATION : {
    GET_EVALUATION    : ['/employee-details/{userId}', API_METHOD.GET],
    GET_EVALUATIONS   : ['/employee-details', API_METHOD.GET_ALL],
    EDIT_EVALUATION   : ['/employee-details', API_METHOD.UPDATE],
    DELETE_EVALUATION : ['/employee-details', API_METHOD.DELETE],
    CREATE_EVALUATION : ['/employee-details', API_METHOD.CREATE]
  }
};

const API_ENDPOINT = reduce(API_ENDPOINT_RAW, (acc, endpointsMap, resource) => ({
  ...acc,
  [resource] : isObject(endpointsMap) ? reduce(endpointsMap, (innerAcc, values, key) => ({
    ...innerAcc,
    [key] : [...values, toUpper(key)]
  }), {}) : endpointsMap
}), {});

const METHOD_POSITION = 1;
const NAME_POSITION = 2;

const getParts = resource => ({
  resourceMethod : nth(resource, METHOD_POSITION),
  resourceName   : nth(resource, NAME_POSITION)
});

const getResourceName = resource => {
  const {resourceName} = getParts(resource);

  return resourceName;
};


export {
  getParts,
  getResourceName,
  API_ENDPOINT
};
