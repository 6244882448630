import {func, bool} from 'prop-types';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Button} from '@mui/material';
import {PlusOne} from '@mui/icons-material';


const INVITE_LAYOUT = {
  BUTTON   : 'button',
  LOADER   : 'loader',
  INACTIVE : 'inactive'
};

const Invite = ({onButtonClick, isInviteFinished}) => {
  const [elementToShow, changeElementToShow] = React.useState(INVITE_LAYOUT.BUTTON);

  const inviteFunction = () => {
    onButtonClick();
    changeElementToShow(INVITE_LAYOUT.LOADER);
  };

  React.useEffect(() => {
    if (isInviteFinished && elementToShow === INVITE_LAYOUT.LOADER) {
      changeElementToShow(INVITE_LAYOUT.INACTIVE);
    }
  }, [isInviteFinished]);

  switch (elementToShow) {
    case INVITE_LAYOUT.BUTTON:
      return (
        <Button
          onClick={() => inviteFunction()} size="small" startIcon={<PlusOne />}
          variant="outlined"
        >Invite</Button>);
    case INVITE_LAYOUT.LOADER:
      return (<CircularProgress color="warning" />);
    default:
      return <p>Invited</p>;
  }
};

Invite.displayName = 'Invite';
Invite.propTypes = {
  onButtonClick    : func.isRequired,
  isInviteFinished : bool.isRequired
};
export default Invite;
