import {connect} from 'react-redux';
import CompletionApproval from './CompletionApproval';
import {apiRequestAction} from 'action/apiResource';
import {getHasCallSucceeded, getIsCallInProgress, getHasCallFailed} from 'selector/app';

const mapStateToProps = state => ({
  editObjectiveSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }),
  editKeyResultSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  }),
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  }),
  isCallFailed : getHasCallFailed(state, {
    requestTracker : apiRequestAction.objective.editObjective.requestTracker
  }) || getHasCallFailed(state, {
    requestTracker : apiRequestAction.keyResult.editKeyResult.requestTracker
  })
});

const mapDispatchToProps = {
  editObjective : apiRequestAction.objective.editObjective(),
  editKeyResult : apiRequestAction.keyResult.editKeyResult()
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletionApproval);
