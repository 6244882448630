import UserModal from './UserModal';
import {connect} from 'react-redux';
import {getHasCallSucceeded} from 'selector/app';
import {apiRequestAction} from 'action/apiResource';
import {getUserCapabilities} from 'selector/user';
import {getTeams} from '../../store/selector/team';
import {getRoles} from '../../store/selector/role';
import {getJobs} from '../../store/selector/job';
import {getAllTeamLeads} from '../../store/selector/user';

const mapStateToProps = state => ({
  teamLeads          : getAllTeamLeads(state),
  capabilities       : getUserCapabilities(state),
  jobs               : getJobs(state),
  teams              : getTeams(state),
  roles              : getRoles(state),
  editUserSuccessful : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.editUser.requestTracker
  })
});

const mapDispatchToProps = {
  getTeamleads : apiRequestAction.user.getTeamleads(),
  editUser     : apiRequestAction.user.editUser({
    skipEntityIdCheck : true
  }),
  addUser : apiRequestAction.user.addUser({
    skipEntityIdCheck : true
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);

