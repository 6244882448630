import {createSelector} from 'reselect';
import get from 'lodash/get';

const requestTrackerSelector = (state, {requestTrackerError}) => requestTrackerError;

export const getApiResponse = state => state.apiResponse;

export const getApiResponseError = createSelector(
  [getApiResponse, requestTrackerSelector],
  (apiResponse, requestId) => get(apiResponse, requestId, [])
);
