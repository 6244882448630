import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_SKILLS = buildActionType(getParts(API_ENDPOINT.SKILLS.GET_SKILLS));

const setSkillsReducer = (state, {payload: {data : {skills}}}) => ({
  ...state,
  skills
});

const cleanupReducer = () => initialState;

const skillsReducer = handleActions({
  [GET_SKILLS]                 : setSkillsReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default skillsReducer;
