import {createSelector} from 'reselect';
import get from 'lodash/get';


const teamMembersSelector = state => state.teamMembers;

export const getTeamMembers = createSelector(
  [teamMembersSelector],
  teamMembers => get(teamMembers, 'teamMembers', [])
);
