import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const OBJECTIVES_GET_ALL_SUCCESS = buildActionType(getParts(API_ENDPOINT.OBJECTIVE.GET_OBJECTIVES));
const OBJECTIVE_GET_SUCCESS = buildActionType(getParts(API_ENDPOINT.OBJECTIVE.GET_OBJECTIVE));

const getSingleObjectiveReducer = (state, {payload : {data : {objective}}}) => ({
  ...state,
  objective
});

const getUsersObjectiveReducer = (state, {payload: {data: {items, total}}}) => ({
  ...state,
  items,
  total
});

const cleanupReducer = () => initialState;

const objectiveReducer = handleActions({
  [OBJECTIVES_GET_ALL_SUCCESS] : getUsersObjectiveReducer,
  [OBJECTIVE_GET_SUCCESS]      : getSingleObjectiveReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default objectiveReducer;
