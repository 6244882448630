import {connect} from 'react-redux';
import ActionBar from './ActionBar';
import {apiRequestAction} from 'action/apiResource';
import {getHasCallSucceeded} from 'selector/app';
import {isCurrentUserOwner, getJobs, getTeams, getUser} from 'selector/user';

const mapStateToProps = state => ({
  isOwner                    : isCurrentUserOwner(state),
  isTeamsManager             : getUser(state).isTeamsManager,
  createObjectiveSuccessfull : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.objective.createObjective.requestTracker
  }),
  jobs  : getJobs(state),
  teams : getTeams(state)
});

const mapDispatchToProps = {
  getObjectives : apiRequestAction.objective.getObjectives(),
  getTeams      : apiRequestAction.user.getTeams()
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
