import {connect} from 'react-redux';
import {getIsAuthenticated, getFirstLogin} from 'selector/auth';
import {clearApiResponseErrors} from 'action/apiResponse';
import PublicRoute from './PublicRoute';

const mapStateToProps = state => ({
  isAuthenticated : getIsAuthenticated(state),
  firstLogin      : getFirstLogin(state)
});

const mapDispatchToProps = {
  clearApiResponseErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
