const ACTION = {
  COMPLETE : 'Complete',
  ARCHIVE  : 'Archive',
  RESTORE  : 'Restore',
  DELETE   : 'Delete'
};

const STATUS = {
  IN_PROGRESS          : 'in progress',
  COMPLETED            : 'completed',
  ARCHIVED             : 'archived',
  DELETED              : 'deleted',
  WAITING_FOR_APPROVAL : 'waiting for approval'
};

const PRIORITY = {
  LOWEST  : 'lowest',
  LOW     : 'low',
  MEDIUM  : 'medium',
  HIGH    : 'high',
  HIGHEST : 'highest'
};

const CATEGORY = {
  PERSONAL_STUFF : 'Personal',
  TECHNOLOGIES   : 'Technology',
  SOFT_SKILLS    : 'Soft Skills',
  MANAGEMENT     : 'Management'
};

const SUBCATEGORY = [
  {
    categoryName : 'Technology',
    subcategory  : ['Development', 'Testing', 'Support', 'DevOps', 'Hardware', 'Trainings', 'Other']
  },
  {
    categoryName : 'Management',
    subcategory  : ['Projects', 'Team', 'Clients', 'Organization', 'Other']
  },
  {
    categoryName : 'Soft Skills',
    subcategory  : ['Communication', 'Flexibility', 'Leadership', 'Adaptability', 'Other']
  },
  {
    categoryName : 'Personal',
    subcategory  : ['Time management', 'Creativity', 'Hobby', 'Other']
  }
];

const ROLES = {
  USER          : 'User',
  ADMIN         : 'Admin',
  TEAM_LEAD     : 'Team Lead',
  TEAMS_MANAGER : 'Teams Manager',
  HR            : 'HR'
};

// eslint-disable-next-line no-magic-numbers
const PERCENTAGE = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export {
  ACTION,
  STATUS,
  PRIORITY,
  CATEGORY,
  SUBCATEGORY,
  PERCENTAGE,
  ROLES
};
