import React from 'react';
import {func, bool, string} from 'prop-types';
import {useFormik} from 'formik';
import {
  Button, IconButton, FormControl, InputAdornment,
  TextField
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import './LoginForm.scss';
import {ModalLogin} from 'components';
import * as yup from 'yup';

const LoginForm = props => { //eslint-disable-line
  const [username, setUsername] = React.useState(''); //eslint-disable-line

  const validationSchema = yup.object({
    username : yup.string().required('Username is required'),
    password : yup.string().required('Password is required')
  });
  const loginForm = useFormik({
    initialValues : {
      username : '',
      password : ''
    },
    validationSchema,

    onSubmit : values => {
      props.executeOnSubmit(values);
    }
  });

  const [values, setValues] = React.useState({
    showPassword : false
  });

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword : !values.showPassword
    });
  };

  const handleMouseDownPassword = event => event.preventDefault();

  return (
    <form onSubmit={event => {
      event.preventDefault();
      loginForm.handleSubmit(event);
    }}
    >
      <div className="container-input">
        <TextField
          error={loginForm.touched.username && Boolean(loginForm.errors.username)}
          fullWidth
          helperText={loginForm.touched.username && loginForm.errors.username}
          id="username"
          label="Username *"
          name="username"
          onChange={loginForm.handleChange}
          value={loginForm.values.username || username}
        />
      </div>
      <div className="container-input">
        <FormControl fullWidth variant="outlined">
          <TextField
            InputProps={{
              endAdornment :
              (<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>)
            }}
            error={loginForm.touched.password && Boolean(loginForm.errors.password)}
            helperText={loginForm.touched.password && loginForm.errors.password}
            id="password"
            label="Password *"
            name="password"
            onChange={loginForm.handleChange}
            type={values.showPassword ? 'text' : 'password'}
            value={loginForm.values.password}
          />
        </FormControl>
      </div>
      <div className="container-password">
        <a onClick={handleOpen}>Forgot / recover password</a>
        <Button color="primary" type="submit" variant="contained">Sign In</Button>

        <div className="login-error">
          <p>{props.errors}</p>
        </div>

        <ModalLogin
          handleClose={handleClose}
          openModal={openModal}
        />
      </div>
    </form>

  );
};

LoginForm.displayName = 'LoginForm';
LoginForm.propTypes = {
  executeOnSubmit : func.isRequired,
  iscallFailed    : bool.isRequired,
  errors          : string,
  fromLogin       : bool.isRequired
};

LoginForm.defaultProps = {
  errors : ''
};

export default LoginForm;
