import {handleActions} from 'redux-actions';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {buildActionType} from 'action/apiResource';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_ROLES = buildActionType(getParts(API_ENDPOINT.ROLE.GET_ROLES));

const setRolesReducer = (state, {payload : data}) => ({
  ...state,
  roles : data.data
});

const cleanupReducer = () => initialState;

const rolesReducer = handleActions({
  [GET_ROLES]                  : setRolesReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default rolesReducer;
