/* eslint-disable no-magic-numbers */
import React, {useState} from 'react';
import {Button} from 'primereact/button';

// eslint-disable-next-line react/display-name
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top      : 0,
      behavior : 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Button
      className="go-to-top-button"
      icon="pi pi-arrow-up"
      onClick={scrollToTop}
      style={{display : visible ? 'flex' : 'none'}}
    />
  );
};

export default ScrollButton;
