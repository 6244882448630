import './Login.scss';

import {Loader, LoginForm, Invite} from 'components';
import {bool, func, string, object, array, oneOfType} from 'prop-types';

import React from 'react';
import {ROUTE_PATH} from 'constant';
import {useHistory} from 'react-router-dom';
import map from 'lodash/map';
import filter from 'lodash/filter';
import {Button, Step, StepLabel, Stepper, TextField} from '@mui/material';


const Login = ({
  user,
  isLoading,
  teamMembers,
  isTeamLead,
  getTeamMembers,
  inviteTeamMember,
  ...props
}) => {
  let steps = [
    'Login',
    'Confirm your information'
  ];

  const teamLeadFinalStep = 'See team mates';

  const [activeStep, setActiveStep] = React.useState(0);

  const [showStepper, setShowStepper] = React.useState(false);

  const history = useHistory();

  const confirmInformation = () => {
    if (activeStep === 1 && !isTeamLead) {
      history.push(ROUTE_PATH.TEAM);
    }
    if (isTeamLead) {
      getTeamMembers();
    }
  };

  const finishRegister = () => {
    history.push(ROUTE_PATH.OBJECTIVES);
  };

  const handleInvite = email => {
    inviteTeamMember({
      teamMemberEmail : email
    });
  };

  React.useEffect(() => {
    if (props.firstLogin && props.hasGetTeamMembersSucceeded) {
      setActiveStep(activeStep + 1);
    }
  }, [props.hasGetTeamMembersSucceeded]);

  const checkDataLayout = () => {  //eslint-disable-line
    return (
      <div className="logged-in-container-center">
        <div className="logged-in-container-style">
          <TextField
            InputLabelProps={{shrink : true}}
            className="logged-in-input-style"
            disabled
            label="Name"
            value={user.name}
          />
        </div>
        <div className="logged-in-container-style">
          <TextField
            InputLabelProps={{shrink : true}}
            className="logged-in-input-style"
            disabled
            label="Email"
            value={user.email}
          />
        </div>
        <div className="logged-in-container-button">
          <Button color="primary" onClick={() => confirmInformation()} variant="contained">Continue</Button>
        </div>
      </div>
    );
  };

  const teamLeaderLayout = () => { //eslint-disable-line
    const pontentialMembers = filter(teamMembers, member => member.registered === false);

    return (
      <div className="logged-in-container-list">
        {map(pontentialMembers, member => (
          <div key={member}>
            <span className="user-name" >{member.name}</span>
            <Invite isInviteFinished={props.wasInviteSuccessful} onButtonClick={() => handleInvite(member.email)} />
          </div>
        ))}
        <Button color="primary" onClick={finishRegister} variant="contained">Continue</Button>
      </div>
    );
  };

  const loginLayout = () => { //eslint-disable-line
    return (
      <div className="container-center">
        <span className="title">Bitstone Hub</span>
        <img className="image-logo" src="/bitstone-logo.png" />
        <LoginForm
          errors={props.formSubmitErrors}
          executeOnSubmit={props.login}
          fromLogin
          iscallFailed={props.iscallFailed}
        />
      </div>
    );
  };

  const chooseLayout = () => {
    switch (activeStep) {
      case 0:
        return loginLayout();
      case 1:
        return checkDataLayout();
      case 2:
        return teamLeaderLayout();
      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (props.firstLogin === false) {
      history.push(ROUTE_PATH.OBJECTIVES);
    }
    if (props.firstLogin === true) {
      setActiveStep(activeStep + 1);
      setShowStepper(true);
    }
  }, [props.firstLogin]);


  const stepper = () => {
    if (showStepper === true) {
      return (
        <Stepper activeStep={activeStep} alternativeLabel className="logged-in-stepper-style">
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
          {isTeamLead && <Step key={teamLeadFinalStep}><StepLabel>{teamLeadFinalStep}</StepLabel></Step>}
        </Stepper>
      );
    }

    return null;
  };

  return (
    <Loader className="main-div" isLoading={isLoading}>
      {stepper()}
      {chooseLayout()}
    </Loader>
  );
};

Login.displayName = 'Login';
Login.propTypes = {
  isLoading               : bool.isRequired,
  login                   : func.isRequired,
  iscallFailed            : bool.isRequired,
  formSubmitErrors        : string.isRequired,
  hasLoggedInSuccessfully : bool.isRequired,
  firstLogin              : oneOfType([bool, string]).isRequired,
  isTeamLead              : bool.isRequired,
  user                    : object.isRequired,
  getTeamMembers          : func.isRequired,
  inviteTeamMember        : func.isRequired,
  teamMembers             : array.isRequired,

  hasGetTeamMembersSucceeded : bool.isRequired,
  wasInviteSuccessful        : bool.isRequired
};

export default Login;
