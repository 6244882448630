import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';
import CloseIcon from '@mui/icons-material/Close';
import {bool, func, string} from 'prop-types';
import './ConfirmationDialog.scss';
import {If} from 'components';
import {PERCENTAGE} from 'constant';

const ConfirmationDialog = ({
  open,
  handleClose,
  action,
  complete,
  deleteItem,
  model,
  updatePercentage
}) => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    onClose={handleClose}
    open={open}
  >
    <DialogTitle id="alert-dialog-title">
      {`${capitalize(action)} confirmation`}
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position : 'absolute',
            right    : 8,
            top      : 8,
            color    : theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {`Are you sure you want to ${toLower(action)} the selected ${model}?`}
      </DialogContentText>
      <If condition={toLower(action) === 'complete' && model === 'key result'}>
        <DialogContentText>
          {'Please chose your completion score (%): '}
        </DialogContentText>
      </If>
      <div className="confirmation-separator">
        <If condition={toLower(action) === 'complete' && model === 'key result'}>
          <Select defaultValue={PERCENTAGE[0]} onChange={event => updatePercentage(event.target.value)}>
            {PERCENTAGE.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
          </Select>
        </If>
      </div>
    </DialogContent>

    <DialogActions>
      <Button
        autoFocus
        color="primary"
        onClick={handleClose}
        variant="contained"
      >Cancel</Button>
      <Button
        color="secondary"
        onClick={() => {
          if (action === 'complete' || action === 'start') {
            complete();
          } else if (action === 'delete') {
            deleteItem();
          } else {
            complete(action);
          }
          handleClose();
        }}
        variant="contained"
      >{capitalize(action)}</Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.displayName = 'ConfirmationDialog';
ConfirmationDialog.propTypes = {
  open        : bool.isRequired,
  handleClose : func.isRequired,
  complete    : func.isRequired,
  deleteItem  : func.isRequired,
  action      : string,
  model       : string,

  updatePercentage : func
};

ConfirmationDialog.defaultProps = {
  action     : 'complete',
  model      : 'key result',
  open       : false,
  deleteItem : () => null,

  updatePercentage : () => null
};
export default ConfirmationDialog;
