import {handleActions} from 'redux-actions';
import {buildActionType} from 'action/apiResource';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};

const GET_DAYS_LEFT = buildActionType(getParts(API_ENDPOINT.HOLIDAYS.GET_DAYS_LEFT));
const GET_ALL_HOLIDAYS = buildActionType(getParts(API_ENDPOINT.HOLIDAYS.GET_ALL_HOLIDAYS));
const GET_ALL_DAYS_LEFT = buildActionType(getParts(API_ENDPOINT.HOLIDAYS.GET_ALL_DAYS_LEFT));

const setDaysLeftReducer = (state, {payload: {data : {holidays}}}) => ({
  ...state,
  daysLeft : holidays
});
const setAllDaysLeftReducer = (state, {payload: {data : {holidays}}}) => ({
  ...state,
  allDaysLeft : holidays
});
const setAllHolidaysReducer = (state, {payload: {data : {allHolidays}}}) => ({
  ...state,
  allHolidays
});

const cleanupReducer = () => initialState;

const timetrackingReducer = handleActions({
  [GET_DAYS_LEFT]              : setDaysLeftReducer,
  [GET_ALL_HOLIDAYS]           : setAllHolidaysReducer,
  [GET_ALL_DAYS_LEFT]          : setAllDaysLeftReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default timetrackingReducer;
