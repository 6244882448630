import {handleActions} from 'redux-actions';
import {ActionTypes as AppActionTypes} from 'action/apiResponse';
import {buildActionType} from 'action/apiResource';
import {getParts, API_ENDPOINT} from 'constant';

const initialState = {};
const SET_TEAM_MEMBERS = buildActionType(getParts(API_ENDPOINT.USER.GET_TEAM_MEMBERS));

const setTeamMembersReducer = (state, {payload: {data : {items}}}) => ({
  ...state,
  teamMembers : items
});
const cleanupReducer = () => initialState;

const teamMembersReducer = handleActions({
  [SET_TEAM_MEMBERS]           : setTeamMembersReducer,
  [AppActionTypes.APP_CLEANUP] : cleanupReducer
}, initialState);

export default teamMembersReducer;
