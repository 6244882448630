import {API_ENDPOINT, getParts} from 'constant';
import {buildActionType} from 'action/apiResource';
import {all, takeEvery, call, put} from 'redux-saga/effects';
import {setToken, setLoginFlag} from 'action/auth';
import {setUser} from 'action/user';
import {appCleanup} from 'action/apiResponse';
import LocalStorage from 'service/LocalStorage';

const LOGIN_SUCCESS = buildActionType(getParts(API_ENDPOINT.AUTH.LOGIN));
const LOGOUT_SUCCESS = buildActionType(getParts(API_ENDPOINT.AUTH.LOGOUT));

function* loginSuccessSaga (action) {
  const {payload: {data : {accessToken, user, firstLogin}}} = action;

  yield call(LocalStorage.set, 'accessToken', accessToken);
  yield call(LocalStorage.set, 'user', JSON.stringify(user));
  yield call(LocalStorage.set, 'firstLogin', JSON.stringify(firstLogin));

  yield put(setToken(accessToken));
  yield put(setUser(user));
  yield put(setLoginFlag(firstLogin));
}

function* restoreAccessToken () {
  try {
    const accessToken = yield call(LocalStorage.get, 'accessToken');
    const user = yield call(LocalStorage.get, 'user');
    const firstLogin = yield call(LocalStorage.get, 'firstLogin');

    if (accessToken) {
      yield put(setToken(accessToken));
    }
    if (user) {
      yield put(setUser(JSON.parse(user)));
    }
    if (firstLogin) {
      yield put(setLoginFlag(firstLogin));
    }
  } catch (ex) {
    console.log('Cannot restore access Token from storage'); //eslint-disable-line
  }
}

function* logoutSuccess () {
  try {
    yield put(appCleanup());
    yield LocalStorage.destroy();
  } catch (ex) {
    console.log('Cannot logout successfully'); //eslint-disable-line
  }
}

const manageAuthTokenSaga = function* manageAuthTokenSaga () {
  yield all([
    takeEvery(LOGIN_SUCCESS, loginSuccessSaga),
    takeEvery(LOGOUT_SUCCESS, logoutSuccess)
  ]);
};


export {
  manageAuthTokenSaga,
  restoreAccessToken
};
