import {connect} from 'react-redux';
import EmployeeDetails from './EmployeeDetails';
import {apiRequestAction} from 'action/apiResource';
import {getApiResponseError} from 'selector/apiResponse';
import {getUserDetails, isCurrentUserTeamLead, isCurrentUserOwner, getTeams, getUser} from 'selector/user';
import {getIsCallInProgress, getHasCallSucceeded} from 'selector/app';
import {getTeamMembers} from 'selector/teamMembers';
import map from 'lodash/map';

const mapStateToProps = state => ({
  userDetails      : getUserDetails(state),
  teams            : getTeams(state),
  evaluation       : state.evaluation.userEvaluations,
  isOwner          : isCurrentUserOwner(state),
  isTeamLead       : isCurrentUserTeamLead(state) || isCurrentUserOwner(state),
  isTeamsManager   : getUser(state).isTeamsManager,
  teamMembers      : getTeamMembers(state),
  deleteInProgress : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.evaluation.deleteEvaluation.requestTracker
  }),
  createInProgress : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.evaluation.createEvaluation.requestTracker
  }),
  editInProgress : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.evaluation.editEvaluation.requestTracker
  }),
  addTeamUserSuccess : getHasCallSucceeded(state, {
    requestTracker : apiRequestAction.user.addTeamUser.requestTracker
  }),
  addTeamError : map(getApiResponseError(state, {
    requestTrackerError : apiRequestAction.user.addTeamUser.requestTracker
  }), error => error.apiErrorMessage).join(', '),
  isLoading : getIsCallInProgress(state, {
    requestTracker : apiRequestAction.evaluation.getEvaluation.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.user.getTeamMembers.requestTracker
  }) || getIsCallInProgress(state, {
    requestTracker : apiRequestAction.user.addTeamUser.requestTracker
  })
});

const mapDispatchToProps = {
  getTeams         : apiRequestAction.user.getTeams(),
  getUserDetails   : apiRequestAction.user.getUserDetails(),
  editUser         : apiRequestAction.user.addTeamUser(),
  getEvaluation    : apiRequestAction.evaluation.getEvaluation(),
  getEvaluations   : apiRequestAction.evaluation.getEvaluations(),
  deleteEvaluation : apiRequestAction.evaluation.deleteEvaluation()
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
