import React from 'react';
import map from 'lodash/map';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {PUBLIC_ROUTES, PRIVATE_ROUTES} from './constant';
import {PublicRoute} from './PublicRoute';
import {PrivateRoute} from './PrivateRoute';

import {
  NotFound
} from 'pages';


const RouterProvider = () => (
  <Router>
    <Switch>
      {map(PUBLIC_ROUTES, (route, index) => {
        const routeProps = {
          key : `public${index}`,
          ...route
        };

        return <PublicRoute {...routeProps} />;
      })}
      {map(PRIVATE_ROUTES, (route, index) => {
        const privateRouteProps = {
          key : `private${index}`,
          ...route
        };

        return <PrivateRoute {...privateRouteProps} />;
      })}
      <Route component={NotFound} />
    </Switch>
  </Router>
);

RouterProvider.displayName = 'RouterProvider';
RouterProvider.propTypes = {
};

export default RouterProvider;
