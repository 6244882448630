import {useEffect, useState} from 'react';

export const useScroll = (maxHeightWithNoScroll, targetRef) => {
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = targetRef.current?.scrollTop || 0;
      const shouldHideElement = scrollTop > maxHeightWithNoScroll;

      setScrolled(shouldHideElement);
    };

    if (targetRef.current) {
      targetRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetRef.current) {
        targetRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [targetRef.current]);

  return scrolled;
};
